@use "sass:math";
@import 'utils/variables';
@import 'utils/mixins';
@import 'account-trainings';
@import 'fixes/marathon-fixes';
@import '../../../Belvg_Customer/web/css/source/icon-navigation';
@import '../../../Belvg_Nutrition/web/css/source/style';

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?r4x5qa");
  src: url("../fonts/icomoon.eot?r4x5qa#iefix") format("embedded-opentype"),
  url("../fonts/icomoon.woff2?r4x5qa") format("woff2"),
  url("../fonts/icomoon.ttf?r4x5qa") format("truetype"),
  url("../fonts/icomoon.woff?r4x5qa") format("woff"),
  url("../fonts/icomoon.svg?r4x5qa#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/open-sans-condensed-v14-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Condensed Light"), local("OpenSansCondensed-Light"), url("../fonts/open-sans-condensed-v14-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-condensed-v14-latin-300.woff2") format("woff2"), url("../fonts/open-sans-condensed-v14-latin-300.woff") format("woff"), url("../fonts/open-sans-condensed-v14-latin-300.ttf") format("truetype"), url("../fonts/open-sans-condensed-v14-latin-300.svg#OpenSansCondensed") format("svg");
  /* Legacy iOS */
}

/* open-sans-condensed-700 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/open-sans-condensed-v14-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Condensed Bold"), local("OpenSansCondensed-Bold"), url("../fonts/open-sans-condensed-v14-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-condensed-v14-latin-700.woff2") format("woff2"), url("../fonts/open-sans-condensed-v14-latin-700.woff") format("woff"), url("../fonts/open-sans-condensed-v14-latin-700.ttf") format("truetype"), url("../fonts/open-sans-condensed-v14-latin-700.svg#OpenSansCondensed") format("svg");
  /* Legacy iOS */
}

$color-green: #76B82A;
$color-red: #FB2A1A;
$color-blue: #29A9FC;
$color-blue-light: #70c4fa;
$color-blue-extra-light: #c8e9ff;
$color-orange: #ffa800;
$color-yellow-light: #fffdde;

$color-gray-light: #F0F2F4;
$color-gray-mid: #CED1D3;
$color-gray-dark: #263B47;
$color-gray-text: #777777;
$color-gray-fragebogen: #566B75;

$color-weiss-trans: rgba(255,255,255,0.8);
$color-schwarz-trans: rgba(0,0,0,0.7);

@mixin transition($content: all 0.3s ease) {
  -webkit-transition: $content;
  -moz-transition: $content;
  -ms-transition: $content;
  -o-transition: $content;
  transition: $content;
}

@mixin box-shadow {
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
}
@mixin border-radius($content: 5px) {
  border-radius: $content;
  -moz-border-radius: $content;
  -webkit-border-radius: $content;
}
@mixin animate-all-fast {
  transition: all 0.1s ease-out;
}
@mixin animate-all-med {
  transition: all 1s ease-out;
}
@mixin animate-all-slow {
  transition: all 2s ease-out;
}
@mixin boxsize($content: border-box) {
  -webkit-box-sizing: $content;
  -moz-box-sizing: $content;
  -ms-box-sizing: $content;
  -o-box-sizing: $content;
  box-sizing: $content;
}
@mixin icomoon-settings {
  text-align: center;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hideclass {
  opacity: 0;
}

* {
  margin: 0;
  padding: 0; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

html {
  height: 100%;
  position: relative;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: $color-gray-dark;
  background: $color-gray-light;
  min-height: 100%;
  line-height: 1.5em;
  position: relative;

  &.customer-account-create, &.customer-account-login, &.customer-account-forgotpassword, &.customer-account-createpassword, &.customer-account-logoutsuccess {
    height:100%;
  }
}

a.open-tp-day-enter {
  text-decoration:none;
}

h1 {
  color: $color-gray-mid;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 36px;
  padding: 60px 20px 40px 20px;
  line-height: 1.3em;
}

h2 {
  color: $color-gray-dark;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 24px;
  padding: 30px 0;

  &.example {
    width: 100%;
  }
}

h3 {
  font-size: 20px;
  font-weight: 300;
  padding: 15px 0;
}
p {
  padding-bottom: 15px;
}
strong {
  font-weight: 700;
}
.text-red {
  color: $color-red;

}
.text-big {
  font-size: 20px;
  line-height: 30px;
  padding: 5px 0;
}
a {
  color: $color-blue;
}


@media only screen and (max-width: 768px) {
  h1 {
    font-size: 24px;
  }
}

.row {
  margin-left: -12px;
  margin-right: -12px;
  padding-top: 2px;
  padding-bottom: 2px; }

.row:after {
  content: "";
  display: block;
  clear: both; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-12 {
  width: 100%; }

.col-xs-11 {
  width: 91.66667%; }

.col-xs-10 {
  width: 83.33333%; }

.col-xs-9 {
  width: 75%; }

.col-xs-8 {
  width: 66.66667%; }

.col-xs-7 {
  width: 58.33333%; }

.col-xs-6 {
  width: 50%; }

.col-xs-5 {
  width: 41.66667%; }

.col-xs-4 {
  width: 33.33333%; }

.col-xs-3 {
  width: 25%; }

.col-xs-2 {
  width: 16.66667%; }

.col-xs-1 {
  width: 8.33333%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-pull-11 {
  right: 91.66667%; }

.col-xs-pull-10 {
  right: 83.33333%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-8 {
  right: 66.66667%; }

.col-xs-pull-7 {
  right: 58.33333%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-5 {
  right: 41.66667%; }

.col-xs-pull-4 {
  right: 33.33333%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-2 {
  right: 16.66667%; }

.col-xs-pull-1 {
  right: 8.33333%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-push-11 {
  left: 91.66667%; }

.col-xs-push-10 {
  left: 83.33333%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-8 {
  left: 66.66667%; }

.col-xs-push-7 {
  left: 58.33333%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-5 {
  left: 41.66667%; }

.col-xs-push-4 {
  left: 33.33333%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-2 {
  left: 16.66667%; }

.col-xs-push-1 {
  left: 8.33333%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-offset-12 {
  margin-left: 100%; }

.col-xs-offset-11 {
  margin-left: 91.66667%; }

.col-xs-offset-10 {
  margin-left: 83.33333%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-8 {
  margin-left: 66.66667%; }

.col-xs-offset-7 {
  margin-left: 58.33333%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-5 {
  margin-left: 41.66667%; }

.col-xs-offset-4 {
  margin-left: 33.33333%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-2 {
  margin-left: 16.66667%; }

.col-xs-offset-1 {
  margin-left: 8.33333%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.text-xs-left {
  text-align: left; }

.text-xs-right {
  text-align: right; }

.text-xs-center {
  text-align: center; }

@media (max-width: 768px) {
  .col-xs-dont-show {
    display: none !important; } }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }

  .col-sm-12 {
    width: 100%; }

  .col-sm-11 {
    width: 91.66667%; }

  .col-sm-10 {
    width: 83.33333%; }

  .col-sm-9 {
    width: 75%; }

  .col-sm-8 {
    width: 66.66667%; }

  .col-sm-7 {
    width: 58.33333%; }

  .col-sm-6 {
    width: 50%; }

  .col-sm-5 {
    width: 41.66667%; }

  .col-sm-4 {
    width: 33.33333%; }

  .col-sm-3 {
    width: 25%; }

  .col-sm-2 {
    width: 16.66667%; }

  .col-sm-1 {
    width: 8.33333%; }

  .col-sm-pull-12 {
    right: 100%; }

  .col-sm-pull-11 {
    right: 91.66667%; }

  .col-sm-pull-10 {
    right: 83.33333%; }

  .col-sm-pull-9 {
    right: 75%; }

  .col-sm-pull-8 {
    right: 66.66667%; }

  .col-sm-pull-7 {
    right: 58.33333%; }

  .col-sm-pull-6 {
    right: 50%; }

  .col-sm-pull-5 {
    right: 41.66667%; }

  .col-sm-pull-4 {
    right: 33.33333%; }

  .col-sm-pull-3 {
    right: 25%; }

  .col-sm-pull-2 {
    right: 16.66667%; }

  .col-sm-pull-1 {
    right: 8.33333%; }

  .col-sm-pull-0 {
    right: auto; }

  .col-sm-push-12 {
    left: 100%; }

  .col-sm-push-11 {
    left: 91.66667%; }

  .col-sm-push-10 {
    left: 83.33333%; }

  .col-sm-push-9 {
    left: 75%; }

  .col-sm-push-8 {
    left: 66.66667%; }

  .col-sm-push-7 {
    left: 58.33333%; }

  .col-sm-push-6 {
    left: 50%; }

  .col-sm-push-5 {
    left: 41.66667%; }

  .col-sm-push-4 {
    left: 33.33333%; }

  .col-sm-push-3 {
    left: 25%; }

  .col-sm-push-2 {
    left: 16.66667%; }

  .col-sm-push-1 {
    left: 8.33333%; }

  .col-sm-push-0 {
    left: auto; }

  .col-sm-offset-12 {
    margin-left: 100%; }

  .col-sm-offset-11 {
    margin-left: 91.66667%; }

  .col-sm-offset-10 {
    margin-left: 83.33333%; }

  .col-sm-offset-9 {
    margin-left: 75%; }

  .col-sm-offset-8 {
    margin-left: 66.66667%; }

  .col-sm-offset-7 {
    margin-left: 58.33333%; }

  .col-sm-offset-6 {
    margin-left: 50%; }

  .col-sm-offset-5 {
    margin-left: 41.66667%; }

  .col-sm-offset-4 {
    margin-left: 33.33333%; }

  .col-sm-offset-3 {
    margin-left: 25%; }

  .col-sm-offset-2 {
    margin-left: 16.66667%; }

  .col-sm-offset-1 {
    margin-left: 8.33333%; }

  .col-sm-offset-0 {
    margin-left: 0; }

  .text-sm-left {
    text-align: left; }

  .text-sm-right {
    text-align: right; }

  .text-sm-center {
    text-align: center; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }

  .col-md-12 {
    width: 100%; }

  .col-md-11 {
    width: 91.66667%; }

  .col-md-10 {
    width: 83.33333%; }

  .col-md-9 {
    width: 75%; }

  .col-md-8 {
    width: 66.66667%; }

  .col-md-7 {
    width: 58.33333%; }

  .col-md-6 {
    width: 50%; }

  .col-md-5 {
    width: 41.66667%; }

  .col-md-4 {
    width: 33.33333%; }

  .col-md-3 {
    width: 25%; }

  .col-md-2 {
    width: 16.66667%; }

  .col-md-1 {
    width: 8.33333%; }

  .col-md-pull-12 {
    right: 100%; }

  .col-md-pull-11 {
    right: 91.66667%; }

  .col-md-pull-10 {
    right: 83.33333%; }

  .col-md-pull-9 {
    right: 75%; }

  .col-md-pull-8 {
    right: 66.66667%; }

  .col-md-pull-7 {
    right: 58.33333%; }

  .col-md-pull-6 {
    right: 50%; }

  .col-md-pull-5 {
    right: 41.66667%; }

  .col-md-pull-4 {
    right: 33.33333%; }

  .col-md-pull-3 {
    right: 25%; }

  .col-md-pull-2 {
    right: 16.66667%; }

  .col-md-pull-1 {
    right: 8.33333%; }

  .col-md-pull-0 {
    right: auto; }

  .col-md-push-12 {
    left: 100%; }

  .col-md-push-11 {
    left: 91.66667%; }

  .col-md-push-10 {
    left: 83.33333%; }

  .col-md-push-9 {
    left: 75%; }

  .col-md-push-8 {
    left: 66.66667%; }

  .col-md-push-7 {
    left: 58.33333%; }

  .col-md-push-6 {
    left: 50%; }

  .col-md-push-5 {
    left: 41.66667%; }

  .col-md-push-4 {
    left: 33.33333%; }

  .col-md-push-3 {
    left: 25%; }

  .col-md-push-2 {
    left: 16.66667%; }

  .col-md-push-1 {
    left: 8.33333%; }

  .col-md-push-0 {
    left: auto; }

  .col-md-offset-12 {
    margin-left: 100%; }

  .col-md-offset-11 {
    margin-left: 91.66667%; }

  .col-md-offset-10 {
    margin-left: 83.33333%; }

  .col-md-offset-9 {
    margin-left: 75%; }

  .col-md-offset-8 {
    margin-left: 66.66667%; }

  .col-md-offset-7 {
    margin-left: 58.33333%; }

  .col-md-offset-6 {
    margin-left: 50%; }

  .col-md-offset-5 {
    margin-left: 41.66667%; }

  .col-md-offset-4 {
    margin-left: 33.33333%; }

  .col-md-offset-3 {
    margin-left: 25%; }

  .col-md-offset-2 {
    margin-left: 16.66667%; }

  .col-md-offset-1 {
    margin-left: 8.33333%; }

  .col-md-offset-0 {
    margin-left: 0%; }

  .text-md-left {
    text-align: left; }

  .text-md-right {
    text-align: right; }

  .text-md-center {
    text-align: center; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }

  .col-lg-12 {
    width: 100%; }

  .col-lg-11 {
    width: 91.66667%; }

  .col-lg-10 {
    width: 83.33333%; }

  .col-lg-9 {
    width: 75%; }

  .col-lg-8 {
    width: 66.66667%; }

  .col-lg-7 {
    width: 58.33333%; }

  .col-lg-6 {
    width: 50%; }

  .col-lg-5 {
    width: 41.66667%; }

  .col-lg-4 {
    width: 33.33333%; }

  .col-lg-3 {
    width: 25%; }

  .col-lg-2 {
    width: 16.66667%; }

  .col-lg-1 {
    width: 8.33333%; }

  .col-lg-pull-12 {
    right: 100%; }

  .col-lg-pull-11 {
    right: 91.66667%; }

  .col-lg-pull-10 {
    right: 83.33333%; }

  .col-lg-pull-9 {
    right: 75%; }

  .col-lg-pull-8 {
    right: 66.66667%; }

  .col-lg-pull-7 {
    right: 58.33333%; }

  .col-lg-pull-6 {
    right: 50%; }

  .col-lg-pull-5 {
    right: 41.66667%; }

  .col-lg-pull-4 {
    right: 33.33333%; }

  .col-lg-pull-3 {
    right: 25%; }

  .col-lg-pull-2 {
    right: 16.66667%; }

  .col-lg-pull-1 {
    right: 8.33333%; }

  .col-lg-pull-0 {
    right: auto; }

  .col-lg-push-12 {
    left: 100%; }

  .col-lg-push-11 {
    left: 91.66667%; }

  .col-lg-push-10 {
    left: 83.33333%; }

  .col-lg-push-9 {
    left: 75%; }

  .col-lg-push-8 {
    left: 66.66667%; }

  .col-lg-push-7 {
    left: 58.33333%; }

  .col-lg-push-6 {
    left: 50%; }

  .col-lg-push-5 {
    left: 41.66667%; }

  .col-lg-push-4 {
    left: 33.33333%; }

  .col-lg-push-3 {
    left: 25%; }

  .col-lg-push-2 {
    left: 16.66667%; }

  .col-lg-push-1 {
    left: 8.33333%; }

  .col-lg-push-0 {
    left: auto; }

  .col-lg-offset-12 {
    margin-left: 100%; }

  .col-lg-offset-11 {
    margin-left: 91.66667%; }

  .col-lg-offset-10 {
    margin-left: 83.33333%; }

  .col-lg-offset-9 {
    margin-left: 75%; }

  .col-lg-offset-8 {
    margin-left: 66.66667%; }

  .col-lg-offset-7 {
    margin-left: 58.33333%; }

  .col-lg-offset-6 {
    margin-left: 50%; }

  .col-lg-offset-5 {
    margin-left: 41.66667%; }

  .col-lg-offset-4 {
    margin-left: 33.33333%; }

  .col-lg-offset-3 {
    margin-left: 25%; }

  .col-lg-offset-2 {
    margin-left: 16.66667%; }

  .col-lg-offset-1 {
    margin-left: 8.33333%; }

  .col-lg-offset-0 {
    margin-left: 0; }

  .text-lg-left {
    text-align: left; }

  .text-lg-right {
    text-align: right; }

  .text-lg-center {
    text-align: center; } }

/*Buttons-----------*/
.btn-wrapper {
  padding-top: 30px;

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}


.btn, .introjs-button {
  border-radius: 50px;
  border: 0;
  outline:0;
  font-size: 16px;
  padding: 0 25px;
  box-sizing: border-box;
  display: inline-block;
  margin: 2px;
  text-decoration: none;
  color: #fff;
  background: $color-blue;
  height: 34px;
  line-height: 34px;
  text-transform: uppercase;
  cursor: pointer;
  @include transition(all 0.1s ease-out);

  [class^="icon-"], [class*=" icon-"] {
    position: relative;
    left: -5px;
  }
  &.active {
    transform: scale(0.95) !important;
  }
  &:disabled {
    opacity: 0.1 !important;
  }
  &.btn-mini {
    font-size:15px;
  }
}

.btn-hidden {
  display:none;
}

.btn-icon-right {
  [class^="icon-"], [class*=" icon-"] {
    left: 5px;
  }
}
.btn-default {
  color: #fff;
  background: $color-blue;
}
.btn-red {
  color: #fff;
  background: $color-red;
}
.btn-icon {
  width: 34px;
  text-align: center;
  padding: 0;

  a {
    color:inherit;
    text-decoration:none;
  }

  [class^="icon-"], [class*=" icon-"] {
    left: 0 !important;
  }

  &.btn-small {
    width: 22px;
    padding: 0;
  }
}
a.btn-icon {
  line-height: 40px;
}
.btn-with-icon {
  position: relative;

  [class^="icon-"], [class*=" icon-"] {
    background: $color-gray-dark;
    color: #fff;
    border-radius: 50px;
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 0;
  }

  &.btn-small {
    padding-left: 30px;
    padding-right: 15px;

    [class^="icon-"], [class*=" icon-"] {
      width: 24px;
      height: 24px;
      line-height: 25px;
      text-align: center;
      box-sizing: border-box;
      padding-left: 2px;
    }
  }
}
.btn-100 {
  width: 100%;
}
.btn-80 {
  width: 80%;
}
.btn-left {
  float: left;
}
.btn-right {
  float: right;
}
.btn-icon-top {
  height: auto;
  text-align: center;
  border-radius: 17px;
  line-height: 18px;
  padding: 10px;

  span {
    display: block;
    font-size: 24px;
    padding-bottom: 10px;
    left: 0 !important;
    color: $color-blue !important;
  }
}
.btn-icon-top.btn-default {
  span {
    color: #fff !important;
  }
}
.btn-small ,
.introjs-button {
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
}
.btn-gray {
  color: $color-gray-dark !important;
  background: $color-gray-mid;
}
/*
.diary-wrapper {
  .btn-gray {
    background: rgba(0, 0, 0, 0.1);
  }
}
*/
.btn-white {
  background: #fff;
  color: $color-blue;
  font-family: "Open Sans", sans-serif;

  &.btn-outline {
    border: 1px solid #fff !important;
    color: #fff !important;
    background: transparent !important;
  }
}
.btn-dark {
  background: $color-gray-dark;
  color: #fff;
}
.btn-dark.btn-outline {
  background: none;
  border: 2px solid $color-gray-dark;
  color: $color-gray-dark;
}
.btn-white.btn-outline {
  border: 1px solid #fff !important;
  color: #fff !important;
  background: transparent !important;

}
.btn-trans {
  background: transparent;
}

.btn-outline {
  background: none;
  color: $color-blue;
  box-shadow: inset 0 0 0 2px $color-blue;
}


.btn-group {
  display: inline-block;

  .btn {
    display: block;
    float: left;
    margin: 0;
    border-radius: 0;
    margin-right: 1px;

    &:first-child {
      border-radius: 30px 0 0 30px;
    }
    &:last-child {
      border-radius: 0 30px 30px 0;
      margin-right: 0;
    }
  }
  .btn-small {
    padding-left: 15px;
    padding-right: 15px;
  }
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}
.btn-nav {
  .btn {
    opacity: 1;
    margin-right: 0;
    margin-left: -1px;
    border: 1px solid $color-blue;
    background: #fff;
    color: $color-blue !important;
    line-height: 12px;

    &.active {
      opacity: 1;
      background: $color-blue;
      color: #fff !important;
    }
  }
  .btn-gray {
    border: 1px solid $color-gray-mid;
    color: $color-gray-dark !important;

    &.active {
      background: $color-gray-mid;
      color: $color-gray-dark !important;
    }
  }
}

[data-action='show_pdf'] {
  display:none;

  &.active {
    display:inline-block;
  }
}

.btn-group-row-edit {
  float: right;
  padding: 0 5px;
  position: relative;
  top: -2px;
  margin-bottom: 0;

  .btn {
    margin-right: -7px;
    background: transparent !important;
    display: inline-block !important;
    color: $color-blue !important;
  }
}

.switch-btns {
  background: $color-gray-light;
  @include border-radius;
  padding: 2px 0 2px 2px;
  font-size: 12px;
  color: #000;
  display: inline-block;

  a {
    display: block;
    float: left;
    text-decoration: none;
    color: #000;
    @include border-radius;
    padding: 1px 20px;
    position: relative;
    margin-right: 2px;

    span {
      position: relative;
      z-index: 1;
    }

    &:after {
      content: "";
      display: block;
      background: #fff;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      @include border-radius;
      transform: scale(0);
      @include animate-all-fast;
    }

    &.active,
    &:hover {
      &:after {
        transform: scale(1);
      }
    }
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  &.switch-btns-white {
    background: #fff;
    a {
      &:after {
        background: $color-gray-light;
      }
    }
  }
}


.print-btn {
  text-decoration: none;
  color: $color-gray-mid;
  font-size: 30px;
  @include animate-all-fast;
}


.desktop {
  .btn:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
  .btn-small:hover {
    transform: scale(1.05);
  }
  .btn-nav {
    .btn:hover {
      opacity: 1;
    }
  }
  .btn-group {
    .btn:hover {
      transform: scale(1);
    }
  }
  .page-functions-btn:hover {
    color: $color-gray-dark;
  }
  .print-btn:hover {
    color: $color-gray-dark;
  }
  .btn-group-row-edit {
    .btn:hover {
      transform: scale(1.7);
    }
  }
}

@media only screen and (max-width: 768px) {
  .btn-group {
    .btn {
    }
    .btn-small {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
    }
  }

  .btn-icon {
    width: 190px;
  }
}

.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
}

.no-js .inputfile + label {
  display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  /* pointer-events: none; */
  /* in case of FastClick lib use */
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  /* 4px */
  margin-right: 0.25em;
  /* 4px */
}

label.btn {

  text-transform: none;

  > div {
    display: inline-block;
  }
}

/*Forms------------*/

.form-error {
  font-size: 12px;
  background: $color-red;
  color: #fff;
  padding: 4px 6px;
  @include border-radius;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin: 0 3px;
  line-height: 14px !important;
}
.password-wrapper {
  width: calc(100%);
  margin: 0;
  padding: 0;
  position: relative;

  .password-status {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    width: 50px;
    height: 43px;
    line-height: 43px;
    color: #fff;
    @include border-radius(0 5px 5px 0);
    border-left: 1px solid #fff;
    z-index:1;

    &.password-status-1 {
      background: $color-red;
      &:before {
        content: "schwach"
      }
    }
    &.password-status-2 {
      background: $color-orange;
      &:before {
        content: "mittel"
      }
    }
    &.password-status-3 {
      background: $color-green;
      &:before {
        content: "stark";
      }
    }
  }

  input {
    width: 100%;
    box-sizing: border-box;
    padding-right: 55px !important;
  }
}
.custom-checkbox,
.custom-radio {
  input {
    display: none;
  }
}
.custom-checkbox,
.custom-radio {
  label {
    display: block;
    border-radius: 30px;
    padding: 3px 5px 3px 30px !important;
    text-align: left;
    position: relative;
    color: $color-blue;
    @include animate-all-med;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      left: 0px;
      top: 50%;
      margin-top: -10px;
      position: absolute;
      background: #fff;
    }
    &:after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      background: $color-blue;
      border-radius: 20px;
      top: 50%;
      margin-top: -5px;
      left: 5px;
      opacity: 0;
      transform: scale(2);
      @include animate-all-fast;
    }
  }
  .checked {
    color: #fff;


    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.custom-checkbox {
  label {
    color: #fff;

    &:before {
      @include border-radius;
    }
    &:after {
      @include icomoon-settings;
      content: "\edfb";
      background: transparent;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      color: $color-blue;
      left: 0;
      margin-top: -10px;
    }
  }
}
.custum-input-wrapper {
  position: relative;
  text-align: left;
  display: block;
  padding: 5px 0;
  display: flex;
  justify-content: space-around;
}

.custum-input-wrapper {

}

.day-choose {
  width: 100%; }
.day-choose .custum-input-wrapper {
  padding: 2px 0; }
.day-choose .custom-checkbox {
  width: 100%;
}
.day-choose label {
  width: 100%;
  box-sizing: border-box;
  background: #29A9FC;
  padding-left: 40px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important; }
.day-choose label:before {
  left: 10px; }
.day-choose label:after {
  opacity: 1;
  transform: scale(1);
  left: 11px;
  content: ""; }
.day-choose .checked {
  background: #FB2A1A; }
.day-choose .checked:after {
  content: "\edfc";
  color: #FB2A1A; }


.page-functions {
  position: absolute;
  right: 20px;
  top: 60px;
  z-index: 20;

  a {
    position: relative;

    &.active {
      &:before {
        content: "\edfb";
        @include icomoon-settings;
        background: $color-green;
        border-radius: 50%;
        font-size: 10px;
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        line-height: 16px;
        text-align: center;
        right: 5px;
        top: -3px;
        color: #fff !important;
      }
    }
  }
}
.page-functions-left {
  position: absolute;
  left: 20px;
  top: 58px;
  z-index: 20;
}
.add-training {
  text-align: center;
  padding-top: 15px;
  .btn-icon {
    width: auto !important;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 768px) {
  .page-functions-left {
    top: 8px;

    .btn-icon {
      width: auto !important;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
@media screen and (max-width: 768px) {
  .page-functions {
    position: relative;
    right: 0;
    top: -30px;
    text-align: right;
    padding-right: 20px;
  }
}
.page-functions-btn {
  text-decoration: none;
  color: $color-gray-mid;
  font-size: 30px;
  @include animate-all-fast;
  margin-left: 10px;
}
.last-check {
  position: absolute;
  color: $color-gray-mid;
  font-weight: 700;
  font-size: 14px;
  top: 57px;
  right: 160px;
  line-height: 18px;
  text-align: right;
  z-index: 20;

  span {
    font-weight: 300;
    color: $color-gray-dark;
    display: block;
  }
}
.mobile {
  .page-functions{
    display: none;
  }
  .last-check {
    right: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .page-functions,
  .last-check {
    top: 10px;
  }
}




/*Accorion-Trigger--------*/

.accordion-trigger {
  cursor: pointer;
  position: relative;
  text-decoration: underline;
  font-size: 14px;
  padding-left: 15px;

  &:after {
    @include icomoon-settings;
    content: "\ee04";
    display: inline-block;
    margin-left: 5px;
  }
  &.closed {

    .accordion-content {
      display: none;
    }
  }

  &.open {
    .accordion-trigger {
      &:after {
        content: "\ee03";
      }
    }
  }
}




.cd-main-content {
  position:relative;
}

.add-banner {
  margin-bottom: 20px;
  img {
    width: 100% !important;
    height: auto !important;
    @include border-radius;
    @include box-shadow;
  }
}

/*Panels-----------*/
.panel {
  @include border-radius;
  padding: 15px;

  hr {
    border: 0;
    border-top: 1px solid $color-gray-light;
    margin: 20px -15px;
  }
}
.panel-white {
  background: #fff;
}
.panel-shadow {
  @include box-shadow;
}

.date-nav {
  font-size: 14px;
  padding: 5px 0;
}
.date-nav-wrapper {
  text-align: center;
  padding: 0 15px 30px 15px;
  margin-top: -40px;
}


.video-expand {
  margin-left: -20px;
  width: calc(100% + 40px);
}


.responsive-video {
  position: relative;
  padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
  padding-top: 0;
  height: 0;
  overflow: hidden;

  .responsive-video-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000 no-repeat center url("../images/LT-Signet.svg");
    background-size: contain;
    cursor: pointer;
  }
  iframe.plays + .responsive-video-overlay {
    display: none;
  }

  .responsive-video-overlay:before {
    display: block;
    position: absolute;
    z-index: 101;
    width: 64px;
    height: 64px;
    top:0;
    left:0;
    bottom:0;
    right: 0;
    content: '';
    margin: auto;
    background: no-repeat center url("../images/video-play-64.png");
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}



.video-link {
  overflow: hidden;
  @include border-radius;
  position: relative;
  text-decoration: none;
  color: #fff;
  display: block;
  max-width: 250px;

  img {
    width: 100% !important;
    height: auto !important;
    margin-bottom: -7px;
    @include animate-all-slow;
  }

  span {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: #fff;
    color: $color-gray-dark;
    line-height: 50px;
    text-align: center;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    font-size: 20px;
    box-sizing: border-box;
    padding-left: 2px;
    @include animate-all-fast;
    z-index: 10;
  }
}

.desktop {
  .video-link:hover {
    overflow: hidden;

    img {
      transform: scale(1.1);
    }
    span {
      transform: scale(1.1);
    }
  }
}

.start-popup-list {
  list-style-type: none;
  padding: 2px 0;
  margin-top: 15px;
  //@include border-radius;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#29a9fc+2,70c4fa+50,29a9fc+98 */
  background: rgb(41,169,252); /* Old browsers */
  background: -moz-linear-gradient(left, rgba(41,169,252,1) 2%, rgba(112,196,250,1) 50%, rgba(41,169,252,1) 98%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(41,169,252,1) 2%,rgba(112,196,250,1) 50%,rgba(41,169,252,1) 98%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(41,169,252,1) 2%,rgba(112,196,250,1) 50%,rgba(41,169,252,1) 98%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29a9fc', endColorstr='#29a9fc',GradientType=1 ); /* IE6-9 */

  li {
    //background: $color-gray-dark;
    padding: 0 10px 26px 10px;
    //text-transform: uppercase;
    // border: 1px dashed rgba(0,0,0,0.2);
    margin-top: 2px;
    background: $color-blue;

    &:first-child {
      margin-top: 0;
      //border-radius: 4px 4px 0 0;
    }
    &:last-child {
      //border-radius: 0 0 4px 4px;
    }

    strong {
      border: 2px solid $color-blue-light;
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      line-height: 40px;
      font-size: 20px;
      margin-bottom: -14px;
      font-weight: 700;
      // @include box-shadow;
      position: relative;
      top: -22px;
      background: $color-blue;

      &:after {
        width: 0;
        height: 0;
        position: absolute;
        content: "";
        display: block;
        border: 6px solid transparent;
        border-top: 6px solid $color-blue-light;
        left: 50%;
        margin-left: -6px;
        bottom: -12px;
      }
    }
  }
}



.popup {
  max-width: 700px;
  position: relative;
  margin: 0 auto;
  background: #fff;
  @include border-radius;

  header,
  footer,
  section {
    padding: 10px;
    text-align: center;
    position: relative;

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    h3 {
      padding: 8px 0;
    }
    hr {
      border: 0;
      border-top: 1px solid rgba(255,255,255,0.3);
      height: 0;
      width: calc(100% + 40px);
      margin: 15px -10px 15px -10px;
    }
  }
  footer {
    border-radius: 0 0 5px 5px;
    min-height: 25px;
  }
  header {
    border-radius: 5px 5px 0 0;
    min-height: 25px;
  }
}

.popup-award {
  width: 300px;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  position: relative;
  //color: $color-blue;

  h2 {
    color: #fff;
    padding-bottom: 5px;
    font-weight: 700;
    //color: $color-blue;
  }
  .mfp-close {
    display: none;
  }
}
.mfp-award {
  .mfp-container {
    overflow: hidden !important;
  }
}



@function posOrNeg(){
  @return random() * 2 - 1;
}


$total: 400;
$size: 25;

.popup-award {
  .glow-wrapper {
    position: absolute;
    transform-style: preserve-3d;
    perspective: 300px;
    width: 300px;
    height: 200px;
  }
  img {
    position: relative;
  }
  .glow1 {
    width: 100px;
    height: 100px;
    border-radius: 200px;
    background: $color-blue;
    box-shadow: 0 0 200px 200px $color-blue;
  }
  .glow2 {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 100px;
    top: 50px;
  }
  .award-glow-wrapper {
    position: relative;
  }
}

.c {
  position: absolute;
  width: $size+px;
  height: $size+px;
  margin-top: math.div(-$size,2)+px;
  margin-left: math.div(-$size, 2)+px;
  transform: translate3d(150px,100px, -1000px);
  border-radius: 50%;
}

@for $i from 1 through $total {
  $color: hsl(($i * .1)+204, 97%, 57%);

  .c:nth-child(#{$i}){
    animation: anim#{$i} 1.5s infinite alternate;
    animation-delay: $i * -.015s;
    background: $color;
    background: radial-gradient(circle at top left, lighten($color, 15%), $color);
    box-shadow: 0 0 25px 3px lighten($color, 5%);
    border: 1px solid $color;
  }
  @keyframes anim#{$i}{
    60% {
      opacity: 1;
    }
    100% {
      transform: translate3d(random(300)+px, random(200)+px, 0);
      opacity: 0;
    }
  }
}



/* overlay at start */
.mfp-award.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
/* overlay animate in */
.mfp-award.mfp-bg.mfp-ready {
  opacity: 0.9;
}
/* overlay animate out */
.mfp-award.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-award.mfp-wrap .mfp-content {



  img {
    transform: scale(5);
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    transition-delay: 0.5s;
  }
  .glow1 {
    transform: scale(0);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    transition-delay: 0.7s;
  }
  .glow2 {
    transform: scale(1);
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    transition-delay: 0.9s;
  }
  .glow-wrapper {
    opacity: 0;
    -webkit-transition: all 0s ease-out;
    -moz-transition: all 0s ease-out;
    transition: all 0s ease-out;
    transition-delay: 0.9s;
  }
  .text01 {
    transform: scale(2);
    opacity: 0;
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    transition: all 1s ease-out;
    transition-delay: 1.5s;
  }
  .text02 {
    transform: scale(2);
    opacity: 0;
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    transition: all 1s ease-out;
    transition-delay: 1.8s;
  }

}
/* content animate it */
.mfp-award.mfp-wrap.mfp-ready .mfp-content {

  img {
    transform: scale(1);
    opacity: 1;
  }
  .glow1 {
    transform: scale(1);
  }
  .glow2 {
    transform: scale(0);
  }

  .glow-wrapper {
    opacity: 1;
  }
  .text01,
  .text02 {
    opacity: 1;
    transform: scale(1);
  }
}
/* content animate out */
.mfp-award.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.page-functions {
  position: absolute;
  right: 20px;
  top: 60px;
  z-index:20;

  .page-functions-btn {
    text-decoration: none;
    color: #CED1D3;
    font-size: 30px;
    transition: all 0.1s ease-out;
    margin-left: 10px;
  }
}
.last-check {
  position: absolute;
  color: $color-gray-mid;
  font-weight: 700;
  font-size: 14px;
  top: 57px;
  right: 110px;
  line-height: 18px;
  text-align: right;
  z-index:20;

  span {
    font-weight: 300;
    color: $color-gray-dark;
    display: block;
  }
}
.mobile {
  page-functions{
    display: none;
  }
  .last-check {
    right: auto;
    left: 20px;
    text-align: left;
  }
  .btn-mini {
    font-size:14px;
  }
}

@media only screen and (min-width: 768px) {
  .page-functions,
  .last-check {
    top: 10px;
  }
}



/*Trainingsplan--------------*/


.marker-own-training {
  font-family: 'Open Sans';
  border-radius: 20px;
  display: inline-block;
  border: 1px solid rgba(0,0,0,1);
  font-size: 10px;
  color: rgba(0,0,0,1);
  padding: 2px 10px 0 10px;
  width: 60px;
  margin-bottom: -5px;
  margin-top: -5px;
  top: 4px;
  text-align: center;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  position: relative;
  height: 22px;
  transform: rotate(-5deg);
}

.test-popup {
  display:inline-block;
  position: absolute;
  z-index: 10;
  width: 316px;
  left: 50%;
  margin-left: -158px;
  text-align: center;
  @include box-shadow;
  @include border-radius;
  padding: 10px;
  top: 50px;
  background:#FFFFFF;
}
.training-panel .test-popup {
  top:40%;
}

.tp-day,
.diary-wrapper {
  background: #fff;
  @include border-radius;
  @include box-shadow;
  padding: 0;
  margin-bottom: 5px;

  a {
    color: $color-blue;
  }

  .tp-day-header {
    border-bottom: 1px solid $color-gray-light;

    .tp-day-day {
      font-size: 20px;
      font-weight: 700;
      padding: 30px 0 30px 8px;
      text-align: center;
      width: 60px;
      display: inline-block;
      vertical-align: middle;
      box-sizing: border-box;
    }
    .tp-day-title {
      font-size: 20px;
      font-weight: 300;
      display: inline-block;
      width: calc(100% - 160px);
      vertical-align: middle;
      box-sizing: border-box;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .tp-day-date {
      font-size: 14px;
      display: inline-block;
      width: 85px;
      vertical-align: middle;
      text-align: center;
    }
  }

  .tp-exercises {
    position: relative;

    .tp-exercise-item {
      border-bottom: 1px solid $color-gray-light;
      padding: 8px 15px 9px 0;
      box-sizing: border-box;
      overflow: hidden;


      &.detailed-trigger {
        padding-left: 20px;
      }
      .tp-exercise-short {
        padding-left: 15px;

        a {
          color: #000000 !important;
          text-transform: none;
          margin-left: 10px;
          height: 28px;
          line-height: 28px;
          font-size: 14px;
        }
        a.btn {
          background: #cdd0d2;
        }
      }
      .tp-exercise-detailed {
        position: relative;
        padding-left: 40px;

        .video-link {
          margin-top: 7px;
        }

        &:before {
          content: "1";
          display: block;
          width: 25px;
          height: 30px;
          border-radius: 0 30px 30px 0;
          background: $color-gray-light;
          position: absolute;
          left: 5px;
          top: 50%;
          margin-top: -15px;
          text-align: center;
          color: $color-blue;
          line-height: 30px;
          box-sizing: border-box;
          padding-right: 4px;
          @include animate-all-fast;

        }

      }
      .closed {
        display: none;
      }

      &:nth-child(2) .tp-exercise-detailed:before {
        content: "2";
      }
      &:nth-child(3) .tp-exercise-detailed:before {
        content: "3";
      }
      &:nth-child(4) .tp-exercise-detailed:before {
        content: "4";
      }
      &:nth-child(5) .tp-exercise-detailed:before {
        content: "5";
      }
      &:nth-child(6) .tp-exercise-detailed:before {
        content: "6";
      }
      &:nth-child(7) .tp-exercise-detailed:before {
        content: "7";
      }

      &.tp-exercise-item-competition {
        position: relative;
        padding-left: 30px;

        .icon-trophy1 {
          position: absolute;
          left: 10px;
          top: 50%;
          margin-top: -15px;
          font-size: 18px;
          display: inline-block;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          border-radius: 30px;
        }
        .text-light {
          opacity: 0.5;
        }
        .tp-exercise-item-competition-detail {
          display: inline-block;
          padding-right: 10px;
        }
      }

      &.tp-exercise-item-maincompetition {
        .icon-trophy1 {
          background: $color-gray-dark;
          color: #fff;
        }
      }
    }
    &:before {
      content: "";
      display: block;
      width: 5px;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
    }

  }
  .tp-day-footer {
    padding: 10px;
  }


  &.intensity-1 {
    a,
    .tp-day-day,
    .tp-day-date,
    .tp-exercises .accordion-trigger {
      color: $color-blue;
    }
    .tp-exercises:before,
    .tp-day-footer .btn-default {
      background: $color-blue;
    }
  }

  &.intensity-2 {
    a,
    .tp-day-day,
    .tp-day-date,
    .tp-exercises .accordion-trigger,
    .tp-exercise-detailed:before {
      color: $color-green !important;
    }
    .tp-exercises:before,
    .tp-day-footer .btn-default {
      background: $color-green;
    }
  }

  &.intensity-3 {
    a,
    .tp-day-day,
    .tp-day-date,
    .tp-exercises .accordion-trigger,
    .tp-exercise-detailed:before {
      color: $color-orange !important;
    }
    .tp-exercises:before,
    .tp-day-footer .btn-default {
      background: $color-orange;
    }
  }

  &.intensity-4 {
    a,
    .tp-day-day,
    .tp-day-date,
    .tp-exercises .accordion-trigger,
    .tp-exercise-detailed:before {
      color: $color-red !important;
    }
    .tp-exercises:before,
    .tp-day-footer .btn-default {
      background: $color-red;
    }
  }

  .tp-day-day-message-wrapper {
    display: block;
    position: absolute;
    /*background: rgba(240,242,244,0.95);*/
    background: $color-gray-light;
    z-index: 20;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    box-sizing: border-box;
    text-transform: uppercase;
    border: 1px solid $color-gray-mid;
    @include border-radius;
    overflow: hidden;
    opacity: 0;
    transition: all 0s ease-out 0s;

    .btn-wrapper {
      position: absolute;
      bottom: 15px;
      width: 100%;
      opacity: 0;
      transform: scale(1);
      transition: all 1s ease-out 0.9s;
    }

    .tp-day-message {
      display: table;
      border: 3px solid $color-blue;
      border-radius: 200px;
      position: absolute;
      background: $color-gray-light;
      width: 120px;
      height: 120px;
      left: 50%;
      margin-left: -60px;
      top: 50%;
      margin-top: -90px;
      text-align: center;
      color: $color-blue;
      font-size: 12px;
      line-height: 14px;
      opacity: 0;
      transform: scale(5);
      transition: all 0.2s ease-out 0.5s;

      > div {
        display: table-row;
        > div {
          display: table-cell;
          vertical-align: middle;
          padding: 0 15px;
          span {
            font-size: 45px;
            color: $color-blue;
            display: block;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
  &.tp-day-done {
    box-shadow: none;
    min-height: 200px;
    .tp-day-day {
      position: relative;
      z-index: 70;
    }

    footer {
      .btn {
        opacity: 0;
      }
    }

    .tp-day-day-message-wrapper {
      height: 100%;
      opacity: 1;

      .btn-wrapper {
        opacity: 1;
        transform: scale(1);
      }

      .tp-day-message {
        opacity: 1;
        transform: scale(1);

        &.tp-day-not-done {
          border-color: $color-red;

          > div {

            > div {
              color: $color-red;

              span {
                color: $color-red;
              }
            }
          }
        }
      }
    }
  }
}
/*
.tp-day-empty {
	background: $color-gray-mid;
	@include border-radius;
	padding: 0;
	margin-bottom: 5px;
	vertical-align: middle;

	.tp-day-day {
		font-size: 20px;
		font-weight: 700;
		padding: 15px 0 15px 8px;
		text-align: center;
		width: 60px;
		display: inline-block;
		vertical-align: middle;
		box-sizing: border-box;
		color: #fff;
	}
	.btn-gray {
		background: $color-gray-light;
	}
}
*/
.tp-day-empty {
  padding: 0;
  border: 1px solid $color-gray-mid;
  vertical-align: middle;
  margin-bottom: 5px;
  @include border-radius;

  .tp-day-day {
    font-size: 20px;
    font-weight: 700;
    padding: 15px 0 15px 8px;
    text-align: center;
    width: 60px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    color: $color-gray-mid;
  }
  .btn-gray {
  }
}


.tp-stats {
  @include border-radius;
  background: $color-gray-mid;
  border-radius: 5px;
  margin-bottom: 40px;
  padding-bottom: 10px;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  .tp-stats-item {
    text-align: left;
    display: inline-block;
    padding: 0 15px;
    margin-top: 15px;
    margin-bottom: 0;
    position: relative;

    .owl-stage-outer {
      overflow-x: hidden !important;
      overflow-y: auto !important;
    }

    .tp-stats-item-desc {
      color: #fff;
      font-weight: 700;
      font-size: 14px;
    }
    .tp-stats-item-value {
      font-weight: 300;
      font-size: 24px;
    }
    .tp-stats-item-text {
      font-size: 14px;

      a {
        display: block;
        color: $color-gray-dark;
      }
    }

    &.tp-stats-item-medal {
      line-height: 14px;
      position: relative;
      padding-left: 70px;

      img {
        position: absolute;
        left: 15px;
        top: -5px;
      }
    }

    &:last-child {
      border: 0;
    }
  }

  &.tp-stats-dashboard {
    text-align: left;
  }

  .dashboard-stats-carousel {
    width: 920px;

    .item {
      background: $color-gray-mid;
      //background: red;

      .tp-stats-fact {
        box-sizing: border-box;
        width: calc(25% - 15px);
        float: left;
        border-right: 1px solid $color-gray-light;
        padding-right: 15px;
        margin-right: 15px;
        line-height: 22px;

        &:last-child {
          border: 0;
        }
      }

      .tp-stats-header {
        padding-bottom: 10px;
        color: $color-gray-light;
        text-transform: uppercase;

      }

      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .tp-stats-awards {


      .btn {
        display: inline-block;
        color: #000;
      }
      .tp-stats-award {
        line-height: 18px;
        width: calc(33% - 15px);
        float: left;
        border-right: 1px solid $color-gray-light;
        padding-right: 15px;
        margin-right: 15px;
        box-sizing: border-box;
        height: 44px;
        margin-bottom: 10px;

        img {
          height: 65px !important;
          width: auto !important;
          float: left;
          margin-right: 10px;
          margin-top: -10px;
        }

        .tp-stats-item-text {
          display: table-cell;
          height: 45px;
          vertical-align: middle;
        }

        &:last-child {
          border: 0;
        }
      }
    }

  }

  .owl-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    top: auto;
    margin-top: 0;

  }
}
.tp-stats-header {
  color: $color-gray-mid;
  transform: translateX(30px);
  @include animate-all-med;
}
.active .tp-stats-header {
  color: $color-gray-light !important;
  transform: translateX(0);
}


@media only screen and (max-width: 1450px) {

  .tp-stats {
    .dashboard-stats-carousel {
      width: 730px;
    }
  }
}
@media only screen and (max-width: 1200px) {

  .tp-stats {
    .dashboard-stats-carousel {
      width: 630px;
    }
  }
}
@media only screen and (max-width: 992px), print  {

  .tp-stats {
    .dashboard-stats-carousel {
      width: 560px;
      left: -5px;

      .item  .tp-stats-fact,
      .tp-stats-awards .tp-stats-award {
        width: 50%;
        padding: 10px 15px;
        margin-right: 0;
        height: 65px;

        &:nth-child(3),
        &:nth-child(2) {
          border-bottom: 1px solid $color-gray-light;
        }
        &:nth-child(3) {
          border-right: 0;
        }
        &:nth-child(4) {
          border-right: 1px solid $color-gray-light;
        }
      }
      .tp-stats-awards .tp-stats-award {
        margin-bottom: 0 !important;
      }
    }
  }
}
@media only screen and (max-width: 768px) {

  .tp-stats {
    margin-bottom: 50px;
    position: relative;

    .dashboard-stats-carousel {
      width:  calc(100% - 10px);
      left: 5px;

      .item  .tp-stats-fact,
      .tp-stats-awards .tp-stats-award {
        text-align: center;
        width: 100%;
        padding: 10px 5px 5px 5px;
        margin-right: 0;
        border: 0 !important;
        border-bottom: 1px solid $color-gray-light !important;

        img {
          margin-top: -22px !important;
          margin-bottom: -5px !important;
        }

        &:last-child {
          border: 0 !important;
        }
      }
      .tp-stats-awards .tp-stats-award {
        height: auto;
        margin-bottom: 10px !important;
        img {
          float: none;
          margin: 0 auto 3px auto;
          margin-bottom: 1px !important;
          position: relative;
          top: 3px;
        }
        .tp-stats-item-text {
          height: auto;
          display: block;
        }
      }
      .tp-stats-header {
        text-align: center;
        padding-top: 10px;
      }
    }

    .tp-stats-item {
      display: block;

    }
  }
}

.tp-week-nav {
  padding-bottom: 10px;

  a {
    display: inline-block;
    color: $color-gray-mid;
    position: relative;
    font-size: 36px;
    font-weight: 300;
    text-decoration: none;
    padding: 15px 0 45px 0;
    @include border-radius;
    @include animate-all-fast;
    vertical-align: top;
    line-height: 30px;
    width: 92px;

    .tp-week-item-kw {
      display: block;
      text-align: left;
      font-size: 12px;
      font-weight: 700;
      position: absolute;
      right: 6px;
      top: 7px;
      @include animate-all-fast;
    }

    .tp-week-item-point {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 10px;
      margin-left: -9px;
      display: block;
      border-radius: 30px;
      width: 16px;
      height: 16px;
      border: 2px solid $color-gray-mid;
      @include animate-all-fast;

      &.intensity-1 {
        border: 2px solid $color-blue;
      }
      &.intensity-2 {
        border: 2px solid $color-green;
      }
      &.intensity-3 {
        border: 2px solid $color-orange;
      }
      &.intensity-4 {
        border: 2px solid $color-red;
      }

    }

    &:after {
      content: "";
      display: block;
      height: 2px;
      background: $color-gray-mid;
      width: 62px;
      position: absolute;
      bottom: 19px;
      left: 40%;
      margin-left: 27px;
      z-index: 10;
    }

    &:last-child:after {
      display: none;
    }

    &.tagebuch-link {
      height: 36px;
      span {
      }
    }

    &.tp-week-link {
      height: 36px;
    }
    &.active {
      color: $color-gray-dark;
      .tp-week-item-point.intensity-1 {
        background: $color-blue;
      }
      .tp-week-item-point.intensity-2 {
        background: $color-green;
      }
      .tp-week-item-point.intensity-3  {
        background: $color-orange;
      }
      .tp-week-item-point.intensity-4 {
        background: $color-red;
      }

      &:before {
        content: "";
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        border: 8px solid transparent;
        border-bottom: 8px solid $color-gray-mid;
        left: 50%;
        margin-left: -8px;
        bottom: -13px;
      }
    }
  }
}

.user-infos {
  .user-img {
    .user-img-wrapper {
      position: relative;
      top: 60px;
      width: 120px;
      left: -10px;

      .btn {
        position: absolute;
        bottom: 7px;
        right: 0;
        z-index: 2;
      }
      @media print {
        .btn {
          visibility: hidden;
        }
      }
      .circular--landscape {
        display: inline-block;
        position: relative;
        width: 120px;
        height: 120px;
        overflow: hidden;
        -webkit-border-radius: 60px;
        border-radius: 60px;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          height: 100%;
          width: auto;
          -webkit-transform: translate(-50%,-50%);
          -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
          z-index: 1;
        }
      }
      .btn {
        line-height:normal;

        .icon-gear3 {
          font-size: 29px;
        }
        &:hover {
          transform:none;
          -webkit-animation: spin 4s linear infinite;
          -moz-animation: spin 4s linear infinite;
          animation: spin 4s linear infinite;
        }
      }
    }
  }
  h1 {
    padding-bottom: 20px;
  }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }



@media only screen and (max-width: 992px) {
  .user-infos {
    .user-img {
      .user-img-wrapper {
        top: 40px;
        width: 120px;
        left: 0;
        display: inline-block;

        .btn {
          position: absolute;
          bottom: 7px;
          right: 0;
        }

        img {
          border-radius: 200px;
          width: 120px !important;
          height: 120px !important;
        }
      }
    }
    h1 {
      padding-bottom: 20px;
    }
  }

}
@media only screen and (max-width: 768px) {
  .tp-week-nav {
    padding-bottom: 10px;

    a {
      display: inline-block;
      color: $color-gray-mid;
      position: relative;
      font-size: 36px;
      font-weight: 300;
      text-decoration: none;
      padding: 15px 0 45px 0;
      @include border-radius;
      @include animate-all-fast;
      vertical-align: top;
      line-height: 30px;
      width: 65px;

      .tp-week-item-kw {
        display: none;
      }

      &:after {
        width: 30px;
      }
    }


  }
  .tp-day,
  .tp-day-empty,
  .tp-stats {
    // border-radius: 0;
  }
  .tp-day,
  .tp-day-empty {
    margin-bottom: 15px;
  }
}


.desktop {
  .tp-week-nav {
    a:hover {
      background: #fff;
      color: $color-gray-dark;

      .tp-week-item-point {
        transform: scale(1.2);
      }
      .tp-week-item-kw {
      }
    }
  }
}

.diary-wrapper {
  box-shadow: 0 0 0 10px $color-gray-dark;
  background: $color-yellow-light;


  .tp-day {
    font-family: 'Open Sans', sans-serif;
    overflow: hidden;

    strong {
      color: $color-gray-dark !important;
    }
  }

  .week-ribbon {
    overflow: hidden;
    background: $color-red;
    color: $color-red;
    font-weight: 700;
    font-size: 14px;
    position: absolute;
    right: 30px;
    top: -12px;
    padding: 3px 10px 27px 10px;
    text-align: center;
    line-height: 21px;

    > div {
      position: relative;
      z-index: 10;
    }

    &:after {
      content: "";
      display: block;
      width: 100px;
      height: 100px;
      transform: rotate(45deg);
      position: absolute;
      left: 50%;
      margin-left: -50px;
      background: $color-yellow-light;
      box-sizing: border-box;
      bottom: -92px;
      border: 2px solid $color-red;
    }
    &:before {
      content: "";
      display: block;
      width: calc(100% - 4px);
      height: 100px;
      position: absolute;
      left: 2px;
      background: rgba(0,0,0,0.3);
      top: 0;
    }
    .week-number {
      font-size: 30px;
      font-weight: 300;
      color: #fff;
    }
  }
  header {
    border-top: 1px solid $color-gray-dark;
    border-bottom: 1px solid rgba(0,0,0,0.1) !important;

    .tp-day-day {
      width: auto !important;
      text-align: center !important;
      padding-left: 18px !important;

      > div {
        font-weight: 300;
        display: inline-block;
      }
    }
  }
  header:nth-of-type(1) {
    border-top: 0;
  }
  .diary-exercises {
    // color: $color-blue;
    font-size: 14px;

    strong {
      font-family: 'Open sans', sans-serif;
      color: rgba(0,0,0,0.3);
    }


    .diary-exercise-item {
      border-bottom: 1px solid rgba(0,0,0,0.1);
      padding: 4px 15px 4px 20px;
      box-sizing: border-box;
      font-family: 'Coming Soon';

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      .btn-group-row-edit {
        margin-bottom: -10px;
      }

      > div {
        padding-bottom: 5px;
      }

      &.tp-exercise-item-competition {
        position: relative;
        padding-left: 50px;

        .icon-trophy1 {
          position: absolute;
          left: 10px;
          top: 50%;
          margin-top: -18px;
          font-size: 18px;
          display: inline-block;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          border-radius: 30px;
        }
        .text-light {
          opacity: 0.5;
        }
        .tp-exercise-item-competition-detail {
          display: inline-block;
          padding-right: 10px;
        }
      }

      &.tp-exercise-item-maincompetition {
        .icon-trophy1 {
          background: $color-gray-dark;
          color: #fffdde;
        }
      }
      span a.btn {
        display:inline-block;
        margin:0 5px;
        background: rgba(0,0,0,.1);
        color: #263B47;
        font-size: 14px;
        height: 24px;
        line-height: 24px;
        font-family: 'Arial', sans-serif;
        text-transform: none;
      }
    }
  }
  .diary-item {
    font-family: 'Coming Soon';
  }

  .diary-exercise-planed {
    background: #fff;
    box-sizing: border-box;

    .diary-exercise-header {
      padding: 3px 10px;
      text-align: center;
      color: #fff;
      margin-bottom: 3px;
    }

    strong {
      color: #000;
    }

    .diary-exercise-item {
      border-bottom: 1px solid rgba(0,0,0,0.1);
      font-family: 'Open sans', sans-serif;
      color: $color-gray-dark;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 14px;

      &:first-child {
      }
      a.btn {
        display:inline-block;
        background: rgba(0,0,0,.1);
        color: #263B47;
        font-size: 14px;
        height: 24px;
        line-height: 24px;
        font-family: Arial;
        text-transform: none;
      }
      a.open-popup-video {
        display: inline;
      }
    }
  }


  .effort-scale {
    height: 6px;
    border-radius: 10px;
    margin-top: 5px;
    position: relative;
    width: 100px;
    display: inline-block;
    background:-moz-linear-gradient(left,rgba(41,169,252,1) 20%,rgba(90,170,49,1) 30%,rgba(90,170,49,1) 45%,rgba(255,168,0,1) 55%,rgba(255,168,0,1) 70%,rgba(251,42,26,1) 80%);
    background:-webkit-linear-gradient(left,rgba(41,169,252,1) 20%,rgba(90,170,49,1) 30%,rgba(90,170,49,1) 45%,rgba(255,168,0,1) 55%,rgba(255,168,0,1) 70%,rgba(251,42,26,1) 80%);
    background:linear-gradient(to right,rgba(41,169,252,1) 20%,rgba(90,170,49,1) 30%,rgba(90,170,49,1) 45%,rgba(255,168,0,1) 55%,rgba(255,168,0,1) 70%,rgba(251,42,26,1) 80%);

    .effort-scale-marker {
      width: 2px;
      height: 14px;
      position: absolute;
      top: -4px;
      background: $color-gray-dark;

      /*
			width: 30px;
		  	background: rgba(255,255,255,0.33);
		  	height: 10px;
		  	margin-left: -15px;
		  	position: absolute;
		  	top: -3px;
		  	*/
      /*
		  	&:before {
			  	content: "";
			  	clear: both;
			  	width: 16px;
			  	height: 10px;
			  	position: absolute;
			  	top: 0;
			  	left: 7px;
			  	background: rgba(255,255,255,0.6);
		  	}
		  	&:after {
			  	content: "";
			  	clear: both;
			  	width: 2px;
			  	height: 14px;
			  	position: absolute;
			  	top: -5px;
			  	left: 11px;
			  	background: $color-gray-dark;
			  	border: 3px solid #fff;
		  	}
		  	*/
    }
  }
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#29a9fc',endColorstr='#fb2a1a',GradientType=1);
}

.diary-exercise-planed.intensity-1 {
  .diary-exercise-header {
    background: $color-blue;
  }
}
.diary-exercise-planed.intensity-2 {
  .diary-exercise-header {
    background: $color-green;
  }
}
.diary-exercise-planed.intensity-3 {
  .diary-exercise-header {
    background: $color-orange;
  }
}
.diary-exercise-planed.intensity-4 {
  .diary-exercise-header {
    background: $color-red;
  }
}

.diary-carousel {
  position: relative;

  .owl-nav {

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    .disabled {
      display: none;
    }
    .owl-next {
      background: none;
      padding: 0;
      margin: 0;
      float: right;

      .btn {
        background: rgba(0,0,0,0.1);
        color: $color-gray-dark;
        position: relative;
        top: -5px;
        right: -2px;
        border-radius: 30px 0 0 30px;
      }
    }
    .owl-prev {
      background: none;
      padding: 0;
      margin: 0;
      float: left;

      .btn {
        background: rgba(0,0,0,0.1);
        color: $color-gray-dark;
        position: relative;
        top: -5px;
        left: -2px;
        border-radius: 0 30px 30px 0;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .diary-wrapper{
    .diary-exercises {


      > .diary-item {
      }
    }
    .diary-item {
      width: 500px;
    }
    .diary-exercise-planed {
      box-sizing: border-box;
      width: 280px;
      @include box-shadow;
      transform: rotate(3deg);
      margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 20px;
      overflow: hidden;
      @include border-radius;

      .diary-exercise-item {
        font-size: 12px;
        padding: 5px 10px;
        line-height: 16px;


      }
      .diary-exercise-header {
        padding: 3px 10px;
        text-align: center;
        color: #fff;
        margin-bottom: 3px;
        display: block;
      }
    }

  }

  .trainingsplan-wrapper {
    .ac-header {
      .col-md-10 {
        float: inherit;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .diary-wrapper{

    .diary-item {
      width: 600px;
    }
    .diary-exercise-planed {
      width: 280px;
      margin-left: 10px;
    }
  }
}
@media only screen and (min-width: 1450px) {
  .diary-wrapper{

    .diary-item {
      width: 800px;
    }
    .diary-exercise-planed {
      width: 300px;
      margin-left: 20px;
    }
  }
}

.message-upgrade {
  position: fixed;
  bottom: 0;
  z-index: 20;
  background: $color-gray-dark;
  width: 1146px;
  border-radius: 5px 5px 0 0;
  padding: 10px;
  box-sizing: border-box;

  .message-upgrade-p {
    color: #fff;
    padding-bottom: 10px;
    text-align: center;
  }

  .message-upgrade-a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 1450px) {
    width: 906px;
  }
  @media only screen and (max-width: 1200px) {
    width: 796px;
  }
  @media only screen and (max-width: 992px) {
    width: 576px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    left: 0;
    border-radius: 0;
  }
}

.message-write {
  position: fixed;
  bottom: 0;
  z-index: 20;
  background: $color-gray-dark;
  width: 1146px;
  border-radius: 5px 5px 0 0;
  padding: 10px;
  box-sizing: border-box;

  .input-file-upload {
    float: left;

    label {
      box-sizing: border-box;
      max-width: 300px;
    }
  }

  textarea#message-input {
    @include border-radius;
    border: 0;
    color: $color-gray-dark;
    padding: 5px 10px;
    font-size: 16px;
    line-height: 20px;
    background: #fff;
    width: calc(100% - 20px);
    // box-sizing: border-box;
    // height: 90px;
    overflow: hidden;
  }
  @media only screen and (max-width: 1450px) {
    width: 906px;
  }
  @media only screen and (max-width: 1200px) {
    width: 796px;
  }
  @media only screen and (max-width: 992px) {
    width: 576px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    left: 0;
    border-radius: 0;
  }
}



.message-docs-summary {
  background: $color-gray-dark;
  color: #fff;
  position: fixed;
  top: 0;
  right: -1px;
  height: 100%;
  z-index: 20;
  width: 0;
  @include animate-all-fast;
  border-left: 1px solid $color-gray-light;

  .message-docs-summary-content {
    width: 280px;
    margin: 20px auto;
    max-height: calc(100% - 40px);
    overflow: auto;

    a {
      display: block;
      color: #fff;
      border-bottom: 1px solid rgba(255,255,255,0.1);
      padding: 5px 0;
      width: 280px;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .message-docs-summary-trigger {
    background: $color-gray-dark;
    color: $color-blue;
    width: 40px;
    height: 40px;
    position: absolute;
    left: -40px;
    top: 50%;
    margin-top: -20px;
    text-align: center;
    line-height: 40px;
    font-size: 24px;
    border-radius: 5px 0 0 5px;
    cursor: pointer;

    > div {
      width: 32px;
      position: absolute;
      left: 50%;
      margin-left: -16px;
      height: 8px;
      margin-top: -4px;
      top: 50%;
      @include animate-all-med;

      &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: 4px solid transparent;
        border-right: 4px solid #fff;
        position: absolute;
        left: -4px;
        top: 0;
      }
    }
  }


  &.open {
    width: 320px;

    .message-docs-summary-trigger {

      > div {
        transform: rotate(-180deg);
      }
    }
  }


  a[href$=".pdf"] {
    &:before {
      @include icomoon-settings;
      content: "\ea21";
      position: relative;
      display: inline-block;
      padding-right: 5px;
    }
  }

  a[href$=".jpg"],
  a[href$=".gif"],
  a[href$=".png"] {
    &:before {
      @include icomoon-settings;
      content: "\ea98";
      position: relative;
      display: inline-block;
      padding-right: 5px;
    }
  }
  a.coach-upload {
    color: $color-blue;
  }
  .message-docs-summary-phonetrigger {
    display: none;
  }
}


@media only screen and (max-width: 420px) {
  .message-docs-summary {
    border:0;



    .message-docs-summary-content {
    }

    .message-docs-summary-trigger {
      top: 76px;

      > div {

        &:before {
        }
      }
    }


    &.open {
      width: 100%;

      .message-docs-summary-phonetrigger {
        position: absolute;
        display: block;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 24px;
      }
    }
  }
}


.messages-wrapper {
  padding: 0 10px 160px 10px;

  .message-user,
  .message-coach {
    @include border-radius;
    padding: 15px;
    margin-bottom: 15px;
    position: relative;
    width: calc(100% - 190px);
    box-sizing: border-box;

    .message-date {
      font-size: 12px;
      opacity: 0.5;
    }

    img {
      width: 70px !important;
      height: 70px !important;
      border-radius: 200px;
      position: absolute;
      top: 50%;
      margin-top: -35px;
    }

    .message-docs {
      border-top: 1px dashed rgba(255,255,255,0.2);
      margin:15px -15px -7px;
      padding: 7px 15px 0 15px;

      a[href$=".pdf"] {
        button {
          &:before {
            @include icomoon-settings;
            content: "\ea21";
            position: relative;
            left: -6px;
          }
        }
      }
      a[href$=".jpg"],
      a[href$=".gif"],
      a[href$=".png"] {
        button {
          &:before {
            @include icomoon-settings;
            content: "\ea98";
            position: relative;
            left: -6px;
          }
        }
      }
    }
  }
  .message-coach {
    background: $color-gray-mid;
    margin-left: 85px;

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top: 10px solid $color-gray-mid;
      position: absolute;
      top: 50%;
      left: -10px;
    }

    img {
      left: -85px;
    }
  }
  .message-user {
    background: $color-blue;
    color: #fff;
    margin-left: 105px;

    img {
      right: -85px;
    }

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top: 10px solid $color-blue;
      position: absolute;
      top: 50%;
      right: -10px;
    }
  }
}



@media only screen and (max-width: 768px) {
  .messages-wrapper {

    .message-user,
    .message-coach {
      width: calc(100% - 20px);
      box-sizing: border-box;

      img {
        display: none;
      }
    }
    .message-coach {
      margin-left: 0;
    }
    .message-user {
      margin-left: 20px;
    }
  }
}


.ac-header {
  padding-top: 20px;
}
.ac-plan {

  table {
    width: 100%;
    border-collapse: collapse;
    background: #fff;

    tr {
      border-bottom: 1px solid $color-gray-light;

      td {
        padding: 8px 10px;
        line-height: 21px;
        // background: $color-gray-mid;

        &.ac-aim-text {
          word-break: break-word;
        }
      }

      &:first-child {
        border-top: 1px solid $color-gray-light;
      }

    }
  }
  .ac-date {
    width: 110px;
    font-size: 14px;
    color: $color-gray-mid;
    vertical-align: middle;

    span {
      color: $color-gray-dark;
      font-size: 18px;
      display: inline-block;
      position: relative;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      top: 2px;
      margin-right: 3px;
    }
    .ac-legend {
      display: inline-block;
    }
  }
  .ac-main-competition {
    td.ac-date {
      span {
        background: $color-gray-dark;
        border-radius: 30px;
        color: #fff;
      }
    }
  }

  .ac-aim-text {

  }
  .ac-legend {
    color: $color-gray-mid;
    font-weight: 700;
    font-size: 14px;
  }
  .ac-competition-name {
    //font-weight: 300;
    //font-size: 20px;
  }
  .ac-competition-distance,
  .ac-competition-time1 {
    width: 70px;
  }
  .ac-competition-time2 {
    width: 112px;
  }
  .ac-buttons {
    width: 55px;
    text-align: right;
    vertical-align: middle;
    padding-left: 0;
  }

  table.ac-done-wrapper {
    position: relative;
    background: $color-gray-dark;

    .icon-check.hidden {
      display:none;
    }
    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top: 10px solid #fff;
      position: absolute;
      left: 50%;
      margin-left: -5px;
    }

    tr {
      td {
        background: $color-gray-dark;
        color: #fff;
        border-bottom: 1px solid rgba(255,255,255,0.1);
      }
    }
    .ac-date {

      span {
        color: #fff;
      }
    }
    .ac-competition-time1 {
      opacity: 0.3;
    }
    .ac-main-competition {
      td.ac-date {
        span {
          background: #fff;
          color: $color-gray-dark;
        }
      }
    }
  }

  .custom-radio {
    label {
      border: 1px solid rgba(255,255,255,0.1);
      @include border-radius;
      margin-bottom: 5px;
      padding: 10px 10px 10px 35px !important;
      line-height: 20px;
      font-size: 16px;
      @include animate-all-fast;

      &:after,
      &:before {
        display: none;
      }

      &:before {
        display: block;
        color: $color-blue;
        background: $color-gray-dark;
        border: 1px solid rgba(255,255,255,0.1);
        top: 50%;
        margin: 0;
        left: 7px;
        margin-left: 0;
        margin-top: -11px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }

      &.checked {
        border: 1px solid #fff;

        &:before {
          display: block;
          content: "\edfb";
          @include icomoon-settings;
          color: $color-blue;
          background: #fff;
          border: 1px solid #fff;
          top: 50%;
          margin: 0;
          left: 7px;
          margin-top: -11px;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
        }
      }
      &:after {
        height: 0;
        background: none;
        clear: both;
        display: block;
        position: relative;
      }
      .ac-plan-time {
        float: left;
        text-transform: uppercase;

        span.ac-plan-time_mini {
          font-size: 12px;
          display: block;
        }
      }
      .ac-plan-price {
        float: right;
        text-align: right;

        span {
          font-size: 12px;
          display: block;
        }
      }
    }
  }

  .flexible-laufzeit {
    .flex-laufzeit-input-wrapper-basic,
    .flex-laufzeit-input-wrapper-premium,
    .flex-laufzeit-input-wrapper-standard {
      display: none;
      line-height: 18px;
      text-align: center;
      text-transform: uppercase;
      padding: 10px;
      color: $color-blue;
      background: #fff;
      margin-top: -5px;
      margin-bottom: 5px;
      border-radius: 0 0 5px 5px;

      > div {
        float: left;
        width: 50%;
        position: relative;
        font-size: 12px;
        color: $color-gray-dark;
        font-weight: 700;
      }

      input {
        border: 0;
        @include border-radius;
        padding: 5px;
        width: calc(100% - 4px);
        box-sizing: border-box;
        text-align: center;
        background: $color-gray-dark;
        color: #fff;
        margin: 2px 2px 0 2px;
        font-size: 16px;
      }
      &:after {
        content: "";
        display: block;
        clear: both;
      }
      &.show {
        display: block;
      }
    }
    .custom-radio {
      label {
        &.checked {
          border-radius: 5px 5px 0 0;
        }
      }
    }
    .flex-hide {
      display:none;
    }
  }

}


@media only screen and (max-width: 992px) {
  .ac-plan {
    table {
      display: block;
      padding: 0;
      width: calc(100% + 30px);

      tr {
        display: block;
        padding: 15px 5px;
        position: relative;
        border-bottom: 1px solid $color-gray-light;

        td {
          display: block;
          border: 0 !important;
          padding-top: 0;
          padding-bottom: 0;

          &.ac-date {
            position: relative;
            left: -5px;
          }

          &.ac-competition-distance,
          &.ac-competition-time1,
          &.ac-competition-time2 {
            width: auto;
            display: inline-block;
          }
          &.ac-competition-name {
            padding-bottom: 10px;
            padding-top: 10px;
            font-size: 20px;
            .ac-legend {
              display: none;
            }
          }
          &.ac-buttons {
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }
        /*
				ac-date
				ac-aim-text
				ac-competition-name
				ac-competition-distance
				ac-competition-time1
				ac-competition-time2
				ac-buttons
				*/
      }

      &.ac-done-wrapper {
        tr	{
          border: 0;
          border-bottom: 1px solid rgba(255,255,255,0.1);
        }
      }
    }
  }
}

.popup-tp-exercise-video {
}

#popup-ac-competition-edit {
  div[data-source='common'] {
    padding: 0;
    top: -10px;
    background: none;
    color: #FFFFFF;
    border-bottom: 1px solid #FF0000;
    display: inline-block;
    width: auto;
  }
}
#add_new_marathon {
  width:100%;

  input {
    @include boxsize;
  }
  div.mage-error {
    font-size: 14px;
    position: relative;
    top: -10px;
  }
}

.popup-edit, .popup-dialog {
  max-width: 400px;

  .mage-error {
    color: $color-red;
    font-weight: 400;
    background: #FFFFFF;
    padding: 3px 5px;
    width: 100%;
    top: -5px;
    position: relative;
    @include boxsize;
  }
  &.registration-step {
    .messages {
      font-size: 12px;
      line-height: normal;
      font-weight: 600;
      letter-spacing: 0.2px;
    }
    .mage-error {
      top: 0;
      margin-bottom: 0;
    }
  }
  .popup-text {
    padding: 0 15px;
  }
  .add-information {
    color: #263B47;

    span {
      font-size: 70px;
      opacity: 0.2;
      display: inline-block;
      padding: 10px 0;
    }
  }
  input[type=text].error {
    border:2px solid $color-red;
  }
  .scroll-wrapper {
    overflow: scroll;
    display: block;
    max-height: 100%;
    width: 100%;
    text-align: left;
    font-size: 16px;
    padding-left: 10px;

    div {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding: 2px 0;
    }
  }
  &#popup-weight-history .scroll-wrapper {
    overflow:auto;
  }

  section {
    background: $color-blue;
    color: #fff;
    padding: 20px 10px;
    height: 550px;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 400px;
    box-sizing: border-box;

    .wrapper {
      flex: 0 0 auto;
      margin: 5px;
    }
    .strecke-alt {
      display: none;
      width: 100%;

      input {
        box-sizing: border-box;
      }
    }
    .captcha-image {
      margin-top: 15px;

      img {
        height:45px;
      }
      img, button {
        vertical-align:middle;
        display:inline-block;
      }
    }
  }
  footer {
    background: $color-blue;
    border-radius: 0 0 5px 5px;
    height: 35px;

    .btn {
      opacity: 1;
      width: auto;
      position: relative;
      top: auto;
      right: auto;
    }
  }
  &#popup-start header {
    background: #29A9FC;
  }
  .popup-edit-progress {
    width: calc(100% - 20px);
    height: 10px;
    background: rgba(255,255,255,0.3);
    border-radius: 20px;
    margin-bottom: 10px;
    position: absolute;
    top: -5px;

    .popup-edit-progress-bar {
      position: absolute;
      border-radius: 20px;
      background: #fff;
      height: 10px;
      left: 0;
      top: 0;
    }
    .popup-edit-progress-count {
      position: absolute;
      width: 100%;
      margin-top: -25px;
      color: rgba(255,255,255,0.3);
    }
  }
  .popup-edit-new-suggestion {
    background: rgba(255,255,255,0.3);
    padding: 20px 10px 10px 10px;
    width: 100%;
    border-radius: 5px;

    h4 {
      color: $color-blue;
      text-transform: uppercase;
      padding-bottom: 10px;
      font-size: 20px;
    }
  }
  h4 {
    font-size: 24px;
    padding-bottom: 10px;
    line-height: 30px;
    font-weight: 300;
    width: 100%;
  }

  textarea,
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="date"],
  input[type="number"],
  select {
    @include border-radius;
    background: #fff;
    width: 100%;
    border:0;
    padding: 10px;
    font-size: 16px;
    color: $color-gray-dark;
    font-family: 'Arial', 'Open Sans', sans-serif;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="date"],
  input[type="number"],
  textarea {
    text-align: center;
    font-size: 20px;
    font-weight: 300;
  }
  label {
    padding-top: 10px;
    padding-bottom: 2px;
    display: block;
    width: 100%;
  }
  textarea {
    height: 100px;
  }
  select {
    background: $color-blue;
    color: #fff;
    height: 42px;
    box-sizing: border-box;
  }
}

.popup-dialog {
  section {
    background: #fff;
    color: $color-gray-dark;
  }
  footer {
    background: #fff;
  }
}

.form-white {
  .mage-error {
    color:$color-red;
    font-size:13px;
  }
  .success-message {
    color: $color-green;
    font-size: 20px;
  }
  textarea, input[type="text"], input[type="password"], input[type="date"], select {
    @include border-radius;
    width: 100%;
    border: 0;
    padding: 10px;
    font-size: 16px;
    color: #263B47;
    background: $color-gray-light;
    font-family: 'Arial', 'Open Sans', sans-serif;
    @include boxsize;
  }
  input[type="text"], input[type="password"], input[type="date"], textarea {
    text-align: center;
    font: {
      size: 20px;
      weight: 300;
    };
  }
  label {
    padding-top: 10px;
    padding-bottom: 2px;
    display: block;
    width: 100%;
    @include boxsize;
  }
  textarea {
    height: 100px;
  }
  select {
    height: 42px;
  }
}

#popup-watch-sync {
  .watch-wrapper {
    display: block;
    background: #fff;
    @include border-radius;
    position: relative;
    padding: 20px 0;
    margin: 5px 15px;
    @include animate-all-fast;

    &:hover {
      //transform: scale(1.05);
    }
    img {
      width: 40% !important;
      height: auto !important;
      margin-bottom: -5px;
    }

    &.watch-sync-done {
      background: $color-gray-light;
      color: #fff;
      box-shadow: inset 0 0 0 2px rgba(255,255,255,1);

      img {
        //opacity: 0.2;
      }

      &:before {
        content: "\edfb";
        @include icomoon-settings;
        position: absolute;
        z-index: 3;
        font-size: 24px;
        line-height: 24px;
        width: 30px;
        height: 30px;
        top: 50%;
        margin-top: -13px;
        left: 10px;
        line-height: 30px;
        font-size: 18px;
        background: $color-green;
        border-radius: 50%;
      }
      span {
        //position: absolute;
        z-index: 3;
        right: 10px;
        top: 50%;
        display: inline-block;
        //margin-top: -12px;
        width: auto;
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    span {
      margin: 12px 0 0 0;
    }

  }
}

::-webkit-input-placeholder {
  color: $color-blue-light;
}
::-moz-placeholder {
  color: $color-blue-light;
}
:-ms-input-placeholder {
  color: $color-blue-light;
}
:-moz-placeholder {
  color: $color-blue-light;
}

.input-range-legend-wrapper {
  width: 100%;

  > div {
    float: left;
    width: 25%;
    text-align: center;
  }
  &:after {
    content: "";
    clear: both;
    display: block;
  }
}
input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 0 0 15px;
  -webkit-tap-highlight-color: transparent;
  background: none;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 20px;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid $color-blue-light;
  background:rgb(41,169,252);
  background:-moz-linear-gradient(left,rgba(41,169,252,1) 20%,rgba(90,170,49,1) 30%,rgba(90,170,49,1) 45%,rgba(255,168,0,1) 55%,rgba(255,168,0,1) 70%,rgba(251,42,26,1) 80%);
  background:-webkit-linear-gradient(left,rgba(41,169,252,1) 20%,rgba(90,170,49,1) 30%,rgba(90,170,49,1) 45%,rgba(255,168,0,1) 55%,rgba(255,168,0,1) 70%,rgba(251,42,26,1) 80%);
  background:linear-gradient(to right,rgba(41,169,252,1) 20%,rgba(90,170,49,1) 30%,rgba(90,170,49,1) 45%,rgba(255,168,0,1) 55%,rgba(255,168,0,1) 70%,rgba(251,42,26,1) 80%);
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#29a9fc',endColorstr='#fb2a1a',GradientType=1);
}
input[type=range]::-webkit-slider-thumb {
  height: 30px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
  @include box-shadow;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background:rgb(41,169,252);
  background:-moz-linear-gradient(left,rgba(41,169,252,1) 20%,rgba(90,170,49,1) 30%,rgba(90,170,49,1) 45%,rgba(255,168,0,1) 55%,rgba(255,168,0,1) 70%,rgba(251,42,26,1) 80%);
  background:-webkit-linear-gradient(left,rgba(41,169,252,1) 20%,rgba(90,170,49,1) 30%,rgba(90,170,49,1) 45%,rgba(255,168,0,1) 55%,rgba(255,168,0,1) 70%,rgba(251,42,26,1) 80%);
  background:linear-gradient(to right,rgba(41,169,252,1) 20%,rgba(90,170,49,1) 30%,rgba(90,170,49,1) 45%,rgba(255,168,0,1) 55%,rgba(255,168,0,1) 70%,rgba(251,42,26,1) 80%);
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#29a9fc',endColorstr='#fb2a1a',GradientType=1);
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
  border: 1px solid #000000;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  height: 8.4px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
}







.intensity-explanation {
  text-align: center;
  padding: 20px 0;

  > div {
    display: inline-block;
    padding: 0 10px;
    font-size: 14px;
    text-transform: uppercase;

    &:before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 10px;
      position: relative;
      top: 2px;
      margin-right: 2px;
    }

    &.intensity-1:before {
      background: $color-blue;
    }
    &.intensity-2:before {
      background: $color-green;
    }
    &.intensity-3:before {
      background: $color-orange;
    }
    &.intensity-4:before {
      background: $color-red;
    }
  }
}

@media only screen and (max-width: 400px) {
  .popup-edit,
  .popup-dialog {
    section {
      width: 100%;
    }
  }
}


.trainingsplan,
.tagebuch {

  .header-wrapper {
    padding: 30px 20px;
    h2 {
      font-weight: 700;
      font-size: 21px;
      padding: 0;
      margin-top: -5px;
      margin-bottom: -5px;
    }
    .tp-day-date {
      font-size: 12px;
      color: $color-gray-text;
    }
    .tp-day-intensity {
      font-size: 12px;
      text-transform: uppercase;
    }
  }


  .tp-wrapper{
    margin: 0 40px;
    background: #fff;
    @include border-radius;

    .ka-panel {
      &.close {
        .ka-content {
          display: none;
        }
      }

      &.open {
        .ka-content {
          display: block;
        }
      }
    }

    &.intensity-1 {
      .ka-panel .ka-handler span,
      .header-wrapper .tp-day-intensity  {
        color: $color-blue;
      }
      .tp-actions {
        .tp-action {
          a,
          button {
            background: $color-blue;
          }
        }
      }
    }

    &.intensity-2 {
      .ka-panel .ka-handler span,
      .header-wrapper .tp-day-intensity  {
        color: $color-green;
      }
      .tp-actions {
        .tp-action {
          a,
          button {
            background: $color-green;
          }
        }
      }
    }

    &.intensity-3 {
      .ka-panel .ka-handler span,
      .header-wrapper .tp-day-intensity  {
        color: $color-orange;
      }
      .tp-actions {
        .tp-action {
          a,
          button {
            background: $color-orange;
          }
        }
      }
    }

    &.intensity-4 {
      .ka-panel .ka-handler span,
      .header-wrapper .tp-day-intensity  {
        color: $color-red;
      }
      .tp-actions {
        .tp-action {
          a,
          button {
            background: $color-red;
          }
        }
      }
    }
  }
  .tp-content {
    .ka-panel {
      .ka-handler {
        font-size: 14px;
        padding: 20px 40px 20px 60px;
        border-top: 1px solid $color-gray-light;
        position: relative;
        cursor: pointer;
        font-weight: 400;

        &-number {
          display: block;
          background: #000;
          width: 24px;
          height: 24px;
          color: #fff;
          text-align: center;
          @include border-radius;
          line-height: 24px;
          font-weight: 700;
          position: absolute;
          left: 20px;
          top: 50%;
          margin-top: -12px;
        }

        .tp-day-exercise-info {
          color: $color-gray-text;
          font-size: 12px;
          line-height: 14px;
        }

        span {
          float: right;
          @include animate-all-fast;
          position: absolute;
          right: 20px;
          top: 50%;
          margin-top: -8px;
        }
      }
      &.open {
        .ka-handler {
          span {
            transform: rotate(180deg);
          }
        }
      }
      .ka-content {
        padding: 0 20px 20px 20px;
        color: $color-gray-text;
        font-size: 12px;
        line-height: 1.4em;
      }
    }

    .tp-hint {
      background: $color-gray-light;
      border-radius: 5px;
      padding: 5px 5px 5px 30px;
      position: relative;
      margin-top: 15px;

      .icon-watch2 {
        display: block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -15px;
        font-size: 24px;
      }
    }

  }
  .tp-actions {
    text-align: center;
    padding-top: 30px;
    .tp-action {
      display: inline-block;
      font-size: 12px;
      text-align: center;
      width: 70px;
      line-height: 14px;
      position: relative;

      &.done {
        &:before {
          content: "\edfb";
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background: $color-green;
          color: #fff;
          @include icomoon-settings;
          position: absolute;
          right: -3px;
          top: -8px;
          line-height: 20px;
          font-size: 12px;
          z-index: 5;
        }
      }

      a,
      button {
        display: inline-block;
        width: 60px;
        height: 60px;
        @include border-radius;
        line-height: 60px;
        color: #fff;
        font-size: 28px;
        text-decoration: none;
        margin-bottom: 6px;
        @include animate-all-fast;
        border: 0;
        cursor: pointer;

        &:disabled {
          background: $color-gray-light !important;
          color: $color-gray-text;
          cursor: default;


          &:hover {
            transform: scale(1);
          }

        }

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .trainingsplan-wrapper {
      padding: 0 !important;
      margin: 0 !important;
    }
    .week-nav-wrapper {
      margin-bottom: 0 !important;
    }
    .tp-wrapper {
      margin: 0;
      border-radius: 0;
    }
  }
}

.tagebuch {
  background: $color-gray-dark;

  .tagebuch-item {
    background: #fff;
    @include border-radius;
    margin: 0 40px 2px 40px;
    position: relative;
    overflow: hidden;

    .ka-handler {
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 12px;
      padding: 30px 40px 20px 20px;
      position: relative;
      @include animate-all-fast;
      cursor: pointer;

      .icon-chevron-down {
        color: $color-blue;
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -5px;
        font-size: 16px;
        @include animate-all-fast;
      }

      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }
    .tagebuch-item-date {
      display: inline-block;
      color: $color-blue;
      padding-right: 5px;
    }
    .tagebuch-item-unit-count {
      display: inline-block;
      border-radius: 20px;
      background: $color-blue;
      color: #fff;
      padding: 0px 10px;
      height: 18px;
      line-height: 18px;

      strong {
        font-weight: 700;
      }
    }

    .open {
      .ka-handler {
        background: $color-gray-light;

        .icon-chevron-down {
          transform: rotate(180deg);
        }
      }
    }
    .ka-panel {
      .ka-content {
        transition: all .5s ease-in-out;
      }
      &.close {
        .ka-content {
          height: 0;
          opacity: 0;
        }
      }

      &.open {
        .ka-content {
          height: auto;
          opacity: 1;
        }
      }
    }
    .ka-content
    a {
      font-size: 14px;
      padding: 20px 40px 20px 60px;
      border-top: 1px solid $color-gray-light;
      position: relative;
      cursor: pointer;
      font-weight: 400;
      color: #000;
      display: block;
      text-decoration: none;
      border-top: 1px solid $color-gray-light;

      .tp-day-exercise-info {
        color: $color-gray-text;
        font-size: 12px;
        line-height: 14px;
      }

      span {
        float: right;
        @include animate-all-fast;
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -8px;
        color: $color-blue;

        &.icon-flag3, &.icon-trophy1 {
          right: auto;
          margin-top: -12px;
          color: #000;
        }
      }
      &:before {
        display: block;
        background: $color-blue;
        width: 24px;
        height: 24px;
        color: #fff;
        text-align: center;
        @include border-radius;
        font-weight: 700;
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -12px;
        font-size: 16px;
        box-sizing: border-box;
        padding-top: 3px;
        @include icomoon-settings;
      }

      &:hover {
        span {
          right: 15px;

          &.icon-flag3, &.icon-trophy1 {
            right: auto;
          }
        }
      }

      &.tagebuch-item-unit-done {
        &:before {
          content: "\edfb";
        }
      }
      &.tagebuch-item-unit-manually {
        &:before {
          content: "\e918";
        }
      }
      &.tagebuch-item-unit-clock {
        &:before {
          content: "\eda1";
        }
      }
      &.tagebuch-item-unit-tracking {
        &:before {
          content: "\edf1";
        }
      }
    }
  }
}

.choose-week,
.choose-month,
.choose-year {
  .owl-item {
    opacity: 0.5;
    @include animate-all-fast;

    &.active {
      opacity: 1;
    }

    .item {
      a {
        display: block;
        background: #fff;
        @include border-radius;
        border-radius: 30px;
        color: $color-gray-text;
        text-decoration: none;
        // font-family: 'Open Sans Condensed';
        font-weight: 700;
        font-size: 16px;
        line-height: 14px;
        padding: 8px 3px 6px 3px;
        @include animate-all-fast;
        height: 42px;
        box-sizing: border-box;

        &.active,
        &:hover {
          background: $color-blue;
          color: #fff;
        }

        .year {

          font-family: 'Open Sans';
          font-weight: 400;
          font-size: 10px;
        }
      }
    }
  }
}
.choose-month {
  .owl-item {
    .item {
      a {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}
.choose-year {
  .owl-item {
    .item {
      a {
        line-height: 42px;
        padding: 0;

      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .tagebuch {
    .tagebuch-item {
      margin: 0 0 2px 0;
      border-radius: 0;
    }
  }
}

.training {

}
.training-feeling-bar {
  //background: url(images/training-feel-hg.png) 50% 50% no-repeat;
  //background-size: cover;
  height: 31px;
  border-radius: 20px;
  background-image: linear-gradient(90deg, #009DF0 0%, #76B82A 30%, #FFA800 64%, #FB2A1A 100%);
  padding: 2px;
  box-sizing: border-box;
  position: relative;

  > div {
    background: #fff;
    height: 27px;
    border-radius: 20px;

    .training-feeling-bar-count {

      background: red;
      height: 27px;
      border-radius: 20px;
      width: 50%;
      overflow: hidden;

      &:before {
        content: "";
        display: block;
        height: 27px;
        width: 200%;

        background-image: linear-gradient(90deg, #009DF0 0%, #76B82A 30%, #FFA800 64%, #FB2A1A 100%);
      }

      &.count-0 {
        width: 0%;
      }
      &.count-10 {
        width: 10%;
        &:before {
          width: 1000%
        }
      }
      &.count-20 {
        width: 20%;
        &:before {
          width: 500%
        }
      }
      &.count-30 {
        width: 30%;
        &:before {
          width: 333%
        }
      }
      &.count-40 {
        width: 40%;
        &:before {
          width: 250%
        }
      }
      &.count-30 {
        width: 30%;
        &:before {
          width: 333%
        }
      }
      &.count-30 {
        width: 30%;
        &:before {
          width: 333%
        }
      }
      &.count-50 {
        width: 50%;
        &:before {
          width: 200%
        }
      }
      &.count-60 {
        width: 60%;
        &:before {
          width: 166%
        }
      }
      &.count-70 {
        width: 70%;
        &:before {
          width: 143%
        }
      }
      &.count-80 {
        width: 80%;
        &:before {
          width: 125%
        }
      }
      &.count-90 {
        width: 90%;
        &:before {
          width: 111%
        }
      }
      &.count-100 {
        width: 100%;
        &:before {
          width: 100%
        }
      }
    }
  }
}

/*Formulare----------------*/

.default-form {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="date"] {
    background: #fff;
    border: 0;
    border-bottom: 2px solid $color-gray-light;
    width: 100%;
    font-size: 18px;
    color: $color-blue;
    padding: 3px 0 10px 0;
    font-weight: 300;
    @include animate-all-fast;

    &:hover,
    &:active,
    &:focus {
      color: #000;
    }
  }

  select {
    width: 100%;
    font-size: 18px !important;
    padding: 5px;
  }

  .calc-element {
    background: #fff;
    border: 0;
    border-bottom: 2px solid transparent;
    width: 100%;
    font-size: 18px;
    color: #000;
    padding: 0px 0 10px 0;
    font-weight: 300;
    text-align: left;
    @include animate-all-fast;
  }
  textarea {
    background: #fff;
    border: 2px solid $color-gray-light;
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    color: $color-blue;
    padding: 3px 0 10px 0;
    font-weight: 300;
    @include animate-all-fast;
    @include border-radius;
    padding: 15px;

    &:hover,
    &:active,
    &:focus {
      color: #000;
    }
  }
  .password-strength {
    position: relative;

    .bar {
      background-image: linear-gradient(132deg, #00CCF9 0%, #009DF0 100%);
      border-radius: 8px;
      height: 8px;
    }
  }
  label {
    font-size: 12px;
    color: $color-gray-text;
    text-align: left;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-gray-light;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color-gray-light;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $color-gray-light;
  }

  hr {
    border: 0;
    border-bottom: 1px solid $color-gray-light !important;
    padding: 0;
    margin: 25px -20px !important;
    width: calc(100% + 60px);
  }

}

.spacer {
  height: 30px;
}

.eigenschaften-form {
    position: relative;
    display: flex !important;
    flex-wrap: wrap;
    gap: 2px;
    margin-top: 10px;

    .custom-checkbox {
        position: relative;
        width: calc(33.3% - 2px);

        label {
            border-radius: 5px;
            text-align: center;
            background: $color-gray-fragebogen;
            color: #fff;
            display: block;
            text-align: center;
            text-overflow: inherit !important;
            white-space: normal !important;
            overflow: visible !important;
            hyphens: auto;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
            padding: 50px 10px 12px 10px !important;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            height: 100%;
            box-sizing: border-box;
            @include animate-all-fast;

            &:before {
                content: "\edfb";
                @include icomoon-settings;
                position: absolute;
                top: calc(50% - 24px);
                color: $color-blue;
                font-size: 20px;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                left: 50%;
                margin-left: -15px;
                @include animate-all-fast;
                opacity: 0;
                transform: scale(1.2);
                background: transparent;
            }

            &:after {
                display: none;
            }

            &.checked {
                background: #fff;
                color: #000;

                &:before {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }
    .text-xs-right {
        text-align: right;
        width: 100%;
        padding-top: 10px;
    }
}

@media only screen and (max-width: 500px) {
  .default-form {
    label {
      font-size: 10px;
    }
  }
}

/**
 *
 * Main stylesheet for Powerange.
 * http://abpetkov.github.io/powerange/
 *
 */

/**
 * Horizontal slider style (default).
 */

#js-display-change {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  padding: 0 0 15px 0;
}
.training-feeling-range {
  padding: 2px;
  border-radius: 20px;
  background-image: linear-gradient(90deg, #009DF0 0%, #76B82A 30%, #FFA800 64%, #FB2A1A 100%);
  > div {
    overflow: hidden;
    border-radius: 20px;
  }
}
.range-bar {
  background-color: #fff;
  border-radius: 15px;
  display: block;
  height: 27px;
  position: relative;
  width: calc(100% - 28px);
  margin-left: 6px;

  background-image: linear-gradient(90deg, #009DF0 0%, #76B82A 30%, #FFA800 64%, #FB2A1A 100%);
}

.range-quantity {

  border-radius: 15px;
  display: block;
  height: 100%;
  width: 0;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 1000px;
    height: 27px;
    background: #fff;
    position: absolute;
    right: -1014px;
    top: 0;
  }
}

.range-handle {
  background-color: #fff;
  border-radius: 100%;
  cursor: move;
  height: 27px;
  left: 0;
  top: 0px;
  position: absolute;
  width: 27px;
  z-index: 20;
  text-align: center;
  line-height: 28px;
  color: $color-gray-mid;

  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);

  &:before {
    @include icomoon-settings;
    content: "\ede5";
  }
}

.range-min,
.range-max {
  color: #181819;
  font-size: 12px;
  height: 20px;
  padding-top: 4px;
  position: absolute;
  text-align: center;
  top: -9px;
  width: 24px;
}

.range-min {
  left: -30px;
}

.range-max {
  right: -30px;
}

/**
 * Vertical slider style.
 */

.vertical {
  height: 100%;
  width: 4px;
}

.vertical .range-quantity {
  bottom: 0;
  height: 0;
  position: absolute;
  width: 100%;
}

.vertical .range-handle {
  bottom: 0;
  left: -13px;
  top: auto;
}

.vertical .range-min,
.vertical .range-max {
  left: -10px;
  right: auto;
  top: auto;
}

.vertical .range-min {
  bottom: -30px;
}

.vertical .range-max {
  top: -30px;
}

/**
 * Style for disabling text selection on handle move.
 */

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/**
 * Style for handle cursor on disabled slider.
 */

.range-disabled {
  cursor: default;
}


.lazy-loading-animation {
  text-align: center;
  padding: 20px 0;
}


.popup-training-detail {
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
}


.item-stats-wrapper {
  border-top: 1px solid $color-gray-light;
  border-bottom: 1px solid $color-gray-light;
  margin-left: -10px;
  margin-right: -10px;
  padding: 10px 20px;

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.popup-stats-header {
  padding-left: 30px;
  text-align: left;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;


  span {
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 18px;
  }

  h3 {
    font-family: 'Open Sans Condensed';
    font-weight: 700;
    font-size: 21px;
    color: #000000;
    text-transform: uppercase;
  }

  div {
    font-size: 12px;
    color: $color-gray-text;
    font-family: 'Open Sans';
    font-weight: 400;
    text-transform: none;

    img {
      height: 12px !important;
      width: auto !important;
      position: relative;
      top: 2px;
    }
  }

}

.item-stat,
.item-stat-text {
  float: left;
  width: 33%;
  line-height: 18px;
  color: $color-gray-text;
  text-align: left;
  font-size: 12px;
  padding: 10px 0;
  word-break: break-all;
  
  .item-stat-count {
    font-size: 18px;
    color: #000;
  }
  .item-stat-text-wrapper {
    font-size: 14px;
    color: #000;
  }
}
.item-stat-text {
  float: none;
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-right: -30px;
  border-bottom: 1px solid $color-gray-light;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
}
.item-stats-laps-wrapper {
  position: relative;
  height: 120px;
  overflow: hidden;
  width: calc(100% + 60px);
  margin-left: -30px;
  cursor: pointer;

  &.collapsed {
    height: auto;
    padding-bottom: 52px;
  }

  .item-stats-laps-trigger {
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 100%);
    height: 85px;
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    padding-top: 65px;
    color: $color-blue;

    span {
      display: block;
    }
  }

  &.active {
    height: auto;
    padding-bottom: 30px;

    .item-stats-laps-trigger {
      height: 30px;
      padding-top: 10px;

      span {
        transform: rotate(180deg);
      }
    }
  }
}
table.item-stats-laps {
  font-size: 12px;
  border-collapse: collapse;
  text-align: left;
  width: 100%;

  tr {
    th {
      color: $color-blue;
      font-weight: 700;
      padding: 5px 0px;

      &:first-child{
        padding-left: 20px;
      }
      &:last-child{
        padding-right: 20px;
      }
    }
  }
  tr {
    td {
      padding: 5px 0px;

      &:first-child{
        padding-left: 20px;
      }
      &:last-child{
        padding-right: 20px;
      }
    }
    &:nth-child(even) {
      td {
        background: $color-gray-light;
      }
    }
  }
}

.date-range-wrapper {
  @include border-radius;
  background: $color-gray-light;
  margin: 0 40px 20px 40px;
  text-align: center;
  font-size: 12px;
  padding: 10px 0;

  input {
    font-size: 12px;
    text-align: center;
    border: 0;
    width: 85px;
    height: 22px;
    border-radius: 20px;
    font-weight: 700;
  }
  .owl-nav {
    .owl-prev,
    .owl-next {
      width: 20px;

      &:before,
      &:after {
        font-size: 12px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .date-range-wrapper {
    margin: -22px 0 20px 0;
    border-radius: 0;
  }
}
.item-sats-media-date {
  font-size: 12px;
  color: $color-gray-text;
}
.item-sats-media {
  margin-left: -10px;
  margin-right: -10px;
  iframe {
    width: 100% !important;
    height: 400px !important;
  }

  .item-sats-media-nav {
    text-align: left;
    padding: 15px;

    &:after {
      display: block;
      content: "";
      clear: both;
    }

    a {
      display: block;
      width: 50px;
      height: 50px;
      float: left;
      border: 2px solid $color-gray-light;
      @include border-radius;
      text-align: center;
      text-decoration: none;
      color: $color-gray-light;
      margin: 5px;

      img {
        width: 50px !important;
        height: 50px !important;
        @include border-radius;
      }

      span {
        font-size: 36px;
        line-height: 50px;
        text-decoration: none;
      }

      &.active,
      &:hover {
        border: 2px solid $color-blue;
        color: $color-blue;
      }
    }
  }
}

.feed-stats {
  margin-bottom: 20px;
  .item {
    background: rgba(0,0,0,0.1);
    height: 135px;
    @include border-radius;
    line-height: 18px;
    position: relative;

    .feed-stats-text {
      position: absolute;
      bottom: 10px;;
      left: 10px;
      width: calc(100% - 20px);
    }

    span {
      color: $color-blue;
      font-size: 40px;
      display: block;
      padding-bottom: 35px;
      position: absolute;
      left: 10px;
      top: 10px;
    }
    img {
      position: absolute;
      left: 5px;
      top: 5px;
      max-height: 80px !important;
      width: auto !important;
    }
    .feed-stats-count {
      color: #fff;
      font-weight: 900;
      font-size: 16px;
    }
    .feed-stats-unit {
      color: $color-blue;
      font-size: 12px;
    }
  }
  .owl-item {
    opacity: 0.5;
    @include animate-all-med;
  }
  .active {
    opacity: 1;
  }

  .owl-nav {
    .owl-prev,
    .owl-next {
      height: 135px;

      &:before,
      &:after {
        color: #fff;
      }
    }
  }
}

.trigger-edit-close,
.trigger-zurereit-close {
  color: $color-blue;
  position: absolute;
  left: 10px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.anmeldung,
.login {
  background: $color-gray-fragebogen;
  overflow: hidden;

  .error {
    color: $color-red;
    margin-bottom: 15px;
    line-height: 1.2;
  }

  .form-group {
    .error {
      color: $color-red;
      font-size: 0.8em;
      margin: 0;
      display: none;
      padding-top: 3px;
    }

    &--error {
      input, .checkbox-label, .check-label {
        border: $color-red 1px solid !important;
      }

      .error {
        display: block;
      }

      .label-animated {
        display: none;
      }
    }
  }

  .term-condition {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .animation-enter-active {
    transition: all .2s linear, opacity 0.3s ease;
  }
  .animation-leave-active {
    transition: all .2s linear, opacity 0.3s ease;
  }
  .animation-leave-to {
    transform: translateX(-400px);
    opacity: 0;
  }
  .animation-enter {
    transform: translateX(400px);
    opacity: 0;
  }

  .form-text {
    color: #fff;
    font-weight: 700;
  }

  .img-choose {
    a,
    > div {
      display: block;
      position: relative;
      @include border-radius;
      overflow: hidden;
      margin: 10px 15px;
      height: 150px;
      overflow: hidden;

      img {
        width: 100% !important;
        height: auto !important;
        @include border-radius;
        position: absolute;
        bottom: 0;
        left: 0;
        @include animate-all-slow;
      }

      span {
        display: block;
        clear: both;
        position: absolute;
        font-weight: 700;
        font-style: italic;
        color: #fff;
        left: 20px;
        bottom: 15px;
        text-transform: uppercase;
        font-size: 24px;
        z-index: 2;
      }
    }

    a {
      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
    > div {
      img {
        opacity: 0.2;
      }
    }
    @media only screen and (max-width: 400px) {
      a,
      > div {
        margin-right: 30px;
        height: 135px;

        span {
          font-size: 18px;
        }
      }
    }
  }

  .anmeldung-middle,
  .anmeldung-show {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    
    @media (max-height: 560px) {
      height: auto !important;
    }
    
    @include animate-all-fast;

    .row {
      margin-left: -2px;
      margin-right: -2px;
      padding-top: 2px;
      padding-bottom: 2px;
    }

    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
      position: relative;
      min-height: 1px;
      padding-left: 2px;
      padding-right: 2px;
    }

  }
  .popup {
    background: $color-gray-dark;
    // border: 1px solid rgba(255,255,255,0.1);
    width: 400px;
    height: 600px;
    box-sizing: border-box;
    font-size: 14px;
    margin: 0;
    position: absolute;

    &.next-step {
      position: absolute;
      margin-left: 400px;
      opacity: 0;
    }


    h4 {
      font-family: 'Open Sans Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      line-height: 22px;
      padding-top: 45px;
      padding-left: 20px;
      padding-right: 20px;

      .subhead {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding-top: 15px;
        font-family: 'Open Sans';
      }
    }
    .trigger-next {

    }
    .trigger-back {
      color: #fff;
      position: absolute;
      left: 20px;
      top: 20px;
      cursor: pointer;
      z-index: 60;
    }
    .anmeldung-text {
      position: relative;
      text-align: center;
      color: #fff;

      .img-wrapper {
        position: absolute;
        top: 155px;
        width: calc(100% - 20px);

        @media (max-height: 560px) {
          position: relative;
          width: auto;
          top: 10px;
        }
        
      }

      a {
        //display: inline !important;
        background: none !important;
        text-decoration: underline !important;
      }

      .preselect {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin-top: 10px;

        a {
          display: flex;
          background: $color-blue !important;
          color: #fff;
          font-weight: 700;
          @include border-radius;
          height: 100px;
          text-decoration: none !important;
          width: calc(50% - 3px);

          &:hover {
            background: #fff !important;
            color: $color-blue;
          }

          span {
            display: block;
            margin: auto;
          }
        }
      }
    }
    .anmeldung-content {

      position: absolute;
      bottom: 0;
      left: 10px;
      width: calc(100%  - 40px);
      
      @media (max-height: 560px) {
        position: relative;
        width: auto;
        padding: 0 20px;
        left: 0;
      }
      

      a {
        display: block;
        background: $color-gray-fragebogen;
        box-shadow: 0 4px 6px 0 rgba(0,0,0,0.15);
        border-radius: 15px;
        text-decoration: none;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        height: 90px;
        position: relative;
        @include animate-all-fast;

        img, svg {
          position: absolute;
          right: 10px;
          top: 10px;
        }

        .text {
          position: absolute;
          left: 10px;
          bottom: 10px;
          width: calc(100% - 20px);
        }
        .status-bar-wrapper {
          position: relative;
          height: 20px;
          margin: 0 10px;
        }
        .status-bar {
          background-image: linear-gradient(132deg, #00CCF9 0%, #009DF0 100%);
          border-radius: 8px;
          height: 8px;
          position: absolute;
          left: 0;
          top: 10px;

          &.status-bar-25 {
            width: 25%;
          }
          &.status-bar-50 {
            width: 50%;
          }
          &.status-bar-75 {
            width: 75%;
          }
          &.status-bar-100 {
            width: 100%;
          }
        }

        &:hover {
          background: #fff;
          color: #000;
        }
      }
    }
    .popup-text {
      span {
        color: $color-blue;
        color: rgba(0,0,0,0.3);
        font-size: 40px;
        display: block;
        position: relative;
        padding: 5px 0;
        top: -13px;
      }
    }

    section,
    footer {
      background: none;
    }

    input,
    textarea {
      color: #fff;
      background: $color-gray-dark;
    }
    textarea {
      height: 160px;
      border: 2px solid $color-gray-fragebogen;
    }
    input {
      font-size: 14px;
      border-bottom: 1px solid $color-gray-fragebogen;
      padding: 0 0 7px 0;
      margin-top: -6px;
      margin-bottom: 4px;

      &:hover,
      &:focus,
      &:active {
        color: #fff;
      }
    }
    label.label-animated {
      color: $color-gray-fragebogen;
      @include animate-all-fast;
      opacity: 1;
      transform: translateX(0);
      margin-bottom: -8px;
    }

    .hide-label {
      label {
        opacity: 0;
        transform: translateX(30px);
      }
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $color-gray-fragebogen;
      opacity: 1; /* Firefox */
      font-size: 12px;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $color-gray-fragebogen;
      font-size: 12px;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: $color-gray-fragebogen;
      font-size: 12px;
    }
    .default-form {
      margin-bottom: 15px;
      display: block;
    }

  }


  @media only screen and (max-width: 400px) {
    .popup {
      width: 100%;
    }
  }
  @media only screen and (max-height: 600px) {
    .popup {
      height: 100%;
    }
  }
  /*header {
      height: 50px;
      background: url(images/LT-Signet.svg) 50% 50% no-repeat;
  }*/
  .popup-edit-progress {
    background: rgba(0,0,0,0.1);

    .popup-edit-progress-count {
      color: rgba(0,0,0,0.3);
      display: none;
    }
    .popup-edit-progress-bar {
      background: $color-blue;
    }
  }
  .fragebogen-end {
    background: url(../images/hg-fragebogen-end.jpg) 50% 50% no-repeat;
    background-size: cover;

    .anmeldung-text {
      height: 80%;
      position: relative;

      .text {
        position: absolute;
        bottom: 0;
        width: calc(100% - 20px);
        font-weight: 700;

        span {
          color: $color-blue;
        }
      }
    }
  }
  .fragebogen-ziel-art,
  .registrierung-form {
    .anmeldung-text {
      .img-wrapper {
        top: 110px;
        @media (max-height: 560px) {
          top: 10px;
        }
      }
    }
    label.check-label {
      display: block;
      background: $color-gray-fragebogen;
      box-shadow: 0 4px 6px 0 rgba(0,0,0,0.15);
      border-radius: 15px;
      font-weight: 700;
      font-size: 14px;
      color: #fff;
      text-align: center;
      float: left;
      width: calc(100% / 2 - 4px);
      margin: 2px 2px 2px 2px;
      height: 45px;
      line-height: 45px;
      padding-left: 45px;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      text-align: left;
      @include animate-all-fast;

      &:before {
        @include icomoon-settings;
        content: "\edfb";
        color: $color-blue;
        opacity: 0;
        transform: scale(1.5);
        position: absolute;
        text-align: center;
        left: 14px;
        top: 10px;
        font-size: 24px;
        @include animate-all-fast;
      }

      &.checked {
        background: #fff;
        color: #000;

        &:before {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
    textarea {
      height: 90px;
      margin-top: 10px;
    }
  }

  .registrierung-form {
    label.check-label {
      width: calc(100% / 3 - 4px);
      padding-left: 37px;

      &:before {
        display: none;
      }
      &:after {
        content: "";
        height: 24px;
        width: 24px;
        display: block;
        position: absolute;
        left: 7px;
        top: 10px;
        background: red;
      }
      &.weiblich {
        &:after {
          background: url(../images/portrait-female.svg) 50% 50% no-repeat;
        }
      }
      &.maennlich {
        &:after {
          background: url(../images/portrait-male.svg) 50% 50% no-repeat;
        }
      }
      &.divers {
        &:after {
          background: url(../images/portrait-divers.svg) 50% 50% no-repeat;
        }
      }
    }
    .picture-upload {
      height: 80px;
      width: 80px;
      background: $color-gray-fragebogen;
      background-size: cover;
      -webkit-background-size: cover;
      border-radius: 50px;
      font-size: 35px;
      line-height: 80px;
      margin-top: 10px;
    }
    label.checkbox-label {
      @include border-radius;
      box-shadow: 0 4px 6px 0 rgba(0,0,0,0.15);
      width: 24px;
      height: 24px;
      background: $color-gray-fragebogen;
      @include animate-all-fast;
      text-align: center;
      color: $color-blue;
      font-size: 16px;
      cursor: pointer;
      line-height: 24px;

      span {
        @include animate-all-fast;
        transform: scale(1.3);
        opacity: 0;
      }

      &.checked {
        background: #fff;

        span {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
    .checkbox-wrapper {
      color: #fff;
      padding: 15px 0 15px 30px;
      position: relative;
      text-align: left;
      font-size: 12px;
      line-height: 14px;
      clear: both;

      label.checkbox-label {
        position: absolute;
        left: 0;
        top: 10px;
      }
    }
    .text-scroll {
      min-height: 320px;
      max-height: 450px;
      text-align: left;
      font-size: 12px;
      line-height: 14px;
      color: #fff;
      overflow: scroll;
      padding: 0 10px;
    }
  }

  .fragebogen-training-day {
    label.check-label {
      display: block;
      background: $color-gray-fragebogen;
      box-shadow: 0 4px 6px 0 rgba(0,0,0,0.15);
      border-radius: 15px;
      font-weight: 700;
      font-size: 14px;
      color: #fff;
      text-align: center;
      float: left;
      width: calc(100% / 7 - 4px);
      margin: 2px 2px 2px 2px;
      height: 90px;
      padding-top: 52px;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      @include animate-all-fast;

      &:before {
        @include icomoon-settings;
        content: "\edfb";
        color: $color-blue;
        opacity: 0;
        transform: scale(1.5);
      }

      &:after {
        @include icomoon-settings;
        content: "\edfc";
        color: $color-red;
        opacity: 1;
        transform: scale(1);
      }

      &:before,
      &:after {
        position: absolute;
        width: 100%;
        text-align: center;
        left: 0;
        top: 12px;
        font-size: 24px;
        @include animate-all-fast;
      }

      &.checked {
        background: #fff;
        color: #000;

        &:before {
          opacity: 1;
          transform: scale(1);
        }

        &:after {
          opacity: 0;
          transform: scale(0.5);
        }
      }
    }
  }
}
.just-show {
  overflow: auto;
  .popup {
    position: relative;
  }
}

.login {
  background: $color-gray-light;

  .login-form {
    background: #fff;

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      background: #fff;
      color: $color-gray-dark;
      border-bottom: 1px solid $color-blue-light;
    }

    .login-text {
      color: $color-gray-dark !important;

      a {
        color: $color-gray-dark !important;
        display: inline !important;
        background: none !important;
        border-radius: none !important;
        box-shadow: none !important;
      }
    }

    .page-main {
      flex-direction: column;
      .page.messages {
        margin: -15px 20px;
      }
    }

  }
  .anmeldung-text {
    color: $color-gray-dark !important;

    img {
      width: 60% !important;
      height: auto !important;
      margin-top: 20px;
    }
  }
  h1 {
    font-size: 40px;
  }
}


/*Dashboard-----------*/
.panel-head {
  color: $color-gray-mid;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 10px;
}
.dashboard-shortcuts {
  position: relative;
  a.shortcut {
    position: relative;
    background: $color-blue;
    color: #fff;
    display: table;
    @include border-radius;
    text-align: center;
    height: 170px;
    width: 100%;
    text-decoration: none;
    @include animate-all-fast;
    box-sizing: border-box;

    > div {
      display: table-row;

      > div {
        display: table-cell;
        vertical-align: middle;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;

        span {
          position: absolute;
          right: 7px;
          top: 7px;
          font-size: 24px;
        }

        .dashboard-shortuts-count {
          font-weight: 700;
          font-size: 48px;
          padding-bottom: 12px;
        }
      }
    }

    table.dashboard-marathon {
      width:100%;

      td {
        vertical-align: bottom;
        text-align: left;
        & > span {
          font-size: 20px;
          position: relative;
          right: 0;
          top: 0;
        }
      }
    }

    .competition {
      text-align: left;
      margin-left: -10px;
      margin-right: -10px;
      border-bottom: 1px solid rgba(255,255,255,0.1);
      padding: 2px 15px;
      font-size: 14px;
      width: calc(100% + 20px);
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      &:last-child {
        border: 0;
      }
      span.icon-trophy1 {
        position: relative;
        top: 0;
        right: 0;
        font-size: 16px;

      }
      span.icon-flag3 {
        position: relative;
        top: 0;
        right: 0;
        font-size: 16px;
      }
      div:first-child {
        flex-basis: 25%;
      }
      div:nth-child(2) {
        text-align: center;
      }
      div:last-child {
        text-align: right;
      }
    }
    .main-competition {
      font-size: 16px;
      padding: 10px 35px 10px 15px;

      > div {

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}


.header-info {
  background: #29A9FC;
  color: #fff;
  text-align: center;
  height: auto;
  line-height:70px;
  vertical-align:middle;
  z-index: 2;
  position: relative;

  .header-info_text {
    display: inline-block;
    text-align:center;
    vertical-align:middle;
    line-height:30px;
  }
}

.desktop {
  .dashboard-shortcuts {
    a.shortcut {
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(0.95);
      }
    }
  }
}

.dashboard-stats,
.dashboard-shortcuts {
  margin-top: 30px;
  clear: both;
}

.header-info {
  background: $color-blue;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.content-wrapper {
  position: relative;
}

@media only screen and (max-width: 768px) {
  .cd-main-content .content-wrapper {
    padding-top: 55px;
  }
  .header-info {
    font-size: 14px;
    line-height: 52px;
    height: auto;

    .header-info_text {
      line-height:24px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .header-info {
    font-size: 12px;

    .header-info_text {
      line-height:16px;
    }
  }
}

@media only screen and (max-width: 550px) {
  .header-info {
    height: auto;
    line-height:65px;
  }
}

@media only screen and (min-width: 768px) {
  .cd-main-content .content-wrapper {

  }
  /*.cd-main-content::before {
    content: 'tablet';
  }*/
}
@media only screen and (min-width: 1170px) {
  .cd-main-content .content-wrapper {

  }
  /*.cd-main-content::before {
    content: 'desktop';
  }*/
}

/* --------------------------------

Header

-------------------------------- */
.cd-main-header {
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  height: 45px;
  width: 100%;
  background: $color-gray-dark;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .nav-logo {
    height: 30px;
    width: auto !important;
    margin-left: 20px;
    margin-top: 7px;
  }
}
.cd-main-header::after {
  clear: both;
  content: "";
  display: table;

}
@media only screen and (min-width: 769px) {
  .cd-main-header {
    position: fixed;
    height: 0;

    .nav-logo {
      display:none;
    }
  }
}


.cd-nav-trigger {
  /* navigation trigger - visible on mobile devices only */
  float: right;
  position: relative;
  display: block;
  width: 34px;
  height: 44px;
  margin-right: 5%;
  /* hide text */
  overflow: hidden;
  white-space: nowrap;
  color: transparent;
}
.cd-nav-trigger span, .cd-nav-trigger span::before, .cd-nav-trigger span::after {
  /* hamburger icon in CSS */
  position: absolute;
  display: inline-block;
  height: 3px;
  width: 24px;
  background: #ffffff;
}
.cd-nav-trigger span {
  /* line in the center */
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -1px;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
}
.cd-nav-trigger span::before, .cd-nav-trigger span::after {
  /* other 2 lines */
  content: '';
  right: 0;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition: -webkit-transform 0.2s;
  -moz-transition: -moz-transform 0.2s;
  transition: transform 0.2s;
}
.cd-nav-trigger span::before {
  /* menu icon top line */
  top: -6px;
}
.cd-nav-trigger span::after {
  /* menu icon bottom line */
  top: 6px;
}
.cd-nav-trigger.nav-is-visible span {
  /* hide line in the center */
  background: rgba(255, 255, 255, 0);
}
.cd-nav-trigger.nav-is-visible span::before, .cd-nav-trigger.nav-is-visible span::after {
  /* keep visible other 2 lines */
  background: white;
}
.cd-nav-trigger.nav-is-visible span::before {
  -webkit-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -moz-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -ms-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -o-transform: translateX(4px) translateY(-3px) rotate(45deg);
  transform: translateX(4px) translateY(-3px) rotate(45deg);
}
.cd-nav-trigger.nav-is-visible span::after {
  -webkit-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -moz-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -ms-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -o-transform: translateX(4px) translateY(2px) rotate(-45deg);
  transform: translateX(4px) translateY(2px) rotate(-45deg);
}
@media only screen and (min-width: 769px) {
  .cd-nav-trigger {
    display: none;
  }
}


/* --------------------------------

Top Navigation

-------------------------------- */
.cd-nav {
  /* top nav - not visible on mobile devices */
  display: none;


}
@media only screen and (min-width: 768px) {
  .cd-nav {
    display: block;
    float: right;
    height: 100%;
  }
}

.cd-top-nav > li > a::before {
  /* reset style */
  display: none;
}
.cd-top-nav > li a {
  padding: 1em 5% !important;
}
.cd-top-nav img {
  /* avatar image */
  position: absolute;
  left: 1.8em;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: none;
}
@media only screen and (min-width: 768px) {
  .cd-top-nav {
    height: 100%;
  }
  .cd-top-nav a {
    display: block;
    font-size: 1.4rem;
    color: #ffffff;
  }
  .cd-top-nav > li {
    display: inline-block;
    margin-right: 1em;
    height: 100%;
  }
  .cd-top-nav > li:last-of-type {
    margin-right: 0;
  }
  .cd-top-nav > li a {
    padding: 1em .6em !important;
  }
  .cd-top-nav img {
    display: block;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-top-nav li:not(.has-children) a:hover {
    color: #1784c7;
  }
}

.desktop {
  .cd-side-nav > ul > li a:hover {
    background: #000;
    color: #fff;
    opacity: 1;

    span {
      opacity: 1;
    }
  }
}

.mt-video-wrapper {
  .mt-video-genre {
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 30px;

    &:nth-child(odd) {
      background: #fff;
    }
    &:first-child {
      border-top: 1px solid rgba(0,0,0,0.1);
    }
  }

  .owl-item {
    .item {
      .btn {
        position: absolute;
        top: 50%;
        margin-top: -19px;
        left: 50%;
        margin-left: -62px;
      }
    }
  }
}

.no-display {
  display: none;
}

.video-carousel-nav, .video-carousel, .fav-video-carousel {
  .item {
    a.add-fav {
      right: 20px;
      top: 20px;
    }
  }
}

.video-carousel-nav,
.video-carousel,
.fav-video-carousel,
.award-carousel,
.award-stats-carousel,
.challenge-carousel {
  overflow: hidden;
  padding-bottom: 10px;
  .owl-item {
    opacity: 0.1;
    @include animate-all-med;


    .item {
      padding: 10px;

      a {
        text-decoration: none;
        color: $color-gray-dark;

        .video-img-wrapper {
          @include border-radius;
          @include box-shadow;
          overflow: hidden;
          margin-bottom: 5px;
        }

        h4 {

        }
      }
      &.new {
        a {
          .video-img-wrapper {
            position: relative;

            &:before {
              content: "neu";
              background: $color-red;
              position: absolute;
              top: 0;
              left: 0;
              border-radius: 0 0 5px 0;
              color: #fff;
              text-transform: uppercase;
              padding: 3px 10px;
              font-weight: 700;
            }
          }
        }
      }
      .video-dummy {
        .video-img-wrapper {
          @include border-radius;
          overflow: hidden;
          margin-bottom: 5px;
        }

      }
    }

    &.active {
      opacity: 1;
    }
  }
}

.video-carousel-nav,
.video-carousel,
.award-carousel,
.fav-video-carousel,
.award-stats-carousel,
.angebote-carousel,
.challenge-carousel {
  .owl-nav {
    margin: 0;
    padding: 0;

    .owl-prev,
    .owl-next {
      position: absolute;
      left: 0;
      opacity: 1;
      width: 90px;
      top: 0;
      height: calc(100% - 40px);
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      text-align: center;
      color: rgba(255,255,255,0);
      background: none;

      &:before {
        @include icomoon-settings;
        content: "\ee05";
        display: block;
        height: 50px;
        line-height: 40px;
        font-size: 40px;
        position: absolute;
        width: 100%;
        top: 50%;
        margin-top: -20px;
        color: #000;
      }
    }
    .owl-next {
      right: 0;
      left: auto;

      &:before {
        @include icomoon-settings;
        content: "\ee06";
      }
    }
    .disabled {
      display: none;
    }
  }
}

.owl-dots {
  position: relative;
  top: -10px;
  .owl-dot {
    height: 0;

    span {
      background: transparent;
      border: 2px solid $color-gray-mid;
      margin: 4px;
      width: 12px;
      height: 12px;
    }

    &.active {
      span {
        background: $color-gray-mid;

      }
    }
  }
}


.awards {
  background: #000;

  h1 {
    color: $color-gray-dark;
  }

  h2 {
    text-align: center;
    color: $color-gray-dark;
    padding-bottom: 10px;
  }
  .award-carousel {
    .owl-item {
      box-sizing: border-box;
      margin: 0 !important;


      .item {
        text-align: center;
        //border: 2px solid $color-gray-mid;
        //margin: 0 10px;
        //@include border-radius;
        perspective: 400px;
        padding: 0 10px;
        box-sizing: border-box;
        line-height: 16px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: $color-gray-dark;

        img {
          height: 140px !important;
          width: auto !important;
          display: inline-block;
          transform: rotateY(-360deg);
          @include animate-all-med;
        }

        &.award-next {
          color: $color-gray-dark;

          img {
            transform: rotateY(0deg) !important;
            transition: none !important;
            opacity: 0.5;
          }
        }

      }

      &.active {
        opacity: 1;

        .item {
          img {
            transform: rotateY(0deg);
          }
        }
      }
    }
  }

  .owl-dots {
    margin-top: -5px !important;

    .owl-dot {

      span {
        border: 2px solid $color-gray-dark;
      }

      &.active {
        span {
          background: $color-gray-dark;

        }
      }
    }
  }

  .video-carousel-nav .owl-nav .owl-prev:before,
  .video-carousel-nav .owl-nav .owl-next:before,
  .video-carousel .owl-nav .owl-prev:before,
  .video-carousel .owl-nav .owl-next:before,
  .fav-video-carousel .owl-nav .owl-prev:before,
  .fav-video-carousel .owl-nav .owl-next:before,
  .award-carousel .owl-nav .owl-prev:before,
  .award-carousel .owl-nav .owl-next:before,
  .award-stats-carousel .owl-nav .owl-prev:before,
  .award-stats-carousel .owl-nav .owl-next:before {
    color: #fff;
  }

  .award-stats-carousel {
    .owl-item {
      opacity: 0.5;

      .item {
        position: relative;
        background: $color-gray-dark;
        color: #000;
        @include border-radius;
        margin: 0 2px;
        padding-left: 70px;
        font-size: 12px;
        line-height: 14px;
        color: rgba(255,255,255,0.3);
        perspective: 70px;

        .fact {
          color: #fff;
          font-size: 24px;
          padding-top: 8px;
          padding-bottom: 5px;
        }

        img {
          height: 50px !important;
          width: auto !important;
          position: absolute;
          left: 10px;
          top: 50%;
          margin-top: -25px;
          opacity: 0;
          /*transform: rotateY(-60deg);
                    @include animate-all-med;*/
        }
      }

      &.active {
        opacity: 1;

        .item {
          img {
            opacity: 1;
            transform: rotateY(0deg);
          }
        }
      }
    }
    .owl-nav .owl-prev,
    .owl-nav .owl-next {
      margin-top: 5px !important;
    }
  }

}






.payment-advantages {
  display: inline-block;
  background: $color-gray-mid;
  border-radius: 20px;
  padding: 7px 15px;
  margin: 10px 10px 25px 10px;

  > div {
    display: inline-block;
    padding: 3px 6px;

    span {
      color: $color-blue;
    }
  }
}




.payment-routine-header {
  max-width: 880px;
  margin: 0 auto;
  padding: 15px 0 25px 0;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  .payment-routine-step-name {
    font-size: 14px;
    width: 33%;
    float: left;
    color: $color-gray-mid;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 15px;

    a {
      color: $color-gray-mid;
      text-decoration: none;
    }
  }

  .payment-routine-status {
    height: 10px;
    position: relative;
    background: $color-gray-light;
    border-radius: 20px;

    &:before {
      content: "";
      display: block;
      width: 33%;
      height: 10px;
      background: $color-blue;
      border-radius: 20px;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-top: 7px solid $color-blue;
      position: absolute;
      top: 10px;
      left: 16.5%;
      margin-left: -7px;
    }
  }

  &.step-1 {
    .payment-routine-step-name-step-1 {
      color: $color-blue;
    }
    .payment-routine-status {
      &:before {
        left: 0;
      }
      &:after {
        left: 16.5%;
      }
    }
  }
  &.step-2 {
    .payment-routine-step-name-step-2 {
      color: $color-blue;
    }
    .payment-routine-status {
      &:before {
        left: 33%;
      }
      &:after {
        left: 49.5%;
      }
    }
  }
  &.step-3 {
    .payment-routine-step-name-step-3 {
      color: $color-blue;
    }
    .payment-routine-status {
      &:before {
        left: 67%;
      }
      &:after {
        left: 82.5%;
      }
    }
  }
}

.desktop {
  .payment-routine-header {
    .payment-routine-step-name {
      a:hover {
        color: $color-blue;
      }
    }
  }
}


@media only screen and (max-width: 768px) {
  .payment-routine-header {
    .payment-routine-step-name {
      display: none;

    }
    .payment-routine-status {
      text-align: center;
      color: #fff;
      height: 32px;

      &:before {
        height: 32px;
        line-height: 32px;
        font-weight: 700;
        font-size: 24px;
      }

      &:after {
        display: none;
      }
    }
    &.step-1 {
      .payment-routine-status {
        &:before {
          content: "1";
        }
      }
    }
    &.step-2 {
      .payment-routine-status {
        &:before {
          content: "2";
        }
      }
    }
    &.step-3 {
      .payment-routine-status {
        &:before {
          content: "3";
        }
      }
    }
    &.step-4 {
      .payment-routine-status {
        &:before {
          content: "4";
        }
      }
    }
  }
}


.angebote-carousel {
  margin-bottom: 20px;

  .owl-item {
    .item {
      opacity: 0.3;

      a {
        display: block;
        @include border-radius;
        overflow: hidden;
        @include box-shadow;
        margin-bottom: 10px;
        margin-top: 10px;
        text-align: center;
        text-decoration: none;
        background: $color-gray-dark;
        position: relative;


        img {
          opacity: 0.3;
          position: absolute;
          left: 0;
          top: 0;
          @include animate-all-med;
        }

        span {
          display: block;
          width: 100%;
          padding: 30px 10px;
          position: relative;
          box-sizing: border-box;
          z-index: 10;
          text-transform: uppercase;
          font-weight: 700;
          text-shadow: 1px 1px 2px #000;
        }
      }

      &.nav-active {
        a {
          box-shadow: 0 0 0 5px $color-blue;
        }
      }
    }

    &.active {
      .item {
        opacity: 1;
      }
    }
  }

  .owl-dots {
    margin-top: -5px !important;
  }
  .owl-nav {
    margin: 0;
    padding: 0;

    .owl-prev,
    .owl-next {
      line-height: 40px !important;
      width: 36px;
      height: 40px;
      top: 50%;
      margin-top: -32px;
      font-size: 22px !important;

      &:before {
        font-size: 22px !important;
        line-height: 40px !important;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .angebote-carousel {

    .item {
      a {
        span {
          font-size: 12px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .container-angebote {
    width: 100%;
    padding: 0;

    .row {
      padding: 0;
      margin: 0;

      > div {
        padding: 0;
        margin: 0;
      }
    }
  }
}


.desktop {
  .angebote-carousel {
    .item {
      a:hover {
        img {
          transform: scale(1.3);
        }
      }
    }
  }
}

.profile-item {
  padding: 3px 15px;
  border-radius: 5px;
  margin-bottom: 2px;
  background: #fff;
  transition: all 0.1s ease-out; }
.profile-item:after {
  content: "";
  display: block;
  clear: both; }

.desktop .profile-item:hover {
  background: $color-gray-light; }

.abo-info, .payment-info {
  background: $color-gray-light;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.abo-info .payment-img img, .payment-info .payment-img img {
  vertical-align:middle;
  width: auto !important;
}
.abo-info .payment-img img {
  height: 20px !important;
}
.payment-info .payment-img img {
  height:32px;
  display:inline-block;
  margin-left:5px;
}
.abo-info .abo-name,
.abo-info .payment-name,
.payment-info .abo-name, .payment-info .payment-name {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  min-height: 25px;
}
.payment-info .payment-name {
  line-height:32px;
}
.abo-info .abo-type,
.abo-info .abo-time,
.payment-info .abo-type,
.payment-info .abo-time {
  font-size: 14px; }
.abo-info .abo-duration,
.payment-info .abo-duration {
  font-size: 20px;
  font-weight: 700;
  min-height: 25px; }
.abo-info .abo-canceled,
.payment-info .abo-canceled {
  background: #263B47;
  color: #fff;
  clear: both;
  position: relative;
  bottom: -12px;
  margin-left: -3px;
  margin-right: -3px;
  text-transform: uppercase;
  border-radius: 0 0 5px 5px;
  padding: 2px; }
.abo-info .abo-canceled:before,
.payment-info .abo-canceled:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-bottom: 7px solid #263B47;
  position: absolute;
  left: 50%;
  margin-left: -7px;
  top: -14px; }

.cost-comparison,
.payment-method-wrapper {
  max-width: 880px;
  margin: 0 auto;

  & > div {
    background: $color-gray-light;
    position: relative;
    overflow: hidden;
    padding-bottom: 0;
    @include border-radius;
    margin-bottom: 15px;

    h2 {
      text-align: center;

      span {
        display: block;
        font-size: 16px;
        text-transform: none;
      }
    }

    &.cost-comparison-hint {
      background: $color-blue;
      color: #fff;
      @include box-shadow;
      z-index: 10;

      h2 {
        color: #fff;
      }
    }

    ul {
      list-style-type: none;
      padding-left: 25px;
      max-width: 300px;
      box-sizing: border-box;
      margin: 0 auto;

      li {
        position: relative;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4em;
        padding-bottom: 7px;

        strong {
          text-transform: uppercase;
        }

        &:before {
          @include icomoon-settings;
          color: $color-blue;
          content: "\edfb";
          position: absolute;
          left: -25px;
          top: 3px;
        }
      }
      &.credit-card-types {
        padding:0;
        max-width:none;

        li {
          list-style-type: none;
          display:inline-block;
          padding-top:7px;

          &>img {
            max-height: 20px;
            width: auto;
          }
          &::before {
            display:none;
          }
        }
        &.payment-method_right {
          position:absolute;
          right:10px;

          li {
            padding-top:0;
          }
        }
      }
    }

    &.cost-comparison-hint {
      background: $color-blue;
      color: #fff;
      @include box-shadow;
      z-index: 10;

      h2 {
        color: #fff;
      }

      ul {
        li {
          &:before {
            color: #fff;
          }
        }
      }
    }

  }

  footer {
    position: relative;
    bottom: 0;
    left: 0;
    text-align: center;
    background: $color-gray-dark;
    color: #fff;
    padding: 15px 10px;
    box-sizing: border-box;
    margin-left: -12px;
    margin-right: -12px;
    font-size: 14px;
    width: calc(100% + 24px);
    margin-top: 20px;

    .row {
      padding-left: 0;
      padding-right: 0;
      margin: 0;
    }

    /*
        .custom-radio {

            label {
                text-align: center;
                padding: 20px 5px 5px 5px;
                background: $color-blue;
                @include border-radius;
                line-height: 18px;
                margin-left: -9px;
                margin-right: -9px;
                height: 100%;

                &:before {
                    top: 5px;
                    left: 50%;
                    margin-left: -10px;
                    margin-top: 0;
                }
                &:after {
                    top: 10px;
                    left: 50%;
                    margin-left: -5px;
                    margin-top: 0;
                }
            }
        }
        */
  }
  .input-disabled {
    opacity: 0.15;
    label {
      &:before {
        display: none;
      }
    }
  }
  .active-package {
    .input-disabled {
      opacity: 1;
      label {
        background: $color-blue;
        border: $color-blue;
        color: $color-gray-dark;

        &:before {
          display: block;
          border: 1px solid $color-gray-dark;
          content: "\edfb";
          @include icomoon-settings;
          color: $color-blue;
          top: 50%;
          margin: 0;
          left: 7px;
          margin-top: -11px;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
        }
        .ac-plan-time {
          span {
            display: block;
            color: #fff;
          }
        }
      }
    }
  }
}

.payment-finish {
  .check-message-wrapper {
    display: inline-block;

    .check-message {
      display: table;
      border: 3px solid $color-blue;
      border-radius: 200px;
      width: 120px;
      height: 120px;
      text-align: center;
      color: $color-blue;
      font-size: 12px;
      line-height: 14px;


      > div {
        display: table-row;
        > div {
          display: table-cell;
          vertical-align: middle;
          padding: 0 15px 10px 15px;
          span {
            font-size: 45px;
            color: $color-blue;
            display: block;
            padding-bottom: 10px;
          }
        }
      }
    }
  }

  a {
    text-decoration: none;
  }
}


.payment {
  padding-bottom: 25px;

  &.eccard {
    padding-bottom:0;
  }
}

.payment-method-wrapper {
  > div {
    background: none;
  }

  .modal-header {
    display:none;
  }

  .payment-info-text {
    padding-top: 20px;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray-mid;

    a {
      color: $color-gray-mid;
    }
  }
  .payment-data {
    background: transparent;
    font-size: 14px;
    line-height: 26px;

    h3 {
      padding-top: 5px;
    }
  }
  .payment-method-row {
    background: none;

    #checkoutSteps {
      li {
        list-style-type:none;
      }
    }
    .payment-method {
      background: $color-gray-light;
      @include border-radius;
      @include animate-all-fast;
      border: 1px solid $color-gray-light;
      margin-bottom: 5px;

      .field-error {
        color:red;
        font-size:12px;
      }

      input[type="email"], input[type="text"], input[type="password"], input[type="number"] {
        @include border-radius;
        background: #fff;
        width: 100%;
        border:0;
        padding: 10px;
        font-size: 16px;
        color: $color-gray-dark;
        box-sizing: border-box;
        margin: 3px 0;
        font-family: 'Open Sans', sans-serif;
      }

      .control select {
        background: #FFFFFF;
        height: 42px;
        font-size:16px;
        width:100%;
        border: 0;
        @include border-radius;
        color: #6ac2fe;
        padding: 10px;
        margin: 3px 0;
        font-family: 'Open Sans', sans-serif;
      }

      .payment-form {
        display: none;
        padding-bottom: 10px;
        position: relative;
      }

      label.check-label {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        background: transparent;
        border: 0;
        margin: 0;
        color: #000;
        padding-top: 20px !important;
        padding-bottom: 20px !important;

        img {
          height: 20px !important;
          width: auto !important;
          position: initial;

          &.payment-icon {
            order: 3;
            margin: 8px 0;
          }
        }
        .credit-card-types {
          &.payment-method_right {
            order: 1;
            position: initial;
            right:10px;
            width: max-content;
            margin: 8px 0;

            li {
              padding-top:0;
              padding-bottom: 0;

              img {
                position:relative;
                right:0;
                top:0;
                margin-top:0;
              }
            }
          }
        }

        &:before {
          background: #fff;
          border: 1px solid rgba(0,0,0,0.2);
        }
      }

      &.show {
        border: 1px solid $color-blue;
        background: $color-blue-extra-light;

        label.check-label {

          &:before {
            background: #fff;
            color: $color-blue;
            border:1px solid #fff;
          }
        }
      }
    }
    .checkout-billing-address {
      font-size: 0;

      .field {
        width:50%;
        padding:0 10px;
        display:inline-block;
        font-size:16px;
        vertical-align:top;
        @include boxsize();

        &.field-select-billing {
          width:100%;
        }

        .control .field {
          width:100%;
          padding:0;
        }
      }
    }
    .billing-address-details, .payment-method-note {
      font-size:16px;
      padding:0 10px;
    }
    .form {
      padding:0 10px;
    }
    .actions-toolbar {
      padding:10px;

      &.actions-toolbar_ec {
        padding:0;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .cost-comparison {

    > div {

      &:nth-child(2) {
        border-right: 5px solid #fff;
      }

      &:last-child {
        border-left: 5px solid #fff;
      }

      &.cost-comparison-hint {
        top: -10px;
      }
    }
  }
}



[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;



  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home2:before {
  content: "\e90f"; }

.icon-office:before {
  content: "\e916"; }

.icon-pencil:before {
  content: "\e918"; }

.icon-fire:before {
  content: "\e95a"; }

.icon-gear3:before {
  content: "\e9c5"; }

.icon-saw:before {
  content: "\e9cd"; }

.icon-heart:before {
  content: "\e9e7"; }

.icon-star:before {
  content: "\e9e9"; }

.icon-flag3:before {
  content: "\e9ee"; }

.icon-paper-plane:before {
  content: "\e9f9"; }

.icon-paperclip:before {
  content: "\e9fa"; }

.icon-eye2:before {
  content: "\ea0f"; }

.icon-printer:before {
  content: "\ea1d"; }

.icon-file-empty:before {
  content: "\ea21"; }

.icon-scissors:before {
  content: "\ea40"; }

.icon-trophy1:before {
  content: "\ea5e"; }

.icon-playlist2:before {
  content: "\ea74"; }

.icon-camera3:before {
  content: "\ea98"; }

.icon-book-bookmarked2:before {
  content: "\eaa8"; }

.icon-user:before {
  content: "\eab4"; }

.icon-user-check:before {
  content: "\eabb"; }

.icon-group:before {
  content: "\eac2"; }

.icon-portrait-male:before {
  content: "\eace"; }

.icon-portrait-female:before {
  content: "\eacf"; }

.icon-running:before {
  content: "\eadf"; }

.icon-bathroom-scale:before {
  content: "\eaea"; }

.icon-height:before {
  content: "\eaeb"; }

.icon-basket:before {
  content: "\eb20"; }

.icon-map:before {
  content: "\eb6f";
}
.icon-calendar1:before {
  content: "\eb73"; }

.icon-lamp-bright:before {
  content: "\ebda"; }

.icon-bubble:before {
  content: "\ec11"; }

.icon-heart2:before {
  content: "\ec2d"; }

.icon-chart:before {
  content: "\ec4e"; }

.icon-pot:before {
  content: "\ec6e"; }

.icon-victory:before {
  content: "\eade";
}

.icon-bootcamp:before {
  content: "\e915";
}

.icon-stretching:before {
  content: "\e917";
}

.icon-yoga:before {
  content: "\e91a";
}

.icon-kraft:before {
  content: "\e919";
}

.icon-info:before {
  content: "\e913";
}

.icon-cardio:before {
  content: "\e911";
}

.icon-apple:before {
  content: "\ec99"; }

.icon-smiley:before {
  content: "\ed1c"; }

.icon-neutral:before {
  content: "\ed20"; }

.icon-grin-evil:before {
  content: "\ed33"; }

.icon-watch-health:before {
  content: "\ed9f"; }

.icon-watch2:before {
  content: "\eda1";
}

.icon-clock:before {
  content: "\eda3"; }

.icon-stopwatch:before {
  content: "\edaa";
}
.icon-stopwatch-running:before {
  content: "\edac";
}

.icon-download:before {
  content: "\edad"; }

.icon-extract-up:before {
  content: "\edb7";
}
.icon-menu:before {
  content: "\ede5";
}

.icon-ul2:before {
  content: "\ede7"; }

.icon-checklist2:before {
  content: "\ede9"; }

.icon-play:before {
  content: "\edf1"; }

.icon-exclamation:before {
  content: "\edf9"; }

.icon-question:before {
  content: "\edfa"; }

.icon-check:before {
  content: "\edfb"; }

.icon-cross:before {
  content: "\edfc"; }

.icon-plus:before {
  content: "\edfe"; }

.icon-percent:before {
  content: "\ee01"; }

.icon-chevron-up:before {
  content: "\ee03"; }

.icon-chevron-down:before {
  content: "\ee04"; }

.icon-chevron-left:before {
  content: "\ee05"; }

.icon-chevron-right:before {
  content: "\ee06"; }

.icon-expand-vertical:before {
  content: "\ee07"; }

.icon-arrow-up:before {
  content: "\ee0b"; }

.icon-arrow-down:before {
  content: "\ee0c"; }

.icon-arrow-left:before {
  content: "\ee0d"; }

.icon-arrow-right:before {
  content: "\ee0e"; }

.icon-info-circle:before {
  content: "\ee28"; }

.icon-ruler:before {
  content: "\ee93"; }

.icon-menu2:before {
  content: "\ef06";
}

.icon-trophy:before {
  content: "\e900"; }

.icon-calendar:before {
  content: "\e901"; }

.icon-map-marker:before {
  content: "\e902"; }

.icon-pin:before {
  content: "\e902"; }

.icon-location:before {
  content: "\e902"; }

.icon-heart-shiny:before {
  content: "\e903"; }

.icon-male:before {
  content: "\e904"; }

.icon-female:before {
  content: "\e905"; }

.icon-pulse:before {
  content: "\e906"; }

.icon-bag1:before {
  content: "\e907"; }

.icon-home-user:before {
  content: "\e908"; }

.icon-bandages:before {
  content: "\e909"; }

.icon-medical:before {
  content: "\e909"; }

.icon-patch:before {
  content: "\e909"; }

.icon-calendar-check:before {
  content: "\e90a"; }

.icon-calendar2:before {
  content: "\e90a"; }

.icon-date:before {
  content: "\e90a"; }

.icon-schedule:before {
  content: "\e90a"; }

.icon-palm:before {
  content: "\e90b"; }

.icon-envelope:before {
  content: "\e90c"; }

.icon-mail2:before {
  content: "\e90c"; }

.icon-letter:before {
  content: "\e90c"; }

.icon-email:before {
  content: "\e90c"; }

.icon-smartphone:before {
  content: "\e90d"; }

.icon-phone2:before {
  content: "\e90d"; }

.icon-mobile-phone:before {
  content: "\e90d"; }

.icon-telephone:before {
  content: "\e90e"; }

.icon-phone22:before {
  content: "\e90e"; }

.icon-call:before {
  content: "\e90e"; }

.icon-contact:before {
  content: "\e90e"; }

.icon-handset:before {
  content: "\e90e"; }

.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  background-color: #000;
  opacity: 0;
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(0, 0, 0, 0.4)), color-stop(100%, rgba(0, 0, 0, 0.9)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  background: radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000',endColorstr='#e6000000',GradientType=1)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  @include transition(all 0.3s ease-out);
}

.introjs-fixParent {
  z-index: auto !important;
  opacity: 1.0 !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important; }

.introjs-showElement,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
  z-index: 9999999 !important; }

.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: white;
  opacity: 0;
  filter: alpha(opacity=0); }

.introjs-relativePosition,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
  position: relative; }

.introjs-helperLayer {
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  box-shadow: 0 0px 0px 10px rgba(255, 255, 255, 0.3);
  @include transition(all 0.3s ease-out);
}

.introjs-tooltipReferenceLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  @include transition(all 0.3s ease-out);
}

.introjs-helperLayer *,
.introjs-helperLayer *:before,
.introjs-helperLayer *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -ms-box-sizing: content-box;
  -o-box-sizing: content-box;
  box-sizing: content-box; }

.introjs-helperNumberLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  top: 1px;
  left: 1px;
  z-index: 9999999999 !important;
  padding: 2px;
  font-family: Arial, verdana, tahoma;
  font-size: 13px;
  font-weight: 700;
  color: white;
  text-align: center;
  background: #29A9FC;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 4px 0 5px 0;
  display: none; }

.introjs-arrow {
  border: 5px solid transparent;
  content: '';
  position: absolute; }

.introjs-arrow.top {
  top: -10px;
  border-bottom-color: white; }

.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color: white; }

.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: white; }

.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color: white; }

.introjs-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-left-color: white; }

.introjs-arrow.bottom {
  bottom: -10px;
  border-top-color: white; }

.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: white; }

.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: white; }

.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color: white; }

.introjs-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-right-color: white; }

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  padding: 30px 10px 10px 10px;
  background-color: white;
  min-width: 200px;
  max-width: 300px;
  border-radius: 5px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  @include transition(opacity 0.1s ease-out);
}

.introjs-tooltipbuttons {
  text-align: right;
  white-space: nowrap; }

.introjs-tooltiptext {
  padding-bottom: 10px; }

.introjs-skipbutton {
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 4px;
  top: 4px;
  text-decoration: none;
  text-align: center;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
  color: #263B47;
  padding: 0;
  background: transparent; }

.introjs-donebutton {
  border-radius: 50px;
  border: 0;
  box-sizing: border-box;
  display: inline-block;
  margin: 2px;
  padding: 0 25px;
  text-decoration: none;
  color: #fff;
  background: #29A9FC;
  cursor: pointer;
  @include transition(all 0.1s ease-out);
  height: 24px;
  line-height: 25px;
  font-size: 14px;
  text-transform: none;
  position: relative;
  width: auto;
  right: 0;
  top: 0;
  float: right;
}

.introjs-disabled, .introjs-disabled:hover, .introjs-disabled:focus {
  display: none; }

.introjs-hidden {
  display: none; }

.introjs-bullets {
  text-align: left;
  position: absolute;
  top: 0px; }

.introjs-bullets ul {
  box-sizing: content-box;
  clear: both;
  margin: 15px auto 0;
  padding: 0;
  display: inline-block; }

.introjs-bullets ul li {
  box-sizing: content-box;
  list-style: none;
  float: left;
  margin: 0 2px; }

.introjs-bullets ul li a {
  box-sizing: content-box;
  display: block;
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  text-decoration: none;
  cursor: pointer; }

.introjs-bullets ul li a:hover {
  background: #999; }

.introjs-bullets ul li a.active {
  background: #999; }

.introjs-progress {
  box-sizing: content-box;
  overflow: hidden;
  height: 10px;
  margin: 10px 0 5px 0;
  border-radius: 4px;
  background-color: #ecf0f1; }

.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #08c; }

.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%; }

.introjs-fixedTooltip {
  position: fixed; }

.introjs-hint {
  box-sizing: content-box;
  position: absolute;
  background: transparent;
  width: 20px;
  height: 15px;
  cursor: pointer; }

.introjs-hint:focus {
  border: 0;
  outline: 0; }

.introjs-hidehint {
  display: none; }

.introjs-fixedhint {
  position: fixed; }

.introjs-hint:hover > .introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.57); }

.introjs-hint-pulse {
  box-sizing: content-box;
  width: 10px;
  height: 10px;
  border: 5px solid rgba(60, 60, 60, 0.27);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: rgba(136, 136, 136, 0.24);
  z-index: 10;
  position: absolute;
  @include transition(all 0.2s ease-out);
}

.introjs-hint-no-anim .introjs-hint-dot {
  -webkit-animation: none;
  -moz-animation: none;
  animation: none; }

.introjs-hint-dot {
  box-sizing: content-box;
  border: 10px solid rgba(146, 146, 146, 0.36);
  background: transparent;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  -webkit-animation: introjspulse 3s ease-out;
  -moz-animation: introjspulse 3s ease-out;
  animation: introjspulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  opacity: 0; }

.belvg-loader img {
  position:absolute;
  left:50%;
  margin-left:-32px;
  z-index:1;
}
.preload-hide {
  & > * {
    opacity:0.2;
    pointer-events: none;
  }
  & > .belvg-loader {
    opacity:1;
    display:block !important;
  }
}

.gaus-blur {
  @include transition('1s');
  zoom: 1;
  -o-filter: blur(10px);
  -moz-filter: blur(10px);
  -webkit-filter: blur(10px);
  filter: progid:DXImageTransform.Microsoft.Blur(pixelRadius=3);
  -ms-filter: "progid:DXImageTransform.Microsoft.Blur(pixelradius=3)";
  pointer-events: none;
}

.green_checkmark {
  position: relative;
  width: 21px;
  height: 12px;
  display: inline-block;
}

.check-sign {
  position: absolute;
  background: green;
}

#check-part-1 {
  width: 10px;
  height: 2px;
  transform:rotate(45deg);
  top:7px;
}

#check-part-2 {
  width: 16px;
  height: 2px;
  transform: rotate(-45deg);
  left: 5px;
  top: 5px;
}

@-webkit-keyframes introjspulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0; }
  25% {
    -webkit-transform: scale(0);
    opacity: 0.1; }
  50% {
    -webkit-transform: scale(0.1);
    opacity: 0.3; }
  75% {
    -webkit-transform: scale(0.5);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.0; } }

@-moz-keyframes introjspulse {
  0% {
    -moz-transform: scale(0);
    opacity: 0.0; }
  25% {
    -moz-transform: scale(0);
    opacity: 0.1; }
  50% {
    -moz-transform: scale(0.1);
    opacity: 0.3; }
  75% {
    -moz-transform: scale(0.5);
    opacity: 0.5; }
  100% {
    -moz-transform: scale(1);
    opacity: 0.0; } }

@keyframes introjspulse {
  0% {
    transform: scale(0);
    opacity: 0.0; }
  25% {
    transform: scale(0);
    opacity: 0.1; }
  50% {
    transform: scale(0.1);
    opacity: 0.3; }
  75% {
    transform: scale(0.5);
    opacity: 0.5; }
  100% {
    transform: scale(1);
    opacity: 0.0; }
}


.nutrition {
  background: url(../images/hg-ernaehrung.jpg) 50% 50% no-repeat;
  background-attachment: fixed;
  background-size: cover;

  a {
    color: #29A9FC;
  }
}

.nutrition .cd-side-nav {
  box-shadow: 0 0 10px 0 black;
}

.nutrition h1 {
  background: #000;
  color: #fff;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .nutrition .content-wrapper {
    padding-top: 45px !important;
  }
}

.nutrition h2 {
  font-family: 'Open Sans Condensed';
  font-size: 30px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 32px;
}

.nutrition .header-wrapper {
  padding: 40px 40px 0 40px;
  margin-bottom: 45px;
  clear: both;
}

.nutrition .header-wrapper a {
  text-transform: uppercase;
  float: right;
  margin-top: 4px;
  text-decoration: none;
}

.nutrition .header-wrapper h2, .nutrition .header-wrapper h3 {
  float: left;
  padding: 0;
}

.nutrition .intensity-explanation {
  color: #fff;
}

.nutrition .page-functions a {
  color: rgba(255, 255, 255, 0.5);
}

.nutrition .page-functions a:hover {
  color: #fff;
}

.nutrition .week-nav-wrapper {
  border-radius: 5px;
  background: rgba(150, 150, 150, 0.4);
  margin: 0 40px 40px 40px;
  padding: 0px 0 !important;
}

@media only screen and (max-width: 768px) {
  .nutrition .week-nav-wrapper {
    border-radius: 0;
    margin: 0 0px 40px 0px;
  }
}

.nutrition .week-nav {
  overflow: auto;
}

.nutrition .week-nav .owl-stage-outer,
.nutrition .week-nav .owl-stage {
  padding-bottom: 5px;
  padding-top: 20px;
}

.nutrition .week-nav .item {
  background: #fff;
  border-radius: 100px;
  padding: 2px;
}

.nutrition .week-nav .item:after {
  content: "";
  display: block;
  clear: both;
}

.nutrition .week-nav .item .week-info {
  position: absolute;
  color: #fff;
  font-size: 12px;
  top: -25px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.nutrition .week-nav .item .week-nav-day {
  color: rgba(0, 0, 0, 0.5);
  float: left;
  width: calc(100% / 7);
  text-align: center;
  line-height: 20px;
  padding: 5px 0;
  position: relative;
  border-radius: 100px;
}

.nutrition .week-nav .item .week-nav-day.active {
  background: $color-gray-light;
  cursor: pointer;
}

.nutrition .week-nav .item .week-nav-day.active .number {
  color: #000;
}

/*
.nutrition .week-nav .item .week-nav-day.training-planed .number {
  color: #000;
}

.nutrition .week-nav .item .week-nav-day.training-planed:hover {
  background: $color-gray-light;
}
*/

.nutrition .week-nav .item .week-nav-day .weekday {
  font-weight: 300;
}

.nutrition .week-nav .item .week-nav-day .number {
  font-weight: 700;
}

.nutrition .week-nav .item .week-nav-day.intensity-1:after,
.nutrition .week-nav .item .week-nav-day.intensity-2:after,
.nutrition .week-nav .item .week-nav-day.intensity-3:after,
.nutrition .week-nav .item .week-nav-day.intensity-4:after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  left: 50%;
  margin-left: -5px;
  bottom: -7px;
}

.nutrition .week-nav .item .week-nav-day.intensity-1:after {
  background: #29A9FC;
}

.nutrition .week-nav .item .week-nav-day.intensity-2:after {
  background: #76B82A;
}

.nutrition .week-nav .item .week-nav-day.intensity-3:after {
  background: #ffa800;
}

.nutrition .week-nav .item .week-nav-day.intensity-4:after {
  background: #FB2A1A;
}

.nutrition .week-nav .owl-nav.disabled + .owl-dots {
  margin-top: -5px;
  padding: 0 0 15px 0;
}

.nutrition .week-nav .owl-nav {
  margin: 0;
  padding: 0;
}

.nutrition .week-nav .owl-nav .owl-prev,
.nutrition .week-nav .owl-nav .owl-next {
  position: absolute;
  left: 0px;
  opacity: 1;
  width: 60px;
  top: 0;
  height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 0px;
  margin: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0);
  background: none;
}

.nutrition .week-nav .owl-nav .owl-prev:before,
.nutrition .week-nav .owl-nav .owl-next:before {
  text-align: center;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ee05";
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  position: absolute;
  width: 100%;
  top: 0;
  color: #29A9FC;
}

.nutrition .week-nav .owl-nav .owl-next {
  right: 0px;
  left: auto;
}

.nutrition .week-nav .owl-nav .owl-next:before {
  text-align: center;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ee06";
  line-height: 40px;
}

.nutrition .week-nav .owl-nav .disabled {
  display: none;
}

@media only screen and (max-width: 768px) {
  .nutrition .week-nav {
    margin-top: -22px;
  }
  .nutrition .week-nav .item .week-nav-day {
    font-size: 12px;
    line-height: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.nutrition .meal-carousel {
  border-radius: 5px;
  overflow: hidden;
}

.nutrition .meal-carousel .owl-item {
  opacity: 0.3;
}

.nutrition .meal-carousel .owl-item.active {
  opacity: 1;
}

.nutrition .meal-carousel a.open-meal {
  display: block;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  color: #000;
  text-decoration: none;
  font-family: 'Open Sans';
  font-size: 18px;
  line-height: 23px;
}

.nutrition .meal-carousel a.open-meal .meal-title {
  padding: 20px;
}

.nutrition .meal-carousel a.open-meal img {
  width: 100% !important;
  height: auto !important;
}

.nutrition .meal-carousel a.open-meal .img-wrapper {
  position: relative;
}

.nutrition .meal-carousel .owl-nav {
  margin: 0;
  padding: 0;
}

.nutrition .meal-carousel .owl-nav .owl-prev,
.nutrition .meal-carousel .owl-nav .owl-next {
  position: absolute;
  left: 0px;
  opacity: 1;
  width: 35px;
  height: 100%;
  top: 0;
  height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 0px;
  margin: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0);
  background: none;
}

.nutrition .meal-carousel .owl-nav .owl-prev:before,
.nutrition .meal-carousel .owl-nav .owl-next:before {
  text-align: center;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ee05";
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -20px;
  color: #fff;
}

.nutrition .meal-carousel .owl-nav .owl-next {
  right: 0px;
  left: auto;
}

.nutrition .meal-carousel .owl-nav .owl-next:before {
  text-align: center;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ee06";
  line-height: 40px;
}

.nutrition .meal-carousel .owl-nav .disabled {
  display: none;
}

.nutrition .popup-meal {
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  background: url(../images/hg-ernaehrung-blur.jpg) 50% 50% no-repeat;
  background-size: cover;
  color: #fff;
  text-align: left;
}

.nutrition .popup-meal .mfp-close {
  background: none;
  color: #29A9FC;
  opacity: 1;
  border-radius: 50px;
  width: 34px;
  height: 34px;
  line-height: 36px;
  top: 5px;
  right: 5px;
}

.nutrition .popup-meal section {
  padding: 0;
  transition: all 0.3s ease-out;
}

.nutrition .popup-meal section h3 {
  font-family: 'Open Sans Condensed';
  font-size: 21px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 28px;
  padding-top: 0;
  font-weight: 700;
}

.nutrition .popup-meal section h3 span {
  font-weight: 400;
  font-size: 12px;
  display: block;
}

.nutrition .popup-meal section .popup-meal-header {
  text-align: left;
  padding: 50px 20px 20px 20px;
}

.nutrition .popup-meal section .popup-meal-text {
  padding: 25px;
  text-align: center;
}

.nutrition .popup-meal section .popup-meal-img-wrapper {
  width: 100% !important;
  min-height: 400px;
  position: relative;
}

.nutrition .popup-meal section img {
  width: 100% !important;
  height: auto !important;
}

.nutrition-challenges-index {
  .ui-datepicker {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    margin-top: -150px;
    margin-left: -139px;
  }
}

@media only screen and (max-width: 768px) {
  .nutrition .nutrition-wrapper {
    padding: 0 !important;
    margin: 0 !important;
  }

  .nutrition .week-nav .owl-nav .owl-prev:before,
  .nutrition .week-nav .owl-nav .owl-next:before {
    top: 6px;
  }
}

.nutrition .meal-portions {
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  margin-left: -25px;
  width: calc(100% + 50px);
  padding-bottom: 20px;
  padding-top: 20px;
}

.nutrition .meal-portions .meal-portions-editor {
  position: relative;
  display: inline-block;
  width: 240px;
}

.nutrition .meal-portions .meal-portions-editor .meal-portion-count {
  background: #000000;
  border: 2px solid #009DF0;
  width: 72px;
  height: 72px;
  border-radius: 100px;
  font-size: 36px;
  line-height: 72px;
  display: inline-block;
  font-weight: 700;
  position: relative;
  z-index: 5;
  margin-top: 5px;
}

.nutrition .meal-portions .meal-portions-editor button {
  color: #fff;
  font-size: 36px;
  text-align: center;
  height: 50px;
  border: 0;
  background: #29A9FC;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  width: 95px;
  padding-bottom: 5px;
  font-weight: 300;
  transition: all 0.1s ease-out;
}

.nutrition .meal-portions .meal-portions-editor button:hover {
  transform: scale(1.05);
}

.nutrition .meal-portions .meal-portions-editor button:active {
  transform: scale(0.95);
}

.nutrition .meal-portions .meal-portions-editor .btn-minus {
  left: 0;
  border-radius: 50px 0 0 50px;
}

.nutrition .meal-portions .meal-portions-editor .btn-plus {
  right: 0;
  border-radius: 0 50px 50px 0;
}

.nutrition .meal-checklist .item {
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  padding: 8px 5px;
}

.nutrition .meal-checklist .item .checkbox {
  width: 100px;
  height: 100px;
  display: inline-block;
  margin-bottom: 5px;
  border-radius: 50px;
  background: #000;
  position: relative;
  cursor: pointer;
}

.nutrition .meal-checklist .item .checkbox:after {
  content: "";
  display: block;
  width: 98px;
  height: 40px;
  position: absolute;
  left: 1px;
  top: 30px;
  background: #29A9FC;
  border-radius: 52px;
  z-index: 2;
  transition: all 0.4s ease-out;
}

.nutrition .meal-checklist .item .checkbox:before {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #000;
  border-radius: 52px;
  z-index: 4;
}

.nutrition .meal-checklist .item .checkbox span {
  color: #29A9FC;
  font-size: 32px;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  transition: all 0.2s ease-out;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.nutrition .meal-checklist .item .checkbox img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50px;
  z-index: 5;
  position: relative;
  transition: all 0.3s ease-out;
  margin-bottom: 4px;
}

.nutrition .meal-checklist .item .checkbox:hover img {
  opacity: 0.7;
}

.nutrition .meal-checklist .item .checkbox.active img {
  opacity: 0.3;
}

.nutrition .meal-checklist .item .checkbox.active span {
  opacity: 1;
}

.nutrition .meal-checklist .item .checkbox.active:after {
  width: 104px;
  height: 104px;
  left: -2px;
  top: -2px;
  transform: rotate(180deg);
}

.nutrition .meal-zubreitung {
  //display: none;
}

.nutrition .meal-zubreitung ul.meal-ingredients {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nutrition .meal-zubreitung ul.meal-ingredients li {
  text-align: left;
  position: relative;
  box-sizing: border-box;
  padding-left: 85px;
}

.nutrition .meal-zubreitung ul.meal-ingredients li .prop {
  width: 75px;
  position: absolute;
  left: 0;
  top: 3px;
  text-align: center;
  box-sizing: border-box;
}

.nutrition .meal-zubreitung ul.meal-steps {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nutrition .meal-zubreitung ul.meal-steps li .step-number {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff;
  line-height: 50px;
  font-family: 'Open Sans Condensed';
  font-weight: 700;
  font-size: 24px;
  border: 2px solid rgba(0, 157, 240, 0.2);
  border-radius: 30px;
  position: relative;
  margin: 10px 0;
}

.nutrition .meal-zubreitung ul.meal-steps li .step-number:before,
.nutrition .meal-zubreitung ul.meal-steps li .step-number:after {
  content: "";
  display: block;
  height: 2px;
  width: 70px;
  background: #29A9FC;
  opacity: 0.2;
  position: absolute;
  top: 50%;
  margin-top: -1px;
}

.nutrition .meal-zubreitung ul.meal-steps li .step-number:before {
  left: -80px;
}

.nutrition .meal-zubreitung ul.meal-steps li .step-number:after {
  right: -80px;
}

.nutrition .trigger-zurereit-close {
  color: #29A9FC;
  position: absolute;
  left: 10px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

a.add-fav {
  position: absolute;
  background: #fff;
  color: #29A9FC !important;
  display: block;
  width: 32px;
  height: 32px;
  line-height: 34px;
  z-index: 10;
  right: 10px;
  top: 10px;
  border-radius: 5px;
  transition: all 0.1s ease-out;
  text-align: center;
  text-decoration: none;
}

a.add-fav:hover {
  transform: scale(1.1);
}

a.add-fav.active {
  background: #29A9FC;
  color: #fff !important;
}

.meal-info {
  text-align: right;
  width: calc(100% - 40px);
  position: absolute;
  right: 20px;
  bottom: 12px;
}

.week-nav-wrapper {
  @include border-radius;
  background: rgba(150,150,150,0.4);
  margin: 0 40px 40px 40px;
  padding: 0px 0 !important;
}
@media only screen and (max-width: 768px) {
  .week-nav-wrapper {
    border-radius: 0;
    margin: 0 0px 40px 0px;
  }
}

.week-nav {
  overflow: hidden;

  .owl-item {
    opacity: 0.2;
    @include animate-all-fast;

    &.active {
      opacity: 1;
    }
  }

  //.owl-stage-outer,
  .owl-stage {
    padding-bottom: 28px;
    padding-top: 35px;
  }

  .item {
    border-radius: 100px;
    padding: 2px;

    &:before {
      content: "";
      display: block;
      background: red;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      background: #fff;
      z-index: 10;
    }

    &:after {
      content: "";
      display: block;
      clear: both;
    }
    .week-info {
      position: absolute;
      color: $color-gray-text;
      font-size: 14px;
      top: -28px;
      //font-weight: 700;
      text-align: center;
      width: 100%;
      line-height: 20px;

    }
    .week-info2 {
      color: #fff;
      border-radius: 10px 10px 0 0;
      background: none;
      position: absolute;
      bottom: -27px;
      font-size: 12px;
      text-align: center;
      border-radius: 20px;
      right: 0;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 700;

      strong {
        text-transform: uppercase;
      }
    }

    .week-nav-day {
      color: rgba(0,0,0,0.5);
      float: left;
      width: calc(100% / 7);
      text-align: center;
      line-height: 20px;
      padding: 5px 0;
      position: relative;
      border-radius: 100px;
      z-index: 20;

      &.active {
        background: $color-gray-light;

        .number {
          color: #000;
        }
      }

      &.training-planed {
        &.intensity-0 {
          .number {
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .number {
          color: #000;
          cursor: pointer;
        }

        &:hover {
          background: $color-gray-light;
        }
      }

      .weekday {
        font-weight: 300;
      }

      .number {
        font-weight: 700;
      }

      &.intensity-1,
      &.intensity-2,
      &.intensity-3,
      &.intensity-4 {
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          left: 50%;
          margin-left: -5px;
          bottom: -7px;

        }
      }
      &.intensity-1 {
        &:after {
          background: $color-blue;
        }
      }
      &.intensity-2 {
        &:after {
          background: $color-green;
        }
      }
      &.intensity-3 {
        &:after {
          background: $color-orange;
        }
      }
      &.intensity-4 {
        &:after {
          background: $color-red;
        }
      }
    }
  }

  .owl-nav {
    .owl-prev,
    .owl-next {
      width: 15px;
      height: 54px;
      margin-top: 43px;
      top: auto !important;
      bottom: 28px;

      &:before,
      &:after {
        color: #fff;
        font-size: 12px;
      }
    }
  }
  .owl-nav.disabled + .owl-dots {
    margin-top: -5px;
    padding: 0 0 15px 0;
  }


}

.nutrition {
  .week-nav {
    .item {
      .week-info {
        color: #fff;
      }
      .week-info2 {
        color: rgba(255,255,255,0.5);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .week-nav {
    margin-top: -22px;

    .item {
      .week-nav-day {
        font-size: 12px;
        line-height: 14px;
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
    .owl-nav {
      .owl-prev,
      .owl-next {
        width: 20px;
        height: 40px;
      }
    }
  }
}


.subnav {
  position: absolute;
  width: 100%;
  margin-top: -54px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    li {
      width: calc(100% / 4);
      float: left;
      text-align: center;
      padding: 0;
      margin: 0;

      a {
        text-decoration: none;
        display: block;
        padding: 4px 10px;

        &:hover {
          color: $color-gray-dark;
        }
      }

      &.active {
        a {
          border-bottom: 2px solid $color-blue;
        }
      }
    }
  }
}

.nutrition {
  .subnav {
    ul {
      li {
        width: calc(100% / 3 - 20px);

        a {
          &:hover {
            color: #fff;

            span {
              color: #fff;
            }
          }
        }

        &:last-child {
          width: 60px;
          font-weight: 700;
          a {
            color: #fff;
          }

          span {
            color: $color-blue;
            font-weight: 400;
          }

          a {
            &:hover {
              color: #fff;

              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.prop {
  background: #fff;
  color: #000;
  font-size: 12px;
  line-height: 12px;
  border-radius: 20px;
  padding: 3px 8px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;

  &.intesity-1 {
    background: $color-blue;
    color: #fff;
  }
  &.intesity-2 {
    background: $color-green;
    color: #fff;
  }
  &.intesity-3 {
    background: $color-orange;
    color: #fff;
  }
  &.intesity-4 {
    background: $color-red;
    color: #fff;
  }
}


/*Carousel----------------*/
.owl-carousel {
  position: relative;
}
.owl-nav {
  margin: 0;
  padding: 0;

  .owl-prev,
  .owl-next {
    position: absolute;
    left: 0px;
    opacity: 1;
    width: 35px;
    height: 100%;
    top: 0;
    height: 40px;
    box-sizing: border-box;
    padding: 0px;
    margin: 0;
    text-align: center;
    color: rgba(255,255,255,0);
    background: none;

    &:hover {
      background: none;
      color: rgba(0,0,0,0);
    }

    &:before {
      @include icomoon-settings;
      content: "\ee05";
      display: block;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      position: absolute;
      width: 100%;
      top: 50%;
      margin-top: -20px;
      color: #000;
    }
  }
  .owl-next {
    right: 0px;
    left: auto;

    &:before {
      @include icomoon-settings;
      content: "\ee06";
      line-height: 40px;
    }
  }
  .disabled {
    display: none;
  }
}
.owl-dots {
  .owl-dot {
    span {
      border: 2px solid rgba(255,255,255,0.2);
    }
    &.active {
      span {
        background: rgba(255,255,255,0.2);
        border: 2px solid rgba(255,255,255,0);
      }
    }
  }
}

/*Popups------------------*/


.popup-text {
  padding: 25px 20px;

  &-title {
    text-align: center;
  }

  &-descr {
    padding-bottom: 15px;
    text-align: initial;

    p {
      padding-bottom: 0;
    }

    blockquote {
      border-left: 4px solid #ccc;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 16px;
    }

    ul, ol {
      padding-left: 48px;
    }
  }

  .training-feeling-bar-display {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 15px;
  }
}
.popup-meal,
.popup-challenge {
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  background: url(../images/hg-ernaehrung-blur.jpg) 50% 50% no-repeat;
  background-size: cover;
  color: #fff;
  text-align: left;

  .mfp-close {
    background: none;
    color: $color-blue;
    opacity: 1;
    border-radius: 50px;
    width: 34px;
    height: 34px;
    line-height: 36px;
    top: 5px;
    right: 5px;
  }

  section {
    padding: 0;
    transition: all 0.3s ease-out;

    h3 {
      font-family: 'Open Sans Condensed';
      font-size: 21px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 28px;
      padding-top: 0;
      font-weight: 700;

      span {
        font-weight: 400;
        font-size: 12px;
        display: block;
      }
    }

    .popup-meal-header {
      text-align: left;
      padding: 50px 20px 20px 20px;
    }



    .popup-meal-img-wrapper {
      width: 100% !important;
      position: relative;

      img {
        width: 100% !important;
        height: auto !important;
      }

    }

    .popup-meal-ingredients {
      background: rgba(0,0,0,0.5);
      padding: 5px 0;
      position: relative;
      top: -5px;

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      .popup-meal-ingredient {
        float: left;
        width: 25%;
        box-sizing: border-box;
        padding: 0 5px;
        line-height: 19px;

        .popup-meal-ingredient-count {
          font-weight: 700;
          font-size: 18px;
        }
        .popup-meal-ingredient-unit {
          font-size: 12px;
        }
        @media only screen and (max-width: 450px) {
          .popup-meal-ingredient-unit {
            font-size: 12px;
          }
        }
      }
    }

  }
}

.popup-challenge {
  input[type="email"],
  input[type="text"],
  input[type="date"] {
    text-align: center;
    background: transparent !important;
    color: #fff !important;
    border-bottom: 2px solid $color-gray-fragebogen !important;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $color-gray-text !important;
      opacity: 1; /* Firefox */
      font-size: 14px !important;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $color-gray-text !important;
      font-size: 14px !important;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: $color-gray-text !important;
      font-size: 14px !important;
    }
  }
  .mfp-close {
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.8);
  }
}

.ernaehrung,
.trainingsplan,
.tagebuch,
.meal-intro {
  h1 {
    margin-bottom: 20px;
    background: #fff;
  }
  @media only screen and (max-width: 768px) {
    .content-wrapper {
      padding-top: 45px !important;
    }
  }

  h2 {
    font-family: 'Open Sans Condensed';
    font-size: 30px;
    letter-spacing: 0;
    line-height: 32px;
  }
  h4 {
    font-family: 'Open Sans Condensed';
    font-weight: 700;
    font-size: 16px;
    padding: 20px 0 10px 0;
  }
}


.nutrition {
  background: url(../images/hg-ernaehrung.jpg) 50% 50% no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .popup-slide-content {
    background: url(../images/hg-ernaehrung-blur.jpg) 50% 50% no-repeat;
  }

  .cd-side-nav {
    box-shadow: 0 0 10px 0 rgba(0,0,0,1);
  }


  h1 {
    background: #000;
    color: #fff;
  }
  h2, h3, h4 {
    color: #fff;
  }

  .header-wrapper {
    padding: 40px 40px 0 40px;
    margin-bottom: 45px;
    clear: both;

    a {
      text-transform: uppercase;
      float: right;
      margin-top: 4px;
      text-decoration: none;
    }
    h2, h3 {
      float: left;
      padding: 0;
    }
  }

  .intensity-explanation {
    color: #fff;
  }

  .page-functions {
    a {
      color: rgba(255,255,255,0.5);

      &:hover {
        color: #fff;
      }
    }
  }


  .meal-carousel {
    @include border-radius;
    overflow: hidden;

    .owl-item {
      opacity: 0.3;

      &.active {
        opacity: 1;
      }
    }



    a.open-meal {
      display: block;
      background: #fff;
      @include border-radius;
      overflow: hidden;
      color: #000;
      text-decoration: none;
      font-family: 'Open Sans';
      font-size: 18px;
      line-height: 23px;

      .meal-title {
        padding: 20px;
      }

      img {
        width: 100% !important;
        height: auto !important;
      }
      .img-wrapper {
        position: relative;
      }
    }

    .owl-nav {
      .owl-prev,
      .owl-next {
        height: 100%;

        &:before,
        &:after {
          color: #fff;
        }
      }
    }
  }

  .meal-container-items {
    padding: 0 40px;
  }
  .meal-container {
    .meal-fav {
      a.open-meal {
        text-decoration: none;
        color: #fff;
        display: block;
        position: relative;
        text-align: center;
        line-height: 16px;
        font-size: 14px;
        padding-bottom: 20px;

        img {
          width: calc(100% + 20px) !important;
          height: auto !important;
          @include border-radius;
          margin-left: -10px;
          margin-bottom: 5px;
        }
      }
      a.add-fav {
      }
      @media only screen and (max-width: 768px) {
        a.open-meal {
          font-size: 12px;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .meal-container-items {
        padding: 0 20px;
      }
      .header-wrapper {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .meal-filter {
      padding: 20px 40px;
      position: relative;

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      label {
        border: 2px solid rgba(255,255,255,0.2);
        @include border-radius;
        width: calc(25% - 4px);
        float: left;
        padding: 0 4px !important;
        box-sizing: border-box;
        margin: 2px;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;
        height: 40px;
        line-height: 40px;
        position: relative;
        cursor: pointer;
        @include animate-all-fast;

        &:before {
          display: none;
        }

        &:after {
          content: "\edfb";
          @include icomoon-settings;
          color: #fff;
          background: none;
          left: 50%;
          width: 30px;
          margin-left: -15px;
          top: 1px;
          margin-top: 0;
          font-size: 18px;
          position: absolute;
          transform: scale(2);
          @include animate-all-fast;
          opacity: 0;
        }

        &.check-label-light {
          color: $color-blue;
          border: 2px solid rgba(41,169,252,0.2);
          &:after {
            color: $color-blue;
          }
        }
        &.check-label-medium {
          color: $color-green;
          border: 2px solid rgba(118,184,42,0.2);
          &:after {
            color: $color-green;
          }
        }
        &.check-label-power {
          color: $color-orange;
          border: 2px solid rgba(255,168,0,0.2);
          &:after {
            color: $color-orange;
          }
        }
        &.check-label-booster {
          color: $color-red;
          border: 2px solid rgba(251,41,26,0.2);
          &:after {
            color: $color-red;
          }
        }

        &.checked {
          border: 2px solid #fff;
          line-height: 53px;

          &:after {
            opacity: 1;
            transform: scale(1);
          }

          &.check-label-light {
            border: 2px solid $color-blue;
          }
          &.check-label-medium {
            border: 2px solid $color-green;
          }
          &.check-label-power {
            border: 2px solid $color-orange;
          }
          &.check-label-booster {
            border: 2px solid $color-red;
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .meal-filter {
        padding: 20px 20px 40px 20px;
      }
    }

    .meal-shoppinglist-item {
      background: rgba(0,0,0,0.5);
      min-height: 50px;
      position: relative;
      box-sizing: border-box;
      padding: 8px 10px 5px 60px;
      color: #fff;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 2px;

      .meal-weight {
        font-size: 12px;
        font-weight: 700;
      }

      .check-trigger {
        position: absolute;
        left: 10px;
        top: 5px;
        width: 40px !important;
        height: 40px !important;
        border-radius: 20px;
        overflow: hidden;
        background: $color-blue;
        cursor: pointer;

        span {
          display: block;
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          position: absolute;
          left: 0;
          top: 0;
          transition: all 0.2s ease-out;
          transform: scale(1.5);
          opacity: 0;
        }

        img {
          width: 40px !important;
          height: 40px !important;
          border-radius: 19px;
          transition: all 0.2s ease-out;
        }
        &.active {
          img {
            opacity: 0;
          }
          span {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .meal-shoppinglist-item {
        margin-left: -20px;
        margin-right: -20px;
      }
    }
    .ka-panel {
      .ka-handler {
        cursor: pointer;

        span {
          float: right;
          position: relative;
          top: 6px;
          @include animate-all-fast;
        }
      }

      &.open {
        .ka-handler {
          span {
            transform: rotate(180deg);
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .ka-panel {
        .ka-content {
          margin-left: -20px;
          margin-right: -20px;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}

.challenge-link {
  @include border-radius;
  display: block;
  overflow: hidden;
  position: relative;
  background: #000;
  margin-bottom: 20px;

  .challenge-photo {
    width: 100% !important;
    height: auto !important;
    opacity: 0.8;
    margin-bottom: 0;
    transition: all 0.5s ease-out;
    object-fit: cover;
    max-height: 195px;
    min-height: 195px;
  }
  .challenge-description-preview {
    &.with-logo,
    &.without-logo {
      overflow: hidden;
      line-height: 19px;
    }

    &.with-logo {
      max-height: 19px;
      bottom: 10%;
      top: auto;
    }

    &.without-logo {
      bottom: auto;
      top: 20px;
      max-height: 156px;
    }

    p {
      padding: 0;
    }
  }
  .challenge-logo {
    position: absolute;
    height: auto !important;
    width: 70% !important;
    left: 50%;
    margin-left: -35%;
    z-index: 2;
    top: 50%;
    margin-top: -65px;
    transition: all 0.5s ease-out;
  }
  .text {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: $color-blue;
    color: #fff;
    box-sizing: border-box;
    opacity: 0;
    transform: scale(1.1);
    transition: all 0.5s ease-out;
    font-size: 14px;
    line-height: 17px;

    > div {
      position: absolute;
      bottom: 9%;
      width: 80%;
      left: 10%;
      text-align: initial;
    }

    .preview-title {
      text-align: center;
    }
  }

  &:hover {
    .challenge-photo {
      transform: scale(1.2);
    }
    .challenge-logo {
      margin-top: -110px;
    }
    .text {
      opacity: 1;
      transform: scale(1);
    }
  }
}
@media only screen and (max-width: 1450px) and (min-width: 1200px) {
  .challenge-link {

    .challenge-logo {
      margin-top: -50px;
    }
    .text {

      > div {
        top: 50%;
      }
    }

    &:hover {
      .challenge-logo {
        margin-top: -95px;
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .challenge-link {

    .challenge-logo {
      margin-top: -100px;
    }
    .text {

      > div {
        top: 60%;
      }
    }

    &:hover {
      .challenge-logo {
        margin-top: -190px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .challenge-link {

    .challenge-logo {
      width: 300px !important;
      margin-left: -150px;
      margin-top: -75px;
    }
    .text {

      > div {
        top: 60%;
      }
    }

    &:hover {
      .challenge-logo {
        margin-top: -140px;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .challenge-link {

    .challenge-logo {
      width: 250px !important;
      margin-left: -125px;
      margin-top: -60px;
    }
    .text {

      > div {
        top: 50%;
      }
    }

    &:hover {
      .challenge-logo {
        margin-top: -110px;
      }
    }
  }
}

.mini-menu-trigger {
  color: $color-blue;
  transform: rotate(90deg);
  font-size: 20px;
  cursor: pointer;
}
.popup-menu {
  position: absolute;
  right: 50px;
}
.dropdown-menu {
  position: absolute;
  z-index: 9999999;
  display: none;
  opacity: 0;
  top: 0;
  left: 0;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
  -webkit-transform: translateY(-20px) scale(0.93);
  transform: translateY(-20px) scale(0.93);
  border-radius: 3px;
  z-index: 120;
}
.dropdown-menu.dropdown-opened {
  opacity: 1;
  -webkit-transform: none !important;
  transform: none !important; }
.dropdown-menu.fixed {
  position: fixed; }

.dropdown-menu.max-height ul {
  max-height: 184px; }
.dropdown-menu ul {
  min-width: 120px;
  list-style: none;
  background: $color-gray-light;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  overflow: auto;
  padding: 5px 0;
  margin: 0;
  margin-left: -60px;
}
.dropdown-menu ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 18px; }
.dropdown-menu ul li > a,
.dropdown-menu ul li label {
  display: block;
  color: #555;
  text-decoration: none;
  line-height: 18px;
  padding: 5px 15px;
  white-space: nowrap;
  font-size: 12px;
  transition: all 0.1s;
  text-align: left;

  &:hover {
    background: $color-blue;
    color: #fff;
  }
}

@media screen and (max-width: 420px) {
  .dropdown-menu ul li > a {
    line-height: 32px;
    padding-left: 24px;
    padding-right: 24px;
  }
}


