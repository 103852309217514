@media screen and (min-width: 992px) {
  .mediathek-videotype {
    padding-bottom: 20px;
  }
  .mediathek-videotype .selectric,
.mediathek-videotype .selectric-label {
    display: none;
  }
  .mediathek-videotype .selectric-wrapper .selectric-items {
    display: block;
    position: relative;
    width: 100%;
    box-shadow: none;
  }
  .mediathek-videotype .selectric-wrapper .selectric-items ul {
    text-align: center;
  }
  .mediathek-videotype .selectric-wrapper .selectric-items ul li {
    display: inline-block;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 95px;
    box-sizing: border-box;
    height: 115px;
    border: 1px solid #fff;
    font-size: 12px;
    cursor: pointer;
    vertical-align: top;
    transition: all 0.1s ease-out;
    line-height: 16px;
  }
  .mediathek-videotype .selectric-wrapper .selectric-items ul li:before {
    text-align: center;
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    font-size: 36px;
    padding-bottom: 10px;
    color: #29A9FC;
  }
  .mediathek-videotype .selectric-wrapper .selectric-items ul li:hover:before {
    text-shadow: 0 0 10px #fff;
    color: #fff;
  }
  .mediathek-videotype .selectric-wrapper .selectric-items ul li.selected:before {
    color: #fff;
  }
  .mediathek-videotype .selectric-wrapper .selectric-items ul li.alle:before {
    content: "\e912";
  }
  .mediathek-videotype .selectric-wrapper .selectric-items ul li.kraft:before {
    content: "\e919";
  }
  .mediathek-videotype .selectric-wrapper .selectric-items ul li.cardio:before {
    content: "\e911";
  }
  .mediathek-videotype .selectric-wrapper .selectric-items ul li.yoga:before {
    content: "\e91a";
  }
  .mediathek-videotype .selectric-wrapper .selectric-items ul li.stretching:before {
    content: "\e917";
  }
  .mediathek-videotype .selectric-wrapper .selectric-items ul li.bootcamp:before {
    content: "\e915";
  }
  .mediathek-videotype .selectric-wrapper .selectric-items ul li.info:before {
    content: "\e913";
  }
  .mediathek-videotype .selectric-wrapper .selectric-items ul li.favoriten:before {
    content: "\ec2d";
  }
}
.live-mediathek {
  clear: both;
  background: #fff;
  padding: 0 30px 50px 30px;
}
.live-mediathek hr {
  margin: 10px 0;
  border: 0;
  border-top: 1px solid #F0F2F4;
  clear: both;
}

.live-mediathek input[type=date],
.live-mediathek input#calendar,
.live-mediathek span.fake-calendar {
  background: #fff;
  position: relative;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin: 4px 0;
  border: 1px solid #29A9FC;
  color: #000;
  font-size: 14px;
  font-weight: 300;
  font-family: "Open Sans";
  padding: 5px 10px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
}
.live-mediathek input[type=date]:focus, .live-mediathek input[type=date]:focus-visible, .live-mediathek input[type=date]:active, .live-mediathek input[type=date]:hover,
.live-mediathek input#calendar:focus,
.live-mediathek input#calendar:focus-visible,
.live-mediathek input#calendar:active,
.live-mediathek input#calendar:hover,
.live-mediathek span.fake-calendar:focus,
.live-mediathek span.fake-calendar:focus-visible,
.live-mediathek span.fake-calendar:active,
.live-mediathek span.fake-calendar:hover {
  border-color: #c4c4c4;
}
.live-mediathek input[type=date]:hover,
.live-mediathek input#calendar:hover,
.live-mediathek span.fake-calendar:hover {
  cursor: pointer;
}

.live-mediathek input#calendar {
  opacity: 0;
  visibility: hidden;
}

.live-mediathek span.fake-calendar {
  display: block;
  height: 31px;
  position: absolute;
  width: calc(100% - 24px);
  bottom: 0;
  z-index: 1;
  line-height: 1.4em;
}

.live-mediathek span.fake-calendar::after {
  content: "\e901";
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  margin-top: 2px;
}

.live-mediathek input#calendar::placeholder {
  color: #444;
  font-weight: 500;
}

@media screen and (max-width: 550px) {
  .live-mediathek {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.mediathek-wrapper {
  text-align: center;
}
.mediathek-wrapper .add-fav .lazy-loading-animation {
  background: #fff;
  border-radius: 4px;
  height: 32px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  width: 32px;
}
.mediathek-wrapper .add-fav .lazy-loading-animation img {
  position: absolute;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  top: 50%;
  width: 20px;
}

.mediathek-item {
  padding: 5px;
  position: relative;
  display: inline-block;
  width: 200px;
  text-align: left;
}
.mediathek-item .text-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
  font-size: 12px;
  line-height: 19px;
  font-weight: 700;
  padding: 40px 12px 18px 12px;
  box-sizing: border-box;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}
.mediathek-item .text-wrapper .video-title {
  font-size: 16px;
  font-weight: 300;
  padding-bottom: 2px;
  padding-top: 4px;
}
.mediathek-item .text-wrapper .type-icon {
  font-size: 18px;
  vertical-align: center;
  display: inline-block;
  background: #fff;
  color: #000;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  top: 3px;
}
.mediathek-item .text-wrapper .video-status {
  background: #29A9FC;
  height: 5px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.mediathek-item.intensity-1 .text-wrapper .type-icon,
.mediathek-item.intensity-1 .text-wrapper .video-status {
  background: #29A9FC;
}
.mediathek-item.intensity-2 .text-wrapper .type-icon,
.mediathek-item.intensity-2 .text-wrapper .video-status {
  background: #76B82A;
}
.mediathek-item.intensity-3 .text-wrapper .type-icon,
.mediathek-item.intensity-3 .text-wrapper .video-status {
  background: #ffa800;
}
.mediathek-item.intensity-4 .text-wrapper .type-icon,
.mediathek-item.intensity-4 .text-wrapper .video-status {
  background: #CED1D3;
}
.mediathek-item a.videolink {
  text-decoration: none;
  color: #263B47;
  position: relative;
  display: block;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;
  transition: all 0.1s ease-out;
}
.mediathek-item a.videolink .video-img-wrapper {
  overflow: hidden;
  margin-bottom: 5px;
}
.mediathek-item a.videolink .video-img-wrapper img {
  width: 100% !important;
  height: auto !important;
  margin-bottom: -8px;
  transition: all 0.1s ease-out;
}
.mediathek-item a.videolink:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.6);
  z-index: 2;
}
.mediathek-item a.videolink:hover .video-img-wrapper img {
  transform: scale(1.1);
}
.mediathek-item.new a .video-img-wrapper {
  position: relative;
}
.mediathek-item.new a .video-img-wrapper:before {
  content: "neu";
  background: #FB2A1A;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 5px 0;
  color: #fff;
  text-transform: uppercase;
  padding: 3px 10px;
  font-weight: 700;
}
.mediathek-item .video-dummy .video-img-wrapper {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  margin-bottom: 5px;
}

@media screen and (max-width: 550px) {
  .mediathek-item {
    width: calc(50% - 10px);
  }
  .live-mediathek .mediathek-item {
    width: 200px;
  }
}
#popup-tp-live-video {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}
#popup-tp-live-video .popup-text,
#popup-tp-live-video header,
#popup-tp-live-video h3 {
  text-align: left;
}
#popup-tp-live-video .header-date {
  font-size: 14px;
}
#popup-tp-live-video .tp-actions .tp-action {
  vertical-align: top;
}
#popup-tp-live-video .tp-actions .tp-action button {
  background: #29A9FC;
}

/*======================================
  Selectric v1.11.0
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}
.selectric-wrapper.power .selectric {
  background: #f158dd !important;
}
.selectric-wrapper.booster .selectric {
  background: #9044c6 !important;
}
.selectric-wrapper.healthy .selectric {
  background: #a3c24f !important;
}
.selectric-wrapper.light .selectric {
  background: #51b6cc !important;
}
.selectric-wrapper.selectric-enplan-kind-select li[data-index="1"] {
  color: #f158dd !important;
}
.selectric-wrapper.selectric-enplan-kind-select li[data-index="2"] {
  color: #9044c6 !important;
}
.selectric-wrapper.selectric-enplan-kind-select li[data-index="3"] {
  color: #a3c24f !important;
}
.selectric-wrapper.selectric-enplan-kind-select li[data-index="4"] {
  color: #51b6cc !important;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  background: #fff;
  position: relative;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin: 4px 0;
  border: 1px solid #29A9FC;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 0 10px;
  line-height: 30px;
  color: #444;
  height: 30px;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  color: transprent;
  text-align: center;
  font: 0/0 a;
  *font: 20px/30px Lucida Sans Unicode, Arial Unicode MS, Arial;
}

.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #29A9FC;
  border-bottom: none;
}

.desktop .selectric:hover .button:after {
  border-top-color: #76B82A;
}

.selectric-label {
  font-size: 12px;
  color: #29A9FC;
  text-transform: uppercase;
  margin-bottom: -6px;
}

.selectric-focus .selectric {
  border-color: #aaaaaa;
}

.selectric-hover .selectric {
  border-color: #c4c4c4;
}

.selectric-hover .selectric .button {
  color: #a2a2a2;
}

.selectric-hover .selectric .button:after {
  border-top-color: #a2a2a2;
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric {
  border-color: #c4c4c4;
}

.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  z-index: 2;
  box-shadow: 0 0 10px -6px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items li {
  display: block;
  padding: 10px;
  color: #000;
  cursor: pointer;
  font-size: 14px;
}

.selectric-items li.selected {
  background: #29A9FC;
  color: #fff;
}

.selectric-items li.highlighted {
  background: #29A9FC;
  color: #fff;
}

.selectric-items li:hover {
  background: #29A9FC;
  color: #fff;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}

.trainingsplan .header-wrapper,
.tagebuch .header-wrapper,
.livetraining .header-wrapper {
  padding: 30px 20px;
}
.trainingsplan .header-wrapper h2,
.tagebuch .header-wrapper h2,
.livetraining .header-wrapper h2 {
  font-weight: 700;
  font-size: 21px;
  padding: 0;
  margin-top: -5px;
  margin-bottom: -5px;
}
.trainingsplan .header-wrapper .tp-day-date,
.tagebuch .header-wrapper .tp-day-date,
.livetraining .header-wrapper .tp-day-date {
  font-size: 12px;
  color: #777777;
}
.trainingsplan .header-wrapper .tp-day-intensity,
.tagebuch .header-wrapper .tp-day-intensity,
.livetraining .header-wrapper .tp-day-intensity {
  font-size: 12px;
  text-transform: uppercase;
}
.trainingsplan .tp-wrapper,
.tagebuch .tp-wrapper,
.livetraining .tp-wrapper {
  margin: 0 40px;
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.trainingsplan .tp-wrapper .ka-panel.close .ka-content,
.tagebuch .tp-wrapper .ka-panel.close .ka-content,
.livetraining .tp-wrapper .ka-panel.close .ka-content {
  display: none;
}
.trainingsplan .tp-wrapper .ka-panel.open .ka-content,
.tagebuch .tp-wrapper .ka-panel.open .ka-content,
.livetraining .tp-wrapper .ka-panel.open .ka-content {
  display: block;
}
.trainingsplan .tp-wrapper.intensity-1 .ka-panel .ka-handler span,
.trainingsplan .tp-wrapper.intensity-1 .header-wrapper .tp-day-intensity,
.tagebuch .tp-wrapper.intensity-1 .ka-panel .ka-handler span,
.tagebuch .tp-wrapper.intensity-1 .header-wrapper .tp-day-intensity,
.livetraining .tp-wrapper.intensity-1 .ka-panel .ka-handler span,
.livetraining .tp-wrapper.intensity-1 .header-wrapper .tp-day-intensity {
  color: #29A9FC;
}
.trainingsplan .tp-wrapper.intensity-1 .tp-actions .tp-action a,
.trainingsplan .tp-wrapper.intensity-1 .tp-actions .tp-action button,
.tagebuch .tp-wrapper.intensity-1 .tp-actions .tp-action a,
.tagebuch .tp-wrapper.intensity-1 .tp-actions .tp-action button,
.livetraining .tp-wrapper.intensity-1 .tp-actions .tp-action a,
.livetraining .tp-wrapper.intensity-1 .tp-actions .tp-action button {
  background: #29A9FC;
}
.trainingsplan .tp-wrapper.intensity-2 .ka-panel .ka-handler span,
.trainingsplan .tp-wrapper.intensity-2 .header-wrapper .tp-day-intensity,
.tagebuch .tp-wrapper.intensity-2 .ka-panel .ka-handler span,
.tagebuch .tp-wrapper.intensity-2 .header-wrapper .tp-day-intensity,
.livetraining .tp-wrapper.intensity-2 .ka-panel .ka-handler span,
.livetraining .tp-wrapper.intensity-2 .header-wrapper .tp-day-intensity {
  color: #76B82A;
}
.trainingsplan .tp-wrapper.intensity-2 .tp-actions .tp-action a,
.trainingsplan .tp-wrapper.intensity-2 .tp-actions .tp-action button,
.tagebuch .tp-wrapper.intensity-2 .tp-actions .tp-action a,
.tagebuch .tp-wrapper.intensity-2 .tp-actions .tp-action button,
.livetraining .tp-wrapper.intensity-2 .tp-actions .tp-action a,
.livetraining .tp-wrapper.intensity-2 .tp-actions .tp-action button {
  background: #76B82A;
}
.trainingsplan .tp-wrapper.intensity-3 .ka-panel .ka-handler span,
.trainingsplan .tp-wrapper.intensity-3 .header-wrapper .tp-day-intensity,
.tagebuch .tp-wrapper.intensity-3 .ka-panel .ka-handler span,
.tagebuch .tp-wrapper.intensity-3 .header-wrapper .tp-day-intensity,
.livetraining .tp-wrapper.intensity-3 .ka-panel .ka-handler span,
.livetraining .tp-wrapper.intensity-3 .header-wrapper .tp-day-intensity {
  color: #ffa800;
}
.trainingsplan .tp-wrapper.intensity-3 .tp-actions .tp-action a,
.trainingsplan .tp-wrapper.intensity-3 .tp-actions .tp-action button,
.tagebuch .tp-wrapper.intensity-3 .tp-actions .tp-action a,
.tagebuch .tp-wrapper.intensity-3 .tp-actions .tp-action button,
.livetraining .tp-wrapper.intensity-3 .tp-actions .tp-action a,
.livetraining .tp-wrapper.intensity-3 .tp-actions .tp-action button {
  background: #ffa800;
}
.trainingsplan .tp-wrapper.intensity-4 .ka-panel .ka-handler span,
.trainingsplan .tp-wrapper.intensity-4 .header-wrapper .tp-day-intensity,
.tagebuch .tp-wrapper.intensity-4 .ka-panel .ka-handler span,
.tagebuch .tp-wrapper.intensity-4 .header-wrapper .tp-day-intensity,
.livetraining .tp-wrapper.intensity-4 .ka-panel .ka-handler span,
.livetraining .tp-wrapper.intensity-4 .header-wrapper .tp-day-intensity {
  color: #FB2A1A;
}
.trainingsplan .tp-wrapper.intensity-4 .tp-actions .tp-action a,
.trainingsplan .tp-wrapper.intensity-4 .tp-actions .tp-action button,
.tagebuch .tp-wrapper.intensity-4 .tp-actions .tp-action a,
.tagebuch .tp-wrapper.intensity-4 .tp-actions .tp-action button,
.livetraining .tp-wrapper.intensity-4 .tp-actions .tp-action a,
.livetraining .tp-wrapper.intensity-4 .tp-actions .tp-action button {
  background: #FB2A1A;
}
.trainingsplan .tp-content .ka-panel .ka-handler,
.tagebuch .tp-content .ka-panel .ka-handler,
.livetraining .tp-content .ka-panel .ka-handler {
  font-size: 14px;
  padding: 20px 40px 20px 60px;
  border-top: 1px solid #F0F2F4;
  position: relative;
  cursor: pointer;
  font-weight: 400;
}
.trainingsplan .tp-content .ka-panel .ka-handler-number,
.tagebuch .tp-content .ka-panel .ka-handler-number,
.livetraining .tp-content .ka-panel .ka-handler-number {
  display: block;
  background: #000;
  width: 24px;
  height: 24px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  line-height: 24px;
  font-weight: 700;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -12px;
}
.trainingsplan .tp-content .ka-panel .ka-handler .tp-day-exercise-info,
.tagebuch .tp-content .ka-panel .ka-handler .tp-day-exercise-info,
.livetraining .tp-content .ka-panel .ka-handler .tp-day-exercise-info {
  color: #777777;
  font-size: 12px;
  line-height: 14px;
}
.trainingsplan .tp-content .ka-panel .ka-handler span,
.tagebuch .tp-content .ka-panel .ka-handler span,
.livetraining .tp-content .ka-panel .ka-handler span {
  float: right;
  transition: all 0.1s ease-out;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.trainingsplan .tp-content .ka-panel .ka-handler .tp-live-start-event span,
.tagebuch .tp-content .ka-panel .ka-handler .tp-live-start-event span,
.livetraining .tp-content .ka-panel .ka-handler .tp-live-start-event span {
  float: inherit;
  position: static;
}
.trainingsplan .tp-content .ka-panel.open .ka-handler span,
.tagebuch .tp-content .ka-panel.open .ka-handler span,
.livetraining .tp-content .ka-panel.open .ka-handler span {
  transform: rotate(180deg);
}
.trainingsplan .tp-content .ka-panel .ka-content,
.tagebuch .tp-content .ka-panel .ka-content,
.livetraining .tp-content .ka-panel .ka-content {
  padding: 0 20px 20px 20px;
  color: #777777;
  font-size: 12px;
  line-height: 1.4em;
}
.trainingsplan .tp-content.tp-live-content .video-expand,
.tagebuch .tp-content.tp-live-content .video-expand,
.livetraining .tp-content.tp-live-content .video-expand {
  margin-bottom: 25px;
}
.trainingsplan .tp-content.tp-live-content .tp-live-countdown,
.tagebuch .tp-content.tp-live-content .tp-live-countdown,
.livetraining .tp-content.tp-live-content .tp-live-countdown {
  position: relative;
  background: #000;
  margin-bottom: 25px;
  margin-left: -20px;
  width: calc(100% + 40px);
}
.trainingsplan .tp-content.tp-live-content .tp-live-countdown .btn-wrapper,
.tagebuch .tp-content.tp-live-content .tp-live-countdown .btn-wrapper,
.livetraining .tp-content.tp-live-content .tp-live-countdown .btn-wrapper {
  position: absolute;
  bottom: 10px;
  z-index: 3;
  width: 100%;
  text-align: center;
}
.trainingsplan .tp-content.tp-live-content .tp-live-countdown img,
.tagebuch .tp-content.tp-live-content .tp-live-countdown img,
.livetraining .tp-content.tp-live-content .tp-live-countdown img {
  width: 100% !important;
  height: auto !important;
  opacity: 0.4;
  margin-bottom: -5px;
}
.trainingsplan .tp-content.tp-live-content .tp-live-countdown .countdown,
.tagebuch .tp-content.tp-live-content .tp-live-countdown .countdown,
.livetraining .tp-content.tp-live-content .tp-live-countdown .countdown {
  color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -18px;
  text-align: center;
  width: 100%;
  z-index: 2;
}
.trainingsplan .tp-content.tp-live-content .tp-live-countdown .countdown > div,
.tagebuch .tp-content.tp-live-content .tp-live-countdown .countdown > div,
.livetraining .tp-content.tp-live-content .tp-live-countdown .countdown > div {
  display: inline-block;
  font-family: "Open Sans Condensed";
  font-size: 36px;
  font-weight: 700;
  width: 60px;
}
.trainingsplan .tp-content.tp-live-content .tp-live-countdown .countdown > div span,
.tagebuch .tp-content.tp-live-content .tp-live-countdown .countdown > div span,
.livetraining .tp-content.tp-live-content .tp-live-countdown .countdown > div span {
  display: block;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 400;
  padding-top: 8px;
}
.trainingsplan .tp-content.tp-live-content .tp-live-countdown-video,
.tagebuch .tp-content.tp-live-content .tp-live-countdown-video,
.livetraining .tp-content.tp-live-content .tp-live-countdown-video {
  display: none;
  position: relative;
  padding: 56.25% 0 0 0;
}
.trainingsplan .tp-content.tp-live-content .tp-live-countdown-video iframe,
.tagebuch .tp-content.tp-live-content .tp-live-countdown-video iframe,
.livetraining .tp-content.tp-live-content .tp-live-countdown-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.trainingsplan .tp-content.tp-live-content .ka-panel .ka-handler:before,
.tagebuch .tp-content.tp-live-content .ka-panel .ka-handler:before,
.livetraining .tp-content.tp-live-content .ka-panel .ka-handler:before {
  display: none;
}
.trainingsplan .tp-content.tp-live-content .tp-live-date,
.tagebuch .tp-content.tp-live-content .tp-live-date,
.livetraining .tp-content.tp-live-content .tp-live-date {
  border-radius: 0 5px 5px 0;
  color: #fff;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -25px;
  box-sizing: border-box;
  padding: 10px 8px;
  font-size: 12px;
}
.trainingsplan .tp-content.tp-live-content .tp-live-date-name,
.tagebuch .tp-content.tp-live-content .tp-live-date-name,
.livetraining .tp-content.tp-live-content .tp-live-date-name {
  font-size: 16px;
}
.trainingsplan .tp-content.tp-live-content .tp-live-date .tp-live-date-day,
.tagebuch .tp-content.tp-live-content .tp-live-date .tp-live-date-day,
.livetraining .tp-content.tp-live-content .tp-live-date .tp-live-date-day {
  font-size: 18px;
  font-weight: 700;
  line-height: 14px;
}
.trainingsplan .tp-content.tp-live-content .tp-live-date-time,
.tagebuch .tp-content.tp-live-content .tp-live-date-time,
.livetraining .tp-content.tp-live-content .tp-live-date-time {
  display: flex;
  font-size: 12px;
  color: #CED1D3;
  margin-bottom: -8px;
}
.trainingsplan .tp-content.tp-live-content .tp-live-date-time-start, .trainingsplan .tp-content.tp-live-content .tp-live-date-time-end, .trainingsplan .tp-content.tp-live-content .tp-live-date-time-type,
.tagebuch .tp-content.tp-live-content .tp-live-date-time-start,
.tagebuch .tp-content.tp-live-content .tp-live-date-time-end,
.tagebuch .tp-content.tp-live-content .tp-live-date-time-type,
.livetraining .tp-content.tp-live-content .tp-live-date-time-start,
.livetraining .tp-content.tp-live-content .tp-live-date-time-end,
.livetraining .tp-content.tp-live-content .tp-live-date-time-type {
  margin-right: 5px;
}
.trainingsplan .tp-content.tp-live-content .tp-live-coach,
.tagebuch .tp-content.tp-live-content .tp-live-coach,
.livetraining .tp-content.tp-live-content .tp-live-coach {
  font-weight: 700;
  color: #000;
}
.trainingsplan .tp-content.tp-live-content .ka-panel.intensity-1 .ka-handler .icon-chevron-down,
.tagebuch .tp-content.tp-live-content .ka-panel.intensity-1 .ka-handler .icon-chevron-down,
.livetraining .tp-content.tp-live-content .ka-panel.intensity-1 .ka-handler .icon-chevron-down {
  color: #29A9FC;
}
.trainingsplan .tp-content.tp-live-content .ka-panel.intensity-1 .tp-live-date,
.trainingsplan .tp-content.tp-live-content .ka-panel.intensity-1 .tp-action button,
.tagebuch .tp-content.tp-live-content .ka-panel.intensity-1 .tp-live-date,
.tagebuch .tp-content.tp-live-content .ka-panel.intensity-1 .tp-action button,
.livetraining .tp-content.tp-live-content .ka-panel.intensity-1 .tp-live-date,
.livetraining .tp-content.tp-live-content .ka-panel.intensity-1 .tp-action button {
  background: #29A9FC;
}
.trainingsplan .tp-content.tp-live-content .ka-panel.intensity-2 .ka-handler .icon-chevron-down,
.tagebuch .tp-content.tp-live-content .ka-panel.intensity-2 .ka-handler .icon-chevron-down,
.livetraining .tp-content.tp-live-content .ka-panel.intensity-2 .ka-handler .icon-chevron-down {
  color: #76B82A;
}
.trainingsplan .tp-content.tp-live-content .ka-panel.intensity-2 .tp-live-date,
.trainingsplan .tp-content.tp-live-content .ka-panel.intensity-2 .tp-action button,
.tagebuch .tp-content.tp-live-content .ka-panel.intensity-2 .tp-live-date,
.tagebuch .tp-content.tp-live-content .ka-panel.intensity-2 .tp-action button,
.livetraining .tp-content.tp-live-content .ka-panel.intensity-2 .tp-live-date,
.livetraining .tp-content.tp-live-content .ka-panel.intensity-2 .tp-action button {
  background: #76B82A;
}
.trainingsplan .tp-content.tp-live-content .ka-panel.intensity-3 .ka-handler .icon-chevron-down,
.tagebuch .tp-content.tp-live-content .ka-panel.intensity-3 .ka-handler .icon-chevron-down,
.livetraining .tp-content.tp-live-content .ka-panel.intensity-3 .ka-handler .icon-chevron-down {
  color: #ffa800;
}
.trainingsplan .tp-content.tp-live-content .ka-panel.intensity-3 .tp-live-date,
.trainingsplan .tp-content.tp-live-content .ka-panel.intensity-3 .tp-action button,
.tagebuch .tp-content.tp-live-content .ka-panel.intensity-3 .tp-live-date,
.tagebuch .tp-content.tp-live-content .ka-panel.intensity-3 .tp-action button,
.livetraining .tp-content.tp-live-content .ka-panel.intensity-3 .tp-live-date,
.livetraining .tp-content.tp-live-content .ka-panel.intensity-3 .tp-action button {
  background: #ffa800;
}
.trainingsplan .tp-content.tp-live-content .ka-panel.intensity-4 .ka-handler .icon-chevron-down,
.tagebuch .tp-content.tp-live-content .ka-panel.intensity-4 .ka-handler .icon-chevron-down,
.livetraining .tp-content.tp-live-content .ka-panel.intensity-4 .ka-handler .icon-chevron-down {
  color: #CED1D3;
}
.trainingsplan .tp-content.tp-live-content .ka-panel.intensity-4 .tp-live-date,
.trainingsplan .tp-content.tp-live-content .ka-panel.intensity-4 .tp-action button,
.tagebuch .tp-content.tp-live-content .ka-panel.intensity-4 .tp-live-date,
.tagebuch .tp-content.tp-live-content .ka-panel.intensity-4 .tp-action button,
.livetraining .tp-content.tp-live-content .ka-panel.intensity-4 .tp-live-date,
.livetraining .tp-content.tp-live-content .ka-panel.intensity-4 .tp-action button {
  background: #CED1D3;
}
.trainingsplan .tp-actions,
.tagebuch .tp-actions,
.livetraining .tp-actions {
  text-align: center;
  padding-top: 30px;
}
.trainingsplan .tp-actions .tp-action,
.tagebuch .tp-actions .tp-action,
.livetraining .tp-actions .tp-action {
  display: inline-block;
  font-size: 12px;
  text-align: center;
  width: 70px;
  line-height: 14px;
  position: relative;
}
.trainingsplan .tp-actions .tp-action.done:before,
.tagebuch .tp-actions .tp-action.done:before,
.livetraining .tp-actions .tp-action.done:before {
  content: "\edfb";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #76B82A;
  color: #fff;
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: -3px;
  top: -8px;
  line-height: 20px;
  font-size: 12px;
  z-index: 5;
}
.trainingsplan .tp-actions .tp-action a,
.trainingsplan .tp-actions .tp-action button,
.tagebuch .tp-actions .tp-action a,
.tagebuch .tp-actions .tp-action button,
.livetraining .tp-actions .tp-action a,
.livetraining .tp-actions .tp-action button {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  line-height: 60px;
  color: #fff;
  font-size: 28px;
  text-decoration: none;
  margin-bottom: 6px;
  transition: all 0.1s ease-out;
  border: 0;
  cursor: pointer;
}
.trainingsplan .tp-actions .tp-action a:disabled,
.trainingsplan .tp-actions .tp-action button:disabled,
.tagebuch .tp-actions .tp-action a:disabled,
.tagebuch .tp-actions .tp-action button:disabled,
.livetraining .tp-actions .tp-action a:disabled,
.livetraining .tp-actions .tp-action button:disabled {
  background: #F0F2F4 !important;
  color: #777777;
  cursor: default;
}
.trainingsplan .tp-actions .tp-action a:disabled:hover,
.trainingsplan .tp-actions .tp-action button:disabled:hover,
.tagebuch .tp-actions .tp-action a:disabled:hover,
.tagebuch .tp-actions .tp-action button:disabled:hover,
.livetraining .tp-actions .tp-action a:disabled:hover,
.livetraining .tp-actions .tp-action button:disabled:hover {
  transform: scale(1);
}
.trainingsplan .tp-actions .tp-action a:hover,
.trainingsplan .tp-actions .tp-action button:hover,
.tagebuch .tp-actions .tp-action a:hover,
.tagebuch .tp-actions .tp-action button:hover,
.livetraining .tp-actions .tp-action a:hover,
.livetraining .tp-actions .tp-action button:hover {
  transform: scale(1.05);
}
@media only screen and (max-width: 768px) {
  .trainingsplan .trainingsplan-wrapper,
.tagebuch .trainingsplan-wrapper,
.livetraining .trainingsplan-wrapper {
    padding: 0 !important;
    margin: 0 !important;
  }
  .trainingsplan .week-nav-wrapper,
.tagebuch .week-nav-wrapper,
.livetraining .week-nav-wrapper {
    margin-bottom: 0 !important;
  }
  .trainingsplan .tp-wrapper,
.tagebuch .tp-wrapper,
.livetraining .tp-wrapper {
    margin: 0;
    border-radius: 0;
  }
}

.intensity-legend {
  background: transparent !important;
  font-size: 12px;
  line-height: 18px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.intensity-legend > .intensity {
  padding-left: 16px;
  position: relative;
  display: inline-block;
  margin-left: 5px;
}
.intensity-legend > .intensity:before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 0;
  top: 3px;
}
.intensity-legend > .intensity.intensity-1:before {
  background: #29A9FC;
}
.intensity-legend > .intensity.intensity-2:before {
  background: #76B82A;
}
.intensity-legend > .intensity.intensity-3:before {
  background: #ffa800;
}
.intensity-legend > .intensity.intensity-4:before {
  background: #CED1D3;
}

.tagebuch {
  background: #263B47;
}
.tagebuch .tagebuch-item {
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin: 0 40px 2px 40px;
  position: relative;
  overflow: hidden;
}
.tagebuch .tagebuch-item .ka-handler {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  padding: 30px 40px 20px 20px;
  position: relative;
  transition: all 0.1s ease-out;
  cursor: pointer;
}
.tagebuch .tagebuch-item .ka-handler .icon-chevron-down {
  color: #29A9FC;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -5px;
  font-size: 16px;
  transition: all 0.1s ease-out;
}
.tagebuch .tagebuch-item .ka-handler:after {
  content: "";
  display: block;
  clear: both;
}
.tagebuch .tagebuch-item .tagebuch-item-date {
  display: inline-block;
  color: #29A9FC;
  padding-right: 5px;
}
.tagebuch .tagebuch-item .tagebuch-item-unit-count {
  display: inline-block;
  border-radius: 20px;
  background: #29A9FC;
  color: #fff;
  padding: 0px 10px;
  height: 18px;
  line-height: 18px;
}
.tagebuch .tagebuch-item .tagebuch-item-unit-count strong {
  font-weight: 700;
}
.tagebuch .tagebuch-item .open .ka-handler {
  background: #F0F2F4;
}
.tagebuch .tagebuch-item .open .ka-handler .icon-chevron-down {
  transform: rotate(180deg);
}
.tagebuch .tagebuch-item .ka-panel .ka-content {
  transition: all 0.5s ease-in-out;
}
.tagebuch .tagebuch-item .ka-panel.close .ka-content {
  height: 0;
  opacity: 0;
}
.tagebuch .tagebuch-item .ka-panel.open .ka-content {
  height: auto;
  opacity: 1;
}
.tagebuch .tagebuch-item .ka-content a {
  font-size: 14px;
  padding: 20px 40px 20px 60px;
  border-top: 1px solid #F0F2F4;
  position: relative;
  cursor: pointer;
  font-weight: 400;
  color: #000;
  display: block;
  text-decoration: none;
  border-top: 1px solid #F0F2F4;
}
.tagebuch .tagebuch-item .ka-content a .tp-day-exercise-info {
  color: #777777;
  font-size: 12px;
  line-height: 14px;
}
.tagebuch .tagebuch-item .ka-content a span {
  float: right;
  transition: all 0.1s ease-out;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
  color: #29A9FC;
}
.tagebuch .tagebuch-item .ka-content a span.icon-flag3, .tagebuch .tagebuch-item .ka-content a span.icon-trophy1 {
  right: auto;
  margin-top: -12px;
  color: #000;
}
.tagebuch .tagebuch-item .ka-content a:before {
  display: block;
  background: #29A9FC;
  width: 24px;
  height: 24px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-weight: 700;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -12px;
  font-size: 16px;
  box-sizing: border-box;
  padding-top: 3px;
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tagebuch .tagebuch-item .ka-content a:hover span {
  right: 15px;
}
.tagebuch .tagebuch-item .ka-content a:hover span.icon-flag3, .tagebuch .tagebuch-item .ka-content a:hover span.icon-trophy1 {
  right: auto;
}
.tagebuch .tagebuch-item .ka-content a.tagebuch-item-unit-done:before {
  content: "\edfb";
}
.tagebuch .tagebuch-item .ka-content a.tagebuch-item-unit-manually:before {
  content: "\e918";
}
.tagebuch .tagebuch-item .ka-content a.tagebuch-item-unit-clock:before {
  content: "\eda1";
}
.tagebuch .tagebuch-item .ka-content a.tagebuch-item-unit-tracking:before {
  content: "\edf1";
}

.choose-week .owl-item,
.choose-month .owl-item,
.choose-year .owl-item {
  opacity: 0.5;
  transition: all 0.1s ease-out;
}
.choose-week .owl-item.active,
.choose-month .owl-item.active,
.choose-year .owl-item.active {
  opacity: 1;
}
.choose-week .owl-item .item a,
.choose-month .owl-item .item a,
.choose-year .owl-item .item a {
  display: block;
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 30px;
  color: #777777;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  padding: 8px 3px 6px 3px;
  transition: all 0.1s ease-out;
  height: 42px;
  box-sizing: border-box;
}
.choose-week .owl-item .item a.active, .choose-week .owl-item .item a:hover,
.choose-month .owl-item .item a.active,
.choose-month .owl-item .item a:hover,
.choose-year .owl-item .item a.active,
.choose-year .owl-item .item a:hover {
  background: #29A9FC;
  color: #fff;
}
.choose-week .owl-item .item a .year,
.choose-month .owl-item .item a .year,
.choose-year .owl-item .item a .year {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 10px;
}

.choose-month .owl-item .item a {
  font-size: 14px;
  line-height: 14px;
}

.choose-year .owl-item .item a {
  line-height: 42px;
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .tagebuch .tagebuch-item {
    margin: 0 0 2px 0;
    border-radius: 0;
  }
}
.training-feeling-bar {
  height: 31px;
  border-radius: 20px;
  background-image: linear-gradient(90deg, #009DF0 0%, #76B82A 30%, #FFA800 64%, #FB2A1A 100%);
  padding: 2px;
  box-sizing: border-box;
  position: relative;
}
.training-feeling-bar > div {
  background: #fff;
  height: 27px;
  border-radius: 20px;
}
.training-feeling-bar > div .training-feeling-bar-count {
  background: red;
  height: 27px;
  border-radius: 20px;
  width: 50%;
  overflow: hidden;
}
.training-feeling-bar > div .training-feeling-bar-count:before {
  content: "";
  display: block;
  height: 27px;
  width: 200%;
  background-image: linear-gradient(90deg, #009DF0 0%, #76B82A 30%, #FFA800 64%, #FB2A1A 100%);
}
.training-feeling-bar > div .training-feeling-bar-count.count-0 {
  width: 0%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-10 {
  width: 10%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-10:before {
  width: 1000%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-20 {
  width: 20%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-20:before {
  width: 500%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-30 {
  width: 30%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-30:before {
  width: 333%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-40 {
  width: 40%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-40:before {
  width: 250%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-30 {
  width: 30%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-30:before {
  width: 333%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-30 {
  width: 30%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-30:before {
  width: 333%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-50 {
  width: 50%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-50:before {
  width: 200%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-60 {
  width: 60%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-60:before {
  width: 166%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-70 {
  width: 70%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-70:before {
  width: 143%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-80 {
  width: 80%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-80:before {
  width: 125%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-90 {
  width: 90%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-90:before {
  width: 111%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-100 {
  width: 100%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-100:before {
  width: 100%;
}

.ernaehrung h1,
.trainingsplan h1,
.tagebuch h1,
.livetraining h1 {
  margin-bottom: 20px;
  background: #fff;
}
@media only screen and (max-width: 768px) {
  .ernaehrung .content-wrapper,
.trainingsplan .content-wrapper,
.tagebuch .content-wrapper,
.livetraining .content-wrapper {
    padding-top: 45px !important;
  }
}
.ernaehrung h2,
.trainingsplan h2,
.tagebuch h2,
.livetraining h2 {
  font-family: "Open Sans Condensed";
  font-size: 30px;
  letter-spacing: 0;
  line-height: 32px;
}
.ernaehrung h4,
.trainingsplan h4,
.tagebuch h4,
.livetraining h4 {
  font-family: "Open Sans Condensed";
  font-weight: 700;
  font-size: 16px;
  padding: 20px 0 10px 0;
}

.diary-wrapper {
  box-shadow: 0 0 0 10px #263B47;
  background: #fffdde;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#29a9fc",endColorstr="#fb2a1a",GradientType=1);
}
.diary-wrapper .tp-day {
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
}
.diary-wrapper .tp-day strong {
  color: #263B47 !important;
}
.diary-wrapper .week-ribbon {
  overflow: hidden;
  background: #FB2A1A;
  color: #FB2A1A;
  font-weight: 700;
  font-size: 14px;
  position: absolute;
  right: 30px;
  top: -12px;
  padding: 3px 10px 27px 10px;
  text-align: center;
  line-height: 21px;
}
.diary-wrapper .week-ribbon > div {
  position: relative;
  z-index: 10;
}
.diary-wrapper .week-ribbon:after {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  transform: rotate(45deg);
  position: absolute;
  left: 50%;
  margin-left: -50px;
  background: #fffdde;
  box-sizing: border-box;
  bottom: -92px;
  border: 2px solid #FB2A1A;
}
.diary-wrapper .week-ribbon:before {
  content: "";
  display: block;
  width: calc(100% - 4px);
  height: 100px;
  position: absolute;
  left: 2px;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}
.diary-wrapper .week-ribbon .week-number {
  font-size: 30px;
  font-weight: 300;
  color: #fff;
}
.diary-wrapper header {
  border-top: 1px solid #263B47;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.diary-wrapper header .tp-day-day {
  width: auto !important;
  text-align: center !important;
  padding-left: 18px !important;
}
.diary-wrapper header .tp-day-day > div {
  font-weight: 300;
  display: inline-block;
}
.diary-wrapper header:nth-of-type(1) {
  border-top: 0;
}
.diary-wrapper .diary-exercises {
  font-size: 14px;
}
.diary-wrapper .diary-exercises strong {
  font-family: "Open sans", sans-serif;
  color: rgba(0, 0, 0, 0.3);
}
.diary-wrapper .diary-exercises .diary-exercise-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px 15px 4px 20px;
  box-sizing: border-box;
  font-family: "Coming Soon";
}
.diary-wrapper .diary-exercises .diary-exercise-item:after {
  content: "";
  display: block;
  clear: both;
}
.diary-wrapper .diary-exercises .diary-exercise-item .btn-group-row-edit {
  margin-bottom: -10px;
}
.diary-wrapper .diary-exercises .diary-exercise-item > div {
  padding-bottom: 5px;
}
.diary-wrapper .diary-exercises .diary-exercise-item.tp-exercise-item-competition {
  position: relative;
  padding-left: 50px;
}
.diary-wrapper .diary-exercises .diary-exercise-item.tp-exercise-item-competition .icon-trophy1 {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -18px;
  font-size: 18px;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 30px;
}
.diary-wrapper .diary-exercises .diary-exercise-item.tp-exercise-item-competition .text-light {
  opacity: 0.5;
}
.diary-wrapper .diary-exercises .diary-exercise-item.tp-exercise-item-competition .tp-exercise-item-competition-detail {
  display: inline-block;
  padding-right: 10px;
}
.diary-wrapper .diary-exercises .diary-exercise-item.tp-exercise-item-maincompetition .icon-trophy1 {
  background: #263B47;
  color: #fffdde;
}
.diary-wrapper .diary-exercises .diary-exercise-item span a.btn {
  display: inline-block;
  margin: 0 5px;
  background: rgba(0, 0, 0, 0.1);
  color: #263B47;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  font-family: "Arial", sans-serif;
  text-transform: none;
}
.diary-wrapper .diary-item {
  font-family: "Coming Soon";
}
.diary-wrapper .diary-exercise-planed {
  background: #fff;
  box-sizing: border-box;
}
.diary-wrapper .diary-exercise-planed .diary-exercise-header {
  padding: 3px 10px;
  text-align: center;
  color: #fff;
  margin-bottom: 3px;
}
.diary-wrapper .diary-exercise-planed strong {
  color: #000;
}
.diary-wrapper .diary-exercise-planed .diary-exercise-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-family: "Open sans", sans-serif;
  color: #263B47;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
}
.diary-wrapper .diary-exercise-planed .diary-exercise-item a.btn {
  display: inline-block;
  background: rgba(0, 0, 0, 0.1);
  color: #263B47;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  font-family: Arial;
  text-transform: none;
}
.diary-wrapper .diary-exercise-planed .diary-exercise-item a.open-popup-video {
  display: inline;
}
.diary-wrapper .effort-scale {
  height: 6px;
  border-radius: 10px;
  margin-top: 5px;
  position: relative;
  width: 100px;
  display: inline-block;
  background: -moz-linear-gradient(left, rgb(41, 169, 252) 20%, rgb(90, 170, 49) 30%, rgb(90, 170, 49) 45%, rgb(255, 168, 0) 55%, rgb(255, 168, 0) 70%, rgb(251, 42, 26) 80%);
  background: -webkit-linear-gradient(left, rgb(41, 169, 252) 20%, rgb(90, 170, 49) 30%, rgb(90, 170, 49) 45%, rgb(255, 168, 0) 55%, rgb(255, 168, 0) 70%, rgb(251, 42, 26) 80%);
  background: linear-gradient(to right, rgb(41, 169, 252) 20%, rgb(90, 170, 49) 30%, rgb(90, 170, 49) 45%, rgb(255, 168, 0) 55%, rgb(255, 168, 0) 70%, rgb(251, 42, 26) 80%);
}
.diary-wrapper .effort-scale .effort-scale-marker {
  width: 2px;
  height: 14px;
  position: absolute;
  top: -4px;
  background: #263B47;
  /*
  width: 30px;
   	background: rgba(255,255,255,0.33);
   	height: 10px;
   	margin-left: -15px;
   	position: absolute;
   	top: -3px;
   	*/
  /*
  &:before {
  	content: "";
  	clear: both;
  	width: 16px;
  	height: 10px;
  	position: absolute;
  	top: 0;
  	left: 7px;
  	background: rgba(255,255,255,0.6);
  }
  &:after {
  	content: "";
  	clear: both;
  	width: 2px;
  	height: 14px;
  	position: absolute;
  	top: -5px;
  	left: 11px;
  	background: $color-gray-dark;
  	border: 3px solid #fff;
  }
  */
}

.diary-exercise-planed.intensity-1 .diary-exercise-header {
  background: #29A9FC;
}

.diary-exercise-planed.intensity-2 .diary-exercise-header {
  background: #76B82A;
}

.diary-exercise-planed.intensity-3 .diary-exercise-header {
  background: #ffa800;
}

.diary-exercise-planed.intensity-4 .diary-exercise-header {
  background: #FB2A1A;
}

.diary-carousel {
  position: relative;
}
.diary-carousel .owl-nav:after {
  content: "";
  display: block;
  clear: both;
}
.diary-carousel .owl-nav .disabled {
  display: none;
}
.diary-carousel .owl-nav .owl-next {
  background: none;
  padding: 0;
  margin: 0;
  float: right;
}
.diary-carousel .owl-nav .owl-next .btn {
  background: rgba(0, 0, 0, 0.1);
  color: #263B47;
  position: relative;
  top: -5px;
  right: -2px;
  border-radius: 30px 0 0 30px;
}
.diary-carousel .owl-nav .owl-prev {
  background: none;
  padding: 0;
  margin: 0;
  float: left;
}
.diary-carousel .owl-nav .owl-prev .btn {
  background: rgba(0, 0, 0, 0.1);
  color: #263B47;
  position: relative;
  top: -5px;
  left: -2px;
  border-radius: 0 30px 30px 0;
}

@media only screen and (min-width: 992px) {
  .diary-wrapper .diary-item {
    width: 500px;
  }
  .diary-wrapper .diary-exercise-planed {
    box-sizing: border-box;
    width: 280px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    transform: rotate(3deg);
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
  }
  .diary-wrapper .diary-exercise-planed .diary-exercise-item {
    font-size: 12px;
    padding: 5px 10px;
    line-height: 16px;
  }
  .diary-wrapper .diary-exercise-planed .diary-exercise-header {
    padding: 3px 10px;
    text-align: center;
    color: #fff;
    margin-bottom: 3px;
    display: block;
  }
  .trainingsplan-wrapper .ac-header .col-md-10 {
    float: inherit;
  }
}
@media only screen and (min-width: 1200px) {
  .diary-wrapper .diary-item {
    width: 600px;
  }
  .diary-wrapper .diary-exercise-planed {
    width: 280px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 1450px) {
  .diary-wrapper .diary-item {
    width: 800px;
  }
  .diary-wrapper .diary-exercise-planed {
    width: 300px;
    margin-left: 20px;
  }
}
.ac-plan table {
  max-width: 100%;
}
.ac-plan table tr .ac-competition-name-inner {
  word-break: break-all;
}

@media all and (max-width: 1449px) and (min-width: 1200px) {
  .trainingsplan-wrapper.custom-col {
    margin-left: 0;
    width: 100%;
  }
}
#account-nav .customer-account_logo__home {
  padding: 15px 0;
}
#account-nav a, #account-nav .nav.item.current strong {
  transition: all 0.1s ease-out;
  border-radius: 5px;
}
#account-nav .nav.items .nav-head {
  font-weight: 400;
  font-size: 12px;
  color: #29A9FC;
  padding: 8px 0 0 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#account-nav .nav.items .nav.item, #account-nav .nav.items .nav.item.current {
  position: relative;
  border-bottom: none;
  padding: 2px;
}
#account-nav .nav.items .nav.item a, #account-nav .nav.items .nav.item strong, #account-nav .nav.items .nav.item.current a, #account-nav .nav.items .nav.item.current strong {
  padding: 0 10px 0 42px;
  height: 30px;
  line-height: 30px;
}
#account-nav .nav.items .nav.item a:hover span, #account-nav .nav.items .nav.item strong:hover span, #account-nav .nav.items .nav.item.current a:hover span, #account-nav .nav.items .nav.item.current strong:hover span {
  color: #fff;
  opacity: 1;
}
#account-nav .nav.items .nav.item a span, #account-nav .nav.items .nav.item strong span, #account-nav .nav.items .nav.item.current a span, #account-nav .nav.items .nav.item.current strong span {
  font-size: 20px;
  top: 4px;
}
#account-nav .nav.items .nav.sub div.item:before {
  top: 12px;
}
#account-nav .nav.items .nav.nav-item-logout a {
  height: auto;
  padding: inherit;
}
#account-nav .nav.items .nav.nav-item-logout a .btn {
  box-shadow: none;
  padding: 0 10px;
  height: auto;
  line-height: inherit;
}
#account-nav .nav.items .new-message {
  position: relative;
  display: inline-block;
  top: -5px !important;
  right: 0;
  left: 2px;
  background: #FB2A1A;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  opacity: 1 !important;
}
#account-nav .account_nav_icons {
  display: flex;
  padding: 2px;
}
#account-nav .account_nav_icons a {
  color: #fff;
  display: block;
  box-sizing: border-box;
  text-align: center;
  flex-basis: 25%;
  text-decoration: none;
  padding: 0;
  height: 40px;
  line-height: 40px;
  border: 1px solid #263B47;
}
#account-nav .account_nav_icons a:hover {
  background: #000;
}
#account-nav .account_nav_icons a.active {
  background: #000;
}
#account-nav .account_nav_icons a span {
  font-size: 20px;
}
#account-nav .account_nav_icons a .page-functions {
  position: relative;
  top: 0;
  right: 0;
}
#account-nav .account_nav_icons a .watch-connected {
  position: relative;
  margin-left: 0;
}
#account-nav .account_nav_icons a .watch-connected:hover {
  color: #fff;
}
#account-nav .account_nav_icons a .watch-connected.active:after {
  content: "\edfb";
  display: block;
  position: absolute;
  font-size: 10px;
  background: #76B82A;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  top: 1px;
  left: 50%;
}
#account-nav .account_nav_icons a .watch-connected .open-popup-video {
  margin-left: 0;
}

@media only screen and (max-width: 1170px) {
  #account-nav .nav.items .nav.item a, #account-nav .nav.items .nav.item strong, #account-nav .nav.items .nav.item.current a, #account-nav .nav.items .nav.item.current strong {
    padding: 5px;
    line-height: inherit;
    height: auto;
    color: #fff;
  }
  #account-nav .nav.items .nav.item a span, #account-nav .nav.items .nav.item strong span, #account-nav .nav.items .nav.item.current a span, #account-nav .nav.items .nav.item.current strong span {
    font-size: 22px;
    top: 0;
  }
  #account-nav .nav.items .nav.item span {
    opacity: 0.2;
  }
  #account-nav .nav.items .nav.item.current span {
    opacity: 1;
  }
  #account-nav .nav.items .nav.sub div.item:before {
    top: 15px;
    left: 10px;
  }
}
@media only screen and (max-width: 768px) {
  #account-nav .nav.items .nav.item a, #account-nav .nav.items .nav.item strong, #account-nav .nav.items .nav.item.current a, #account-nav .nav.items .nav.item.current strong {
    padding: 10px;
    font-size: 20px;
  }
  #account-nav .nav.items .nav.item a span, #account-nav .nav.items .nav.item strong span, #account-nav .nav.items .nav.item.current a span, #account-nav .nav.items .nav.item.current strong span {
    font-size: 22px;
    top: 2px;
    margin-right: 6px;
  }
  #account-nav .nav.items .nav.sub a {
    padding: 10px 10px 10px 21px;
  }
  #account-nav .nav.items .nav.sub div.item:before {
    top: 20px;
    left: 7px;
  }
}
.ernaehrung-challenges-wrapper .challengelist-item,
.meal-intro .challengelist-item {
  background: rgba(0, 0, 0, 0.5);
  min-height: 50px;
  position: relative;
  box-sizing: border-box;
  padding: 8px 10px 5px 60px;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 2px;
  display: block;
  text-align: left;
  text-decoration: none;
}
.ernaehrung-challenges-wrapper .challengelist-item.challengelist-item-active,
.meal-intro .challengelist-item.challengelist-item-active {
  background: #29A9FC;
  text-decoration: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.ernaehrung-challenges-wrapper .challengelist-item .challengelist-item-title,
.meal-intro .challengelist-item .challengelist-item-title {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 6px;
}
.ernaehrung-challenges-wrapper .challengelist-item .icon-check,
.ernaehrung-challenges-wrapper .challengelist-item .icon-clock,
.meal-intro .challengelist-item .icon-check,
.meal-intro .challengelist-item .icon-clock {
  position: absolute;
  left: 10px;
  top: 5px;
  width: 40px !important;
  height: 40px !important;
  border-radius: 20px;
  overflow: hidden;
  background: transparent;
  cursor: pointer;
  line-height: 40px;
  text-align: center;
}
.ernaehrung-challenges-wrapper .challengelist-item .icon-clock,
.meal-intro .challengelist-item .icon-clock {
  font-size: 24px;
}
.ernaehrung-challenges-wrapper .challenge-info,
.meal-intro .challenge-info {
  display: block;
  border-radius: 0 0 5px 5px;
  position: relative;
  top: -3px;
  font-size: 14px;
  color: #fff;
  padding: 2px 5px;
  width: calc(100% + 6px);
  margin-left: -3px;
  box-sizing: border-box;
}
.ernaehrung-challenges-wrapper .challenge-info.challenge-info-active,
.meal-intro .challenge-info.challenge-info-active {
  background: #29A9FC;
}
.ernaehrung-challenges-wrapper .challenge-link,
.meal-intro .challenge-link {
  margin-bottom: 5px;
}
.ernaehrung-challenges-wrapper .challenge-link.challenge-active,
.meal-intro .challenge-link.challenge-active {
  box-shadow: 0 0 0 3px #29A9FC;
  border-radius: 5px 5px 0 0;
}
.ernaehrung-challenges-wrapper .challenge-link.challenge-done .challenge-photo,
.meal-intro .challenge-link.challenge-done .challenge-photo {
  opacity: 0.2;
}
.ernaehrung-challenges-wrapper .challenge-link.challenge-done:before,
.meal-intro .challenge-link.challenge-done:before {
  content: "\edfb";
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  color: #29A9FC;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 11;
}
.ernaehrung-challenges-wrapper .challenge-link .challenge-logo-wrapper,
.meal-intro .challenge-link .challenge-logo-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  transition: all 0.5s ease-out;
}
.ernaehrung-challenges-wrapper .challenge-link .challenge-logo-wrapper .challenge-logo,
.meal-intro .challenge-link .challenge-logo-wrapper .challenge-logo {
  margin: auto;
  height: auto !important;
  width: 70% !important;
  position: static;
}
.ernaehrung-challenges-wrapper .challenge-link:hover .challenge-logo-wrapper,
.meal-intro .challenge-link:hover .challenge-logo-wrapper {
  top: -20%;
}
.ernaehrung-challenges-wrapper .owl-dots .owl-dot span,
.meal-intro .owl-dots .owl-dot span {
  border: 2px solid #CED1D3;
}
.ernaehrung-challenges-wrapper .owl-dots .owl-dot.active span,
.meal-intro .owl-dots .owl-dot.active span {
  background: #CED1D3;
}
.ernaehrung-challenges-wrapper .ernaehrung-events h4,
.meal-intro .ernaehrung-events h4 {
  cursor: pointer;
}

.ernaehrung-challenges-wrapper .challenge-carousel.challenge-carousel-1 .owl-stage-outer {
  padding-left: 100px;
}
.ernaehrung-challenges-wrapper .challenge-carousel.challenge-carousel-1 .item {
  width: 332px;
}

.meal-intro {
  color: #fff;
  text-align: center;
  margin: 0 40px;
}

.nutrition .nutrition-wrapper .week-nav {
  overflow: hidden;
}
.nutrition .nutrition-wrapper .week-nav .owl-nav .owl-prev,
.nutrition .nutrition-wrapper .week-nav .owl-nav .owl-next {
  width: 14px;
  height: 10%;
}
.nutrition .nutrition-wrapper .week-nav .owl-nav .owl-prev:before,
.nutrition .nutrition-wrapper .week-nav .owl-nav .owl-next:before {
  font-size: 12px;
}
.nutrition .nutrition-wrapper .week-nav .owl-nav .owl-prev {
  left: 0;
}
.nutrition .nutrition-wrapper .week-nav .owl-nav .owl-next {
  right: 0;
}
.nutrition .add-fav.active .meal-plan-loader {
  background: #29A9FC;
}
.nutrition .add-fav.active .meal-plan-loader img.loader.loader-white-light {
  display: block;
}
.nutrition .add-fav.active .meal-plan-loader img.loader.loader-white {
  display: none;
}
.nutrition .add-fav .meal-plan-loader {
  position: absolute;
  background: #fff;
  color: #29A9FC !important;
  display: block;
  width: 32px;
  height: 32px;
  z-index: 10;
  right: 0;
  top: 0;
  border-radius: 5px;
  transition: all 0.1s ease-out;
  text-align: center;
  text-decoration: none;
  padding: 0;
}
.nutrition .add-fav .meal-plan-loader img.loader {
  width: 75% !important;
  margin: 4px auto;
}
.nutrition .add-fav .meal-plan-loader img.loader.loader-white-light {
  display: none;
}
.nutrition .add-fav .meal-plan-loader img.loader.loader-white {
  display: block;
}
.nutrition .field .error {
  color: #FB2A1A;
  margin-top: 15px;
  line-height: 1.2;
}

@media only screen and (max-width: 1170px) {
  .ernaehrung-challenges-wrapper .challenge-carousel.challenge-carousel-1 .owl-stage-outer {
    padding-left: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .meal-intro {
    margin: 0 15px;
  }
}
@media only screen and (max-width: 425px) {
  .ernaehrung-challenges-wrapper .challenge-carousel.challenge-carousel-1 .owl-stage-outer {
    padding-left: 5px;
  }
}
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?r4x5qa");
  src: url("../fonts/icomoon.eot?r4x5qa#iefix") format("embedded-opentype"), url("../fonts/icomoon.woff2?r4x5qa") format("woff2"), url("../fonts/icomoon.ttf?r4x5qa") format("truetype"), url("../fonts/icomoon.woff?r4x5qa") format("woff"), url("../fonts/icomoon.svg?r4x5qa#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/open-sans-condensed-v14-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Condensed Light"), local("OpenSansCondensed-Light"), url("../fonts/open-sans-condensed-v14-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-condensed-v14-latin-300.woff2") format("woff2"), url("../fonts/open-sans-condensed-v14-latin-300.woff") format("woff"), url("../fonts/open-sans-condensed-v14-latin-300.ttf") format("truetype"), url("../fonts/open-sans-condensed-v14-latin-300.svg#OpenSansCondensed") format("svg");
  /* Legacy iOS */
}
/* open-sans-condensed-700 - latin */
@font-face {
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/open-sans-condensed-v14-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Condensed Bold"), local("OpenSansCondensed-Bold"), url("../fonts/open-sans-condensed-v14-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-condensed-v14-latin-700.woff2") format("woff2"), url("../fonts/open-sans-condensed-v14-latin-700.woff") format("woff"), url("../fonts/open-sans-condensed-v14-latin-700.ttf") format("truetype"), url("../fonts/open-sans-condensed-v14-latin-700.svg#OpenSansCondensed") format("svg");
  /* Legacy iOS */
}
.hideclass {
  opacity: 0;
}

* {
  margin: 0;
  padding: 0;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

html {
  height: 100%;
  position: relative;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #263B47;
  background: #F0F2F4;
  min-height: 100%;
  line-height: 1.5em;
  position: relative;
}
body.customer-account-create, body.customer-account-login, body.customer-account-forgotpassword, body.customer-account-createpassword, body.customer-account-logoutsuccess {
  height: 100%;
}

a.open-tp-day-enter {
  text-decoration: none;
}

h1 {
  color: #CED1D3;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 36px;
  padding: 60px 20px 40px 20px;
  line-height: 1.3em;
}

h2 {
  color: #263B47;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 24px;
  padding: 30px 0;
}
h2.example {
  width: 100%;
}

h3 {
  font-size: 20px;
  font-weight: 300;
  padding: 15px 0;
}

p {
  padding-bottom: 15px;
}

strong {
  font-weight: 700;
}

.text-red {
  color: #FB2A1A;
}

.text-big {
  font-size: 20px;
  line-height: 30px;
  padding: 5px 0;
}

a {
  color: #29A9FC;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 24px;
  }
}
.row {
  margin-left: -12px;
  margin-right: -12px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.row:after {
  content: "";
  display: block;
  clear: both;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66667%;
}

.col-xs-10 {
  width: 83.33333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66667%;
}

.col-xs-7 {
  width: 58.33333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66667%;
}

.col-xs-4 {
  width: 33.33333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66667%;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66667%;
}

.col-xs-pull-10 {
  right: 83.33333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66667%;
}

.col-xs-pull-7 {
  right: 58.33333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66667%;
}

.col-xs-pull-4 {
  right: 33.33333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66667%;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66667%;
}

.col-xs-push-10 {
  left: 83.33333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66667%;
}

.col-xs-push-7 {
  left: 58.33333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66667%;
}

.col-xs-push-4 {
  left: 33.33333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66667%;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.text-xs-left {
  text-align: left;
}

.text-xs-right {
  text-align: right;
}

.text-xs-center {
  text-align: center;
}

@media (max-width: 768px) {
  .col-xs-dont-show {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66667%;
  }
  .col-sm-10 {
    width: 83.33333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66667%;
  }
  .col-sm-7 {
    width: 58.33333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66667%;
  }
  .col-sm-4 {
    width: 33.33333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66667%;
  }
  .col-sm-1 {
    width: 8.33333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66667%;
  }
  .col-sm-push-10 {
    left: 83.33333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66667%;
  }
  .col-sm-push-7 {
    left: 58.33333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66667%;
  }
  .col-sm-push-4 {
    left: 33.33333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66667%;
  }
  .col-sm-push-1 {
    left: 8.33333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66667%;
  }
  .col-md-10 {
    width: 83.33333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66667%;
  }
  .col-md-7 {
    width: 58.33333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66667%;
  }
  .col-md-4 {
    width: 33.33333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66667%;
  }
  .col-md-1 {
    width: 8.33333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66667%;
  }
  .col-md-pull-10 {
    right: 83.33333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66667%;
  }
  .col-md-pull-7 {
    right: 58.33333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66667%;
  }
  .col-md-pull-4 {
    right: 33.33333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66667%;
  }
  .col-md-pull-1 {
    right: 8.33333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66667%;
  }
  .col-md-push-10 {
    left: 83.33333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66667%;
  }
  .col-md-push-7 {
    left: 58.33333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66667%;
  }
  .col-md-push-4 {
    left: 33.33333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66667%;
  }
  .col-md-push-1 {
    left: 8.33333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66667%;
  }
  .col-lg-10 {
    width: 83.33333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66667%;
  }
  .col-lg-7 {
    width: 58.33333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66667%;
  }
  .col-lg-4 {
    width: 33.33333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66667%;
  }
  .col-lg-1 {
    width: 8.33333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66667%;
  }
  .col-lg-push-10 {
    left: 83.33333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66667%;
  }
  .col-lg-push-7 {
    left: 58.33333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66667%;
  }
  .col-lg-push-4 {
    left: 33.33333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66667%;
  }
  .col-lg-push-1 {
    left: 8.33333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}
/*Buttons-----------*/
.btn-wrapper {
  padding-top: 30px;
}
.btn-wrapper:after {
  content: "";
  display: block;
  clear: both;
}

.btn, .introjs-button {
  border-radius: 50px;
  border: 0;
  outline: 0;
  font-size: 16px;
  padding: 0 25px;
  box-sizing: border-box;
  display: inline-block;
  margin: 2px;
  text-decoration: none;
  color: #fff;
  background: #29A9FC;
  height: 34px;
  line-height: 34px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}
.btn [class^=icon-], .btn [class*=" icon-"], .introjs-button [class^=icon-], .introjs-button [class*=" icon-"] {
  position: relative;
  left: -5px;
}
.btn.active, .introjs-button.active {
  transform: scale(0.95) !important;
}
.btn:disabled, .introjs-button:disabled {
  opacity: 0.1 !important;
}
.btn.btn-mini, .introjs-button.btn-mini {
  font-size: 15px;
}

.btn-hidden {
  display: none;
}

.btn-icon-right [class^=icon-], .btn-icon-right [class*=" icon-"] {
  left: 5px;
}

.btn-default {
  color: #fff;
  background: #29A9FC;
}

.btn-red {
  color: #fff;
  background: #FB2A1A;
}

.btn-icon {
  width: 34px;
  text-align: center;
  padding: 0;
}
.btn-icon a {
  color: inherit;
  text-decoration: none;
}
.btn-icon [class^=icon-], .btn-icon [class*=" icon-"] {
  left: 0 !important;
}
.btn-icon.btn-small {
  width: 22px;
  padding: 0;
}

a.btn-icon {
  line-height: 40px;
}

.btn-with-icon {
  position: relative;
}
.btn-with-icon [class^=icon-], .btn-with-icon [class*=" icon-"] {
  background: #263B47;
  color: #fff;
  border-radius: 50px;
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 0;
}
.btn-with-icon.btn-small {
  padding-left: 30px;
  padding-right: 15px;
}
.btn-with-icon.btn-small [class^=icon-], .btn-with-icon.btn-small [class*=" icon-"] {
  width: 24px;
  height: 24px;
  line-height: 25px;
  text-align: center;
  box-sizing: border-box;
  padding-left: 2px;
}

.btn-100 {
  width: 100%;
}

.btn-80 {
  width: 80%;
}

.btn-left {
  float: left;
}

.btn-right {
  float: right;
}

.btn-icon-top {
  height: auto;
  text-align: center;
  border-radius: 17px;
  line-height: 18px;
  padding: 10px;
}
.btn-icon-top span {
  display: block;
  font-size: 24px;
  padding-bottom: 10px;
  left: 0 !important;
  color: #29A9FC !important;
}

.btn-icon-top.btn-default span {
  color: #fff !important;
}

.btn-small,
.introjs-button {
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
}

.btn-gray {
  color: #263B47 !important;
  background: #CED1D3;
}

/*
.diary-wrapper {
  .btn-gray {
    background: rgba(0, 0, 0, 0.1);
  }
}
*/
.btn-white {
  background: #fff;
  color: #29A9FC;
  font-family: "Open Sans", sans-serif;
}
.btn-white.btn-outline {
  border: 1px solid #fff !important;
  color: #fff !important;
  background: transparent !important;
}

.btn-dark {
  background: #263B47;
  color: #fff;
}

.btn-dark.btn-outline {
  background: none;
  border: 2px solid #263B47;
  color: #263B47;
}

.btn-white.btn-outline {
  border: 1px solid #fff !important;
  color: #fff !important;
  background: transparent !important;
}

.btn-trans {
  background: transparent;
}

.btn-outline {
  background: none;
  color: #29A9FC;
  box-shadow: inset 0 0 0 2px #29A9FC;
}

.btn-group {
  display: inline-block;
}
.btn-group .btn {
  display: block;
  float: left;
  margin: 0;
  border-radius: 0;
  margin-right: 1px;
}
.btn-group .btn:first-child {
  border-radius: 30px 0 0 30px;
}
.btn-group .btn:last-child {
  border-radius: 0 30px 30px 0;
  margin-right: 0;
}
.btn-group .btn-small {
  padding-left: 15px;
  padding-right: 15px;
}
.btn-group:after {
  content: "";
  display: block;
  clear: both;
}

.btn-nav .btn {
  opacity: 1;
  margin-right: 0;
  margin-left: -1px;
  border: 1px solid #29A9FC;
  background: #fff;
  color: #29A9FC !important;
  line-height: 12px;
}
.btn-nav .btn.active {
  opacity: 1;
  background: #29A9FC;
  color: #fff !important;
}
.btn-nav .btn-gray {
  border: 1px solid #CED1D3;
  color: #263B47 !important;
}
.btn-nav .btn-gray.active {
  background: #CED1D3;
  color: #263B47 !important;
}

[data-action=show_pdf] {
  display: none;
}
[data-action=show_pdf].active {
  display: inline-block;
}

.btn-group-row-edit {
  float: right;
  padding: 0 5px;
  position: relative;
  top: -2px;
  margin-bottom: 0;
}
.btn-group-row-edit .btn {
  margin-right: -7px;
  background: transparent !important;
  display: inline-block !important;
  color: #29A9FC !important;
}

.switch-btns {
  background: #F0F2F4;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 2px 0 2px 2px;
  font-size: 12px;
  color: #000;
  display: inline-block;
}
.switch-btns a {
  display: block;
  float: left;
  text-decoration: none;
  color: #000;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 1px 20px;
  position: relative;
  margin-right: 2px;
}
.switch-btns a span {
  position: relative;
  z-index: 1;
}
.switch-btns a:after {
  content: "";
  display: block;
  background: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  transform: scale(0);
  transition: all 0.1s ease-out;
}
.switch-btns a.active:after, .switch-btns a:hover:after {
  transform: scale(1);
}
.switch-btns:after {
  content: "";
  display: block;
  clear: both;
}
.switch-btns.switch-btns-white {
  background: #fff;
}
.switch-btns.switch-btns-white a:after {
  background: #F0F2F4;
}

.print-btn {
  text-decoration: none;
  color: #CED1D3;
  font-size: 30px;
  transition: all 0.1s ease-out;
}

.desktop .btn:hover {
  cursor: pointer;
  transform: scale(1.02);
}
.desktop .btn-small:hover {
  transform: scale(1.05);
}
.desktop .btn-nav .btn:hover {
  opacity: 1;
}
.desktop .btn-group .btn:hover {
  transform: scale(1);
}
.desktop .page-functions-btn:hover {
  color: #263B47;
}
.desktop .print-btn:hover {
  color: #263B47;
}
.desktop .btn-group-row-edit .btn:hover {
  transform: scale(1.7);
}

@media only screen and (max-width: 768px) {
  .btn-group .btn-small {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
  }
  .btn-icon {
    width: 190px;
  }
}
.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
}

.no-js .inputfile + label {
  display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  /* pointer-events: none; */
  /* in case of FastClick lib use */
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  /* 4px */
  margin-right: 0.25em;
  /* 4px */
}

label.btn {
  text-transform: none;
}
label.btn > div {
  display: inline-block;
}

/*Forms------------*/
.form-error {
  font-size: 12px;
  background: #FB2A1A;
  color: #fff;
  padding: 4px 6px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin: 0 3px;
  line-height: 14px !important;
}

.password-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}
.password-wrapper .password-status {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  width: 50px;
  height: 43px;
  line-height: 43px;
  color: #fff;
  border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  border-left: 1px solid #fff;
  z-index: 1;
}
.password-wrapper .password-status.password-status-1 {
  background: #FB2A1A;
}
.password-wrapper .password-status.password-status-1:before {
  content: "schwach";
}
.password-wrapper .password-status.password-status-2 {
  background: #ffa800;
}
.password-wrapper .password-status.password-status-2:before {
  content: "mittel";
}
.password-wrapper .password-status.password-status-3 {
  background: #76B82A;
}
.password-wrapper .password-status.password-status-3:before {
  content: "stark";
}
.password-wrapper input {
  width: 100%;
  box-sizing: border-box;
  padding-right: 55px !important;
}

.custom-checkbox input,
.custom-radio input {
  display: none;
}

.custom-checkbox label,
.custom-radio label {
  display: block;
  border-radius: 30px;
  padding: 3px 5px 3px 30px !important;
  text-align: left;
  position: relative;
  color: #29A9FC;
  transition: all 1s ease-out;
  cursor: pointer;
}
.custom-checkbox label:before,
.custom-radio label:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  left: 0px;
  top: 50%;
  margin-top: -10px;
  position: absolute;
  background: #fff;
}
.custom-checkbox label:after,
.custom-radio label:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  background: #29A9FC;
  border-radius: 20px;
  top: 50%;
  margin-top: -5px;
  left: 5px;
  opacity: 0;
  transform: scale(2);
  transition: all 0.1s ease-out;
}
.custom-checkbox .checked,
.custom-radio .checked {
  color: #fff;
}
.custom-checkbox .checked:after,
.custom-radio .checked:after {
  opacity: 1;
  transform: scale(1);
}

.custom-checkbox label {
  color: #fff;
}
.custom-checkbox label:before {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.custom-checkbox label:after {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\edfb";
  background: transparent;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #29A9FC;
  left: 0;
  margin-top: -10px;
}

.custum-input-wrapper {
  position: relative;
  text-align: left;
  display: block;
  padding: 5px 0;
  display: flex;
  justify-content: space-around;
}

.day-choose {
  width: 100%;
}

.day-choose .custum-input-wrapper {
  padding: 2px 0;
}

.day-choose .custom-checkbox {
  width: 100%;
}

.day-choose label {
  width: 100%;
  box-sizing: border-box;
  background: #29A9FC;
  padding-left: 40px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.day-choose label:before {
  left: 10px;
}

.day-choose label:after {
  opacity: 1;
  transform: scale(1);
  left: 11px;
  content: "";
}

.day-choose .checked {
  background: #FB2A1A;
}

.day-choose .checked:after {
  content: "\edfc";
  color: #FB2A1A;
}

.page-functions {
  position: absolute;
  right: 20px;
  top: 60px;
  z-index: 20;
}
.page-functions a {
  position: relative;
}
.page-functions a.active:before {
  content: "\edfb";
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #76B82A;
  border-radius: 50%;
  font-size: 10px;
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  line-height: 16px;
  text-align: center;
  right: 5px;
  top: -3px;
  color: #fff !important;
}

.page-functions-left {
  position: absolute;
  left: 20px;
  top: 58px;
  z-index: 20;
}

.add-training {
  text-align: center;
  padding-top: 15px;
}
.add-training .btn-icon {
  width: auto !important;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (min-width: 768px) {
  .page-functions-left {
    top: 8px;
  }
  .page-functions-left .btn-icon {
    width: auto !important;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 768px) {
  .page-functions {
    position: relative;
    right: 0;
    top: -30px;
    text-align: right;
    padding-right: 20px;
  }
}
.page-functions-btn {
  text-decoration: none;
  color: #CED1D3;
  font-size: 30px;
  transition: all 0.1s ease-out;
  margin-left: 10px;
}

.last-check {
  position: absolute;
  color: #CED1D3;
  font-weight: 700;
  font-size: 14px;
  top: 57px;
  right: 160px;
  line-height: 18px;
  text-align: right;
  z-index: 20;
}
.last-check span {
  font-weight: 300;
  color: #263B47;
  display: block;
}

.mobile .page-functions {
  display: none;
}
.mobile .last-check {
  right: 20px;
}

@media only screen and (min-width: 768px) {
  .page-functions,
.last-check {
    top: 10px;
  }
}
/*Accorion-Trigger--------*/
.accordion-trigger {
  cursor: pointer;
  position: relative;
  text-decoration: underline;
  font-size: 14px;
  padding-left: 15px;
}
.accordion-trigger:after {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ee04";
  display: inline-block;
  margin-left: 5px;
}
.accordion-trigger.closed .accordion-content {
  display: none;
}
.accordion-trigger.open .accordion-trigger:after {
  content: "\ee03";
}

.cd-main-content {
  position: relative;
}

.add-banner {
  margin-bottom: 20px;
}
.add-banner img {
  width: 100% !important;
  height: auto !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

/*Panels-----------*/
.panel {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 15px;
}
.panel hr {
  border: 0;
  border-top: 1px solid #F0F2F4;
  margin: 20px -15px;
}

.panel-white {
  background: #fff;
}

.panel-shadow {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.date-nav {
  font-size: 14px;
  padding: 5px 0;
}

.date-nav-wrapper {
  text-align: center;
  padding: 0 15px 30px 15px;
  margin-top: -40px;
}

.video-expand {
  margin-left: -20px;
  width: calc(100% + 40px);
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
  padding-top: 0;
  height: 0;
  overflow: hidden;
}
.responsive-video .responsive-video-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000 no-repeat center url("../images/LT-Signet.svg");
  background-size: contain;
  cursor: pointer;
}
.responsive-video iframe.plays + .responsive-video-overlay {
  display: none;
}
.responsive-video .responsive-video-overlay:before {
  display: block;
  position: absolute;
  z-index: 101;
  width: 64px;
  height: 64px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  margin: auto;
  background: no-repeat center url("../images/video-play-64.png");
}
.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-link {
  overflow: hidden;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  position: relative;
  text-decoration: none;
  color: #fff;
  display: block;
  max-width: 250px;
}
.video-link img {
  width: 100% !important;
  height: auto !important;
  margin-bottom: -7px;
  transition: all 2s ease-out;
}
.video-link span {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #fff;
  color: #263B47;
  line-height: 50px;
  text-align: center;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  font-size: 20px;
  box-sizing: border-box;
  padding-left: 2px;
  transition: all 0.1s ease-out;
  z-index: 10;
}

.desktop .video-link:hover {
  overflow: hidden;
}
.desktop .video-link:hover img {
  transform: scale(1.1);
}
.desktop .video-link:hover span {
  transform: scale(1.1);
}

.start-popup-list {
  list-style-type: none;
  padding: 2px 0;
  margin-top: 15px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#29a9fc+2,70c4fa+50,29a9fc+98 */
  background: rgb(41, 169, 252); /* Old browsers */
  background: -moz-linear-gradient(left, rgb(41, 169, 252) 2%, rgb(112, 196, 250) 50%, rgb(41, 169, 252) 98%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgb(41, 169, 252) 2%, rgb(112, 196, 250) 50%, rgb(41, 169, 252) 98%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgb(41, 169, 252) 2%, rgb(112, 196, 250) 50%, rgb(41, 169, 252) 98%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#29a9fc", endColorstr="#29a9fc",GradientType=1 ); /* IE6-9 */
}
.start-popup-list li {
  padding: 0 10px 26px 10px;
  margin-top: 2px;
  background: #29A9FC;
}
.start-popup-list li:first-child {
  margin-top: 0;
}
.start-popup-list li strong {
  border: 2px solid #70c4fa;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  line-height: 40px;
  font-size: 20px;
  margin-bottom: -14px;
  font-weight: 700;
  position: relative;
  top: -22px;
  background: #29A9FC;
}
.start-popup-list li strong:after {
  width: 0;
  height: 0;
  position: absolute;
  content: "";
  display: block;
  border: 6px solid transparent;
  border-top: 6px solid #70c4fa;
  left: 50%;
  margin-left: -6px;
  bottom: -12px;
}

.popup {
  max-width: 700px;
  position: relative;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.popup header,
.popup footer,
.popup section {
  padding: 10px;
  text-align: center;
  position: relative;
}
.popup header:after,
.popup footer:after,
.popup section:after {
  content: "";
  display: block;
  clear: both;
}
.popup header h3,
.popup footer h3,
.popup section h3 {
  padding: 8px 0;
}
.popup header hr,
.popup footer hr,
.popup section hr {
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  height: 0;
  width: calc(100% + 40px);
  margin: 15px -10px 15px -10px;
}
.popup footer {
  border-radius: 0 0 5px 5px;
  min-height: 25px;
}
.popup header {
  border-radius: 5px 5px 0 0;
  min-height: 25px;
}

.popup-award {
  width: 300px;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  position: relative;
}
.popup-award h2 {
  color: #fff;
  padding-bottom: 5px;
  font-weight: 700;
}
.popup-award .mfp-close {
  display: none;
}

.mfp-award .mfp-container {
  overflow: hidden !important;
}

.popup-award .glow-wrapper {
  position: absolute;
  transform-style: preserve-3d;
  perspective: 300px;
  width: 300px;
  height: 200px;
}
.popup-award img {
  position: relative;
}
.popup-award .glow1 {
  width: 100px;
  height: 100px;
  border-radius: 200px;
  background: #29A9FC;
  box-shadow: 0 0 200px 200px #29A9FC;
}
.popup-award .glow2 {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 100px;
  top: 50px;
}
.popup-award .award-glow-wrapper {
  position: relative;
}

.c {
  position: absolute;
  width: 25px;
  height: 25px;
  margin-top: -12.5px;
  margin-left: -12.5px;
  transform: translate3d(150px, 100px, -1000px);
  border-radius: 50%;
}

.c:nth-child(1) {
  animation: anim1 1.5s infinite alternate;
  animation-delay: -0.015s;
  background: hsl(204.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c5fd, hsl(204.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40b1fc;
  border: 1px solid hsl(204.1deg, 97%, 57%);
}

@keyframes anim1 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(265px, 156px, 0);
    opacity: 0;
  }
}
.c:nth-child(2) {
  animation: anim2 1.5s infinite alternate;
  animation-delay: -0.03s;
  background: hsl(204.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c5fd, hsl(204.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40b0fc;
  border: 1px solid hsl(204.2deg, 97%, 57%);
}

@keyframes anim2 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(123px, 163px, 0);
    opacity: 0;
  }
}
.c:nth-child(3) {
  animation: anim3 1.5s infinite alternate;
  animation-delay: -0.045s;
  background: hsl(204.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c5fd, hsl(204.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40b0fc;
  border: 1px solid hsl(204.3deg, 97%, 57%);
}

@keyframes anim3 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(29px, 60px, 0);
    opacity: 0;
  }
}
.c:nth-child(4) {
  animation: anim4 1.5s infinite alternate;
  animation-delay: -0.06s;
  background: hsl(204.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c5fd, hsl(204.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40b0fc;
  border: 1px solid hsl(204.4deg, 97%, 57%);
}

@keyframes anim4 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(159px, 1px, 0);
    opacity: 0;
  }
}
.c:nth-child(5) {
  animation: anim5 1.5s infinite alternate;
  animation-delay: -0.075s;
  background: hsl(204.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c4fd, hsl(204.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40affc;
  border: 1px solid hsl(204.5deg, 97%, 57%);
}

@keyframes anim5 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(62px, 21px, 0);
    opacity: 0;
  }
}
.c:nth-child(6) {
  animation: anim6 1.5s infinite alternate;
  animation-delay: -0.09s;
  background: hsl(204.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c4fd, hsl(204.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40affc;
  border: 1px solid hsl(204.6deg, 97%, 57%);
}

@keyframes anim6 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(273px, 154px, 0);
    opacity: 0;
  }
}
.c:nth-child(7) {
  animation: anim7 1.5s infinite alternate;
  animation-delay: -0.105s;
  background: hsl(204.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c4fd, hsl(204.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40affc;
  border: 1px solid hsl(204.7deg, 97%, 57%);
}

@keyframes anim7 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(56px, 132px, 0);
    opacity: 0;
  }
}
.c:nth-child(8) {
  animation: anim8 1.5s infinite alternate;
  animation-delay: -0.12s;
  background: hsl(204.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c4fd, hsl(204.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40aefc;
  border: 1px solid hsl(204.8deg, 97%, 57%);
}

@keyframes anim8 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(65px, 137px, 0);
    opacity: 0;
  }
}
.c:nth-child(9) {
  animation: anim9 1.5s infinite alternate;
  animation-delay: -0.135s;
  background: hsl(204.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c3fd, hsl(204.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40aefc;
  border: 1px solid hsl(204.9deg, 97%, 57%);
}

@keyframes anim9 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(249px, 21px, 0);
    opacity: 0;
  }
}
.c:nth-child(10) {
  animation: anim10 1.5s infinite alternate;
  animation-delay: -0.15s;
  background: hsl(205deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c3fd, hsl(205deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40aefc;
  border: 1px solid hsl(205deg, 97%, 57%);
}

@keyframes anim10 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(66px, 189px, 0);
    opacity: 0;
  }
}
.c:nth-child(11) {
  animation: anim11 1.5s infinite alternate;
  animation-delay: -0.165s;
  background: hsl(205.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c3fd, hsl(205.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40adfc;
  border: 1px solid hsl(205.1deg, 97%, 57%);
}

@keyframes anim11 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(93px, 70px, 0);
    opacity: 0;
  }
}
.c:nth-child(12) {
  animation: anim12 1.5s infinite alternate;
  animation-delay: -0.18s;
  background: hsl(205.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c3fd, hsl(205.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40adfc;
  border: 1px solid hsl(205.2deg, 97%, 57%);
}

@keyframes anim12 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(44px, 127px, 0);
    opacity: 0;
  }
}
.c:nth-child(13) {
  animation: anim13 1.5s infinite alternate;
  animation-delay: -0.195s;
  background: hsl(205.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c2fd, hsl(205.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40adfc;
  border: 1px solid hsl(205.3deg, 97%, 57%);
}

@keyframes anim13 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(23px, 176px, 0);
    opacity: 0;
  }
}
.c:nth-child(14) {
  animation: anim14 1.5s infinite alternate;
  animation-delay: -0.21s;
  background: hsl(205.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c2fd, hsl(205.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40adfc;
  border: 1px solid hsl(205.4deg, 97%, 57%);
}

@keyframes anim14 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(6px, 90px, 0);
    opacity: 0;
  }
}
.c:nth-child(15) {
  animation: anim15 1.5s infinite alternate;
  animation-delay: -0.225s;
  background: hsl(205.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c2fd, hsl(205.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40acfc;
  border: 1px solid hsl(205.5deg, 97%, 57%);
}

@keyframes anim15 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(126px, 173px, 0);
    opacity: 0;
  }
}
.c:nth-child(16) {
  animation: anim16 1.5s infinite alternate;
  animation-delay: -0.24s;
  background: hsl(205.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c2fd, hsl(205.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40acfc;
  border: 1px solid hsl(205.6deg, 97%, 57%);
}

@keyframes anim16 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(10px, 179px, 0);
    opacity: 0;
  }
}
.c:nth-child(17) {
  animation: anim17 1.5s infinite alternate;
  animation-delay: -0.255s;
  background: hsl(205.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c2fd, hsl(205.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40acfc;
  border: 1px solid hsl(205.7deg, 97%, 57%);
}

@keyframes anim17 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(13px, 44px, 0);
    opacity: 0;
  }
}
.c:nth-child(18) {
  animation: anim18 1.5s infinite alternate;
  animation-delay: -0.27s;
  background: hsl(205.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c1fd, hsl(205.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40abfc;
  border: 1px solid hsl(205.8deg, 97%, 57%);
}

@keyframes anim18 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(108px, 130px, 0);
    opacity: 0;
  }
}
.c:nth-child(19) {
  animation: anim19 1.5s infinite alternate;
  animation-delay: -0.285s;
  background: hsl(205.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c1fd, hsl(205.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40abfc;
  border: 1px solid hsl(205.9deg, 97%, 57%);
}

@keyframes anim19 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(23px, 142px, 0);
    opacity: 0;
  }
}
.c:nth-child(20) {
  animation: anim20 1.5s infinite alternate;
  animation-delay: -0.3s;
  background: hsl(206deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c1fd, hsl(206deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40abfc;
  border: 1px solid hsl(206deg, 97%, 57%);
}

@keyframes anim20 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(22px, 159px, 0);
    opacity: 0;
  }
}
.c:nth-child(21) {
  animation: anim21 1.5s infinite alternate;
  animation-delay: -0.315s;
  background: hsl(206.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c1fd, hsl(206.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40aafc;
  border: 1px solid hsl(206.1deg, 97%, 57%);
}

@keyframes anim21 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(10px, 111px, 0);
    opacity: 0;
  }
}
.c:nth-child(22) {
  animation: anim22 1.5s infinite alternate;
  animation-delay: -0.33s;
  background: hsl(206.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c0fd, hsl(206.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40aafc;
  border: 1px solid hsl(206.2deg, 97%, 57%);
}

@keyframes anim22 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(51px, 50px, 0);
    opacity: 0;
  }
}
.c:nth-child(23) {
  animation: anim23 1.5s infinite alternate;
  animation-delay: -0.345s;
  background: hsl(206.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c0fd, hsl(206.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40aafc;
  border: 1px solid hsl(206.3deg, 97%, 57%);
}

@keyframes anim23 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(291px, 100px, 0);
    opacity: 0;
  }
}
.c:nth-child(24) {
  animation: anim24 1.5s infinite alternate;
  animation-delay: -0.36s;
  background: hsl(206.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c0fd, hsl(206.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a9fc;
  border: 1px solid hsl(206.4deg, 97%, 57%);
}

@keyframes anim24 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(152px, 188px, 0);
    opacity: 0;
  }
}
.c:nth-child(25) {
  animation: anim25 1.5s infinite alternate;
  animation-delay: -0.375s;
  background: hsl(206.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72c0fd, hsl(206.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a9fc;
  border: 1px solid hsl(206.5deg, 97%, 57%);
}

@keyframes anim25 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(52px, 187px, 0);
    opacity: 0;
  }
}
.c:nth-child(26) {
  animation: anim26 1.5s infinite alternate;
  animation-delay: -0.39s;
  background: hsl(206.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bffd, hsl(206.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a9fc;
  border: 1px solid hsl(206.6deg, 97%, 57%);
}

@keyframes anim26 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(119px, 86px, 0);
    opacity: 0;
  }
}
.c:nth-child(27) {
  animation: anim27 1.5s infinite alternate;
  animation-delay: -0.405s;
  background: hsl(206.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bffd, hsl(206.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a8fc;
  border: 1px solid hsl(206.7deg, 97%, 57%);
}

@keyframes anim27 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(205px, 62px, 0);
    opacity: 0;
  }
}
.c:nth-child(28) {
  animation: anim28 1.5s infinite alternate;
  animation-delay: -0.42s;
  background: hsl(206.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bffd, hsl(206.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a8fc;
  border: 1px solid hsl(206.8deg, 97%, 57%);
}

@keyframes anim28 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(210px, 140px, 0);
    opacity: 0;
  }
}
.c:nth-child(29) {
  animation: anim29 1.5s infinite alternate;
  animation-delay: -0.435s;
  background: hsl(206.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bffd, hsl(206.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a8fc;
  border: 1px solid hsl(206.9deg, 97%, 57%);
}

@keyframes anim29 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(117px, 55px, 0);
    opacity: 0;
  }
}
.c:nth-child(30) {
  animation: anim30 1.5s infinite alternate;
  animation-delay: -0.45s;
  background: hsl(207deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bffd, hsl(207deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a7fc;
  border: 1px solid hsl(207deg, 97%, 57%);
}

@keyframes anim30 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(195px, 11px, 0);
    opacity: 0;
  }
}
.c:nth-child(31) {
  animation: anim31 1.5s infinite alternate;
  animation-delay: -0.465s;
  background: hsl(207.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72befd, hsl(207.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a7fc;
  border: 1px solid hsl(207.1deg, 97%, 57%);
}

@keyframes anim31 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(259px, 189px, 0);
    opacity: 0;
  }
}
.c:nth-child(32) {
  animation: anim32 1.5s infinite alternate;
  animation-delay: -0.48s;
  background: hsl(207.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72befd, hsl(207.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a7fc;
  border: 1px solid hsl(207.2deg, 97%, 57%);
}

@keyframes anim32 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(285px, 84px, 0);
    opacity: 0;
  }
}
.c:nth-child(33) {
  animation: anim33 1.5s infinite alternate;
  animation-delay: -0.495s;
  background: hsl(207.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72befd, hsl(207.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a7fc;
  border: 1px solid hsl(207.3deg, 97%, 57%);
}

@keyframes anim33 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(123px, 145px, 0);
    opacity: 0;
  }
}
.c:nth-child(34) {
  animation: anim34 1.5s infinite alternate;
  animation-delay: -0.51s;
  background: hsl(207.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72befd, hsl(207.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a6fc;
  border: 1px solid hsl(207.4deg, 97%, 57%);
}

@keyframes anim34 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(272px, 199px, 0);
    opacity: 0;
  }
}
.c:nth-child(35) {
  animation: anim35 1.5s infinite alternate;
  animation-delay: -0.525s;
  background: hsl(207.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bdfd, hsl(207.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a6fc;
  border: 1px solid hsl(207.5deg, 97%, 57%);
}

@keyframes anim35 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(259px, 161px, 0);
    opacity: 0;
  }
}
.c:nth-child(36) {
  animation: anim36 1.5s infinite alternate;
  animation-delay: -0.54s;
  background: hsl(207.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bdfd, hsl(207.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a6fc;
  border: 1px solid hsl(207.6deg, 97%, 57%);
}

@keyframes anim36 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(10px, 40px, 0);
    opacity: 0;
  }
}
.c:nth-child(37) {
  animation: anim37 1.5s infinite alternate;
  animation-delay: -0.555s;
  background: hsl(207.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bdfd, hsl(207.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a5fc;
  border: 1px solid hsl(207.7deg, 97%, 57%);
}

@keyframes anim37 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(221px, 93px, 0);
    opacity: 0;
  }
}
.c:nth-child(38) {
  animation: anim38 1.5s infinite alternate;
  animation-delay: -0.57s;
  background: hsl(207.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bdfd, hsl(207.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a5fc;
  border: 1px solid hsl(207.8deg, 97%, 57%);
}

@keyframes anim38 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(6px, 151px, 0);
    opacity: 0;
  }
}
.c:nth-child(39) {
  animation: anim39 1.5s infinite alternate;
  animation-delay: -0.585s;
  background: hsl(207.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bcfd, hsl(207.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a5fc;
  border: 1px solid hsl(207.9deg, 97%, 57%);
}

@keyframes anim39 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(166px, 131px, 0);
    opacity: 0;
  }
}
.c:nth-child(40) {
  animation: anim40 1.5s infinite alternate;
  animation-delay: -0.6s;
  background: hsl(208deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bcfd, hsl(208deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a4fc;
  border: 1px solid hsl(208deg, 97%, 57%);
}

@keyframes anim40 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(215px, 181px, 0);
    opacity: 0;
  }
}
.c:nth-child(41) {
  animation: anim41 1.5s infinite alternate;
  animation-delay: -0.615s;
  background: hsl(208.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bcfd, hsl(208.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a4fc;
  border: 1px solid hsl(208.1deg, 97%, 57%);
}

@keyframes anim41 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(212px, 127px, 0);
    opacity: 0;
  }
}
.c:nth-child(42) {
  animation: anim42 1.5s infinite alternate;
  animation-delay: -0.63s;
  background: hsl(208.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bcfd, hsl(208.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a4fc;
  border: 1px solid hsl(208.2deg, 97%, 57%);
}

@keyframes anim42 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(94px, 104px, 0);
    opacity: 0;
  }
}
.c:nth-child(43) {
  animation: anim43 1.5s infinite alternate;
  animation-delay: -0.645s;
  background: hsl(208.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bcfd, hsl(208.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a3fc;
  border: 1px solid hsl(208.3deg, 97%, 57%);
}

@keyframes anim43 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(114px, 52px, 0);
    opacity: 0;
  }
}
.c:nth-child(44) {
  animation: anim44 1.5s infinite alternate;
  animation-delay: -0.66s;
  background: hsl(208.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bbfd, hsl(208.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a3fc;
  border: 1px solid hsl(208.4deg, 97%, 57%);
}

@keyframes anim44 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(84px, 179px, 0);
    opacity: 0;
  }
}
.c:nth-child(45) {
  animation: anim45 1.5s infinite alternate;
  animation-delay: -0.675s;
  background: hsl(208.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bbfd, hsl(208.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a3fc;
  border: 1px solid hsl(208.5deg, 97%, 57%);
}

@keyframes anim45 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(128px, 195px, 0);
    opacity: 0;
  }
}
.c:nth-child(46) {
  animation: anim46 1.5s infinite alternate;
  animation-delay: -0.69s;
  background: hsl(208.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bbfd, hsl(208.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a2fc;
  border: 1px solid hsl(208.6deg, 97%, 57%);
}

@keyframes anim46 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(39px, 38px, 0);
    opacity: 0;
  }
}
.c:nth-child(47) {
  animation: anim47 1.5s infinite alternate;
  animation-delay: -0.705s;
  background: hsl(208.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bbfd, hsl(208.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a2fc;
  border: 1px solid hsl(208.7deg, 97%, 57%);
}

@keyframes anim47 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(57px, 185px, 0);
    opacity: 0;
  }
}
.c:nth-child(48) {
  animation: anim48 1.5s infinite alternate;
  animation-delay: -0.72s;
  background: hsl(208.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bafd, hsl(208.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a2fc;
  border: 1px solid hsl(208.8deg, 97%, 57%);
}

@keyframes anim48 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(267px, 120px, 0);
    opacity: 0;
  }
}
.c:nth-child(49) {
  animation: anim49 1.5s infinite alternate;
  animation-delay: -0.735s;
  background: hsl(208.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bafd, hsl(208.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a2fc;
  border: 1px solid hsl(208.9deg, 97%, 57%);
}

@keyframes anim49 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(217px, 167px, 0);
    opacity: 0;
  }
}
.c:nth-child(50) {
  animation: anim50 1.5s infinite alternate;
  animation-delay: -0.75s;
  background: hsl(209deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bafd, hsl(209deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a1fc;
  border: 1px solid hsl(209deg, 97%, 57%);
}

@keyframes anim50 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(106px, 111px, 0);
    opacity: 0;
  }
}
.c:nth-child(51) {
  animation: anim51 1.5s infinite alternate;
  animation-delay: -0.765s;
  background: hsl(209.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72bafd, hsl(209.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a1fc;
  border: 1px solid hsl(209.1deg, 97%, 57%);
}

@keyframes anim51 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(238px, 15px, 0);
    opacity: 0;
  }
}
.c:nth-child(52) {
  animation: anim52 1.5s infinite alternate;
  animation-delay: -0.78s;
  background: hsl(209.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b9fd, hsl(209.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a1fc;
  border: 1px solid hsl(209.2deg, 97%, 57%);
}

@keyframes anim52 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(64px, 66px, 0);
    opacity: 0;
  }
}
.c:nth-child(53) {
  animation: anim53 1.5s infinite alternate;
  animation-delay: -0.795s;
  background: hsl(209.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b9fd, hsl(209.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a0fc;
  border: 1px solid hsl(209.3deg, 97%, 57%);
}

@keyframes anim53 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(130px, 4px, 0);
    opacity: 0;
  }
}
.c:nth-child(54) {
  animation: anim54 1.5s infinite alternate;
  animation-delay: -0.81s;
  background: hsl(209.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b9fd, hsl(209.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a0fc;
  border: 1px solid hsl(209.4deg, 97%, 57%);
}

@keyframes anim54 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(222px, 68px, 0);
    opacity: 0;
  }
}
.c:nth-child(55) {
  animation: anim55 1.5s infinite alternate;
  animation-delay: -0.825s;
  background: hsl(209.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b9fd, hsl(209.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #40a0fc;
  border: 1px solid hsl(209.5deg, 97%, 57%);
}

@keyframes anim55 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(13px, 139px, 0);
    opacity: 0;
  }
}
.c:nth-child(56) {
  animation: anim56 1.5s infinite alternate;
  animation-delay: -0.84s;
  background: hsl(209.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b9fd, hsl(209.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409ffc;
  border: 1px solid hsl(209.6deg, 97%, 57%);
}

@keyframes anim56 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(190px, 92px, 0);
    opacity: 0;
  }
}
.c:nth-child(57) {
  animation: anim57 1.5s infinite alternate;
  animation-delay: -0.855s;
  background: hsl(209.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b8fd, hsl(209.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409ffc;
  border: 1px solid hsl(209.7deg, 97%, 57%);
}

@keyframes anim57 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(89px, 102px, 0);
    opacity: 0;
  }
}
.c:nth-child(58) {
  animation: anim58 1.5s infinite alternate;
  animation-delay: -0.87s;
  background: hsl(209.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b8fd, hsl(209.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409ffc;
  border: 1px solid hsl(209.8deg, 97%, 57%);
}

@keyframes anim58 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(255px, 44px, 0);
    opacity: 0;
  }
}
.c:nth-child(59) {
  animation: anim59 1.5s infinite alternate;
  animation-delay: -0.885s;
  background: hsl(209.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b8fd, hsl(209.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409efc;
  border: 1px solid hsl(209.9deg, 97%, 57%);
}

@keyframes anim59 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(76px, 147px, 0);
    opacity: 0;
  }
}
.c:nth-child(60) {
  animation: anim60 1.5s infinite alternate;
  animation-delay: -0.9s;
  background: hsl(210deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b8fd, hsl(210deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409efc;
  border: 1px solid hsl(210deg, 97%, 57%);
}

@keyframes anim60 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(112px, 187px, 0);
    opacity: 0;
  }
}
.c:nth-child(61) {
  animation: anim61 1.5s infinite alternate;
  animation-delay: -0.915s;
  background: hsl(210.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b7fd, hsl(210.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409efc;
  border: 1px solid hsl(210.1deg, 97%, 57%);
}

@keyframes anim61 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(258px, 5px, 0);
    opacity: 0;
  }
}
.c:nth-child(62) {
  animation: anim62 1.5s infinite alternate;
  animation-delay: -0.93s;
  background: hsl(210.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b7fd, hsl(210.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409dfc;
  border: 1px solid hsl(210.2deg, 97%, 57%);
}

@keyframes anim62 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(209px, 27px, 0);
    opacity: 0;
  }
}
.c:nth-child(63) {
  animation: anim63 1.5s infinite alternate;
  animation-delay: -0.945s;
  background: hsl(210.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b7fd, hsl(210.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409dfc;
  border: 1px solid hsl(210.3deg, 97%, 57%);
}

@keyframes anim63 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(92px, 154px, 0);
    opacity: 0;
  }
}
.c:nth-child(64) {
  animation: anim64 1.5s infinite alternate;
  animation-delay: -0.96s;
  background: hsl(210.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b7fd, hsl(210.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409dfc;
  border: 1px solid hsl(210.4deg, 97%, 57%);
}

@keyframes anim64 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(237px, 58px, 0);
    opacity: 0;
  }
}
.c:nth-child(65) {
  animation: anim65 1.5s infinite alternate;
  animation-delay: -0.975s;
  background: hsl(210.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b6fd, hsl(210.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409dfc;
  border: 1px solid hsl(210.5deg, 97%, 57%);
}

@keyframes anim65 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(211px, 66px, 0);
    opacity: 0;
  }
}
.c:nth-child(66) {
  animation: anim66 1.5s infinite alternate;
  animation-delay: -0.99s;
  background: hsl(210.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b6fd, hsl(210.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409cfc;
  border: 1px solid hsl(210.6deg, 97%, 57%);
}

@keyframes anim66 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(156px, 148px, 0);
    opacity: 0;
  }
}
.c:nth-child(67) {
  animation: anim67 1.5s infinite alternate;
  animation-delay: -1.005s;
  background: hsl(210.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b6fd, hsl(210.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409cfc;
  border: 1px solid hsl(210.7deg, 97%, 57%);
}

@keyframes anim67 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(202px, 46px, 0);
    opacity: 0;
  }
}
.c:nth-child(68) {
  animation: anim68 1.5s infinite alternate;
  animation-delay: -1.02s;
  background: hsl(210.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b6fd, hsl(210.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409cfc;
  border: 1px solid hsl(210.8deg, 97%, 57%);
}

@keyframes anim68 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(85px, 71px, 0);
    opacity: 0;
  }
}
.c:nth-child(69) {
  animation: anim69 1.5s infinite alternate;
  animation-delay: -1.035s;
  background: hsl(210.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b6fd, hsl(210.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409bfc;
  border: 1px solid hsl(210.9deg, 97%, 57%);
}

@keyframes anim69 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(49px, 35px, 0);
    opacity: 0;
  }
}
.c:nth-child(70) {
  animation: anim70 1.5s infinite alternate;
  animation-delay: -1.05s;
  background: hsl(211deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b5fd, hsl(211deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409bfc;
  border: 1px solid hsl(211deg, 97%, 57%);
}

@keyframes anim70 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(291px, 54px, 0);
    opacity: 0;
  }
}
.c:nth-child(71) {
  animation: anim71 1.5s infinite alternate;
  animation-delay: -1.065s;
  background: hsl(211.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b5fd, hsl(211.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409bfc;
  border: 1px solid hsl(211.1deg, 97%, 57%);
}

@keyframes anim71 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(289px, 172px, 0);
    opacity: 0;
  }
}
.c:nth-child(72) {
  animation: anim72 1.5s infinite alternate;
  animation-delay: -1.08s;
  background: hsl(211.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b5fd, hsl(211.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409afc;
  border: 1px solid hsl(211.2deg, 97%, 57%);
}

@keyframes anim72 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(131px, 49px, 0);
    opacity: 0;
  }
}
.c:nth-child(73) {
  animation: anim73 1.5s infinite alternate;
  animation-delay: -1.095s;
  background: hsl(211.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b5fd, hsl(211.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409afc;
  border: 1px solid hsl(211.3deg, 97%, 57%);
}

@keyframes anim73 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(260px, 52px, 0);
    opacity: 0;
  }
}
.c:nth-child(74) {
  animation: anim74 1.5s infinite alternate;
  animation-delay: -1.11s;
  background: hsl(211.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b4fd, hsl(211.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #409afc;
  border: 1px solid hsl(211.4deg, 97%, 57%);
}

@keyframes anim74 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(109px, 126px, 0);
    opacity: 0;
  }
}
.c:nth-child(75) {
  animation: anim75 1.5s infinite alternate;
  animation-delay: -1.125s;
  background: hsl(211.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b4fd, hsl(211.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4099fc;
  border: 1px solid hsl(211.5deg, 97%, 57%);
}

@keyframes anim75 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(202px, 131px, 0);
    opacity: 0;
  }
}
.c:nth-child(76) {
  animation: anim76 1.5s infinite alternate;
  animation-delay: -1.14s;
  background: hsl(211.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b4fd, hsl(211.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4099fc;
  border: 1px solid hsl(211.6deg, 97%, 57%);
}

@keyframes anim76 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(94px, 164px, 0);
    opacity: 0;
  }
}
.c:nth-child(77) {
  animation: anim77 1.5s infinite alternate;
  animation-delay: -1.155s;
  background: hsl(211.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b4fd, hsl(211.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4099fc;
  border: 1px solid hsl(211.7deg, 97%, 57%);
}

@keyframes anim77 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(56px, 5px, 0);
    opacity: 0;
  }
}
.c:nth-child(78) {
  animation: anim78 1.5s infinite alternate;
  animation-delay: -1.17s;
  background: hsl(211.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b3fd, hsl(211.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4098fc;
  border: 1px solid hsl(211.8deg, 97%, 57%);
}

@keyframes anim78 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(52px, 8px, 0);
    opacity: 0;
  }
}
.c:nth-child(79) {
  animation: anim79 1.5s infinite alternate;
  animation-delay: -1.185s;
  background: hsl(211.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b3fd, hsl(211.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4098fc;
  border: 1px solid hsl(211.9deg, 97%, 57%);
}

@keyframes anim79 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(10px, 96px, 0);
    opacity: 0;
  }
}
.c:nth-child(80) {
  animation: anim80 1.5s infinite alternate;
  animation-delay: -1.2s;
  background: hsl(212deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b3fd, hsl(212deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4098fc;
  border: 1px solid hsl(212deg, 97%, 57%);
}

@keyframes anim80 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(291px, 194px, 0);
    opacity: 0;
  }
}
.c:nth-child(81) {
  animation: anim81 1.5s infinite alternate;
  animation-delay: -1.215s;
  background: hsl(212.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b3fd, hsl(212.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4098fc;
  border: 1px solid hsl(212.1deg, 97%, 57%);
}

@keyframes anim81 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(292px, 49px, 0);
    opacity: 0;
  }
}
.c:nth-child(82) {
  animation: anim82 1.5s infinite alternate;
  animation-delay: -1.23s;
  background: hsl(212.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b3fd, hsl(212.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4097fc;
  border: 1px solid hsl(212.2deg, 97%, 57%);
}

@keyframes anim82 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(214px, 12px, 0);
    opacity: 0;
  }
}
.c:nth-child(83) {
  animation: anim83 1.5s infinite alternate;
  animation-delay: -1.245s;
  background: hsl(212.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b2fd, hsl(212.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4097fc;
  border: 1px solid hsl(212.3deg, 97%, 57%);
}

@keyframes anim83 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(254px, 169px, 0);
    opacity: 0;
  }
}
.c:nth-child(84) {
  animation: anim84 1.5s infinite alternate;
  animation-delay: -1.26s;
  background: hsl(212.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b2fd, hsl(212.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4097fc;
  border: 1px solid hsl(212.4deg, 97%, 57%);
}

@keyframes anim84 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(237px, 43px, 0);
    opacity: 0;
  }
}
.c:nth-child(85) {
  animation: anim85 1.5s infinite alternate;
  animation-delay: -1.275s;
  background: hsl(212.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b2fd, hsl(212.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4096fc;
  border: 1px solid hsl(212.5deg, 97%, 57%);
}

@keyframes anim85 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(262px, 150px, 0);
    opacity: 0;
  }
}
.c:nth-child(86) {
  animation: anim86 1.5s infinite alternate;
  animation-delay: -1.29s;
  background: hsl(212.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b2fd, hsl(212.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4096fc;
  border: 1px solid hsl(212.6deg, 97%, 57%);
}

@keyframes anim86 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(46px, 79px, 0);
    opacity: 0;
  }
}
.c:nth-child(87) {
  animation: anim87 1.5s infinite alternate;
  animation-delay: -1.305s;
  background: hsl(212.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b1fd, hsl(212.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4096fc;
  border: 1px solid hsl(212.7deg, 97%, 57%);
}

@keyframes anim87 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(73px, 136px, 0);
    opacity: 0;
  }
}
.c:nth-child(88) {
  animation: anim88 1.5s infinite alternate;
  animation-delay: -1.32s;
  background: hsl(212.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b1fd, hsl(212.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4095fc;
  border: 1px solid hsl(212.8deg, 97%, 57%);
}

@keyframes anim88 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(126px, 156px, 0);
    opacity: 0;
  }
}
.c:nth-child(89) {
  animation: anim89 1.5s infinite alternate;
  animation-delay: -1.335s;
  background: hsl(212.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b1fd, hsl(212.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4095fc;
  border: 1px solid hsl(212.9deg, 97%, 57%);
}

@keyframes anim89 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(152px, 141px, 0);
    opacity: 0;
  }
}
.c:nth-child(90) {
  animation: anim90 1.5s infinite alternate;
  animation-delay: -1.35s;
  background: hsl(213deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b1fd, hsl(213deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4095fc;
  border: 1px solid hsl(213deg, 97%, 57%);
}

@keyframes anim90 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(22px, 121px, 0);
    opacity: 0;
  }
}
.c:nth-child(91) {
  animation: anim91 1.5s infinite alternate;
  animation-delay: -1.365s;
  background: hsl(213.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b0fd, hsl(213.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4094fc;
  border: 1px solid hsl(213.1deg, 97%, 57%);
}

@keyframes anim91 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(17px, 174px, 0);
    opacity: 0;
  }
}
.c:nth-child(92) {
  animation: anim92 1.5s infinite alternate;
  animation-delay: -1.38s;
  background: hsl(213.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b0fd, hsl(213.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4094fc;
  border: 1px solid hsl(213.2deg, 97%, 57%);
}

@keyframes anim92 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(82px, 170px, 0);
    opacity: 0;
  }
}
.c:nth-child(93) {
  animation: anim93 1.5s infinite alternate;
  animation-delay: -1.395s;
  background: hsl(213.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b0fd, hsl(213.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4094fc;
  border: 1px solid hsl(213.3deg, 97%, 57%);
}

@keyframes anim93 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(183px, 32px, 0);
    opacity: 0;
  }
}
.c:nth-child(94) {
  animation: anim94 1.5s infinite alternate;
  animation-delay: -1.41s;
  background: hsl(213.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b0fd, hsl(213.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4093fc;
  border: 1px solid hsl(213.4deg, 97%, 57%);
}

@keyframes anim94 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(275px, 193px, 0);
    opacity: 0;
  }
}
.c:nth-child(95) {
  animation: anim95 1.5s infinite alternate;
  animation-delay: -1.425s;
  background: hsl(213.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72b0fd, hsl(213.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4093fc;
  border: 1px solid hsl(213.5deg, 97%, 57%);
}

@keyframes anim95 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(161px, 54px, 0);
    opacity: 0;
  }
}
.c:nth-child(96) {
  animation: anim96 1.5s infinite alternate;
  animation-delay: -1.44s;
  background: hsl(213.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72affd, hsl(213.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4093fc;
  border: 1px solid hsl(213.6deg, 97%, 57%);
}

@keyframes anim96 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(104px, 132px, 0);
    opacity: 0;
  }
}
.c:nth-child(97) {
  animation: anim97 1.5s infinite alternate;
  animation-delay: -1.455s;
  background: hsl(213.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72affd, hsl(213.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4093fc;
  border: 1px solid hsl(213.7deg, 97%, 57%);
}

@keyframes anim97 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(44px, 127px, 0);
    opacity: 0;
  }
}
.c:nth-child(98) {
  animation: anim98 1.5s infinite alternate;
  animation-delay: -1.47s;
  background: hsl(213.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72affd, hsl(213.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4092fc;
  border: 1px solid hsl(213.8deg, 97%, 57%);
}

@keyframes anim98 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(188px, 172px, 0);
    opacity: 0;
  }
}
.c:nth-child(99) {
  animation: anim99 1.5s infinite alternate;
  animation-delay: -1.485s;
  background: hsl(213.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72affd, hsl(213.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4092fc;
  border: 1px solid hsl(213.9deg, 97%, 57%);
}

@keyframes anim99 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(170px, 84px, 0);
    opacity: 0;
  }
}
.c:nth-child(100) {
  animation: anim100 1.5s infinite alternate;
  animation-delay: -1.5s;
  background: hsl(214deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72aefd, hsl(214deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4092fc;
  border: 1px solid hsl(214deg, 97%, 57%);
}

@keyframes anim100 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(246px, 23px, 0);
    opacity: 0;
  }
}
.c:nth-child(101) {
  animation: anim101 1.5s infinite alternate;
  animation-delay: -1.515s;
  background: hsl(214.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72aefd, hsl(214.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4091fc;
  border: 1px solid hsl(214.1deg, 97%, 57%);
}

@keyframes anim101 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(235px, 140px, 0);
    opacity: 0;
  }
}
.c:nth-child(102) {
  animation: anim102 1.5s infinite alternate;
  animation-delay: -1.53s;
  background: hsl(214.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72aefd, hsl(214.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4091fc;
  border: 1px solid hsl(214.2deg, 97%, 57%);
}

@keyframes anim102 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(16px, 4px, 0);
    opacity: 0;
  }
}
.c:nth-child(103) {
  animation: anim103 1.5s infinite alternate;
  animation-delay: -1.545s;
  background: hsl(214.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72aefd, hsl(214.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4091fc;
  border: 1px solid hsl(214.3deg, 97%, 57%);
}

@keyframes anim103 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(249px, 46px, 0);
    opacity: 0;
  }
}
.c:nth-child(104) {
  animation: anim104 1.5s infinite alternate;
  animation-delay: -1.56s;
  background: hsl(214.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72adfd, hsl(214.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4090fc;
  border: 1px solid hsl(214.4deg, 97%, 57%);
}

@keyframes anim104 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(124px, 47px, 0);
    opacity: 0;
  }
}
.c:nth-child(105) {
  animation: anim105 1.5s infinite alternate;
  animation-delay: -1.575s;
  background: hsl(214.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72adfd, hsl(214.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4090fc;
  border: 1px solid hsl(214.5deg, 97%, 57%);
}

@keyframes anim105 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(280px, 192px, 0);
    opacity: 0;
  }
}
.c:nth-child(106) {
  animation: anim106 1.5s infinite alternate;
  animation-delay: -1.59s;
  background: hsl(214.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72adfd, hsl(214.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4090fc;
  border: 1px solid hsl(214.6deg, 97%, 57%);
}

@keyframes anim106 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(79px, 140px, 0);
    opacity: 0;
  }
}
.c:nth-child(107) {
  animation: anim107 1.5s infinite alternate;
  animation-delay: -1.605s;
  background: hsl(214.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72adfd, hsl(214.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408ffc;
  border: 1px solid hsl(214.7deg, 97%, 57%);
}

@keyframes anim107 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(212px, 161px, 0);
    opacity: 0;
  }
}
.c:nth-child(108) {
  animation: anim108 1.5s infinite alternate;
  animation-delay: -1.62s;
  background: hsl(214.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72adfd, hsl(214.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408ffc;
  border: 1px solid hsl(214.8deg, 97%, 57%);
}

@keyframes anim108 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(208px, 53px, 0);
    opacity: 0;
  }
}
.c:nth-child(109) {
  animation: anim109 1.5s infinite alternate;
  animation-delay: -1.635s;
  background: hsl(214.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72acfd, hsl(214.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408ffc;
  border: 1px solid hsl(214.9deg, 97%, 57%);
}

@keyframes anim109 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(2px, 172px, 0);
    opacity: 0;
  }
}
.c:nth-child(110) {
  animation: anim110 1.5s infinite alternate;
  animation-delay: -1.65s;
  background: hsl(215deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72acfd, hsl(215deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408efc;
  border: 1px solid hsl(215deg, 97%, 57%);
}

@keyframes anim110 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(88px, 5px, 0);
    opacity: 0;
  }
}
.c:nth-child(111) {
  animation: anim111 1.5s infinite alternate;
  animation-delay: -1.665s;
  background: hsl(215.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72acfd, hsl(215.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408efc;
  border: 1px solid hsl(215.1deg, 97%, 57%);
}

@keyframes anim111 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(214px, 166px, 0);
    opacity: 0;
  }
}
.c:nth-child(112) {
  animation: anim112 1.5s infinite alternate;
  animation-delay: -1.68s;
  background: hsl(215.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72acfd, hsl(215.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408efc;
  border: 1px solid hsl(215.2deg, 97%, 57%);
}

@keyframes anim112 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(50px, 197px, 0);
    opacity: 0;
  }
}
.c:nth-child(113) {
  animation: anim113 1.5s infinite alternate;
  animation-delay: -1.695s;
  background: hsl(215.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72abfd, hsl(215.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408dfc;
  border: 1px solid hsl(215.3deg, 97%, 57%);
}

@keyframes anim113 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(42px, 140px, 0);
    opacity: 0;
  }
}
.c:nth-child(114) {
  animation: anim114 1.5s infinite alternate;
  animation-delay: -1.71s;
  background: hsl(215.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72abfd, hsl(215.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408dfc;
  border: 1px solid hsl(215.4deg, 97%, 57%);
}

@keyframes anim114 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(33px, 6px, 0);
    opacity: 0;
  }
}
.c:nth-child(115) {
  animation: anim115 1.5s infinite alternate;
  animation-delay: -1.725s;
  background: hsl(215.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72abfd, hsl(215.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408dfc;
  border: 1px solid hsl(215.5deg, 97%, 57%);
}

@keyframes anim115 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(198px, 120px, 0);
    opacity: 0;
  }
}
.c:nth-child(116) {
  animation: anim116 1.5s infinite alternate;
  animation-delay: -1.74s;
  background: hsl(215.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72abfd, hsl(215.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408dfc;
  border: 1px solid hsl(215.6deg, 97%, 57%);
}

@keyframes anim116 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(45px, 29px, 0);
    opacity: 0;
  }
}
.c:nth-child(117) {
  animation: anim117 1.5s infinite alternate;
  animation-delay: -1.755s;
  background: hsl(215.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72aafd, hsl(215.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408cfc;
  border: 1px solid hsl(215.7deg, 97%, 57%);
}

@keyframes anim117 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(251px, 102px, 0);
    opacity: 0;
  }
}
.c:nth-child(118) {
  animation: anim118 1.5s infinite alternate;
  animation-delay: -1.77s;
  background: hsl(215.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72aafd, hsl(215.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408cfc;
  border: 1px solid hsl(215.8deg, 97%, 57%);
}

@keyframes anim118 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(28px, 62px, 0);
    opacity: 0;
  }
}
.c:nth-child(119) {
  animation: anim119 1.5s infinite alternate;
  animation-delay: -1.785s;
  background: hsl(215.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72aafd, hsl(215.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408cfc;
  border: 1px solid hsl(215.9deg, 97%, 57%);
}

@keyframes anim119 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(150px, 79px, 0);
    opacity: 0;
  }
}
.c:nth-child(120) {
  animation: anim120 1.5s infinite alternate;
  animation-delay: -1.8s;
  background: hsl(216deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72aafd, hsl(216deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408bfc;
  border: 1px solid hsl(216deg, 97%, 57%);
}

@keyframes anim120 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(142px, 29px, 0);
    opacity: 0;
  }
}
.c:nth-child(121) {
  animation: anim121 1.5s infinite alternate;
  animation-delay: -1.815s;
  background: hsl(216.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72aafd, hsl(216.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408bfc;
  border: 1px solid hsl(216.1deg, 97%, 57%);
}

@keyframes anim121 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(140px, 152px, 0);
    opacity: 0;
  }
}
.c:nth-child(122) {
  animation: anim122 1.5s infinite alternate;
  animation-delay: -1.83s;
  background: hsl(216.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a9fd, hsl(216.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408bfc;
  border: 1px solid hsl(216.2deg, 97%, 57%);
}

@keyframes anim122 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(67px, 21px, 0);
    opacity: 0;
  }
}
.c:nth-child(123) {
  animation: anim123 1.5s infinite alternate;
  animation-delay: -1.845s;
  background: hsl(216.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a9fd, hsl(216.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408afc;
  border: 1px solid hsl(216.3deg, 97%, 57%);
}

@keyframes anim123 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(221px, 116px, 0);
    opacity: 0;
  }
}
.c:nth-child(124) {
  animation: anim124 1.5s infinite alternate;
  animation-delay: -1.86s;
  background: hsl(216.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a9fd, hsl(216.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408afc;
  border: 1px solid hsl(216.4deg, 97%, 57%);
}

@keyframes anim124 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(180px, 81px, 0);
    opacity: 0;
  }
}
.c:nth-child(125) {
  animation: anim125 1.5s infinite alternate;
  animation-delay: -1.875s;
  background: hsl(216.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a9fd, hsl(216.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #408afc;
  border: 1px solid hsl(216.5deg, 97%, 57%);
}

@keyframes anim125 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(131px, 152px, 0);
    opacity: 0;
  }
}
.c:nth-child(126) {
  animation: anim126 1.5s infinite alternate;
  animation-delay: -1.89s;
  background: hsl(216.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a8fd, hsl(216.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4089fc;
  border: 1px solid hsl(216.6deg, 97%, 57%);
}

@keyframes anim126 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(21px, 175px, 0);
    opacity: 0;
  }
}
.c:nth-child(127) {
  animation: anim127 1.5s infinite alternate;
  animation-delay: -1.905s;
  background: hsl(216.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a8fd, hsl(216.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4089fc;
  border: 1px solid hsl(216.7deg, 97%, 57%);
}

@keyframes anim127 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(203px, 81px, 0);
    opacity: 0;
  }
}
.c:nth-child(128) {
  animation: anim128 1.5s infinite alternate;
  animation-delay: -1.92s;
  background: hsl(216.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a8fd, hsl(216.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4089fc;
  border: 1px solid hsl(216.8deg, 97%, 57%);
}

@keyframes anim128 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(80px, 199px, 0);
    opacity: 0;
  }
}
.c:nth-child(129) {
  animation: anim129 1.5s infinite alternate;
  animation-delay: -1.935s;
  background: hsl(216.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a8fd, hsl(216.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4088fc;
  border: 1px solid hsl(216.9deg, 97%, 57%);
}

@keyframes anim129 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(48px, 140px, 0);
    opacity: 0;
  }
}
.c:nth-child(130) {
  animation: anim130 1.5s infinite alternate;
  animation-delay: -1.95s;
  background: hsl(217deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a7fd, hsl(217deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4088fc;
  border: 1px solid hsl(217deg, 97%, 57%);
}

@keyframes anim130 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(19px, 78px, 0);
    opacity: 0;
  }
}
.c:nth-child(131) {
  animation: anim131 1.5s infinite alternate;
  animation-delay: -1.965s;
  background: hsl(217.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a7fd, hsl(217.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4088fc;
  border: 1px solid hsl(217.1deg, 97%, 57%);
}

@keyframes anim131 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(131px, 142px, 0);
    opacity: 0;
  }
}
.c:nth-child(132) {
  animation: anim132 1.5s infinite alternate;
  animation-delay: -1.98s;
  background: hsl(217.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a7fd, hsl(217.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4088fc;
  border: 1px solid hsl(217.2deg, 97%, 57%);
}

@keyframes anim132 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(122px, 190px, 0);
    opacity: 0;
  }
}
.c:nth-child(133) {
  animation: anim133 1.5s infinite alternate;
  animation-delay: -1.995s;
  background: hsl(217.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a7fd, hsl(217.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4087fc;
  border: 1px solid hsl(217.3deg, 97%, 57%);
}

@keyframes anim133 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(217px, 143px, 0);
    opacity: 0;
  }
}
.c:nth-child(134) {
  animation: anim134 1.5s infinite alternate;
  animation-delay: -2.01s;
  background: hsl(217.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a7fd, hsl(217.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4087fc;
  border: 1px solid hsl(217.4deg, 97%, 57%);
}

@keyframes anim134 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(160px, 97px, 0);
    opacity: 0;
  }
}
.c:nth-child(135) {
  animation: anim135 1.5s infinite alternate;
  animation-delay: -2.025s;
  background: hsl(217.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a6fd, hsl(217.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4087fc;
  border: 1px solid hsl(217.5deg, 97%, 57%);
}

@keyframes anim135 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(31px, 70px, 0);
    opacity: 0;
  }
}
.c:nth-child(136) {
  animation: anim136 1.5s infinite alternate;
  animation-delay: -2.04s;
  background: hsl(217.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a6fd, hsl(217.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4086fc;
  border: 1px solid hsl(217.6deg, 97%, 57%);
}

@keyframes anim136 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(283px, 120px, 0);
    opacity: 0;
  }
}
.c:nth-child(137) {
  animation: anim137 1.5s infinite alternate;
  animation-delay: -2.055s;
  background: hsl(217.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a6fd, hsl(217.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4086fc;
  border: 1px solid hsl(217.7deg, 97%, 57%);
}

@keyframes anim137 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(240px, 7px, 0);
    opacity: 0;
  }
}
.c:nth-child(138) {
  animation: anim138 1.5s infinite alternate;
  animation-delay: -2.07s;
  background: hsl(217.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a6fd, hsl(217.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4086fc;
  border: 1px solid hsl(217.8deg, 97%, 57%);
}

@keyframes anim138 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(103px, 145px, 0);
    opacity: 0;
  }
}
.c:nth-child(139) {
  animation: anim139 1.5s infinite alternate;
  animation-delay: -2.085s;
  background: hsl(217.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a5fd, hsl(217.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4085fc;
  border: 1px solid hsl(217.9deg, 97%, 57%);
}

@keyframes anim139 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(281px, 20px, 0);
    opacity: 0;
  }
}
.c:nth-child(140) {
  animation: anim140 1.5s infinite alternate;
  animation-delay: -2.1s;
  background: hsl(218deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a5fd, hsl(218deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4085fc;
  border: 1px solid hsl(218deg, 97%, 57%);
}

@keyframes anim140 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(141px, 74px, 0);
    opacity: 0;
  }
}
.c:nth-child(141) {
  animation: anim141 1.5s infinite alternate;
  animation-delay: -2.115s;
  background: hsl(218.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a5fd, hsl(218.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4085fc;
  border: 1px solid hsl(218.1deg, 97%, 57%);
}

@keyframes anim141 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(172px, 14px, 0);
    opacity: 0;
  }
}
.c:nth-child(142) {
  animation: anim142 1.5s infinite alternate;
  animation-delay: -2.13s;
  background: hsl(218.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a5fd, hsl(218.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4084fc;
  border: 1px solid hsl(218.2deg, 97%, 57%);
}

@keyframes anim142 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(300px, 26px, 0);
    opacity: 0;
  }
}
.c:nth-child(143) {
  animation: anim143 1.5s infinite alternate;
  animation-delay: -2.145s;
  background: hsl(218.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a4fd, hsl(218.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4084fc;
  border: 1px solid hsl(218.3deg, 97%, 57%);
}

@keyframes anim143 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(18px, 101px, 0);
    opacity: 0;
  }
}
.c:nth-child(144) {
  animation: anim144 1.5s infinite alternate;
  animation-delay: -2.16s;
  background: hsl(218.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a4fd, hsl(218.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4084fc;
  border: 1px solid hsl(218.4deg, 97%, 57%);
}

@keyframes anim144 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(13px, 140px, 0);
    opacity: 0;
  }
}
.c:nth-child(145) {
  animation: anim145 1.5s infinite alternate;
  animation-delay: -2.175s;
  background: hsl(218.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a4fd, hsl(218.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4083fc;
  border: 1px solid hsl(218.5deg, 97%, 57%);
}

@keyframes anim145 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(4px, 17px, 0);
    opacity: 0;
  }
}
.c:nth-child(146) {
  animation: anim146 1.5s infinite alternate;
  animation-delay: -2.19s;
  background: hsl(218.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a4fd, hsl(218.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4083fc;
  border: 1px solid hsl(218.6deg, 97%, 57%);
}

@keyframes anim146 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(7px, 96px, 0);
    opacity: 0;
  }
}
.c:nth-child(147) {
  animation: anim147 1.5s infinite alternate;
  animation-delay: -2.205s;
  background: hsl(218.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a4fd, hsl(218.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4083fc;
  border: 1px solid hsl(218.7deg, 97%, 57%);
}

@keyframes anim147 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(18px, 178px, 0);
    opacity: 0;
  }
}
.c:nth-child(148) {
  animation: anim148 1.5s infinite alternate;
  animation-delay: -2.22s;
  background: hsl(218.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a3fd, hsl(218.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4083fc;
  border: 1px solid hsl(218.8deg, 97%, 57%);
}

@keyframes anim148 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(76px, 56px, 0);
    opacity: 0;
  }
}
.c:nth-child(149) {
  animation: anim149 1.5s infinite alternate;
  animation-delay: -2.235s;
  background: hsl(218.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a3fd, hsl(218.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4082fc;
  border: 1px solid hsl(218.9deg, 97%, 57%);
}

@keyframes anim149 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(70px, 56px, 0);
    opacity: 0;
  }
}
.c:nth-child(150) {
  animation: anim150 1.5s infinite alternate;
  animation-delay: -2.25s;
  background: hsl(219deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a3fd, hsl(219deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4082fc;
  border: 1px solid hsl(219deg, 97%, 57%);
}

@keyframes anim150 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(133px, 162px, 0);
    opacity: 0;
  }
}
.c:nth-child(151) {
  animation: anim151 1.5s infinite alternate;
  animation-delay: -2.265s;
  background: hsl(219.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a3fd, hsl(219.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4082fc;
  border: 1px solid hsl(219.1deg, 97%, 57%);
}

@keyframes anim151 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(119px, 148px, 0);
    opacity: 0;
  }
}
.c:nth-child(152) {
  animation: anim152 1.5s infinite alternate;
  animation-delay: -2.28s;
  background: hsl(219.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a2fd, hsl(219.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4081fc;
  border: 1px solid hsl(219.2deg, 97%, 57%);
}

@keyframes anim152 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(26px, 125px, 0);
    opacity: 0;
  }
}
.c:nth-child(153) {
  animation: anim153 1.5s infinite alternate;
  animation-delay: -2.295s;
  background: hsl(219.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a2fd, hsl(219.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4081fc;
  border: 1px solid hsl(219.3deg, 97%, 57%);
}

@keyframes anim153 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(127px, 170px, 0);
    opacity: 0;
  }
}
.c:nth-child(154) {
  animation: anim154 1.5s infinite alternate;
  animation-delay: -2.31s;
  background: hsl(219.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a2fd, hsl(219.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4081fc;
  border: 1px solid hsl(219.4deg, 97%, 57%);
}

@keyframes anim154 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(125px, 14px, 0);
    opacity: 0;
  }
}
.c:nth-child(155) {
  animation: anim155 1.5s infinite alternate;
  animation-delay: -2.325s;
  background: hsl(219.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a2fd, hsl(219.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4080fc;
  border: 1px solid hsl(219.5deg, 97%, 57%);
}

@keyframes anim155 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(20px, 52px, 0);
    opacity: 0;
  }
}
.c:nth-child(156) {
  animation: anim156 1.5s infinite alternate;
  animation-delay: -2.34s;
  background: hsl(219.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a1fd, hsl(219.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4080fc;
  border: 1px solid hsl(219.6deg, 97%, 57%);
}

@keyframes anim156 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(193px, 46px, 0);
    opacity: 0;
  }
}
.c:nth-child(157) {
  animation: anim157 1.5s infinite alternate;
  animation-delay: -2.355s;
  background: hsl(219.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a1fd, hsl(219.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4080fc;
  border: 1px solid hsl(219.7deg, 97%, 57%);
}

@keyframes anim157 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(150px, 58px, 0);
    opacity: 0;
  }
}
.c:nth-child(158) {
  animation: anim158 1.5s infinite alternate;
  animation-delay: -2.37s;
  background: hsl(219.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a1fd, hsl(219.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407ffc;
  border: 1px solid hsl(219.8deg, 97%, 57%);
}

@keyframes anim158 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(67px, 97px, 0);
    opacity: 0;
  }
}
.c:nth-child(159) {
  animation: anim159 1.5s infinite alternate;
  animation-delay: -2.385s;
  background: hsl(219.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a1fd, hsl(219.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407ffc;
  border: 1px solid hsl(219.9deg, 97%, 57%);
}

@keyframes anim159 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(232px, 196px, 0);
    opacity: 0;
  }
}
.c:nth-child(160) {
  animation: anim160 1.5s infinite alternate;
  animation-delay: -2.4s;
  background: hsl(220deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a1fd, hsl(220deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407ffc;
  border: 1px solid hsl(220deg, 97%, 57%);
}

@keyframes anim160 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(4px, 138px, 0);
    opacity: 0;
  }
}
.c:nth-child(161) {
  animation: anim161 1.5s infinite alternate;
  animation-delay: -2.415s;
  background: hsl(220.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a0fd, hsl(220.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407efc;
  border: 1px solid hsl(220.1deg, 97%, 57%);
}

@keyframes anim161 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(193px, 185px, 0);
    opacity: 0;
  }
}
.c:nth-child(162) {
  animation: anim162 1.5s infinite alternate;
  animation-delay: -2.43s;
  background: hsl(220.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a0fd, hsl(220.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407efc;
  border: 1px solid hsl(220.2deg, 97%, 57%);
}

@keyframes anim162 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(129px, 185px, 0);
    opacity: 0;
  }
}
.c:nth-child(163) {
  animation: anim163 1.5s infinite alternate;
  animation-delay: -2.445s;
  background: hsl(220.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a0fd, hsl(220.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407efc;
  border: 1px solid hsl(220.3deg, 97%, 57%);
}

@keyframes anim163 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(85px, 59px, 0);
    opacity: 0;
  }
}
.c:nth-child(164) {
  animation: anim164 1.5s infinite alternate;
  animation-delay: -2.46s;
  background: hsl(220.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #72a0fd, hsl(220.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407efc;
  border: 1px solid hsl(220.4deg, 97%, 57%);
}

@keyframes anim164 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(79px, 175px, 0);
    opacity: 0;
  }
}
.c:nth-child(165) {
  animation: anim165 1.5s infinite alternate;
  animation-delay: -2.475s;
  background: hsl(220.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729ffd, hsl(220.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407dfc;
  border: 1px solid hsl(220.5deg, 97%, 57%);
}

@keyframes anim165 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(154px, 178px, 0);
    opacity: 0;
  }
}
.c:nth-child(166) {
  animation: anim166 1.5s infinite alternate;
  animation-delay: -2.49s;
  background: hsl(220.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729ffd, hsl(220.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407dfc;
  border: 1px solid hsl(220.6deg, 97%, 57%);
}

@keyframes anim166 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(76px, 41px, 0);
    opacity: 0;
  }
}
.c:nth-child(167) {
  animation: anim167 1.5s infinite alternate;
  animation-delay: -2.505s;
  background: hsl(220.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729ffd, hsl(220.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407dfc;
  border: 1px solid hsl(220.7deg, 97%, 57%);
}

@keyframes anim167 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(256px, 115px, 0);
    opacity: 0;
  }
}
.c:nth-child(168) {
  animation: anim168 1.5s infinite alternate;
  animation-delay: -2.52s;
  background: hsl(220.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729ffd, hsl(220.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407cfc;
  border: 1px solid hsl(220.8deg, 97%, 57%);
}

@keyframes anim168 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(18px, 147px, 0);
    opacity: 0;
  }
}
.c:nth-child(169) {
  animation: anim169 1.5s infinite alternate;
  animation-delay: -2.535s;
  background: hsl(220.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729efd, hsl(220.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407cfc;
  border: 1px solid hsl(220.9deg, 97%, 57%);
}

@keyframes anim169 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(245px, 108px, 0);
    opacity: 0;
  }
}
.c:nth-child(170) {
  animation: anim170 1.5s infinite alternate;
  animation-delay: -2.55s;
  background: hsl(221deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729efd, hsl(221deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407cfc;
  border: 1px solid hsl(221deg, 97%, 57%);
}

@keyframes anim170 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(173px, 114px, 0);
    opacity: 0;
  }
}
.c:nth-child(171) {
  animation: anim171 1.5s infinite alternate;
  animation-delay: -2.565s;
  background: hsl(221.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729efd, hsl(221.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407bfc;
  border: 1px solid hsl(221.1deg, 97%, 57%);
}

@keyframes anim171 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(43px, 95px, 0);
    opacity: 0;
  }
}
.c:nth-child(172) {
  animation: anim172 1.5s infinite alternate;
  animation-delay: -2.58s;
  background: hsl(221.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729efd, hsl(221.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407bfc;
  border: 1px solid hsl(221.2deg, 97%, 57%);
}

@keyframes anim172 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(226px, 16px, 0);
    opacity: 0;
  }
}
.c:nth-child(173) {
  animation: anim173 1.5s infinite alternate;
  animation-delay: -2.595s;
  background: hsl(221.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729efd, hsl(221.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407bfc;
  border: 1px solid hsl(221.3deg, 97%, 57%);
}

@keyframes anim173 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(97px, 155px, 0);
    opacity: 0;
  }
}
.c:nth-child(174) {
  animation: anim174 1.5s infinite alternate;
  animation-delay: -2.61s;
  background: hsl(221.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729dfd, hsl(221.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407afc;
  border: 1px solid hsl(221.4deg, 97%, 57%);
}

@keyframes anim174 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(213px, 110px, 0);
    opacity: 0;
  }
}
.c:nth-child(175) {
  animation: anim175 1.5s infinite alternate;
  animation-delay: -2.625s;
  background: hsl(221.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729dfd, hsl(221.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407afc;
  border: 1px solid hsl(221.5deg, 97%, 57%);
}

@keyframes anim175 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(99px, 128px, 0);
    opacity: 0;
  }
}
.c:nth-child(176) {
  animation: anim176 1.5s infinite alternate;
  animation-delay: -2.64s;
  background: hsl(221.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729dfd, hsl(221.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #407afc;
  border: 1px solid hsl(221.6deg, 97%, 57%);
}

@keyframes anim176 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(214px, 26px, 0);
    opacity: 0;
  }
}
.c:nth-child(177) {
  animation: anim177 1.5s infinite alternate;
  animation-delay: -2.655s;
  background: hsl(221.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729dfd, hsl(221.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4079fc;
  border: 1px solid hsl(221.7deg, 97%, 57%);
}

@keyframes anim177 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(26px, 141px, 0);
    opacity: 0;
  }
}
.c:nth-child(178) {
  animation: anim178 1.5s infinite alternate;
  animation-delay: -2.67s;
  background: hsl(221.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729cfd, hsl(221.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4079fc;
  border: 1px solid hsl(221.8deg, 97%, 57%);
}

@keyframes anim178 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(152px, 78px, 0);
    opacity: 0;
  }
}
.c:nth-child(179) {
  animation: anim179 1.5s infinite alternate;
  animation-delay: -2.685s;
  background: hsl(221.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729cfd, hsl(221.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4079fc;
  border: 1px solid hsl(221.9deg, 97%, 57%);
}

@keyframes anim179 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(247px, 60px, 0);
    opacity: 0;
  }
}
.c:nth-child(180) {
  animation: anim180 1.5s infinite alternate;
  animation-delay: -2.7s;
  background: hsl(222deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729cfd, hsl(222deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4079fc;
  border: 1px solid hsl(222deg, 97%, 57%);
}

@keyframes anim180 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(175px, 61px, 0);
    opacity: 0;
  }
}
.c:nth-child(181) {
  animation: anim181 1.5s infinite alternate;
  animation-delay: -2.715s;
  background: hsl(222.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729cfd, hsl(222.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4078fc;
  border: 1px solid hsl(222.1deg, 97%, 57%);
}

@keyframes anim181 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(72px, 173px, 0);
    opacity: 0;
  }
}
.c:nth-child(182) {
  animation: anim182 1.5s infinite alternate;
  animation-delay: -2.73s;
  background: hsl(222.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729bfd, hsl(222.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4078fc;
  border: 1px solid hsl(222.2deg, 97%, 57%);
}

@keyframes anim182 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(215px, 178px, 0);
    opacity: 0;
  }
}
.c:nth-child(183) {
  animation: anim183 1.5s infinite alternate;
  animation-delay: -2.745s;
  background: hsl(222.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729bfd, hsl(222.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4078fc;
  border: 1px solid hsl(222.3deg, 97%, 57%);
}

@keyframes anim183 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(201px, 44px, 0);
    opacity: 0;
  }
}
.c:nth-child(184) {
  animation: anim184 1.5s infinite alternate;
  animation-delay: -2.76s;
  background: hsl(222.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729bfd, hsl(222.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4077fc;
  border: 1px solid hsl(222.4deg, 97%, 57%);
}

@keyframes anim184 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(21px, 33px, 0);
    opacity: 0;
  }
}
.c:nth-child(185) {
  animation: anim185 1.5s infinite alternate;
  animation-delay: -2.775s;
  background: hsl(222.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729bfd, hsl(222.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4077fc;
  border: 1px solid hsl(222.5deg, 97%, 57%);
}

@keyframes anim185 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(268px, 29px, 0);
    opacity: 0;
  }
}
.c:nth-child(186) {
  animation: anim186 1.5s infinite alternate;
  animation-delay: -2.79s;
  background: hsl(222.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729bfd, hsl(222.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4077fc;
  border: 1px solid hsl(222.6deg, 97%, 57%);
}

@keyframes anim186 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(142px, 76px, 0);
    opacity: 0;
  }
}
.c:nth-child(187) {
  animation: anim187 1.5s infinite alternate;
  animation-delay: -2.805s;
  background: hsl(222.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729afd, hsl(222.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4076fc;
  border: 1px solid hsl(222.7deg, 97%, 57%);
}

@keyframes anim187 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(299px, 120px, 0);
    opacity: 0;
  }
}
.c:nth-child(188) {
  animation: anim188 1.5s infinite alternate;
  animation-delay: -2.82s;
  background: hsl(222.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729afd, hsl(222.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4076fc;
  border: 1px solid hsl(222.8deg, 97%, 57%);
}

@keyframes anim188 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(33px, 41px, 0);
    opacity: 0;
  }
}
.c:nth-child(189) {
  animation: anim189 1.5s infinite alternate;
  animation-delay: -2.835s;
  background: hsl(222.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729afd, hsl(222.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4076fc;
  border: 1px solid hsl(222.9deg, 97%, 57%);
}

@keyframes anim189 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(207px, 33px, 0);
    opacity: 0;
  }
}
.c:nth-child(190) {
  animation: anim190 1.5s infinite alternate;
  animation-delay: -2.85s;
  background: hsl(223deg, 97%, 57%);
  background: radial-gradient(circle at top left, #729afd, hsl(223deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4075fc;
  border: 1px solid hsl(223deg, 97%, 57%);
}

@keyframes anim190 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(77px, 84px, 0);
    opacity: 0;
  }
}
.c:nth-child(191) {
  animation: anim191 1.5s infinite alternate;
  animation-delay: -2.865s;
  background: hsl(223.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7299fd, hsl(223.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4075fc;
  border: 1px solid hsl(223.1deg, 97%, 57%);
}

@keyframes anim191 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(51px, 64px, 0);
    opacity: 0;
  }
}
.c:nth-child(192) {
  animation: anim192 1.5s infinite alternate;
  animation-delay: -2.88s;
  background: hsl(223.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7299fd, hsl(223.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4075fc;
  border: 1px solid hsl(223.2deg, 97%, 57%);
}

@keyframes anim192 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(116px, 140px, 0);
    opacity: 0;
  }
}
.c:nth-child(193) {
  animation: anim193 1.5s infinite alternate;
  animation-delay: -2.895s;
  background: hsl(223.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7299fd, hsl(223.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4074fc;
  border: 1px solid hsl(223.3deg, 97%, 57%);
}

@keyframes anim193 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(123px, 151px, 0);
    opacity: 0;
  }
}
.c:nth-child(194) {
  animation: anim194 1.5s infinite alternate;
  animation-delay: -2.91s;
  background: hsl(223.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7299fd, hsl(223.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4074fc;
  border: 1px solid hsl(223.4deg, 97%, 57%);
}

@keyframes anim194 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(166px, 36px, 0);
    opacity: 0;
  }
}
.c:nth-child(195) {
  animation: anim195 1.5s infinite alternate;
  animation-delay: -2.925s;
  background: hsl(223.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7298fd, hsl(223.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4074fc;
  border: 1px solid hsl(223.5deg, 97%, 57%);
}

@keyframes anim195 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(65px, 181px, 0);
    opacity: 0;
  }
}
.c:nth-child(196) {
  animation: anim196 1.5s infinite alternate;
  animation-delay: -2.94s;
  background: hsl(223.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7298fd, hsl(223.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4073fc;
  border: 1px solid hsl(223.6deg, 97%, 57%);
}

@keyframes anim196 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(175px, 87px, 0);
    opacity: 0;
  }
}
.c:nth-child(197) {
  animation: anim197 1.5s infinite alternate;
  animation-delay: -2.955s;
  background: hsl(223.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7298fd, hsl(223.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4073fc;
  border: 1px solid hsl(223.7deg, 97%, 57%);
}

@keyframes anim197 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(160px, 20px, 0);
    opacity: 0;
  }
}
.c:nth-child(198) {
  animation: anim198 1.5s infinite alternate;
  animation-delay: -2.97s;
  background: hsl(223.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7298fd, hsl(223.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4073fc;
  border: 1px solid hsl(223.8deg, 97%, 57%);
}

@keyframes anim198 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(234px, 9px, 0);
    opacity: 0;
  }
}
.c:nth-child(199) {
  animation: anim199 1.5s infinite alternate;
  animation-delay: -2.985s;
  background: hsl(223.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7298fd, hsl(223.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4073fc;
  border: 1px solid hsl(223.9deg, 97%, 57%);
}

@keyframes anim199 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(259px, 199px, 0);
    opacity: 0;
  }
}
.c:nth-child(200) {
  animation: anim200 1.5s infinite alternate;
  animation-delay: -3s;
  background: hsl(224deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7297fd, hsl(224deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4072fc;
  border: 1px solid hsl(224deg, 97%, 57%);
}

@keyframes anim200 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(79px, 156px, 0);
    opacity: 0;
  }
}
.c:nth-child(201) {
  animation: anim201 1.5s infinite alternate;
  animation-delay: -3.015s;
  background: hsl(224.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7297fd, hsl(224.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4072fc;
  border: 1px solid hsl(224.1deg, 97%, 57%);
}

@keyframes anim201 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(174px, 65px, 0);
    opacity: 0;
  }
}
.c:nth-child(202) {
  animation: anim202 1.5s infinite alternate;
  animation-delay: -3.03s;
  background: hsl(224.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7297fd, hsl(224.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4072fc;
  border: 1px solid hsl(224.2deg, 97%, 57%);
}

@keyframes anim202 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(117px, 26px, 0);
    opacity: 0;
  }
}
.c:nth-child(203) {
  animation: anim203 1.5s infinite alternate;
  animation-delay: -3.045s;
  background: hsl(224.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7297fd, hsl(224.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4071fc;
  border: 1px solid hsl(224.3deg, 97%, 57%);
}

@keyframes anim203 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(147px, 86px, 0);
    opacity: 0;
  }
}
.c:nth-child(204) {
  animation: anim204 1.5s infinite alternate;
  animation-delay: -3.06s;
  background: hsl(224.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7296fd, hsl(224.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4071fc;
  border: 1px solid hsl(224.4deg, 97%, 57%);
}

@keyframes anim204 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(196px, 93px, 0);
    opacity: 0;
  }
}
.c:nth-child(205) {
  animation: anim205 1.5s infinite alternate;
  animation-delay: -3.075s;
  background: hsl(224.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7296fd, hsl(224.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4071fc;
  border: 1px solid hsl(224.5deg, 97%, 57%);
}

@keyframes anim205 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(25px, 174px, 0);
    opacity: 0;
  }
}
.c:nth-child(206) {
  animation: anim206 1.5s infinite alternate;
  animation-delay: -3.09s;
  background: hsl(224.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7296fd, hsl(224.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4070fc;
  border: 1px solid hsl(224.6deg, 97%, 57%);
}

@keyframes anim206 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(144px, 175px, 0);
    opacity: 0;
  }
}
.c:nth-child(207) {
  animation: anim207 1.5s infinite alternate;
  animation-delay: -3.105s;
  background: hsl(224.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7296fd, hsl(224.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4070fc;
  border: 1px solid hsl(224.7deg, 97%, 57%);
}

@keyframes anim207 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(128px, 155px, 0);
    opacity: 0;
  }
}
.c:nth-child(208) {
  animation: anim208 1.5s infinite alternate;
  animation-delay: -3.12s;
  background: hsl(224.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7295fd, hsl(224.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4070fc;
  border: 1px solid hsl(224.8deg, 97%, 57%);
}

@keyframes anim208 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(49px, 100px, 0);
    opacity: 0;
  }
}
.c:nth-child(209) {
  animation: anim209 1.5s infinite alternate;
  animation-delay: -3.135s;
  background: hsl(224.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7295fd, hsl(224.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406ffc;
  border: 1px solid hsl(224.9deg, 97%, 57%);
}

@keyframes anim209 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(299px, 148px, 0);
    opacity: 0;
  }
}
.c:nth-child(210) {
  animation: anim210 1.5s infinite alternate;
  animation-delay: -3.15s;
  background: hsl(225deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7295fd, hsl(225deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406ffc;
  border: 1px solid hsl(225deg, 97%, 57%);
}

@keyframes anim210 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(143px, 199px, 0);
    opacity: 0;
  }
}
.c:nth-child(211) {
  animation: anim211 1.5s infinite alternate;
  animation-delay: -3.165s;
  background: hsl(225.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7295fd, hsl(225.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406ffc;
  border: 1px solid hsl(225.1deg, 97%, 57%);
}

@keyframes anim211 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(80px, 24px, 0);
    opacity: 0;
  }
}
.c:nth-child(212) {
  animation: anim212 1.5s infinite alternate;
  animation-delay: -3.18s;
  background: hsl(225.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7295fd, hsl(225.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406efc;
  border: 1px solid hsl(225.2deg, 97%, 57%);
}

@keyframes anim212 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(60px, 12px, 0);
    opacity: 0;
  }
}
.c:nth-child(213) {
  animation: anim213 1.5s infinite alternate;
  animation-delay: -3.195s;
  background: hsl(225.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7294fd, hsl(225.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406efc;
  border: 1px solid hsl(225.3deg, 97%, 57%);
}

@keyframes anim213 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(274px, 112px, 0);
    opacity: 0;
  }
}
.c:nth-child(214) {
  animation: anim214 1.5s infinite alternate;
  animation-delay: -3.21s;
  background: hsl(225.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7294fd, hsl(225.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406efc;
  border: 1px solid hsl(225.4deg, 97%, 57%);
}

@keyframes anim214 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(197px, 77px, 0);
    opacity: 0;
  }
}
.c:nth-child(215) {
  animation: anim215 1.5s infinite alternate;
  animation-delay: -3.225s;
  background: hsl(225.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7294fd, hsl(225.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406efc;
  border: 1px solid hsl(225.5deg, 97%, 57%);
}

@keyframes anim215 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(254px, 71px, 0);
    opacity: 0;
  }
}
.c:nth-child(216) {
  animation: anim216 1.5s infinite alternate;
  animation-delay: -3.24s;
  background: hsl(225.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7294fd, hsl(225.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406dfc;
  border: 1px solid hsl(225.6deg, 97%, 57%);
}

@keyframes anim216 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(76px, 25px, 0);
    opacity: 0;
  }
}
.c:nth-child(217) {
  animation: anim217 1.5s infinite alternate;
  animation-delay: -3.255s;
  background: hsl(225.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7293fd, hsl(225.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406dfc;
  border: 1px solid hsl(225.7deg, 97%, 57%);
}

@keyframes anim217 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(164px, 55px, 0);
    opacity: 0;
  }
}
.c:nth-child(218) {
  animation: anim218 1.5s infinite alternate;
  animation-delay: -3.27s;
  background: hsl(225.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7293fd, hsl(225.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406dfc;
  border: 1px solid hsl(225.8deg, 97%, 57%);
}

@keyframes anim218 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(19px, 5px, 0);
    opacity: 0;
  }
}
.c:nth-child(219) {
  animation: anim219 1.5s infinite alternate;
  animation-delay: -3.285s;
  background: hsl(225.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7293fd, hsl(225.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406cfc;
  border: 1px solid hsl(225.9deg, 97%, 57%);
}

@keyframes anim219 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(261px, 178px, 0);
    opacity: 0;
  }
}
.c:nth-child(220) {
  animation: anim220 1.5s infinite alternate;
  animation-delay: -3.3s;
  background: hsl(226deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7293fd, hsl(226deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406cfc;
  border: 1px solid hsl(226deg, 97%, 57%);
}

@keyframes anim220 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(77px, 44px, 0);
    opacity: 0;
  }
}
.c:nth-child(221) {
  animation: anim221 1.5s infinite alternate;
  animation-delay: -3.315s;
  background: hsl(226.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7292fd, hsl(226.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406cfc;
  border: 1px solid hsl(226.1deg, 97%, 57%);
}

@keyframes anim221 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(2px, 122px, 0);
    opacity: 0;
  }
}
.c:nth-child(222) {
  animation: anim222 1.5s infinite alternate;
  animation-delay: -3.33s;
  background: hsl(226.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7292fd, hsl(226.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406bfc;
  border: 1px solid hsl(226.2deg, 97%, 57%);
}

@keyframes anim222 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(225px, 6px, 0);
    opacity: 0;
  }
}
.c:nth-child(223) {
  animation: anim223 1.5s infinite alternate;
  animation-delay: -3.345s;
  background: hsl(226.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7292fd, hsl(226.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406bfc;
  border: 1px solid hsl(226.3deg, 97%, 57%);
}

@keyframes anim223 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(5px, 91px, 0);
    opacity: 0;
  }
}
.c:nth-child(224) {
  animation: anim224 1.5s infinite alternate;
  animation-delay: -3.36s;
  background: hsl(226.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7292fd, hsl(226.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406bfc;
  border: 1px solid hsl(226.4deg, 97%, 57%);
}

@keyframes anim224 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(275px, 95px, 0);
    opacity: 0;
  }
}
.c:nth-child(225) {
  animation: anim225 1.5s infinite alternate;
  animation-delay: -3.375s;
  background: hsl(226.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7292fd, hsl(226.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406afc;
  border: 1px solid hsl(226.5deg, 97%, 57%);
}

@keyframes anim225 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(74px, 160px, 0);
    opacity: 0;
  }
}
.c:nth-child(226) {
  animation: anim226 1.5s infinite alternate;
  animation-delay: -3.39s;
  background: hsl(226.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7291fd, hsl(226.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406afc;
  border: 1px solid hsl(226.6deg, 97%, 57%);
}

@keyframes anim226 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(52px, 154px, 0);
    opacity: 0;
  }
}
.c:nth-child(227) {
  animation: anim227 1.5s infinite alternate;
  animation-delay: -3.405s;
  background: hsl(226.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7291fd, hsl(226.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #406afc;
  border: 1px solid hsl(226.7deg, 97%, 57%);
}

@keyframes anim227 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(75px, 117px, 0);
    opacity: 0;
  }
}
.c:nth-child(228) {
  animation: anim228 1.5s infinite alternate;
  animation-delay: -3.42s;
  background: hsl(226.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7291fd, hsl(226.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4069fc;
  border: 1px solid hsl(226.8deg, 97%, 57%);
}

@keyframes anim228 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(45px, 176px, 0);
    opacity: 0;
  }
}
.c:nth-child(229) {
  animation: anim229 1.5s infinite alternate;
  animation-delay: -3.435s;
  background: hsl(226.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7291fd, hsl(226.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4069fc;
  border: 1px solid hsl(226.9deg, 97%, 57%);
}

@keyframes anim229 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(161px, 120px, 0);
    opacity: 0;
  }
}
.c:nth-child(230) {
  animation: anim230 1.5s infinite alternate;
  animation-delay: -3.45s;
  background: hsl(227deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7290fd, hsl(227deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4069fc;
  border: 1px solid hsl(227deg, 97%, 57%);
}

@keyframes anim230 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(137px, 171px, 0);
    opacity: 0;
  }
}
.c:nth-child(231) {
  animation: anim231 1.5s infinite alternate;
  animation-delay: -3.465s;
  background: hsl(227.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7290fd, hsl(227.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4069fc;
  border: 1px solid hsl(227.1deg, 97%, 57%);
}

@keyframes anim231 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(57px, 9px, 0);
    opacity: 0;
  }
}
.c:nth-child(232) {
  animation: anim232 1.5s infinite alternate;
  animation-delay: -3.48s;
  background: hsl(227.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7290fd, hsl(227.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4068fc;
  border: 1px solid hsl(227.2deg, 97%, 57%);
}

@keyframes anim232 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(194px, 151px, 0);
    opacity: 0;
  }
}
.c:nth-child(233) {
  animation: anim233 1.5s infinite alternate;
  animation-delay: -3.495s;
  background: hsl(227.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7290fd, hsl(227.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4068fc;
  border: 1px solid hsl(227.3deg, 97%, 57%);
}

@keyframes anim233 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(217px, 198px, 0);
    opacity: 0;
  }
}
.c:nth-child(234) {
  animation: anim234 1.5s infinite alternate;
  animation-delay: -3.51s;
  background: hsl(227.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728ffd, hsl(227.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4068fc;
  border: 1px solid hsl(227.4deg, 97%, 57%);
}

@keyframes anim234 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(144px, 3px, 0);
    opacity: 0;
  }
}
.c:nth-child(235) {
  animation: anim235 1.5s infinite alternate;
  animation-delay: -3.525s;
  background: hsl(227.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728ffd, hsl(227.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4067fc;
  border: 1px solid hsl(227.5deg, 97%, 57%);
}

@keyframes anim235 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(189px, 194px, 0);
    opacity: 0;
  }
}
.c:nth-child(236) {
  animation: anim236 1.5s infinite alternate;
  animation-delay: -3.54s;
  background: hsl(227.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728ffd, hsl(227.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4067fc;
  border: 1px solid hsl(227.6deg, 97%, 57%);
}

@keyframes anim236 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(185px, 173px, 0);
    opacity: 0;
  }
}
.c:nth-child(237) {
  animation: anim237 1.5s infinite alternate;
  animation-delay: -3.555s;
  background: hsl(227.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728ffd, hsl(227.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4067fc;
  border: 1px solid hsl(227.7deg, 97%, 57%);
}

@keyframes anim237 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(140px, 29px, 0);
    opacity: 0;
  }
}
.c:nth-child(238) {
  animation: anim238 1.5s infinite alternate;
  animation-delay: -3.57s;
  background: hsl(227.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728ffd, hsl(227.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4066fc;
  border: 1px solid hsl(227.8deg, 97%, 57%);
}

@keyframes anim238 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(177px, 177px, 0);
    opacity: 0;
  }
}
.c:nth-child(239) {
  animation: anim239 1.5s infinite alternate;
  animation-delay: -3.585s;
  background: hsl(227.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728efd, hsl(227.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4066fc;
  border: 1px solid hsl(227.9deg, 97%, 57%);
}

@keyframes anim239 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(60px, 176px, 0);
    opacity: 0;
  }
}
.c:nth-child(240) {
  animation: anim240 1.5s infinite alternate;
  animation-delay: -3.6s;
  background: hsl(228deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728efd, hsl(228deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4066fc;
  border: 1px solid hsl(228deg, 97%, 57%);
}

@keyframes anim240 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(72px, 39px, 0);
    opacity: 0;
  }
}
.c:nth-child(241) {
  animation: anim241 1.5s infinite alternate;
  animation-delay: -3.615s;
  background: hsl(228.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728efd, hsl(228.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4065fc;
  border: 1px solid hsl(228.1deg, 97%, 57%);
}

@keyframes anim241 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(167px, 127px, 0);
    opacity: 0;
  }
}
.c:nth-child(242) {
  animation: anim242 1.5s infinite alternate;
  animation-delay: -3.63s;
  background: hsl(228.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728efd, hsl(228.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4065fc;
  border: 1px solid hsl(228.2deg, 97%, 57%);
}

@keyframes anim242 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(177px, 186px, 0);
    opacity: 0;
  }
}
.c:nth-child(243) {
  animation: anim243 1.5s infinite alternate;
  animation-delay: -3.645s;
  background: hsl(228.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728dfd, hsl(228.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4065fc;
  border: 1px solid hsl(228.3deg, 97%, 57%);
}

@keyframes anim243 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(174px, 97px, 0);
    opacity: 0;
  }
}
.c:nth-child(244) {
  animation: anim244 1.5s infinite alternate;
  animation-delay: -3.66s;
  background: hsl(228.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728dfd, hsl(228.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4064fc;
  border: 1px solid hsl(228.4deg, 97%, 57%);
}

@keyframes anim244 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(99px, 177px, 0);
    opacity: 0;
  }
}
.c:nth-child(245) {
  animation: anim245 1.5s infinite alternate;
  animation-delay: -3.675s;
  background: hsl(228.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728dfd, hsl(228.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4064fc;
  border: 1px solid hsl(228.5deg, 97%, 57%);
}

@keyframes anim245 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(300px, 121px, 0);
    opacity: 0;
  }
}
.c:nth-child(246) {
  animation: anim246 1.5s infinite alternate;
  animation-delay: -3.69s;
  background: hsl(228.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728dfd, hsl(228.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4064fc;
  border: 1px solid hsl(228.6deg, 97%, 57%);
}

@keyframes anim246 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(174px, 134px, 0);
    opacity: 0;
  }
}
.c:nth-child(247) {
  animation: anim247 1.5s infinite alternate;
  animation-delay: -3.705s;
  background: hsl(228.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728cfd, hsl(228.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4064fc;
  border: 1px solid hsl(228.7deg, 97%, 57%);
}

@keyframes anim247 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(57px, 98px, 0);
    opacity: 0;
  }
}
.c:nth-child(248) {
  animation: anim248 1.5s infinite alternate;
  animation-delay: -3.72s;
  background: hsl(228.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728cfd, hsl(228.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4063fc;
  border: 1px solid hsl(228.8deg, 97%, 57%);
}

@keyframes anim248 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(285px, 84px, 0);
    opacity: 0;
  }
}
.c:nth-child(249) {
  animation: anim249 1.5s infinite alternate;
  animation-delay: -3.735s;
  background: hsl(228.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728cfd, hsl(228.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4063fc;
  border: 1px solid hsl(228.9deg, 97%, 57%);
}

@keyframes anim249 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(128px, 130px, 0);
    opacity: 0;
  }
}
.c:nth-child(250) {
  animation: anim250 1.5s infinite alternate;
  animation-delay: -3.75s;
  background: hsl(229deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728cfd, hsl(229deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4063fc;
  border: 1px solid hsl(229deg, 97%, 57%);
}

@keyframes anim250 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(223px, 110px, 0);
    opacity: 0;
  }
}
.c:nth-child(251) {
  animation: anim251 1.5s infinite alternate;
  animation-delay: -3.765s;
  background: hsl(229.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728cfd, hsl(229.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4062fc;
  border: 1px solid hsl(229.1deg, 97%, 57%);
}

@keyframes anim251 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(56px, 81px, 0);
    opacity: 0;
  }
}
.c:nth-child(252) {
  animation: anim252 1.5s infinite alternate;
  animation-delay: -3.78s;
  background: hsl(229.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728bfd, hsl(229.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4062fc;
  border: 1px solid hsl(229.2deg, 97%, 57%);
}

@keyframes anim252 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(109px, 164px, 0);
    opacity: 0;
  }
}
.c:nth-child(253) {
  animation: anim253 1.5s infinite alternate;
  animation-delay: -3.795s;
  background: hsl(229.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728bfd, hsl(229.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4062fc;
  border: 1px solid hsl(229.3deg, 97%, 57%);
}

@keyframes anim253 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(13px, 133px, 0);
    opacity: 0;
  }
}
.c:nth-child(254) {
  animation: anim254 1.5s infinite alternate;
  animation-delay: -3.81s;
  background: hsl(229.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728bfd, hsl(229.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4061fc;
  border: 1px solid hsl(229.4deg, 97%, 57%);
}

@keyframes anim254 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(29px, 92px, 0);
    opacity: 0;
  }
}
.c:nth-child(255) {
  animation: anim255 1.5s infinite alternate;
  animation-delay: -3.825s;
  background: hsl(229.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728bfd, hsl(229.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4061fc;
  border: 1px solid hsl(229.5deg, 97%, 57%);
}

@keyframes anim255 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(266px, 174px, 0);
    opacity: 0;
  }
}
.c:nth-child(256) {
  animation: anim256 1.5s infinite alternate;
  animation-delay: -3.84s;
  background: hsl(229.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728afd, hsl(229.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4061fc;
  border: 1px solid hsl(229.6deg, 97%, 57%);
}

@keyframes anim256 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(107px, 107px, 0);
    opacity: 0;
  }
}
.c:nth-child(257) {
  animation: anim257 1.5s infinite alternate;
  animation-delay: -3.855s;
  background: hsl(229.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728afd, hsl(229.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4060fc;
  border: 1px solid hsl(229.7deg, 97%, 57%);
}

@keyframes anim257 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(115px, 20px, 0);
    opacity: 0;
  }
}
.c:nth-child(258) {
  animation: anim258 1.5s infinite alternate;
  animation-delay: -3.87s;
  background: hsl(229.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728afd, hsl(229.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4060fc;
  border: 1px solid hsl(229.8deg, 97%, 57%);
}

@keyframes anim258 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(125px, 96px, 0);
    opacity: 0;
  }
}
.c:nth-child(259) {
  animation: anim259 1.5s infinite alternate;
  animation-delay: -3.885s;
  background: hsl(229.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #728afd, hsl(229.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4060fc;
  border: 1px solid hsl(229.9deg, 97%, 57%);
}

@keyframes anim259 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(59px, 139px, 0);
    opacity: 0;
  }
}
.c:nth-child(260) {
  animation: anim260 1.5s infinite alternate;
  animation-delay: -3.9s;
  background: hsl(230deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7289fd, hsl(230deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405ffc;
  border: 1px solid hsl(230deg, 97%, 57%);
}

@keyframes anim260 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(173px, 80px, 0);
    opacity: 0;
  }
}
.c:nth-child(261) {
  animation: anim261 1.5s infinite alternate;
  animation-delay: -3.915s;
  background: hsl(230.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7289fd, hsl(230.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405ffc;
  border: 1px solid hsl(230.1deg, 97%, 57%);
}

@keyframes anim261 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(151px, 56px, 0);
    opacity: 0;
  }
}
.c:nth-child(262) {
  animation: anim262 1.5s infinite alternate;
  animation-delay: -3.93s;
  background: hsl(230.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7289fd, hsl(230.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405ffc;
  border: 1px solid hsl(230.2deg, 97%, 57%);
}

@keyframes anim262 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(254px, 179px, 0);
    opacity: 0;
  }
}
.c:nth-child(263) {
  animation: anim263 1.5s infinite alternate;
  animation-delay: -3.945s;
  background: hsl(230.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7289fd, hsl(230.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405efc;
  border: 1px solid hsl(230.3deg, 97%, 57%);
}

@keyframes anim263 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(243px, 119px, 0);
    opacity: 0;
  }
}
.c:nth-child(264) {
  animation: anim264 1.5s infinite alternate;
  animation-delay: -3.96s;
  background: hsl(230.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7289fd, hsl(230.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405efc;
  border: 1px solid hsl(230.4deg, 97%, 57%);
}

@keyframes anim264 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(228px, 162px, 0);
    opacity: 0;
  }
}
.c:nth-child(265) {
  animation: anim265 1.5s infinite alternate;
  animation-delay: -3.975s;
  background: hsl(230.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7288fd, hsl(230.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405efc;
  border: 1px solid hsl(230.5deg, 97%, 57%);
}

@keyframes anim265 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(88px, 21px, 0);
    opacity: 0;
  }
}
.c:nth-child(266) {
  animation: anim266 1.5s infinite alternate;
  animation-delay: -3.99s;
  background: hsl(230.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7288fd, hsl(230.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405efc;
  border: 1px solid hsl(230.6deg, 97%, 57%);
}

@keyframes anim266 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(142px, 27px, 0);
    opacity: 0;
  }
}
.c:nth-child(267) {
  animation: anim267 1.5s infinite alternate;
  animation-delay: -4.005s;
  background: hsl(230.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7288fd, hsl(230.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405dfc;
  border: 1px solid hsl(230.7deg, 97%, 57%);
}

@keyframes anim267 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(151px, 200px, 0);
    opacity: 0;
  }
}
.c:nth-child(268) {
  animation: anim268 1.5s infinite alternate;
  animation-delay: -4.02s;
  background: hsl(230.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7288fd, hsl(230.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405dfc;
  border: 1px solid hsl(230.8deg, 97%, 57%);
}

@keyframes anim268 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(37px, 118px, 0);
    opacity: 0;
  }
}
.c:nth-child(269) {
  animation: anim269 1.5s infinite alternate;
  animation-delay: -4.035s;
  background: hsl(230.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7287fd, hsl(230.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405dfc;
  border: 1px solid hsl(230.9deg, 97%, 57%);
}

@keyframes anim269 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(127px, 4px, 0);
    opacity: 0;
  }
}
.c:nth-child(270) {
  animation: anim270 1.5s infinite alternate;
  animation-delay: -4.05s;
  background: hsl(231deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7287fd, hsl(231deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405cfc;
  border: 1px solid hsl(231deg, 97%, 57%);
}

@keyframes anim270 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(143px, 53px, 0);
    opacity: 0;
  }
}
.c:nth-child(271) {
  animation: anim271 1.5s infinite alternate;
  animation-delay: -4.065s;
  background: hsl(231.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7287fd, hsl(231.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405cfc;
  border: 1px solid hsl(231.1deg, 97%, 57%);
}

@keyframes anim271 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(173px, 114px, 0);
    opacity: 0;
  }
}
.c:nth-child(272) {
  animation: anim272 1.5s infinite alternate;
  animation-delay: -4.08s;
  background: hsl(231.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7287fd, hsl(231.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405cfc;
  border: 1px solid hsl(231.2deg, 97%, 57%);
}

@keyframes anim272 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(91px, 138px, 0);
    opacity: 0;
  }
}
.c:nth-child(273) {
  animation: anim273 1.5s infinite alternate;
  animation-delay: -4.095s;
  background: hsl(231.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7286fd, hsl(231.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405bfc;
  border: 1px solid hsl(231.3deg, 97%, 57%);
}

@keyframes anim273 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(184px, 165px, 0);
    opacity: 0;
  }
}
.c:nth-child(274) {
  animation: anim274 1.5s infinite alternate;
  animation-delay: -4.11s;
  background: hsl(231.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7286fd, hsl(231.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405bfc;
  border: 1px solid hsl(231.4deg, 97%, 57%);
}

@keyframes anim274 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(204px, 39px, 0);
    opacity: 0;
  }
}
.c:nth-child(275) {
  animation: anim275 1.5s infinite alternate;
  animation-delay: -4.125s;
  background: hsl(231.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7286fd, hsl(231.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405bfc;
  border: 1px solid hsl(231.5deg, 97%, 57%);
}

@keyframes anim275 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(212px, 155px, 0);
    opacity: 0;
  }
}
.c:nth-child(276) {
  animation: anim276 1.5s infinite alternate;
  animation-delay: -4.14s;
  background: hsl(231.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7286fd, hsl(231.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405afc;
  border: 1px solid hsl(231.6deg, 97%, 57%);
}

@keyframes anim276 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(172px, 68px, 0);
    opacity: 0;
  }
}
.c:nth-child(277) {
  animation: anim277 1.5s infinite alternate;
  animation-delay: -4.155s;
  background: hsl(231.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7286fd, hsl(231.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405afc;
  border: 1px solid hsl(231.7deg, 97%, 57%);
}

@keyframes anim277 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(112px, 134px, 0);
    opacity: 0;
  }
}
.c:nth-child(278) {
  animation: anim278 1.5s infinite alternate;
  animation-delay: -4.17s;
  background: hsl(231.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7285fd, hsl(231.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #405afc;
  border: 1px solid hsl(231.8deg, 97%, 57%);
}

@keyframes anim278 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(132px, 158px, 0);
    opacity: 0;
  }
}
.c:nth-child(279) {
  animation: anim279 1.5s infinite alternate;
  animation-delay: -4.185s;
  background: hsl(231.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7285fd, hsl(231.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4059fc;
  border: 1px solid hsl(231.9deg, 97%, 57%);
}

@keyframes anim279 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(249px, 82px, 0);
    opacity: 0;
  }
}
.c:nth-child(280) {
  animation: anim280 1.5s infinite alternate;
  animation-delay: -4.2s;
  background: hsl(232deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7285fd, hsl(232deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4059fc;
  border: 1px solid hsl(232deg, 97%, 57%);
}

@keyframes anim280 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(240px, 79px, 0);
    opacity: 0;
  }
}
.c:nth-child(281) {
  animation: anim281 1.5s infinite alternate;
  animation-delay: -4.215s;
  background: hsl(232.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7285fd, hsl(232.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4059fc;
  border: 1px solid hsl(232.1deg, 97%, 57%);
}

@keyframes anim281 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(222px, 94px, 0);
    opacity: 0;
  }
}
.c:nth-child(282) {
  animation: anim282 1.5s infinite alternate;
  animation-delay: -4.23s;
  background: hsl(232.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7284fd, hsl(232.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4059fc;
  border: 1px solid hsl(232.2deg, 97%, 57%);
}

@keyframes anim282 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(191px, 192px, 0);
    opacity: 0;
  }
}
.c:nth-child(283) {
  animation: anim283 1.5s infinite alternate;
  animation-delay: -4.245s;
  background: hsl(232.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7284fd, hsl(232.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4058fc;
  border: 1px solid hsl(232.3deg, 97%, 57%);
}

@keyframes anim283 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(280px, 99px, 0);
    opacity: 0;
  }
}
.c:nth-child(284) {
  animation: anim284 1.5s infinite alternate;
  animation-delay: -4.26s;
  background: hsl(232.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7284fd, hsl(232.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4058fc;
  border: 1px solid hsl(232.4deg, 97%, 57%);
}

@keyframes anim284 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(200px, 30px, 0);
    opacity: 0;
  }
}
.c:nth-child(285) {
  animation: anim285 1.5s infinite alternate;
  animation-delay: -4.275s;
  background: hsl(232.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7284fd, hsl(232.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4058fc;
  border: 1px solid hsl(232.5deg, 97%, 57%);
}

@keyframes anim285 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(88px, 42px, 0);
    opacity: 0;
  }
}
.c:nth-child(286) {
  animation: anim286 1.5s infinite alternate;
  animation-delay: -4.29s;
  background: hsl(232.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7283fd, hsl(232.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4057fc;
  border: 1px solid hsl(232.6deg, 97%, 57%);
}

@keyframes anim286 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(190px, 27px, 0);
    opacity: 0;
  }
}
.c:nth-child(287) {
  animation: anim287 1.5s infinite alternate;
  animation-delay: -4.305s;
  background: hsl(232.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7283fd, hsl(232.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4057fc;
  border: 1px solid hsl(232.7deg, 97%, 57%);
}

@keyframes anim287 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(155px, 146px, 0);
    opacity: 0;
  }
}
.c:nth-child(288) {
  animation: anim288 1.5s infinite alternate;
  animation-delay: -4.32s;
  background: hsl(232.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7283fd, hsl(232.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4057fc;
  border: 1px solid hsl(232.8deg, 97%, 57%);
}

@keyframes anim288 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(164px, 70px, 0);
    opacity: 0;
  }
}
.c:nth-child(289) {
  animation: anim289 1.5s infinite alternate;
  animation-delay: -4.335s;
  background: hsl(232.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7283fd, hsl(232.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4056fc;
  border: 1px solid hsl(232.9deg, 97%, 57%);
}

@keyframes anim289 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(216px, 114px, 0);
    opacity: 0;
  }
}
.c:nth-child(290) {
  animation: anim290 1.5s infinite alternate;
  animation-delay: -4.35s;
  background: hsl(233deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7283fd, hsl(233deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4056fc;
  border: 1px solid hsl(233deg, 97%, 57%);
}

@keyframes anim290 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(65px, 27px, 0);
    opacity: 0;
  }
}
.c:nth-child(291) {
  animation: anim291 1.5s infinite alternate;
  animation-delay: -4.365s;
  background: hsl(233.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7282fd, hsl(233.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4056fc;
  border: 1px solid hsl(233.1deg, 97%, 57%);
}

@keyframes anim291 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(234px, 6px, 0);
    opacity: 0;
  }
}
.c:nth-child(292) {
  animation: anim292 1.5s infinite alternate;
  animation-delay: -4.38s;
  background: hsl(233.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7282fd, hsl(233.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4055fc;
  border: 1px solid hsl(233.2deg, 97%, 57%);
}

@keyframes anim292 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(89px, 107px, 0);
    opacity: 0;
  }
}
.c:nth-child(293) {
  animation: anim293 1.5s infinite alternate;
  animation-delay: -4.395s;
  background: hsl(233.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7282fd, hsl(233.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4055fc;
  border: 1px solid hsl(233.3deg, 97%, 57%);
}

@keyframes anim293 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(103px, 104px, 0);
    opacity: 0;
  }
}
.c:nth-child(294) {
  animation: anim294 1.5s infinite alternate;
  animation-delay: -4.41s;
  background: hsl(233.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7282fd, hsl(233.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4055fc;
  border: 1px solid hsl(233.4deg, 97%, 57%);
}

@keyframes anim294 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(89px, 199px, 0);
    opacity: 0;
  }
}
.c:nth-child(295) {
  animation: anim295 1.5s infinite alternate;
  animation-delay: -4.425s;
  background: hsl(233.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7281fd, hsl(233.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4054fc;
  border: 1px solid hsl(233.5deg, 97%, 57%);
}

@keyframes anim295 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(186px, 18px, 0);
    opacity: 0;
  }
}
.c:nth-child(296) {
  animation: anim296 1.5s infinite alternate;
  animation-delay: -4.44s;
  background: hsl(233.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7281fd, hsl(233.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4054fc;
  border: 1px solid hsl(233.6deg, 97%, 57%);
}

@keyframes anim296 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(91px, 140px, 0);
    opacity: 0;
  }
}
.c:nth-child(297) {
  animation: anim297 1.5s infinite alternate;
  animation-delay: -4.455s;
  background: hsl(233.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7281fd, hsl(233.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4054fc;
  border: 1px solid hsl(233.7deg, 97%, 57%);
}

@keyframes anim297 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(75px, 37px, 0);
    opacity: 0;
  }
}
.c:nth-child(298) {
  animation: anim298 1.5s infinite alternate;
  animation-delay: -4.47s;
  background: hsl(233.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7281fd, hsl(233.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4054fc;
  border: 1px solid hsl(233.8deg, 97%, 57%);
}

@keyframes anim298 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(184px, 116px, 0);
    opacity: 0;
  }
}
.c:nth-child(299) {
  animation: anim299 1.5s infinite alternate;
  animation-delay: -4.485s;
  background: hsl(233.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7280fd, hsl(233.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4053fc;
  border: 1px solid hsl(233.9deg, 97%, 57%);
}

@keyframes anim299 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(146px, 46px, 0);
    opacity: 0;
  }
}
.c:nth-child(300) {
  animation: anim300 1.5s infinite alternate;
  animation-delay: -4.5s;
  background: hsl(234deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7280fd, hsl(234deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4053fc;
  border: 1px solid hsl(234deg, 97%, 57%);
}

@keyframes anim300 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(159px, 171px, 0);
    opacity: 0;
  }
}
.c:nth-child(301) {
  animation: anim301 1.5s infinite alternate;
  animation-delay: -4.515s;
  background: hsl(234.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7280fd, hsl(234.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4053fc;
  border: 1px solid hsl(234.1deg, 97%, 57%);
}

@keyframes anim301 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(285px, 64px, 0);
    opacity: 0;
  }
}
.c:nth-child(302) {
  animation: anim302 1.5s infinite alternate;
  animation-delay: -4.53s;
  background: hsl(234.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7280fd, hsl(234.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4052fc;
  border: 1px solid hsl(234.2deg, 97%, 57%);
}

@keyframes anim302 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(76px, 93px, 0);
    opacity: 0;
  }
}
.c:nth-child(303) {
  animation: anim303 1.5s infinite alternate;
  animation-delay: -4.545s;
  background: hsl(234.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7280fd, hsl(234.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4052fc;
  border: 1px solid hsl(234.3deg, 97%, 57%);
}

@keyframes anim303 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(162px, 47px, 0);
    opacity: 0;
  }
}
.c:nth-child(304) {
  animation: anim304 1.5s infinite alternate;
  animation-delay: -4.56s;
  background: hsl(234.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727ffd, hsl(234.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4052fc;
  border: 1px solid hsl(234.4deg, 97%, 57%);
}

@keyframes anim304 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(89px, 90px, 0);
    opacity: 0;
  }
}
.c:nth-child(305) {
  animation: anim305 1.5s infinite alternate;
  animation-delay: -4.575s;
  background: hsl(234.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727ffd, hsl(234.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4051fc;
  border: 1px solid hsl(234.5deg, 97%, 57%);
}

@keyframes anim305 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(59px, 96px, 0);
    opacity: 0;
  }
}
.c:nth-child(306) {
  animation: anim306 1.5s infinite alternate;
  animation-delay: -4.59s;
  background: hsl(234.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727ffd, hsl(234.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4051fc;
  border: 1px solid hsl(234.6deg, 97%, 57%);
}

@keyframes anim306 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(146px, 34px, 0);
    opacity: 0;
  }
}
.c:nth-child(307) {
  animation: anim307 1.5s infinite alternate;
  animation-delay: -4.605s;
  background: hsl(234.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727ffd, hsl(234.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4051fc;
  border: 1px solid hsl(234.7deg, 97%, 57%);
}

@keyframes anim307 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(233px, 94px, 0);
    opacity: 0;
  }
}
.c:nth-child(308) {
  animation: anim308 1.5s infinite alternate;
  animation-delay: -4.62s;
  background: hsl(234.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727efd, hsl(234.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4050fc;
  border: 1px solid hsl(234.8deg, 97%, 57%);
}

@keyframes anim308 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(10px, 125px, 0);
    opacity: 0;
  }
}
.c:nth-child(309) {
  animation: anim309 1.5s infinite alternate;
  animation-delay: -4.635s;
  background: hsl(234.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727efd, hsl(234.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4050fc;
  border: 1px solid hsl(234.9deg, 97%, 57%);
}

@keyframes anim309 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(206px, 110px, 0);
    opacity: 0;
  }
}
.c:nth-child(310) {
  animation: anim310 1.5s infinite alternate;
  animation-delay: -4.65s;
  background: hsl(235deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727efd, hsl(235deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4050fc;
  border: 1px solid hsl(235deg, 97%, 57%);
}

@keyframes anim310 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(30px, 152px, 0);
    opacity: 0;
  }
}
.c:nth-child(311) {
  animation: anim311 1.5s infinite alternate;
  animation-delay: -4.665s;
  background: hsl(235.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727efd, hsl(235.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404ffc;
  border: 1px solid hsl(235.1deg, 97%, 57%);
}

@keyframes anim311 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(245px, 72px, 0);
    opacity: 0;
  }
}
.c:nth-child(312) {
  animation: anim312 1.5s infinite alternate;
  animation-delay: -4.68s;
  background: hsl(235.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727dfd, hsl(235.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404ffc;
  border: 1px solid hsl(235.2deg, 97%, 57%);
}

@keyframes anim312 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(61px, 12px, 0);
    opacity: 0;
  }
}
.c:nth-child(313) {
  animation: anim313 1.5s infinite alternate;
  animation-delay: -4.695s;
  background: hsl(235.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727dfd, hsl(235.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404ffc;
  border: 1px solid hsl(235.3deg, 97%, 57%);
}

@keyframes anim313 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(181px, 173px, 0);
    opacity: 0;
  }
}
.c:nth-child(314) {
  animation: anim314 1.5s infinite alternate;
  animation-delay: -4.71s;
  background: hsl(235.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727dfd, hsl(235.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404ffc;
  border: 1px solid hsl(235.4deg, 97%, 57%);
}

@keyframes anim314 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(164px, 161px, 0);
    opacity: 0;
  }
}
.c:nth-child(315) {
  animation: anim315 1.5s infinite alternate;
  animation-delay: -4.725s;
  background: hsl(235.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727dfd, hsl(235.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404efc;
  border: 1px solid hsl(235.5deg, 97%, 57%);
}

@keyframes anim315 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(29px, 90px, 0);
    opacity: 0;
  }
}
.c:nth-child(316) {
  animation: anim316 1.5s infinite alternate;
  animation-delay: -4.74s;
  background: hsl(235.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727cfd, hsl(235.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404efc;
  border: 1px solid hsl(235.6deg, 97%, 57%);
}

@keyframes anim316 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(155px, 147px, 0);
    opacity: 0;
  }
}
.c:nth-child(317) {
  animation: anim317 1.5s infinite alternate;
  animation-delay: -4.755s;
  background: hsl(235.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727cfd, hsl(235.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404efc;
  border: 1px solid hsl(235.7deg, 97%, 57%);
}

@keyframes anim317 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(277px, 142px, 0);
    opacity: 0;
  }
}
.c:nth-child(318) {
  animation: anim318 1.5s infinite alternate;
  animation-delay: -4.77s;
  background: hsl(235.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727cfd, hsl(235.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404dfc;
  border: 1px solid hsl(235.8deg, 97%, 57%);
}

@keyframes anim318 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(228px, 198px, 0);
    opacity: 0;
  }
}
.c:nth-child(319) {
  animation: anim319 1.5s infinite alternate;
  animation-delay: -4.785s;
  background: hsl(235.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727cfd, hsl(235.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404dfc;
  border: 1px solid hsl(235.9deg, 97%, 57%);
}

@keyframes anim319 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(236px, 85px, 0);
    opacity: 0;
  }
}
.c:nth-child(320) {
  animation: anim320 1.5s infinite alternate;
  animation-delay: -4.8s;
  background: hsl(236deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727cfd, hsl(236deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404dfc;
  border: 1px solid hsl(236deg, 97%, 57%);
}

@keyframes anim320 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(225px, 103px, 0);
    opacity: 0;
  }
}
.c:nth-child(321) {
  animation: anim321 1.5s infinite alternate;
  animation-delay: -4.815s;
  background: hsl(236.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727bfd, hsl(236.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404cfc;
  border: 1px solid hsl(236.1deg, 97%, 57%);
}

@keyframes anim321 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(142px, 71px, 0);
    opacity: 0;
  }
}
.c:nth-child(322) {
  animation: anim322 1.5s infinite alternate;
  animation-delay: -4.83s;
  background: hsl(236.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727bfd, hsl(236.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404cfc;
  border: 1px solid hsl(236.2deg, 97%, 57%);
}

@keyframes anim322 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(245px, 187px, 0);
    opacity: 0;
  }
}
.c:nth-child(323) {
  animation: anim323 1.5s infinite alternate;
  animation-delay: -4.845s;
  background: hsl(236.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727bfd, hsl(236.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404cfc;
  border: 1px solid hsl(236.3deg, 97%, 57%);
}

@keyframes anim323 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(64px, 116px, 0);
    opacity: 0;
  }
}
.c:nth-child(324) {
  animation: anim324 1.5s infinite alternate;
  animation-delay: -4.86s;
  background: hsl(236.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727bfd, hsl(236.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404bfc;
  border: 1px solid hsl(236.4deg, 97%, 57%);
}

@keyframes anim324 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(268px, 75px, 0);
    opacity: 0;
  }
}
.c:nth-child(325) {
  animation: anim325 1.5s infinite alternate;
  animation-delay: -4.875s;
  background: hsl(236.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727afd, hsl(236.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404bfc;
  border: 1px solid hsl(236.5deg, 97%, 57%);
}

@keyframes anim325 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(195px, 106px, 0);
    opacity: 0;
  }
}
.c:nth-child(326) {
  animation: anim326 1.5s infinite alternate;
  animation-delay: -4.89s;
  background: hsl(236.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727afd, hsl(236.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404bfc;
  border: 1px solid hsl(236.6deg, 97%, 57%);
}

@keyframes anim326 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(300px, 63px, 0);
    opacity: 0;
  }
}
.c:nth-child(327) {
  animation: anim327 1.5s infinite alternate;
  animation-delay: -4.905s;
  background: hsl(236.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727afd, hsl(236.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404afc;
  border: 1px solid hsl(236.7deg, 97%, 57%);
}

@keyframes anim327 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(145px, 88px, 0);
    opacity: 0;
  }
}
.c:nth-child(328) {
  animation: anim328 1.5s infinite alternate;
  animation-delay: -4.92s;
  background: hsl(236.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #727afd, hsl(236.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404afc;
  border: 1px solid hsl(236.8deg, 97%, 57%);
}

@keyframes anim328 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(227px, 101px, 0);
    opacity: 0;
  }
}
.c:nth-child(329) {
  animation: anim329 1.5s infinite alternate;
  animation-delay: -4.935s;
  background: hsl(236.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7279fd, hsl(236.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404afc;
  border: 1px solid hsl(236.9deg, 97%, 57%);
}

@keyframes anim329 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(253px, 27px, 0);
    opacity: 0;
  }
}
.c:nth-child(330) {
  animation: anim330 1.5s infinite alternate;
  animation-delay: -4.95s;
  background: hsl(237deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7279fd, hsl(237deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #404afc;
  border: 1px solid hsl(237deg, 97%, 57%);
}

@keyframes anim330 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(147px, 136px, 0);
    opacity: 0;
  }
}
.c:nth-child(331) {
  animation: anim331 1.5s infinite alternate;
  animation-delay: -4.965s;
  background: hsl(237.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7279fd, hsl(237.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4049fc;
  border: 1px solid hsl(237.1deg, 97%, 57%);
}

@keyframes anim331 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(83px, 112px, 0);
    opacity: 0;
  }
}
.c:nth-child(332) {
  animation: anim332 1.5s infinite alternate;
  animation-delay: -4.98s;
  background: hsl(237.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7279fd, hsl(237.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4049fc;
  border: 1px solid hsl(237.2deg, 97%, 57%);
}

@keyframes anim332 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(140px, 10px, 0);
    opacity: 0;
  }
}
.c:nth-child(333) {
  animation: anim333 1.5s infinite alternate;
  animation-delay: -4.995s;
  background: hsl(237.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7279fd, hsl(237.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4049fc;
  border: 1px solid hsl(237.3deg, 97%, 57%);
}

@keyframes anim333 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(169px, 135px, 0);
    opacity: 0;
  }
}
.c:nth-child(334) {
  animation: anim334 1.5s infinite alternate;
  animation-delay: -5.01s;
  background: hsl(237.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7278fd, hsl(237.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4048fc;
  border: 1px solid hsl(237.4deg, 97%, 57%);
}

@keyframes anim334 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(171px, 109px, 0);
    opacity: 0;
  }
}
.c:nth-child(335) {
  animation: anim335 1.5s infinite alternate;
  animation-delay: -5.025s;
  background: hsl(237.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7278fd, hsl(237.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4048fc;
  border: 1px solid hsl(237.5deg, 97%, 57%);
}

@keyframes anim335 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(65px, 134px, 0);
    opacity: 0;
  }
}
.c:nth-child(336) {
  animation: anim336 1.5s infinite alternate;
  animation-delay: -5.04s;
  background: hsl(237.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7278fd, hsl(237.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4048fc;
  border: 1px solid hsl(237.6deg, 97%, 57%);
}

@keyframes anim336 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(191px, 174px, 0);
    opacity: 0;
  }
}
.c:nth-child(337) {
  animation: anim337 1.5s infinite alternate;
  animation-delay: -5.055s;
  background: hsl(237.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7278fd, hsl(237.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4047fc;
  border: 1px solid hsl(237.7deg, 97%, 57%);
}

@keyframes anim337 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(43px, 144px, 0);
    opacity: 0;
  }
}
.c:nth-child(338) {
  animation: anim338 1.5s infinite alternate;
  animation-delay: -5.07s;
  background: hsl(237.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7277fd, hsl(237.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4047fc;
  border: 1px solid hsl(237.8deg, 97%, 57%);
}

@keyframes anim338 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(243px, 185px, 0);
    opacity: 0;
  }
}
.c:nth-child(339) {
  animation: anim339 1.5s infinite alternate;
  animation-delay: -5.085s;
  background: hsl(237.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7277fd, hsl(237.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4047fc;
  border: 1px solid hsl(237.9deg, 97%, 57%);
}

@keyframes anim339 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(13px, 176px, 0);
    opacity: 0;
  }
}
.c:nth-child(340) {
  animation: anim340 1.5s infinite alternate;
  animation-delay: -5.1s;
  background: hsl(238deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7277fd, hsl(238deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4046fc;
  border: 1px solid hsl(238deg, 97%, 57%);
}

@keyframes anim340 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(84px, 139px, 0);
    opacity: 0;
  }
}
.c:nth-child(341) {
  animation: anim341 1.5s infinite alternate;
  animation-delay: -5.115s;
  background: hsl(238.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7277fd, hsl(238.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4046fc;
  border: 1px solid hsl(238.1deg, 97%, 57%);
}

@keyframes anim341 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(159px, 74px, 0);
    opacity: 0;
  }
}
.c:nth-child(342) {
  animation: anim342 1.5s infinite alternate;
  animation-delay: -5.13s;
  background: hsl(238.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7276fd, hsl(238.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4046fc;
  border: 1px solid hsl(238.2deg, 97%, 57%);
}

@keyframes anim342 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(126px, 5px, 0);
    opacity: 0;
  }
}
.c:nth-child(343) {
  animation: anim343 1.5s infinite alternate;
  animation-delay: -5.145s;
  background: hsl(238.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7276fd, hsl(238.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4045fc;
  border: 1px solid hsl(238.3deg, 97%, 57%);
}

@keyframes anim343 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(271px, 11px, 0);
    opacity: 0;
  }
}
.c:nth-child(344) {
  animation: anim344 1.5s infinite alternate;
  animation-delay: -5.16s;
  background: hsl(238.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7276fd, hsl(238.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4045fc;
  border: 1px solid hsl(238.4deg, 97%, 57%);
}

@keyframes anim344 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(27px, 121px, 0);
    opacity: 0;
  }
}
.c:nth-child(345) {
  animation: anim345 1.5s infinite alternate;
  animation-delay: -5.175s;
  background: hsl(238.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7276fd, hsl(238.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4045fc;
  border: 1px solid hsl(238.5deg, 97%, 57%);
}

@keyframes anim345 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(152px, 98px, 0);
    opacity: 0;
  }
}
.c:nth-child(346) {
  animation: anim346 1.5s infinite alternate;
  animation-delay: -5.19s;
  background: hsl(238.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7276fd, hsl(238.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4044fc;
  border: 1px solid hsl(238.6deg, 97%, 57%);
}

@keyframes anim346 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(154px, 73px, 0);
    opacity: 0;
  }
}
.c:nth-child(347) {
  animation: anim347 1.5s infinite alternate;
  animation-delay: -5.205s;
  background: hsl(238.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7275fd, hsl(238.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4044fc;
  border: 1px solid hsl(238.7deg, 97%, 57%);
}

@keyframes anim347 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(203px, 29px, 0);
    opacity: 0;
  }
}
.c:nth-child(348) {
  animation: anim348 1.5s infinite alternate;
  animation-delay: -5.22s;
  background: hsl(238.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7275fd, hsl(238.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4044fc;
  border: 1px solid hsl(238.8deg, 97%, 57%);
}

@keyframes anim348 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(52px, 158px, 0);
    opacity: 0;
  }
}
.c:nth-child(349) {
  animation: anim349 1.5s infinite alternate;
  animation-delay: -5.235s;
  background: hsl(238.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7275fd, hsl(238.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4044fc;
  border: 1px solid hsl(238.9deg, 97%, 57%);
}

@keyframes anim349 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(112px, 29px, 0);
    opacity: 0;
  }
}
.c:nth-child(350) {
  animation: anim350 1.5s infinite alternate;
  animation-delay: -5.25s;
  background: hsl(239deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7275fd, hsl(239deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4043fc;
  border: 1px solid hsl(239deg, 97%, 57%);
}

@keyframes anim350 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(9px, 38px, 0);
    opacity: 0;
  }
}
.c:nth-child(351) {
  animation: anim351 1.5s infinite alternate;
  animation-delay: -5.265s;
  background: hsl(239.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7274fd, hsl(239.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4043fc;
  border: 1px solid hsl(239.1deg, 97%, 57%);
}

@keyframes anim351 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(297px, 99px, 0);
    opacity: 0;
  }
}
.c:nth-child(352) {
  animation: anim352 1.5s infinite alternate;
  animation-delay: -5.28s;
  background: hsl(239.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7274fd, hsl(239.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4043fc;
  border: 1px solid hsl(239.2deg, 97%, 57%);
}

@keyframes anim352 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(47px, 19px, 0);
    opacity: 0;
  }
}
.c:nth-child(353) {
  animation: anim353 1.5s infinite alternate;
  animation-delay: -5.295s;
  background: hsl(239.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7274fd, hsl(239.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4042fc;
  border: 1px solid hsl(239.3deg, 97%, 57%);
}

@keyframes anim353 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(157px, 152px, 0);
    opacity: 0;
  }
}
.c:nth-child(354) {
  animation: anim354 1.5s infinite alternate;
  animation-delay: -5.31s;
  background: hsl(239.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7274fd, hsl(239.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4042fc;
  border: 1px solid hsl(239.4deg, 97%, 57%);
}

@keyframes anim354 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(284px, 176px, 0);
    opacity: 0;
  }
}
.c:nth-child(355) {
  animation: anim355 1.5s infinite alternate;
  animation-delay: -5.325s;
  background: hsl(239.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7273fd, hsl(239.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4042fc;
  border: 1px solid hsl(239.5deg, 97%, 57%);
}

@keyframes anim355 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(281px, 46px, 0);
    opacity: 0;
  }
}
.c:nth-child(356) {
  animation: anim356 1.5s infinite alternate;
  animation-delay: -5.34s;
  background: hsl(239.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7273fd, hsl(239.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4041fc;
  border: 1px solid hsl(239.6deg, 97%, 57%);
}

@keyframes anim356 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(203px, 189px, 0);
    opacity: 0;
  }
}
.c:nth-child(357) {
  animation: anim357 1.5s infinite alternate;
  animation-delay: -5.355s;
  background: hsl(239.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7273fd, hsl(239.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4041fc;
  border: 1px solid hsl(239.7deg, 97%, 57%);
}

@keyframes anim357 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(266px, 63px, 0);
    opacity: 0;
  }
}
.c:nth-child(358) {
  animation: anim358 1.5s infinite alternate;
  animation-delay: -5.37s;
  background: hsl(239.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7273fd, hsl(239.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4041fc;
  border: 1px solid hsl(239.8deg, 97%, 57%);
}

@keyframes anim358 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(252px, 7px, 0);
    opacity: 0;
  }
}
.c:nth-child(359) {
  animation: anim359 1.5s infinite alternate;
  animation-delay: -5.385s;
  background: hsl(239.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7273fd, hsl(239.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4040fc;
  border: 1px solid hsl(239.9deg, 97%, 57%);
}

@keyframes anim359 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(211px, 67px, 0);
    opacity: 0;
  }
}
.c:nth-child(360) {
  animation: anim360 1.5s infinite alternate;
  animation-delay: -5.4s;
  background: hsl(240deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7272fd, hsl(240deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4040fc;
  border: 1px solid hsl(240deg, 97%, 57%);
}

@keyframes anim360 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(75px, 6px, 0);
    opacity: 0;
  }
}
.c:nth-child(361) {
  animation: anim361 1.5s infinite alternate;
  animation-delay: -5.415s;
  background: hsl(240.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7372fd, hsl(240.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4040fc;
  border: 1px solid hsl(240.1deg, 97%, 57%);
}

@keyframes anim361 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(94px, 134px, 0);
    opacity: 0;
  }
}
.c:nth-child(362) {
  animation: anim362 1.5s infinite alternate;
  animation-delay: -5.43s;
  background: hsl(240.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7372fd, hsl(240.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4140fc;
  border: 1px solid hsl(240.2deg, 97%, 57%);
}

@keyframes anim362 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(49px, 96px, 0);
    opacity: 0;
  }
}
.c:nth-child(363) {
  animation: anim363 1.5s infinite alternate;
  animation-delay: -5.445s;
  background: hsl(240.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7372fd, hsl(240.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4140fc;
  border: 1px solid hsl(240.3deg, 97%, 57%);
}

@keyframes anim363 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(283px, 30px, 0);
    opacity: 0;
  }
}
.c:nth-child(364) {
  animation: anim364 1.5s infinite alternate;
  animation-delay: -5.46s;
  background: hsl(240.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7372fd, hsl(240.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4140fc;
  border: 1px solid hsl(240.4deg, 97%, 57%);
}

@keyframes anim364 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(252px, 193px, 0);
    opacity: 0;
  }
}
.c:nth-child(365) {
  animation: anim365 1.5s infinite alternate;
  animation-delay: -5.475s;
  background: hsl(240.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7372fd, hsl(240.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4240fc;
  border: 1px solid hsl(240.5deg, 97%, 57%);
}

@keyframes anim365 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(297px, 198px, 0);
    opacity: 0;
  }
}
.c:nth-child(366) {
  animation: anim366 1.5s infinite alternate;
  animation-delay: -5.49s;
  background: hsl(240.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7472fd, hsl(240.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4240fc;
  border: 1px solid hsl(240.6deg, 97%, 57%);
}

@keyframes anim366 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(108px, 56px, 0);
    opacity: 0;
  }
}
.c:nth-child(367) {
  animation: anim367 1.5s infinite alternate;
  animation-delay: -5.505s;
  background: hsl(240.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7472fd, hsl(240.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4240fc;
  border: 1px solid hsl(240.7deg, 97%, 57%);
}

@keyframes anim367 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(9px, 12px, 0);
    opacity: 0;
  }
}
.c:nth-child(368) {
  animation: anim368 1.5s infinite alternate;
  animation-delay: -5.52s;
  background: hsl(240.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7472fd, hsl(240.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4340fc;
  border: 1px solid hsl(240.8deg, 97%, 57%);
}

@keyframes anim368 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(191px, 101px, 0);
    opacity: 0;
  }
}
.c:nth-child(369) {
  animation: anim369 1.5s infinite alternate;
  animation-delay: -5.535s;
  background: hsl(240.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7472fd, hsl(240.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4340fc;
  border: 1px solid hsl(240.9deg, 97%, 57%);
}

@keyframes anim369 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(32px, 165px, 0);
    opacity: 0;
  }
}
.c:nth-child(370) {
  animation: anim370 1.5s infinite alternate;
  animation-delay: -5.55s;
  background: hsl(241deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7572fd, hsl(241deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4340fc;
  border: 1px solid hsl(241deg, 97%, 57%);
}

@keyframes anim370 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(173px, 97px, 0);
    opacity: 0;
  }
}
.c:nth-child(371) {
  animation: anim371 1.5s infinite alternate;
  animation-delay: -5.565s;
  background: hsl(241.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7572fd, hsl(241.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4440fc;
  border: 1px solid hsl(241.1deg, 97%, 57%);
}

@keyframes anim371 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(213px, 96px, 0);
    opacity: 0;
  }
}
.c:nth-child(372) {
  animation: anim372 1.5s infinite alternate;
  animation-delay: -5.58s;
  background: hsl(241.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7572fd, hsl(241.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4440fc;
  border: 1px solid hsl(241.2deg, 97%, 57%);
}

@keyframes anim372 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(239px, 40px, 0);
    opacity: 0;
  }
}
.c:nth-child(373) {
  animation: anim373 1.5s infinite alternate;
  animation-delay: -5.595s;
  background: hsl(241.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7572fd, hsl(241.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4440fc;
  border: 1px solid hsl(241.3deg, 97%, 57%);
}

@keyframes anim373 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(257px, 191px, 0);
    opacity: 0;
  }
}
.c:nth-child(374) {
  animation: anim374 1.5s infinite alternate;
  animation-delay: -5.61s;
  background: hsl(241.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7672fd, hsl(241.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4440fc;
  border: 1px solid hsl(241.4deg, 97%, 57%);
}

@keyframes anim374 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(178px, 109px, 0);
    opacity: 0;
  }
}
.c:nth-child(375) {
  animation: anim375 1.5s infinite alternate;
  animation-delay: -5.625s;
  background: hsl(241.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7672fd, hsl(241.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4540fc;
  border: 1px solid hsl(241.5deg, 97%, 57%);
}

@keyframes anim375 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(137px, 150px, 0);
    opacity: 0;
  }
}
.c:nth-child(376) {
  animation: anim376 1.5s infinite alternate;
  animation-delay: -5.64s;
  background: hsl(241.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7672fd, hsl(241.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4540fc;
  border: 1px solid hsl(241.6deg, 97%, 57%);
}

@keyframes anim376 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(163px, 18px, 0);
    opacity: 0;
  }
}
.c:nth-child(377) {
  animation: anim377 1.5s infinite alternate;
  animation-delay: -5.655s;
  background: hsl(241.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7672fd, hsl(241.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4540fc;
  border: 1px solid hsl(241.7deg, 97%, 57%);
}

@keyframes anim377 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(149px, 32px, 0);
    opacity: 0;
  }
}
.c:nth-child(378) {
  animation: anim378 1.5s infinite alternate;
  animation-delay: -5.67s;
  background: hsl(241.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7672fd, hsl(241.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4640fc;
  border: 1px solid hsl(241.8deg, 97%, 57%);
}

@keyframes anim378 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(251px, 105px, 0);
    opacity: 0;
  }
}
.c:nth-child(379) {
  animation: anim379 1.5s infinite alternate;
  animation-delay: -5.685s;
  background: hsl(241.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7772fd, hsl(241.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4640fc;
  border: 1px solid hsl(241.9deg, 97%, 57%);
}

@keyframes anim379 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(72px, 71px, 0);
    opacity: 0;
  }
}
.c:nth-child(380) {
  animation: anim380 1.5s infinite alternate;
  animation-delay: -5.7s;
  background: hsl(242deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7772fd, hsl(242deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4640fc;
  border: 1px solid hsl(242deg, 97%, 57%);
}

@keyframes anim380 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(282px, 73px, 0);
    opacity: 0;
  }
}
.c:nth-child(381) {
  animation: anim381 1.5s infinite alternate;
  animation-delay: -5.715s;
  background: hsl(242.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7772fd, hsl(242.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4740fc;
  border: 1px solid hsl(242.1deg, 97%, 57%);
}

@keyframes anim381 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(101px, 136px, 0);
    opacity: 0;
  }
}
.c:nth-child(382) {
  animation: anim382 1.5s infinite alternate;
  animation-delay: -5.73s;
  background: hsl(242.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7772fd, hsl(242.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4740fc;
  border: 1px solid hsl(242.2deg, 97%, 57%);
}

@keyframes anim382 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(148px, 194px, 0);
    opacity: 0;
  }
}
.c:nth-child(383) {
  animation: anim383 1.5s infinite alternate;
  animation-delay: -5.745s;
  background: hsl(242.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7872fd, hsl(242.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4740fc;
  border: 1px solid hsl(242.3deg, 97%, 57%);
}

@keyframes anim383 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(178px, 125px, 0);
    opacity: 0;
  }
}
.c:nth-child(384) {
  animation: anim384 1.5s infinite alternate;
  animation-delay: -5.76s;
  background: hsl(242.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7872fd, hsl(242.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4840fc;
  border: 1px solid hsl(242.4deg, 97%, 57%);
}

@keyframes anim384 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(59px, 19px, 0);
    opacity: 0;
  }
}
.c:nth-child(385) {
  animation: anim385 1.5s infinite alternate;
  animation-delay: -5.775s;
  background: hsl(242.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7872fd, hsl(242.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4840fc;
  border: 1px solid hsl(242.5deg, 97%, 57%);
}

@keyframes anim385 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(229px, 104px, 0);
    opacity: 0;
  }
}
.c:nth-child(386) {
  animation: anim386 1.5s infinite alternate;
  animation-delay: -5.79s;
  background: hsl(242.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7872fd, hsl(242.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4840fc;
  border: 1px solid hsl(242.6deg, 97%, 57%);
}

@keyframes anim386 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(128px, 16px, 0);
    opacity: 0;
  }
}
.c:nth-child(387) {
  animation: anim387 1.5s infinite alternate;
  animation-delay: -5.805s;
  background: hsl(242.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7972fd, hsl(242.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4940fc;
  border: 1px solid hsl(242.7deg, 97%, 57%);
}

@keyframes anim387 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(159px, 180px, 0);
    opacity: 0;
  }
}
.c:nth-child(388) {
  animation: anim388 1.5s infinite alternate;
  animation-delay: -5.82s;
  background: hsl(242.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7972fd, hsl(242.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4940fc;
  border: 1px solid hsl(242.8deg, 97%, 57%);
}

@keyframes anim388 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(128px, 70px, 0);
    opacity: 0;
  }
}
.c:nth-child(389) {
  animation: anim389 1.5s infinite alternate;
  animation-delay: -5.835s;
  background: hsl(242.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7972fd, hsl(242.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4940fc;
  border: 1px solid hsl(242.9deg, 97%, 57%);
}

@keyframes anim389 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(138px, 54px, 0);
    opacity: 0;
  }
}
.c:nth-child(390) {
  animation: anim390 1.5s infinite alternate;
  animation-delay: -5.85s;
  background: hsl(243deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7972fd, hsl(243deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4a40fc;
  border: 1px solid hsl(243deg, 97%, 57%);
}

@keyframes anim390 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(196px, 67px, 0);
    opacity: 0;
  }
}
.c:nth-child(391) {
  animation: anim391 1.5s infinite alternate;
  animation-delay: -5.865s;
  background: hsl(243.1deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7972fd, hsl(243.1deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4a40fc;
  border: 1px solid hsl(243.1deg, 97%, 57%);
}

@keyframes anim391 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(126px, 15px, 0);
    opacity: 0;
  }
}
.c:nth-child(392) {
  animation: anim392 1.5s infinite alternate;
  animation-delay: -5.88s;
  background: hsl(243.2deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7a72fd, hsl(243.2deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4a40fc;
  border: 1px solid hsl(243.2deg, 97%, 57%);
}

@keyframes anim392 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(118px, 120px, 0);
    opacity: 0;
  }
}
.c:nth-child(393) {
  animation: anim393 1.5s infinite alternate;
  animation-delay: -5.895s;
  background: hsl(243.3deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7a72fd, hsl(243.3deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4a40fc;
  border: 1px solid hsl(243.3deg, 97%, 57%);
}

@keyframes anim393 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(59px, 104px, 0);
    opacity: 0;
  }
}
.c:nth-child(394) {
  animation: anim394 1.5s infinite alternate;
  animation-delay: -5.91s;
  background: hsl(243.4deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7a72fd, hsl(243.4deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4b40fc;
  border: 1px solid hsl(243.4deg, 97%, 57%);
}

@keyframes anim394 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(175px, 181px, 0);
    opacity: 0;
  }
}
.c:nth-child(395) {
  animation: anim395 1.5s infinite alternate;
  animation-delay: -5.925s;
  background: hsl(243.5deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7a72fd, hsl(243.5deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4b40fc;
  border: 1px solid hsl(243.5deg, 97%, 57%);
}

@keyframes anim395 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(254px, 180px, 0);
    opacity: 0;
  }
}
.c:nth-child(396) {
  animation: anim396 1.5s infinite alternate;
  animation-delay: -5.94s;
  background: hsl(243.6deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7b72fd, hsl(243.6deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4b40fc;
  border: 1px solid hsl(243.6deg, 97%, 57%);
}

@keyframes anim396 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(12px, 28px, 0);
    opacity: 0;
  }
}
.c:nth-child(397) {
  animation: anim397 1.5s infinite alternate;
  animation-delay: -5.955s;
  background: hsl(243.7deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7b72fd, hsl(243.7deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4c40fc;
  border: 1px solid hsl(243.7deg, 97%, 57%);
}

@keyframes anim397 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(165px, 83px, 0);
    opacity: 0;
  }
}
.c:nth-child(398) {
  animation: anim398 1.5s infinite alternate;
  animation-delay: -5.97s;
  background: hsl(243.8deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7b72fd, hsl(243.8deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4c40fc;
  border: 1px solid hsl(243.8deg, 97%, 57%);
}

@keyframes anim398 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(285px, 157px, 0);
    opacity: 0;
  }
}
.c:nth-child(399) {
  animation: anim399 1.5s infinite alternate;
  animation-delay: -5.985s;
  background: hsl(243.9deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7b72fd, hsl(243.9deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4c40fc;
  border: 1px solid hsl(243.9deg, 97%, 57%);
}

@keyframes anim399 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(246px, 146px, 0);
    opacity: 0;
  }
}
.c:nth-child(400) {
  animation: anim400 1.5s infinite alternate;
  animation-delay: -6s;
  background: hsl(244deg, 97%, 57%);
  background: radial-gradient(circle at top left, #7c72fd, hsl(244deg, 97%, 57%));
  box-shadow: 0 0 25px 3px #4d40fc;
  border: 1px solid hsl(244deg, 97%, 57%);
}

@keyframes anim400 {
  60% {
    opacity: 1;
  }
  100% {
    transform: translate3d(137px, 92px, 0);
    opacity: 0;
  }
}
/* overlay at start */
.mfp-award.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

/* overlay animate in */
.mfp-award.mfp-bg.mfp-ready {
  opacity: 0.9;
}

/* overlay animate out */
.mfp-award.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-award.mfp-wrap .mfp-content img {
  transform: scale(5);
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-delay: 0.5s;
}
.mfp-award.mfp-wrap .mfp-content .glow1 {
  transform: scale(0);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  transition-delay: 0.7s;
}
.mfp-award.mfp-wrap .mfp-content .glow2 {
  transform: scale(1);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  transition-delay: 0.9s;
}
.mfp-award.mfp-wrap .mfp-content .glow-wrapper {
  opacity: 0;
  -webkit-transition: all 0s ease-out;
  -moz-transition: all 0s ease-out;
  transition: all 0s ease-out;
  transition-delay: 0.9s;
}
.mfp-award.mfp-wrap .mfp-content .text01 {
  transform: scale(2);
  opacity: 0;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  transition: all 1s ease-out;
  transition-delay: 1.5s;
}
.mfp-award.mfp-wrap .mfp-content .text02 {
  transform: scale(2);
  opacity: 0;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  transition: all 1s ease-out;
  transition-delay: 1.8s;
}

/* content animate it */
.mfp-award.mfp-wrap.mfp-ready .mfp-content img {
  transform: scale(1);
  opacity: 1;
}
.mfp-award.mfp-wrap.mfp-ready .mfp-content .glow1 {
  transform: scale(1);
}
.mfp-award.mfp-wrap.mfp-ready .mfp-content .glow2 {
  transform: scale(0);
}
.mfp-award.mfp-wrap.mfp-ready .mfp-content .glow-wrapper {
  opacity: 1;
}
.mfp-award.mfp-wrap.mfp-ready .mfp-content .text01,
.mfp-award.mfp-wrap.mfp-ready .mfp-content .text02 {
  opacity: 1;
  transform: scale(1);
}

/* content animate out */
.mfp-award.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.page-functions {
  position: absolute;
  right: 20px;
  top: 60px;
  z-index: 20;
}
.page-functions .page-functions-btn {
  text-decoration: none;
  color: #CED1D3;
  font-size: 30px;
  transition: all 0.1s ease-out;
  margin-left: 10px;
}

.last-check {
  position: absolute;
  color: #CED1D3;
  font-weight: 700;
  font-size: 14px;
  top: 57px;
  right: 110px;
  line-height: 18px;
  text-align: right;
  z-index: 20;
}
.last-check span {
  font-weight: 300;
  color: #263B47;
  display: block;
}

.mobile page-functions {
  display: none;
}
.mobile .last-check {
  right: auto;
  left: 20px;
  text-align: left;
}
.mobile .btn-mini {
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .page-functions,
.last-check {
    top: 10px;
  }
}
/*Trainingsplan--------------*/
.marker-own-training {
  font-family: "Open Sans";
  border-radius: 20px;
  display: inline-block;
  border: 1px solid rgb(0, 0, 0);
  font-size: 10px;
  color: rgb(0, 0, 0);
  padding: 2px 10px 0 10px;
  width: 60px;
  margin-bottom: -5px;
  margin-top: -5px;
  top: 4px;
  text-align: center;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  position: relative;
  height: 22px;
  transform: rotate(-5deg);
}

.test-popup {
  display: inline-block;
  position: absolute;
  z-index: 10;
  width: 316px;
  left: 50%;
  margin-left: -158px;
  text-align: center;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 10px;
  top: 50px;
  background: #FFFFFF;
}

.training-panel .test-popup {
  top: 40%;
}

.tp-day,
.diary-wrapper {
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  padding: 0;
  margin-bottom: 5px;
}
.tp-day a,
.diary-wrapper a {
  color: #29A9FC;
}
.tp-day .tp-day-header,
.diary-wrapper .tp-day-header {
  border-bottom: 1px solid #F0F2F4;
}
.tp-day .tp-day-header .tp-day-day,
.diary-wrapper .tp-day-header .tp-day-day {
  font-size: 20px;
  font-weight: 700;
  padding: 30px 0 30px 8px;
  text-align: center;
  width: 60px;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
}
.tp-day .tp-day-header .tp-day-title,
.diary-wrapper .tp-day-header .tp-day-title {
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  width: calc(100% - 160px);
  vertical-align: middle;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
}
.tp-day .tp-day-header .tp-day-date,
.diary-wrapper .tp-day-header .tp-day-date {
  font-size: 14px;
  display: inline-block;
  width: 85px;
  vertical-align: middle;
  text-align: center;
}
.tp-day .tp-exercises,
.diary-wrapper .tp-exercises {
  position: relative;
}
.tp-day .tp-exercises .tp-exercise-item,
.diary-wrapper .tp-exercises .tp-exercise-item {
  border-bottom: 1px solid #F0F2F4;
  padding: 8px 15px 9px 0;
  box-sizing: border-box;
  overflow: hidden;
}
.tp-day .tp-exercises .tp-exercise-item.detailed-trigger,
.diary-wrapper .tp-exercises .tp-exercise-item.detailed-trigger {
  padding-left: 20px;
}
.tp-day .tp-exercises .tp-exercise-item .tp-exercise-short,
.diary-wrapper .tp-exercises .tp-exercise-item .tp-exercise-short {
  padding-left: 15px;
}
.tp-day .tp-exercises .tp-exercise-item .tp-exercise-short a,
.diary-wrapper .tp-exercises .tp-exercise-item .tp-exercise-short a {
  color: #000000 !important;
  text-transform: none;
  margin-left: 10px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
}
.tp-day .tp-exercises .tp-exercise-item .tp-exercise-short a.btn,
.diary-wrapper .tp-exercises .tp-exercise-item .tp-exercise-short a.btn {
  background: #cdd0d2;
}
.tp-day .tp-exercises .tp-exercise-item .tp-exercise-detailed,
.diary-wrapper .tp-exercises .tp-exercise-item .tp-exercise-detailed {
  position: relative;
  padding-left: 40px;
}
.tp-day .tp-exercises .tp-exercise-item .tp-exercise-detailed .video-link,
.diary-wrapper .tp-exercises .tp-exercise-item .tp-exercise-detailed .video-link {
  margin-top: 7px;
}
.tp-day .tp-exercises .tp-exercise-item .tp-exercise-detailed:before,
.diary-wrapper .tp-exercises .tp-exercise-item .tp-exercise-detailed:before {
  content: "1";
  display: block;
  width: 25px;
  height: 30px;
  border-radius: 0 30px 30px 0;
  background: #F0F2F4;
  position: absolute;
  left: 5px;
  top: 50%;
  margin-top: -15px;
  text-align: center;
  color: #29A9FC;
  line-height: 30px;
  box-sizing: border-box;
  padding-right: 4px;
  transition: all 0.1s ease-out;
}
.tp-day .tp-exercises .tp-exercise-item .closed,
.diary-wrapper .tp-exercises .tp-exercise-item .closed {
  display: none;
}
.tp-day .tp-exercises .tp-exercise-item:nth-child(2) .tp-exercise-detailed:before,
.diary-wrapper .tp-exercises .tp-exercise-item:nth-child(2) .tp-exercise-detailed:before {
  content: "2";
}
.tp-day .tp-exercises .tp-exercise-item:nth-child(3) .tp-exercise-detailed:before,
.diary-wrapper .tp-exercises .tp-exercise-item:nth-child(3) .tp-exercise-detailed:before {
  content: "3";
}
.tp-day .tp-exercises .tp-exercise-item:nth-child(4) .tp-exercise-detailed:before,
.diary-wrapper .tp-exercises .tp-exercise-item:nth-child(4) .tp-exercise-detailed:before {
  content: "4";
}
.tp-day .tp-exercises .tp-exercise-item:nth-child(5) .tp-exercise-detailed:before,
.diary-wrapper .tp-exercises .tp-exercise-item:nth-child(5) .tp-exercise-detailed:before {
  content: "5";
}
.tp-day .tp-exercises .tp-exercise-item:nth-child(6) .tp-exercise-detailed:before,
.diary-wrapper .tp-exercises .tp-exercise-item:nth-child(6) .tp-exercise-detailed:before {
  content: "6";
}
.tp-day .tp-exercises .tp-exercise-item:nth-child(7) .tp-exercise-detailed:before,
.diary-wrapper .tp-exercises .tp-exercise-item:nth-child(7) .tp-exercise-detailed:before {
  content: "7";
}
.tp-day .tp-exercises .tp-exercise-item.tp-exercise-item-competition,
.diary-wrapper .tp-exercises .tp-exercise-item.tp-exercise-item-competition {
  position: relative;
  padding-left: 30px;
}
.tp-day .tp-exercises .tp-exercise-item.tp-exercise-item-competition .icon-trophy1,
.diary-wrapper .tp-exercises .tp-exercise-item.tp-exercise-item-competition .icon-trophy1 {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -15px;
  font-size: 18px;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 30px;
}
.tp-day .tp-exercises .tp-exercise-item.tp-exercise-item-competition .text-light,
.diary-wrapper .tp-exercises .tp-exercise-item.tp-exercise-item-competition .text-light {
  opacity: 0.5;
}
.tp-day .tp-exercises .tp-exercise-item.tp-exercise-item-competition .tp-exercise-item-competition-detail,
.diary-wrapper .tp-exercises .tp-exercise-item.tp-exercise-item-competition .tp-exercise-item-competition-detail {
  display: inline-block;
  padding-right: 10px;
}
.tp-day .tp-exercises .tp-exercise-item.tp-exercise-item-maincompetition .icon-trophy1,
.diary-wrapper .tp-exercises .tp-exercise-item.tp-exercise-item-maincompetition .icon-trophy1 {
  background: #263B47;
  color: #fff;
}
.tp-day .tp-exercises:before,
.diary-wrapper .tp-exercises:before {
  content: "";
  display: block;
  width: 5px;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}
.tp-day .tp-day-footer,
.diary-wrapper .tp-day-footer {
  padding: 10px;
}
.tp-day.intensity-1 a,
.tp-day.intensity-1 .tp-day-day,
.tp-day.intensity-1 .tp-day-date,
.tp-day.intensity-1 .tp-exercises .accordion-trigger,
.diary-wrapper.intensity-1 a,
.diary-wrapper.intensity-1 .tp-day-day,
.diary-wrapper.intensity-1 .tp-day-date,
.diary-wrapper.intensity-1 .tp-exercises .accordion-trigger {
  color: #29A9FC;
}
.tp-day.intensity-1 .tp-exercises:before,
.tp-day.intensity-1 .tp-day-footer .btn-default,
.diary-wrapper.intensity-1 .tp-exercises:before,
.diary-wrapper.intensity-1 .tp-day-footer .btn-default {
  background: #29A9FC;
}
.tp-day.intensity-2 a,
.tp-day.intensity-2 .tp-day-day,
.tp-day.intensity-2 .tp-day-date,
.tp-day.intensity-2 .tp-exercises .accordion-trigger,
.tp-day.intensity-2 .tp-exercise-detailed:before,
.diary-wrapper.intensity-2 a,
.diary-wrapper.intensity-2 .tp-day-day,
.diary-wrapper.intensity-2 .tp-day-date,
.diary-wrapper.intensity-2 .tp-exercises .accordion-trigger,
.diary-wrapper.intensity-2 .tp-exercise-detailed:before {
  color: #76B82A !important;
}
.tp-day.intensity-2 .tp-exercises:before,
.tp-day.intensity-2 .tp-day-footer .btn-default,
.diary-wrapper.intensity-2 .tp-exercises:before,
.diary-wrapper.intensity-2 .tp-day-footer .btn-default {
  background: #76B82A;
}
.tp-day.intensity-3 a,
.tp-day.intensity-3 .tp-day-day,
.tp-day.intensity-3 .tp-day-date,
.tp-day.intensity-3 .tp-exercises .accordion-trigger,
.tp-day.intensity-3 .tp-exercise-detailed:before,
.diary-wrapper.intensity-3 a,
.diary-wrapper.intensity-3 .tp-day-day,
.diary-wrapper.intensity-3 .tp-day-date,
.diary-wrapper.intensity-3 .tp-exercises .accordion-trigger,
.diary-wrapper.intensity-3 .tp-exercise-detailed:before {
  color: #ffa800 !important;
}
.tp-day.intensity-3 .tp-exercises:before,
.tp-day.intensity-3 .tp-day-footer .btn-default,
.diary-wrapper.intensity-3 .tp-exercises:before,
.diary-wrapper.intensity-3 .tp-day-footer .btn-default {
  background: #ffa800;
}
.tp-day.intensity-4 a,
.tp-day.intensity-4 .tp-day-day,
.tp-day.intensity-4 .tp-day-date,
.tp-day.intensity-4 .tp-exercises .accordion-trigger,
.tp-day.intensity-4 .tp-exercise-detailed:before,
.diary-wrapper.intensity-4 a,
.diary-wrapper.intensity-4 .tp-day-day,
.diary-wrapper.intensity-4 .tp-day-date,
.diary-wrapper.intensity-4 .tp-exercises .accordion-trigger,
.diary-wrapper.intensity-4 .tp-exercise-detailed:before {
  color: #FB2A1A !important;
}
.tp-day.intensity-4 .tp-exercises:before,
.tp-day.intensity-4 .tp-day-footer .btn-default,
.diary-wrapper.intensity-4 .tp-exercises:before,
.diary-wrapper.intensity-4 .tp-day-footer .btn-default {
  background: #FB2A1A;
}
.tp-day .tp-day-day-message-wrapper,
.diary-wrapper .tp-day-day-message-wrapper {
  display: block;
  position: absolute;
  /*background: rgba(240,242,244,0.95);*/
  background: #F0F2F4;
  z-index: 20;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  box-sizing: border-box;
  text-transform: uppercase;
  border: 1px solid #CED1D3;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  opacity: 0;
  transition: all 0s ease-out 0s;
}
.tp-day .tp-day-day-message-wrapper .btn-wrapper,
.diary-wrapper .tp-day-day-message-wrapper .btn-wrapper {
  position: absolute;
  bottom: 15px;
  width: 100%;
  opacity: 0;
  transform: scale(1);
  transition: all 1s ease-out 0.9s;
}
.tp-day .tp-day-day-message-wrapper .tp-day-message,
.diary-wrapper .tp-day-day-message-wrapper .tp-day-message {
  display: table;
  border: 3px solid #29A9FC;
  border-radius: 200px;
  position: absolute;
  background: #F0F2F4;
  width: 120px;
  height: 120px;
  left: 50%;
  margin-left: -60px;
  top: 50%;
  margin-top: -90px;
  text-align: center;
  color: #29A9FC;
  font-size: 12px;
  line-height: 14px;
  opacity: 0;
  transform: scale(5);
  transition: all 0.2s ease-out 0.5s;
}
.tp-day .tp-day-day-message-wrapper .tp-day-message > div,
.diary-wrapper .tp-day-day-message-wrapper .tp-day-message > div {
  display: table-row;
}
.tp-day .tp-day-day-message-wrapper .tp-day-message > div > div,
.diary-wrapper .tp-day-day-message-wrapper .tp-day-message > div > div {
  display: table-cell;
  vertical-align: middle;
  padding: 0 15px;
}
.tp-day .tp-day-day-message-wrapper .tp-day-message > div > div span,
.diary-wrapper .tp-day-day-message-wrapper .tp-day-message > div > div span {
  font-size: 45px;
  color: #29A9FC;
  display: block;
  padding-bottom: 10px;
}
.tp-day.tp-day-done,
.diary-wrapper.tp-day-done {
  box-shadow: none;
  min-height: 200px;
}
.tp-day.tp-day-done .tp-day-day,
.diary-wrapper.tp-day-done .tp-day-day {
  position: relative;
  z-index: 70;
}
.tp-day.tp-day-done footer .btn,
.diary-wrapper.tp-day-done footer .btn {
  opacity: 0;
}
.tp-day.tp-day-done .tp-day-day-message-wrapper,
.diary-wrapper.tp-day-done .tp-day-day-message-wrapper {
  height: 100%;
  opacity: 1;
}
.tp-day.tp-day-done .tp-day-day-message-wrapper .btn-wrapper,
.diary-wrapper.tp-day-done .tp-day-day-message-wrapper .btn-wrapper {
  opacity: 1;
  transform: scale(1);
}
.tp-day.tp-day-done .tp-day-day-message-wrapper .tp-day-message,
.diary-wrapper.tp-day-done .tp-day-day-message-wrapper .tp-day-message {
  opacity: 1;
  transform: scale(1);
}
.tp-day.tp-day-done .tp-day-day-message-wrapper .tp-day-message.tp-day-not-done,
.diary-wrapper.tp-day-done .tp-day-day-message-wrapper .tp-day-message.tp-day-not-done {
  border-color: #FB2A1A;
}
.tp-day.tp-day-done .tp-day-day-message-wrapper .tp-day-message.tp-day-not-done > div > div,
.diary-wrapper.tp-day-done .tp-day-day-message-wrapper .tp-day-message.tp-day-not-done > div > div {
  color: #FB2A1A;
}
.tp-day.tp-day-done .tp-day-day-message-wrapper .tp-day-message.tp-day-not-done > div > div span,
.diary-wrapper.tp-day-done .tp-day-day-message-wrapper .tp-day-message.tp-day-not-done > div > div span {
  color: #FB2A1A;
}

/*
.tp-day-empty {
	background: $color-gray-mid;
	@include border-radius;
	padding: 0;
	margin-bottom: 5px;
	vertical-align: middle;

	.tp-day-day {
		font-size: 20px;
		font-weight: 700;
		padding: 15px 0 15px 8px;
		text-align: center;
		width: 60px;
		display: inline-block;
		vertical-align: middle;
		box-sizing: border-box;
		color: #fff;
	}
	.btn-gray {
		background: $color-gray-light;
	}
}
*/
.tp-day-empty {
  padding: 0;
  border: 1px solid #CED1D3;
  vertical-align: middle;
  margin-bottom: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.tp-day-empty .tp-day-day {
  font-size: 20px;
  font-weight: 700;
  padding: 15px 0 15px 8px;
  text-align: center;
  width: 60px;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  color: #CED1D3;
}
.tp-stats {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  background: #CED1D3;
  border-radius: 5px;
  margin-bottom: 40px;
  padding-bottom: 10px;
}
.tp-stats:after {
  content: "";
  display: block;
  clear: both;
}
.tp-stats .tp-stats-item {
  text-align: left;
  display: inline-block;
  padding: 0 15px;
  margin-top: 15px;
  margin-bottom: 0;
  position: relative;
}
.tp-stats .tp-stats-item .owl-stage-outer {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.tp-stats .tp-stats-item .tp-stats-item-desc {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}
.tp-stats .tp-stats-item .tp-stats-item-value {
  font-weight: 300;
  font-size: 24px;
}
.tp-stats .tp-stats-item .tp-stats-item-text {
  font-size: 14px;
}
.tp-stats .tp-stats-item .tp-stats-item-text a {
  display: block;
  color: #263B47;
}
.tp-stats .tp-stats-item.tp-stats-item-medal {
  line-height: 14px;
  position: relative;
  padding-left: 70px;
}
.tp-stats .tp-stats-item.tp-stats-item-medal img {
  position: absolute;
  left: 15px;
  top: -5px;
}
.tp-stats .tp-stats-item:last-child {
  border: 0;
}
.tp-stats.tp-stats-dashboard {
  text-align: left;
}
.tp-stats .dashboard-stats-carousel {
  width: 920px;
}
.tp-stats .dashboard-stats-carousel .item {
  background: #CED1D3;
}
.tp-stats .dashboard-stats-carousel .item .tp-stats-fact {
  box-sizing: border-box;
  width: calc(25% - 15px);
  float: left;
  border-right: 1px solid #F0F2F4;
  padding-right: 15px;
  margin-right: 15px;
  line-height: 22px;
}
.tp-stats .dashboard-stats-carousel .item .tp-stats-fact:last-child {
  border: 0;
}
.tp-stats .dashboard-stats-carousel .item .tp-stats-header {
  padding-bottom: 10px;
  color: #F0F2F4;
  text-transform: uppercase;
}
.tp-stats .dashboard-stats-carousel .item:after {
  content: "";
  display: block;
  clear: both;
}
.tp-stats .dashboard-stats-carousel .tp-stats-awards .btn {
  display: inline-block;
  color: #000;
}
.tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award {
  line-height: 18px;
  width: calc(33% - 15px);
  float: left;
  border-right: 1px solid #F0F2F4;
  padding-right: 15px;
  margin-right: 15px;
  box-sizing: border-box;
  height: 44px;
  margin-bottom: 10px;
}
.tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award img {
  height: 65px !important;
  width: auto !important;
  float: left;
  margin-right: 10px;
  margin-top: -10px;
}
.tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award .tp-stats-item-text {
  display: table-cell;
  height: 45px;
  vertical-align: middle;
}
.tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award:last-child {
  border: 0;
}
.tp-stats .owl-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  top: auto;
  margin-top: 0;
}

.tp-stats-header {
  color: #CED1D3;
  transform: translateX(30px);
  transition: all 1s ease-out;
}

.active .tp-stats-header {
  color: #F0F2F4 !important;
  transform: translateX(0);
}

@media only screen and (max-width: 1450px) {
  .tp-stats .dashboard-stats-carousel {
    width: 730px;
  }
}
@media only screen and (max-width: 1200px) {
  .tp-stats .dashboard-stats-carousel {
    width: 630px;
  }
}
@media only screen and (max-width: 992px), print {
  .tp-stats .dashboard-stats-carousel {
    width: 560px;
    left: -5px;
  }
  .tp-stats .dashboard-stats-carousel .item .tp-stats-fact,
.tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award {
    width: 50%;
    padding: 10px 15px;
    margin-right: 0;
    height: 65px;
  }
  .tp-stats .dashboard-stats-carousel .item .tp-stats-fact:nth-child(3), .tp-stats .dashboard-stats-carousel .item .tp-stats-fact:nth-child(2),
.tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award:nth-child(3),
.tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award:nth-child(2) {
    border-bottom: 1px solid #F0F2F4;
  }
  .tp-stats .dashboard-stats-carousel .item .tp-stats-fact:nth-child(3),
.tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award:nth-child(3) {
    border-right: 0;
  }
  .tp-stats .dashboard-stats-carousel .item .tp-stats-fact:nth-child(4),
.tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award:nth-child(4) {
    border-right: 1px solid #F0F2F4;
  }
  .tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award {
    margin-bottom: 0 !important;
  }
}
@media only screen and (max-width: 768px) {
  .tp-stats {
    margin-bottom: 50px;
    position: relative;
  }
  .tp-stats .dashboard-stats-carousel {
    width: calc(100% - 10px);
    left: 5px;
  }
  .tp-stats .dashboard-stats-carousel .item .tp-stats-fact,
.tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award {
    text-align: center;
    width: 100%;
    padding: 10px 5px 5px 5px;
    margin-right: 0;
    border: 0 !important;
    border-bottom: 1px solid #F0F2F4 !important;
  }
  .tp-stats .dashboard-stats-carousel .item .tp-stats-fact img,
.tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award img {
    margin-top: -22px !important;
    margin-bottom: -5px !important;
  }
  .tp-stats .dashboard-stats-carousel .item .tp-stats-fact:last-child,
.tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award:last-child {
    border: 0 !important;
  }
  .tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award {
    height: auto;
    margin-bottom: 10px !important;
  }
  .tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award img {
    float: none;
    margin: 0 auto 3px auto;
    margin-bottom: 1px !important;
    position: relative;
    top: 3px;
  }
  .tp-stats .dashboard-stats-carousel .tp-stats-awards .tp-stats-award .tp-stats-item-text {
    height: auto;
    display: block;
  }
  .tp-stats .dashboard-stats-carousel .tp-stats-header {
    text-align: center;
    padding-top: 10px;
  }
  .tp-stats .tp-stats-item {
    display: block;
  }
}
.tp-week-nav {
  padding-bottom: 10px;
}
.tp-week-nav a {
  display: inline-block;
  color: #CED1D3;
  position: relative;
  font-size: 36px;
  font-weight: 300;
  text-decoration: none;
  padding: 15px 0 45px 0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  transition: all 0.1s ease-out;
  vertical-align: top;
  line-height: 30px;
  width: 92px;
}
.tp-week-nav a .tp-week-item-kw {
  display: block;
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  right: 6px;
  top: 7px;
  transition: all 0.1s ease-out;
}
.tp-week-nav a .tp-week-item-point {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 10px;
  margin-left: -9px;
  display: block;
  border-radius: 30px;
  width: 16px;
  height: 16px;
  border: 2px solid #CED1D3;
  transition: all 0.1s ease-out;
}
.tp-week-nav a .tp-week-item-point.intensity-1 {
  border: 2px solid #29A9FC;
}
.tp-week-nav a .tp-week-item-point.intensity-2 {
  border: 2px solid #76B82A;
}
.tp-week-nav a .tp-week-item-point.intensity-3 {
  border: 2px solid #ffa800;
}
.tp-week-nav a .tp-week-item-point.intensity-4 {
  border: 2px solid #FB2A1A;
}
.tp-week-nav a:after {
  content: "";
  display: block;
  height: 2px;
  background: #CED1D3;
  width: 62px;
  position: absolute;
  bottom: 19px;
  left: 40%;
  margin-left: 27px;
  z-index: 10;
}
.tp-week-nav a:last-child:after {
  display: none;
}
.tp-week-nav a.tagebuch-link {
  height: 36px;
}
.tp-week-nav a.tp-week-link {
  height: 36px;
}
.tp-week-nav a.active {
  color: #263B47;
}
.tp-week-nav a.active .tp-week-item-point.intensity-1 {
  background: #29A9FC;
}
.tp-week-nav a.active .tp-week-item-point.intensity-2 {
  background: #76B82A;
}
.tp-week-nav a.active .tp-week-item-point.intensity-3 {
  background: #ffa800;
}
.tp-week-nav a.active .tp-week-item-point.intensity-4 {
  background: #FB2A1A;
}
.tp-week-nav a.active:before {
  content: "";
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  border: 8px solid transparent;
  border-bottom: 8px solid #CED1D3;
  left: 50%;
  margin-left: -8px;
  bottom: -13px;
}

.user-infos .user-img .user-img-wrapper {
  position: relative;
  top: 60px;
  width: 120px;
  left: -10px;
}
.user-infos .user-img .user-img-wrapper .btn {
  position: absolute;
  bottom: 7px;
  right: 0;
  z-index: 2;
}
@media print {
  .user-infos .user-img .user-img-wrapper .btn {
    visibility: hidden;
  }
}
.user-infos .user-img .user-img-wrapper .circular--landscape {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
  overflow: hidden;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.user-infos .user-img .user-img-wrapper .circular--landscape img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}
.user-infos .user-img .user-img-wrapper .btn {
  line-height: normal;
}
.user-infos .user-img .user-img-wrapper .btn .icon-gear3 {
  font-size: 29px;
}
.user-infos .user-img .user-img-wrapper .btn:hover {
  transform: none;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
.user-infos h1 {
  padding-bottom: 20px;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 992px) {
  .user-infos .user-img .user-img-wrapper {
    top: 40px;
    width: 120px;
    left: 0;
    display: inline-block;
  }
  .user-infos .user-img .user-img-wrapper .btn {
    position: absolute;
    bottom: 7px;
    right: 0;
  }
  .user-infos .user-img .user-img-wrapper img {
    border-radius: 200px;
    width: 120px !important;
    height: 120px !important;
  }
  .user-infos h1 {
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .tp-week-nav {
    padding-bottom: 10px;
  }
  .tp-week-nav a {
    display: inline-block;
    color: #CED1D3;
    position: relative;
    font-size: 36px;
    font-weight: 300;
    text-decoration: none;
    padding: 15px 0 45px 0;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    transition: all 0.1s ease-out;
    vertical-align: top;
    line-height: 30px;
    width: 65px;
  }
  .tp-week-nav a .tp-week-item-kw {
    display: none;
  }
  .tp-week-nav a:after {
    width: 30px;
  }
  .tp-day,
.tp-day-empty {
    margin-bottom: 15px;
  }
}
.desktop .tp-week-nav a:hover {
  background: #fff;
  color: #263B47;
}
.desktop .tp-week-nav a:hover .tp-week-item-point {
  transform: scale(1.2);
}
.diary-wrapper {
  box-shadow: 0 0 0 10px #263B47;
  background: #fffdde;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#29a9fc",endColorstr="#fb2a1a",GradientType=1);
}
.diary-wrapper .tp-day {
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
}
.diary-wrapper .tp-day strong {
  color: #263B47 !important;
}
.diary-wrapper .week-ribbon {
  overflow: hidden;
  background: #FB2A1A;
  color: #FB2A1A;
  font-weight: 700;
  font-size: 14px;
  position: absolute;
  right: 30px;
  top: -12px;
  padding: 3px 10px 27px 10px;
  text-align: center;
  line-height: 21px;
}
.diary-wrapper .week-ribbon > div {
  position: relative;
  z-index: 10;
}
.diary-wrapper .week-ribbon:after {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  transform: rotate(45deg);
  position: absolute;
  left: 50%;
  margin-left: -50px;
  background: #fffdde;
  box-sizing: border-box;
  bottom: -92px;
  border: 2px solid #FB2A1A;
}
.diary-wrapper .week-ribbon:before {
  content: "";
  display: block;
  width: calc(100% - 4px);
  height: 100px;
  position: absolute;
  left: 2px;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}
.diary-wrapper .week-ribbon .week-number {
  font-size: 30px;
  font-weight: 300;
  color: #fff;
}
.diary-wrapper header {
  border-top: 1px solid #263B47;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.diary-wrapper header .tp-day-day {
  width: auto !important;
  text-align: center !important;
  padding-left: 18px !important;
}
.diary-wrapper header .tp-day-day > div {
  font-weight: 300;
  display: inline-block;
}
.diary-wrapper header:nth-of-type(1) {
  border-top: 0;
}
.diary-wrapper .diary-exercises {
  font-size: 14px;
}
.diary-wrapper .diary-exercises strong {
  font-family: "Open sans", sans-serif;
  color: rgba(0, 0, 0, 0.3);
}
.diary-wrapper .diary-exercises .diary-exercise-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px 15px 4px 20px;
  box-sizing: border-box;
  font-family: "Coming Soon";
}
.diary-wrapper .diary-exercises .diary-exercise-item:after {
  content: "";
  display: block;
  clear: both;
}
.diary-wrapper .diary-exercises .diary-exercise-item .btn-group-row-edit {
  margin-bottom: -10px;
}
.diary-wrapper .diary-exercises .diary-exercise-item > div {
  padding-bottom: 5px;
}
.diary-wrapper .diary-exercises .diary-exercise-item.tp-exercise-item-competition {
  position: relative;
  padding-left: 50px;
}
.diary-wrapper .diary-exercises .diary-exercise-item.tp-exercise-item-competition .icon-trophy1 {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -18px;
  font-size: 18px;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 30px;
}
.diary-wrapper .diary-exercises .diary-exercise-item.tp-exercise-item-competition .text-light {
  opacity: 0.5;
}
.diary-wrapper .diary-exercises .diary-exercise-item.tp-exercise-item-competition .tp-exercise-item-competition-detail {
  display: inline-block;
  padding-right: 10px;
}
.diary-wrapper .diary-exercises .diary-exercise-item.tp-exercise-item-maincompetition .icon-trophy1 {
  background: #263B47;
  color: #fffdde;
}
.diary-wrapper .diary-exercises .diary-exercise-item span a.btn {
  display: inline-block;
  margin: 0 5px;
  background: rgba(0, 0, 0, 0.1);
  color: #263B47;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  font-family: "Arial", sans-serif;
  text-transform: none;
}
.diary-wrapper .diary-item {
  font-family: "Coming Soon";
}
.diary-wrapper .diary-exercise-planed {
  background: #fff;
  box-sizing: border-box;
}
.diary-wrapper .diary-exercise-planed .diary-exercise-header {
  padding: 3px 10px;
  text-align: center;
  color: #fff;
  margin-bottom: 3px;
}
.diary-wrapper .diary-exercise-planed strong {
  color: #000;
}
.diary-wrapper .diary-exercise-planed .diary-exercise-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-family: "Open sans", sans-serif;
  color: #263B47;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
}
.diary-wrapper .diary-exercise-planed .diary-exercise-item a.btn {
  display: inline-block;
  background: rgba(0, 0, 0, 0.1);
  color: #263B47;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  font-family: Arial;
  text-transform: none;
}
.diary-wrapper .diary-exercise-planed .diary-exercise-item a.open-popup-video {
  display: inline;
}
.diary-wrapper .effort-scale {
  height: 6px;
  border-radius: 10px;
  margin-top: 5px;
  position: relative;
  width: 100px;
  display: inline-block;
  background: -moz-linear-gradient(left, rgb(41, 169, 252) 20%, rgb(90, 170, 49) 30%, rgb(90, 170, 49) 45%, rgb(255, 168, 0) 55%, rgb(255, 168, 0) 70%, rgb(251, 42, 26) 80%);
  background: -webkit-linear-gradient(left, rgb(41, 169, 252) 20%, rgb(90, 170, 49) 30%, rgb(90, 170, 49) 45%, rgb(255, 168, 0) 55%, rgb(255, 168, 0) 70%, rgb(251, 42, 26) 80%);
  background: linear-gradient(to right, rgb(41, 169, 252) 20%, rgb(90, 170, 49) 30%, rgb(90, 170, 49) 45%, rgb(255, 168, 0) 55%, rgb(255, 168, 0) 70%, rgb(251, 42, 26) 80%);
}
.diary-wrapper .effort-scale .effort-scale-marker {
  width: 2px;
  height: 14px;
  position: absolute;
  top: -4px;
  background: #263B47;
  /*
  width: 30px;
   	background: rgba(255,255,255,0.33);
   	height: 10px;
   	margin-left: -15px;
   	position: absolute;
   	top: -3px;
   	*/
  /*
  &:before {
  	content: "";
  	clear: both;
  	width: 16px;
  	height: 10px;
  	position: absolute;
  	top: 0;
  	left: 7px;
  	background: rgba(255,255,255,0.6);
  }
  &:after {
  	content: "";
  	clear: both;
  	width: 2px;
  	height: 14px;
  	position: absolute;
  	top: -5px;
  	left: 11px;
  	background: $color-gray-dark;
  	border: 3px solid #fff;
  }
  */
}

.diary-exercise-planed.intensity-1 .diary-exercise-header {
  background: #29A9FC;
}

.diary-exercise-planed.intensity-2 .diary-exercise-header {
  background: #76B82A;
}

.diary-exercise-planed.intensity-3 .diary-exercise-header {
  background: #ffa800;
}

.diary-exercise-planed.intensity-4 .diary-exercise-header {
  background: #FB2A1A;
}

.diary-carousel {
  position: relative;
}
.diary-carousel .owl-nav:after {
  content: "";
  display: block;
  clear: both;
}
.diary-carousel .owl-nav .disabled {
  display: none;
}
.diary-carousel .owl-nav .owl-next {
  background: none;
  padding: 0;
  margin: 0;
  float: right;
}
.diary-carousel .owl-nav .owl-next .btn {
  background: rgba(0, 0, 0, 0.1);
  color: #263B47;
  position: relative;
  top: -5px;
  right: -2px;
  border-radius: 30px 0 0 30px;
}
.diary-carousel .owl-nav .owl-prev {
  background: none;
  padding: 0;
  margin: 0;
  float: left;
}
.diary-carousel .owl-nav .owl-prev .btn {
  background: rgba(0, 0, 0, 0.1);
  color: #263B47;
  position: relative;
  top: -5px;
  left: -2px;
  border-radius: 0 30px 30px 0;
}

@media only screen and (min-width: 992px) {
  .diary-wrapper .diary-item {
    width: 500px;
  }
  .diary-wrapper .diary-exercise-planed {
    box-sizing: border-box;
    width: 280px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    transform: rotate(3deg);
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
  }
  .diary-wrapper .diary-exercise-planed .diary-exercise-item {
    font-size: 12px;
    padding: 5px 10px;
    line-height: 16px;
  }
  .diary-wrapper .diary-exercise-planed .diary-exercise-header {
    padding: 3px 10px;
    text-align: center;
    color: #fff;
    margin-bottom: 3px;
    display: block;
  }
  .trainingsplan-wrapper .ac-header .col-md-10 {
    float: inherit;
  }
}
@media only screen and (min-width: 1200px) {
  .diary-wrapper .diary-item {
    width: 600px;
  }
  .diary-wrapper .diary-exercise-planed {
    width: 280px;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 1450px) {
  .diary-wrapper .diary-item {
    width: 800px;
  }
  .diary-wrapper .diary-exercise-planed {
    width: 300px;
    margin-left: 20px;
  }
}
.message-upgrade {
  position: fixed;
  bottom: 0;
  z-index: 20;
  background: #263B47;
  width: 1146px;
  border-radius: 5px 5px 0 0;
  padding: 10px;
  box-sizing: border-box;
}
.message-upgrade .message-upgrade-p {
  color: #fff;
  padding-bottom: 10px;
  text-align: center;
}
.message-upgrade .message-upgrade-a {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1450px) {
  .message-upgrade {
    width: 906px;
  }
}
@media only screen and (max-width: 1200px) {
  .message-upgrade {
    width: 796px;
  }
}
@media only screen and (max-width: 992px) {
  .message-upgrade {
    width: 576px;
  }
}
@media only screen and (max-width: 768px) {
  .message-upgrade {
    width: 100%;
    left: 0;
    border-radius: 0;
  }
}

.message-write {
  position: fixed;
  bottom: 0;
  z-index: 20;
  background: #263B47;
  width: 1146px;
  border-radius: 5px 5px 0 0;
  padding: 10px;
  box-sizing: border-box;
}
.message-write .input-file-upload {
  float: left;
}
.message-write .input-file-upload label {
  box-sizing: border-box;
  max-width: 300px;
}
.message-write textarea#message-input {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 0;
  color: #263B47;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 20px;
  background: #fff;
  width: calc(100% - 20px);
  overflow: hidden;
}
@media only screen and (max-width: 1450px) {
  .message-write {
    width: 906px;
  }
}
@media only screen and (max-width: 1200px) {
  .message-write {
    width: 796px;
  }
}
@media only screen and (max-width: 992px) {
  .message-write {
    width: 576px;
  }
}
@media only screen and (max-width: 768px) {
  .message-write {
    width: 100%;
    left: 0;
    border-radius: 0;
  }
}

.message-docs-summary {
  background: #263B47;
  color: #fff;
  position: fixed;
  top: 0;
  right: -1px;
  height: 100%;
  z-index: 20;
  width: 0;
  transition: all 0.1s ease-out;
  border-left: 1px solid #F0F2F4;
}
.message-docs-summary .message-docs-summary-content {
  width: 280px;
  margin: 20px auto;
  max-height: calc(100% - 40px);
  overflow: auto;
}
.message-docs-summary .message-docs-summary-content a {
  display: block;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 5px 0;
  width: 280px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.message-docs-summary .message-docs-summary-trigger {
  background: #263B47;
  color: #29A9FC;
  width: 40px;
  height: 40px;
  position: absolute;
  left: -40px;
  top: 50%;
  margin-top: -20px;
  text-align: center;
  line-height: 40px;
  font-size: 24px;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
}
.message-docs-summary .message-docs-summary-trigger > div {
  width: 32px;
  position: absolute;
  left: 50%;
  margin-left: -16px;
  height: 8px;
  margin-top: -4px;
  top: 50%;
  transition: all 1s ease-out;
}
.message-docs-summary .message-docs-summary-trigger > div:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-right: 4px solid #fff;
  position: absolute;
  left: -4px;
  top: 0;
}
.message-docs-summary.open {
  width: 320px;
}
.message-docs-summary.open .message-docs-summary-trigger > div {
  transform: rotate(-180deg);
}
.message-docs-summary a[href$=".pdf"]:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea21";
  position: relative;
  display: inline-block;
  padding-right: 5px;
}
.message-docs-summary a[href$=".jpg"]:before,
.message-docs-summary a[href$=".gif"]:before,
.message-docs-summary a[href$=".png"]:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea98";
  position: relative;
  display: inline-block;
  padding-right: 5px;
}
.message-docs-summary a.coach-upload {
  color: #29A9FC;
}
.message-docs-summary .message-docs-summary-phonetrigger {
  display: none;
}

@media only screen and (max-width: 420px) {
  .message-docs-summary {
    border: 0;
  }
  .message-docs-summary .message-docs-summary-trigger {
    top: 76px;
  }
  .message-docs-summary.open {
    width: 100%;
  }
  .message-docs-summary.open .message-docs-summary-phonetrigger {
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 24px;
  }
}
.messages-wrapper {
  padding: 0 10px 160px 10px;
}
.messages-wrapper .message-user,
.messages-wrapper .message-coach {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
  width: calc(100% - 190px);
  box-sizing: border-box;
}
.messages-wrapper .message-user .message-date,
.messages-wrapper .message-coach .message-date {
  font-size: 12px;
  opacity: 0.5;
}
.messages-wrapper .message-user img,
.messages-wrapper .message-coach img {
  width: 70px !important;
  height: 70px !important;
  border-radius: 200px;
  position: absolute;
  top: 50%;
  margin-top: -35px;
}
.messages-wrapper .message-user .message-docs,
.messages-wrapper .message-coach .message-docs {
  border-top: 1px dashed rgba(255, 255, 255, 0.2);
  margin: 15px -15px -7px;
  padding: 7px 15px 0 15px;
}
.messages-wrapper .message-user .message-docs a[href$=".pdf"] button:before,
.messages-wrapper .message-coach .message-docs a[href$=".pdf"] button:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea21";
  position: relative;
  left: -6px;
}
.messages-wrapper .message-user .message-docs a[href$=".jpg"] button:before,
.messages-wrapper .message-user .message-docs a[href$=".gif"] button:before,
.messages-wrapper .message-user .message-docs a[href$=".png"] button:before,
.messages-wrapper .message-coach .message-docs a[href$=".jpg"] button:before,
.messages-wrapper .message-coach .message-docs a[href$=".gif"] button:before,
.messages-wrapper .message-coach .message-docs a[href$=".png"] button:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea98";
  position: relative;
  left: -6px;
}
.messages-wrapper .message-coach {
  background: #CED1D3;
  margin-left: 85px;
}
.messages-wrapper .message-coach:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 10px solid #CED1D3;
  position: absolute;
  top: 50%;
  left: -10px;
}
.messages-wrapper .message-coach img {
  left: -85px;
}
.messages-wrapper .message-user {
  background: #29A9FC;
  color: #fff;
  margin-left: 105px;
}
.messages-wrapper .message-user img {
  right: -85px;
}
.messages-wrapper .message-user:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 10px solid #29A9FC;
  position: absolute;
  top: 50%;
  right: -10px;
}

@media only screen and (max-width: 768px) {
  .messages-wrapper .message-user,
.messages-wrapper .message-coach {
    width: calc(100% - 20px);
    box-sizing: border-box;
  }
  .messages-wrapper .message-user img,
.messages-wrapper .message-coach img {
    display: none;
  }
  .messages-wrapper .message-coach {
    margin-left: 0;
  }
  .messages-wrapper .message-user {
    margin-left: 20px;
  }
}
.ac-header {
  padding-top: 20px;
}

.ac-plan table {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
}
.ac-plan table tr {
  border-bottom: 1px solid #F0F2F4;
}
.ac-plan table tr td {
  padding: 8px 10px;
  line-height: 21px;
}
.ac-plan table tr td.ac-aim-text {
  word-break: break-word;
}
.ac-plan table tr:first-child {
  border-top: 1px solid #F0F2F4;
}
.ac-plan .ac-date {
  width: 110px;
  font-size: 14px;
  color: #CED1D3;
  vertical-align: middle;
}
.ac-plan .ac-date span {
  color: #263B47;
  font-size: 18px;
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  top: 2px;
  margin-right: 3px;
}
.ac-plan .ac-date .ac-legend {
  display: inline-block;
}
.ac-plan .ac-main-competition td.ac-date span {
  background: #263B47;
  border-radius: 30px;
  color: #fff;
}
.ac-plan .ac-legend {
  color: #CED1D3;
  font-weight: 700;
  font-size: 14px;
}
.ac-plan .ac-competition-distance,
.ac-plan .ac-competition-time1 {
  width: 70px;
}
.ac-plan .ac-competition-time2 {
  width: 112px;
}
.ac-plan .ac-buttons {
  width: 55px;
  text-align: right;
  vertical-align: middle;
  padding-left: 0;
}
.ac-plan table.ac-done-wrapper {
  position: relative;
  background: #263B47;
}
.ac-plan table.ac-done-wrapper .icon-check.hidden {
  display: none;
}
.ac-plan table.ac-done-wrapper:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 10px solid #fff;
  position: absolute;
  left: 50%;
  margin-left: -5px;
}
.ac-plan table.ac-done-wrapper tr td {
  background: #263B47;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.ac-plan table.ac-done-wrapper .ac-date span {
  color: #fff;
}
.ac-plan table.ac-done-wrapper .ac-competition-time1 {
  opacity: 0.3;
}
.ac-plan table.ac-done-wrapper .ac-main-competition td.ac-date span {
  background: #fff;
  color: #263B47;
}
.ac-plan .custom-radio label {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px 10px 10px 35px !important;
  line-height: 20px;
  font-size: 16px;
  transition: all 0.1s ease-out;
}
.ac-plan .custom-radio label:after, .ac-plan .custom-radio label:before {
  display: none;
}
.ac-plan .custom-radio label:before {
  display: block;
  color: #29A9FC;
  background: #263B47;
  border: 1px solid rgba(255, 255, 255, 0.1);
  top: 50%;
  margin: 0;
  left: 7px;
  margin-left: 0;
  margin-top: -11px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.ac-plan .custom-radio label.checked {
  border: 1px solid #fff;
}
.ac-plan .custom-radio label.checked:before {
  display: block;
  content: "\edfb";
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #29A9FC;
  background: #fff;
  border: 1px solid #fff;
  top: 50%;
  margin: 0;
  left: 7px;
  margin-top: -11px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.ac-plan .custom-radio label:after {
  height: 0;
  background: none;
  clear: both;
  display: block;
  position: relative;
}
.ac-plan .custom-radio label .ac-plan-time {
  float: left;
  text-transform: uppercase;
}
.ac-plan .custom-radio label .ac-plan-time span.ac-plan-time_mini {
  font-size: 12px;
  display: block;
}
.ac-plan .custom-radio label .ac-plan-price {
  float: right;
  text-align: right;
}
.ac-plan .custom-radio label .ac-plan-price span {
  font-size: 12px;
  display: block;
}
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-basic,
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-premium,
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-standard {
  display: none;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  color: #29A9FC;
  background: #fff;
  margin-top: -5px;
  margin-bottom: 5px;
  border-radius: 0 0 5px 5px;
}
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-basic > div,
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-premium > div,
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-standard > div {
  float: left;
  width: 50%;
  position: relative;
  font-size: 12px;
  color: #263B47;
  font-weight: 700;
}
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-basic input,
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-premium input,
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-standard input {
  border: 0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 5px;
  width: calc(100% - 4px);
  box-sizing: border-box;
  text-align: center;
  background: #263B47;
  color: #fff;
  margin: 2px 2px 0 2px;
  font-size: 16px;
}
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-basic:after,
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-premium:after,
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-standard:after {
  content: "";
  display: block;
  clear: both;
}
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-basic.show,
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-premium.show,
.ac-plan .flexible-laufzeit .flex-laufzeit-input-wrapper-standard.show {
  display: block;
}
.ac-plan .flexible-laufzeit .custom-radio label.checked {
  border-radius: 5px 5px 0 0;
}
.ac-plan .flexible-laufzeit .flex-hide {
  display: none;
}

@media only screen and (max-width: 992px) {
  .ac-plan table {
    display: block;
    padding: 0;
    width: calc(100% + 30px);
  }
  .ac-plan table tr {
    display: block;
    padding: 15px 5px;
    position: relative;
    border-bottom: 1px solid #F0F2F4;
    /*
    ac-date
    ac-aim-text
    ac-competition-name
    ac-competition-distance
    ac-competition-time1
    ac-competition-time2
    ac-buttons
    */
  }
  .ac-plan table tr td {
    display: block;
    border: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
  }
  .ac-plan table tr td.ac-date {
    position: relative;
    left: -5px;
  }
  .ac-plan table tr td.ac-competition-distance, .ac-plan table tr td.ac-competition-time1, .ac-plan table tr td.ac-competition-time2 {
    width: auto;
    display: inline-block;
  }
  .ac-plan table tr td.ac-competition-name {
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 20px;
  }
  .ac-plan table tr td.ac-competition-name .ac-legend {
    display: none;
  }
  .ac-plan table tr td.ac-buttons {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .ac-plan table.ac-done-wrapper tr {
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}
#popup-ac-competition-edit div[data-source=common] {
  padding: 0;
  top: -10px;
  background: none;
  color: #FFFFFF;
  border-bottom: 1px solid #FF0000;
  display: inline-block;
  width: auto;
}

#add_new_marathon {
  width: 100%;
}
#add_new_marathon input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
#add_new_marathon div.mage-error {
  font-size: 14px;
  position: relative;
  top: -10px;
}

.popup-edit, .popup-dialog {
  max-width: 400px;
}
.popup-edit .mage-error, .popup-dialog .mage-error {
  color: #FB2A1A;
  font-weight: 400;
  background: #FFFFFF;
  padding: 3px 5px;
  width: 100%;
  top: -5px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.popup-edit.registration-step .messages, .popup-dialog.registration-step .messages {
  font-size: 12px;
  line-height: normal;
  font-weight: 600;
  letter-spacing: 0.2px;
}
.popup-edit.registration-step .mage-error, .popup-dialog.registration-step .mage-error {
  top: 0;
  margin-bottom: 0;
}
.popup-edit .popup-text, .popup-dialog .popup-text {
  padding: 0 15px;
}
.popup-edit .add-information, .popup-dialog .add-information {
  color: #263B47;
}
.popup-edit .add-information span, .popup-dialog .add-information span {
  font-size: 70px;
  opacity: 0.2;
  display: inline-block;
  padding: 10px 0;
}
.popup-edit input[type=text].error, .popup-dialog input[type=text].error {
  border: 2px solid #FB2A1A;
}
.popup-edit .scroll-wrapper, .popup-dialog .scroll-wrapper {
  overflow: scroll;
  display: block;
  max-height: 100%;
  width: 100%;
  text-align: left;
  font-size: 16px;
  padding-left: 10px;
}
.popup-edit .scroll-wrapper div, .popup-dialog .scroll-wrapper div {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 2px 0;
}
.popup-edit#popup-weight-history .scroll-wrapper, .popup-dialog#popup-weight-history .scroll-wrapper {
  overflow: auto;
}
.popup-edit section, .popup-dialog section {
  background: #29A9FC;
  color: #fff;
  padding: 20px 10px;
  height: 550px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 400px;
  box-sizing: border-box;
}
.popup-edit section .wrapper, .popup-dialog section .wrapper {
  flex: 0 0 auto;
  margin: 5px;
}
.popup-edit section .strecke-alt, .popup-dialog section .strecke-alt {
  display: none;
  width: 100%;
}
.popup-edit section .strecke-alt input, .popup-dialog section .strecke-alt input {
  box-sizing: border-box;
}
.popup-edit section .captcha-image, .popup-dialog section .captcha-image {
  margin-top: 15px;
}
.popup-edit section .captcha-image img, .popup-dialog section .captcha-image img {
  height: 45px;
}
.popup-edit section .captcha-image img, .popup-edit section .captcha-image button, .popup-dialog section .captcha-image img, .popup-dialog section .captcha-image button {
  vertical-align: middle;
  display: inline-block;
}
.popup-edit footer, .popup-dialog footer {
  background: #29A9FC;
  border-radius: 0 0 5px 5px;
  height: 35px;
}
.popup-edit footer .btn, .popup-dialog footer .btn {
  opacity: 1;
  width: auto;
  position: relative;
  top: auto;
  right: auto;
}
.popup-edit#popup-start header, .popup-dialog#popup-start header {
  background: #29A9FC;
}
.popup-edit .popup-edit-progress, .popup-dialog .popup-edit-progress {
  width: calc(100% - 20px);
  height: 10px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  margin-bottom: 10px;
  position: absolute;
  top: -5px;
}
.popup-edit .popup-edit-progress .popup-edit-progress-bar, .popup-dialog .popup-edit-progress .popup-edit-progress-bar {
  position: absolute;
  border-radius: 20px;
  background: #fff;
  height: 10px;
  left: 0;
  top: 0;
}
.popup-edit .popup-edit-progress .popup-edit-progress-count, .popup-dialog .popup-edit-progress .popup-edit-progress-count {
  position: absolute;
  width: 100%;
  margin-top: -25px;
  color: rgba(255, 255, 255, 0.3);
}
.popup-edit .popup-edit-new-suggestion, .popup-dialog .popup-edit-new-suggestion {
  background: rgba(255, 255, 255, 0.3);
  padding: 20px 10px 10px 10px;
  width: 100%;
  border-radius: 5px;
}
.popup-edit .popup-edit-new-suggestion h4, .popup-dialog .popup-edit-new-suggestion h4 {
  color: #29A9FC;
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 20px;
}
.popup-edit h4, .popup-dialog h4 {
  font-size: 24px;
  padding-bottom: 10px;
  line-height: 30px;
  font-weight: 300;
  width: 100%;
}
.popup-edit textarea,
.popup-edit input[type=text],
.popup-edit input[type=email],
.popup-edit input[type=password],
.popup-edit input[type=date],
.popup-edit input[type=number],
.popup-edit select, .popup-dialog textarea,
.popup-dialog input[type=text],
.popup-dialog input[type=email],
.popup-dialog input[type=password],
.popup-dialog input[type=date],
.popup-dialog input[type=number],
.popup-dialog select {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  background: #fff;
  width: 100%;
  border: 0;
  padding: 10px;
  font-size: 16px;
  color: #263B47;
  font-family: "Arial", "Open Sans", sans-serif;
}
.popup-edit input[type=text],
.popup-edit input[type=email],
.popup-edit input[type=password],
.popup-edit input[type=date],
.popup-edit input[type=number],
.popup-edit textarea, .popup-dialog input[type=text],
.popup-dialog input[type=email],
.popup-dialog input[type=password],
.popup-dialog input[type=date],
.popup-dialog input[type=number],
.popup-dialog textarea {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
}
.popup-edit label, .popup-dialog label {
  padding-top: 10px;
  padding-bottom: 2px;
  display: block;
  width: 100%;
}
.popup-edit textarea, .popup-dialog textarea {
  height: 100px;
}
.popup-edit select, .popup-dialog select {
  background: #29A9FC;
  color: #fff;
  height: 42px;
  box-sizing: border-box;
}

.popup-dialog section {
  background: #fff;
  color: #263B47;
}
.popup-dialog footer {
  background: #fff;
}

.form-white .mage-error {
  color: #FB2A1A;
  font-size: 13px;
}
.form-white .success-message {
  color: #76B82A;
  font-size: 20px;
}
.form-white textarea, .form-white input[type=text], .form-white input[type=password], .form-white input[type=date], .form-white select {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  width: 100%;
  border: 0;
  padding: 10px;
  font-size: 16px;
  color: #263B47;
  background: #F0F2F4;
  font-family: "Arial", "Open Sans", sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.form-white input[type=text], .form-white input[type=password], .form-white input[type=date], .form-white textarea {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
}
.form-white label {
  padding-top: 10px;
  padding-bottom: 2px;
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.form-white textarea {
  height: 100px;
}
.form-white select {
  height: 42px;
}

#popup-watch-sync .watch-wrapper {
  display: block;
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  position: relative;
  padding: 20px 0;
  margin: 5px 15px;
  transition: all 0.1s ease-out;
}
#popup-watch-sync .watch-wrapper img {
  width: 40% !important;
  height: auto !important;
  margin-bottom: -5px;
}
#popup-watch-sync .watch-wrapper.watch-sync-done {
  background: #F0F2F4;
  color: #fff;
  box-shadow: inset 0 0 0 2px rgb(255, 255, 255);
}
#popup-watch-sync .watch-wrapper.watch-sync-done:before {
  content: "\edfb";
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  z-index: 3;
  font-size: 24px;
  line-height: 24px;
  width: 30px;
  height: 30px;
  top: 50%;
  margin-top: -13px;
  left: 10px;
  line-height: 30px;
  font-size: 18px;
  background: #76B82A;
  border-radius: 50%;
}
#popup-watch-sync .watch-wrapper.watch-sync-done span {
  z-index: 3;
  right: 10px;
  top: 50%;
  display: inline-block;
  width: auto;
  padding-left: 12px;
  padding-right: 12px;
}
#popup-watch-sync .watch-wrapper span {
  margin: 12px 0 0 0;
}

::-webkit-input-placeholder {
  color: #70c4fa;
}

::-moz-placeholder {
  color: #70c4fa;
}

:-ms-input-placeholder {
  color: #70c4fa;
}

:-moz-placeholder {
  color: #70c4fa;
}

.input-range-legend-wrapper {
  width: 100%;
}
.input-range-legend-wrapper > div {
  float: left;
  width: 25%;
  text-align: center;
}
.input-range-legend-wrapper:after {
  content: "";
  clear: both;
  display: block;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 0 0 15px;
  -webkit-tap-highlight-color: transparent;
  background: none;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 20px;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid #70c4fa;
  background: rgb(41, 169, 252);
  background: -moz-linear-gradient(left, rgb(41, 169, 252) 20%, rgb(90, 170, 49) 30%, rgb(90, 170, 49) 45%, rgb(255, 168, 0) 55%, rgb(255, 168, 0) 70%, rgb(251, 42, 26) 80%);
  background: -webkit-linear-gradient(left, rgb(41, 169, 252) 20%, rgb(90, 170, 49) 30%, rgb(90, 170, 49) 45%, rgb(255, 168, 0) 55%, rgb(255, 168, 0) 70%, rgb(251, 42, 26) 80%);
  background: linear-gradient(to right, rgb(41, 169, 252) 20%, rgb(90, 170, 49) 30%, rgb(90, 170, 49) 45%, rgb(255, 168, 0) 55%, rgb(255, 168, 0) 70%, rgb(251, 42, 26) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#29a9fc",endColorstr="#fb2a1a",GradientType=1);
}

input[type=range]::-webkit-slider-thumb {
  height: 30px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: rgb(41, 169, 252);
  background: -moz-linear-gradient(left, rgb(41, 169, 252) 20%, rgb(90, 170, 49) 30%, rgb(90, 170, 49) 45%, rgb(255, 168, 0) 55%, rgb(255, 168, 0) 70%, rgb(251, 42, 26) 80%);
  background: -webkit-linear-gradient(left, rgb(41, 169, 252) 20%, rgb(90, 170, 49) 30%, rgb(90, 170, 49) 45%, rgb(255, 168, 0) 55%, rgb(255, 168, 0) 70%, rgb(251, 42, 26) 80%);
  background: linear-gradient(to right, rgb(41, 169, 252) 20%, rgb(90, 170, 49) 30%, rgb(90, 170, 49) 45%, rgb(255, 168, 0) 55%, rgb(255, 168, 0) 70%, rgb(251, 42, 26) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#29a9fc",endColorstr="#fb2a1a",GradientType=1);
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}

input[type=range]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}

input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
  border: 1px solid #000000;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  height: 8.4px;
}

input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;
}

input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
}

.intensity-explanation {
  text-align: center;
  padding: 20px 0;
}
.intensity-explanation > div {
  display: inline-block;
  padding: 0 10px;
  font-size: 14px;
  text-transform: uppercase;
}
.intensity-explanation > div:before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 10px;
  position: relative;
  top: 2px;
  margin-right: 2px;
}
.intensity-explanation > div.intensity-1:before {
  background: #29A9FC;
}
.intensity-explanation > div.intensity-2:before {
  background: #76B82A;
}
.intensity-explanation > div.intensity-3:before {
  background: #ffa800;
}
.intensity-explanation > div.intensity-4:before {
  background: #FB2A1A;
}

@media only screen and (max-width: 400px) {
  .popup-edit section,
.popup-dialog section {
    width: 100%;
  }
}
.trainingsplan .header-wrapper,
.tagebuch .header-wrapper {
  padding: 30px 20px;
}
.trainingsplan .header-wrapper h2,
.tagebuch .header-wrapper h2 {
  font-weight: 700;
  font-size: 21px;
  padding: 0;
  margin-top: -5px;
  margin-bottom: -5px;
}
.trainingsplan .header-wrapper .tp-day-date,
.tagebuch .header-wrapper .tp-day-date {
  font-size: 12px;
  color: #777777;
}
.trainingsplan .header-wrapper .tp-day-intensity,
.tagebuch .header-wrapper .tp-day-intensity {
  font-size: 12px;
  text-transform: uppercase;
}
.trainingsplan .tp-wrapper,
.tagebuch .tp-wrapper {
  margin: 0 40px;
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.trainingsplan .tp-wrapper .ka-panel.close .ka-content,
.tagebuch .tp-wrapper .ka-panel.close .ka-content {
  display: none;
}
.trainingsplan .tp-wrapper .ka-panel.open .ka-content,
.tagebuch .tp-wrapper .ka-panel.open .ka-content {
  display: block;
}
.trainingsplan .tp-wrapper.intensity-1 .ka-panel .ka-handler span,
.trainingsplan .tp-wrapper.intensity-1 .header-wrapper .tp-day-intensity,
.tagebuch .tp-wrapper.intensity-1 .ka-panel .ka-handler span,
.tagebuch .tp-wrapper.intensity-1 .header-wrapper .tp-day-intensity {
  color: #29A9FC;
}
.trainingsplan .tp-wrapper.intensity-1 .tp-actions .tp-action a,
.trainingsplan .tp-wrapper.intensity-1 .tp-actions .tp-action button,
.tagebuch .tp-wrapper.intensity-1 .tp-actions .tp-action a,
.tagebuch .tp-wrapper.intensity-1 .tp-actions .tp-action button {
  background: #29A9FC;
}
.trainingsplan .tp-wrapper.intensity-2 .ka-panel .ka-handler span,
.trainingsplan .tp-wrapper.intensity-2 .header-wrapper .tp-day-intensity,
.tagebuch .tp-wrapper.intensity-2 .ka-panel .ka-handler span,
.tagebuch .tp-wrapper.intensity-2 .header-wrapper .tp-day-intensity {
  color: #76B82A;
}
.trainingsplan .tp-wrapper.intensity-2 .tp-actions .tp-action a,
.trainingsplan .tp-wrapper.intensity-2 .tp-actions .tp-action button,
.tagebuch .tp-wrapper.intensity-2 .tp-actions .tp-action a,
.tagebuch .tp-wrapper.intensity-2 .tp-actions .tp-action button {
  background: #76B82A;
}
.trainingsplan .tp-wrapper.intensity-3 .ka-panel .ka-handler span,
.trainingsplan .tp-wrapper.intensity-3 .header-wrapper .tp-day-intensity,
.tagebuch .tp-wrapper.intensity-3 .ka-panel .ka-handler span,
.tagebuch .tp-wrapper.intensity-3 .header-wrapper .tp-day-intensity {
  color: #ffa800;
}
.trainingsplan .tp-wrapper.intensity-3 .tp-actions .tp-action a,
.trainingsplan .tp-wrapper.intensity-3 .tp-actions .tp-action button,
.tagebuch .tp-wrapper.intensity-3 .tp-actions .tp-action a,
.tagebuch .tp-wrapper.intensity-3 .tp-actions .tp-action button {
  background: #ffa800;
}
.trainingsplan .tp-wrapper.intensity-4 .ka-panel .ka-handler span,
.trainingsplan .tp-wrapper.intensity-4 .header-wrapper .tp-day-intensity,
.tagebuch .tp-wrapper.intensity-4 .ka-panel .ka-handler span,
.tagebuch .tp-wrapper.intensity-4 .header-wrapper .tp-day-intensity {
  color: #FB2A1A;
}
.trainingsplan .tp-wrapper.intensity-4 .tp-actions .tp-action a,
.trainingsplan .tp-wrapper.intensity-4 .tp-actions .tp-action button,
.tagebuch .tp-wrapper.intensity-4 .tp-actions .tp-action a,
.tagebuch .tp-wrapper.intensity-4 .tp-actions .tp-action button {
  background: #FB2A1A;
}
.trainingsplan .tp-content .ka-panel .ka-handler,
.tagebuch .tp-content .ka-panel .ka-handler {
  font-size: 14px;
  padding: 20px 40px 20px 60px;
  border-top: 1px solid #F0F2F4;
  position: relative;
  cursor: pointer;
  font-weight: 400;
}
.trainingsplan .tp-content .ka-panel .ka-handler-number,
.tagebuch .tp-content .ka-panel .ka-handler-number {
  display: block;
  background: #000;
  width: 24px;
  height: 24px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  line-height: 24px;
  font-weight: 700;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -12px;
}
.trainingsplan .tp-content .ka-panel .ka-handler .tp-day-exercise-info,
.tagebuch .tp-content .ka-panel .ka-handler .tp-day-exercise-info {
  color: #777777;
  font-size: 12px;
  line-height: 14px;
}
.trainingsplan .tp-content .ka-panel .ka-handler span,
.tagebuch .tp-content .ka-panel .ka-handler span {
  float: right;
  transition: all 0.1s ease-out;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.trainingsplan .tp-content .ka-panel.open .ka-handler span,
.tagebuch .tp-content .ka-panel.open .ka-handler span {
  transform: rotate(180deg);
}
.trainingsplan .tp-content .ka-panel .ka-content,
.tagebuch .tp-content .ka-panel .ka-content {
  padding: 0 20px 20px 20px;
  color: #777777;
  font-size: 12px;
  line-height: 1.4em;
}
.trainingsplan .tp-content .tp-hint,
.tagebuch .tp-content .tp-hint {
  background: #F0F2F4;
  border-radius: 5px;
  padding: 5px 5px 5px 30px;
  position: relative;
  margin-top: 15px;
}
.trainingsplan .tp-content .tp-hint .icon-watch2,
.tagebuch .tp-content .tp-hint .icon-watch2 {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -15px;
  font-size: 24px;
}
.trainingsplan .tp-actions,
.tagebuch .tp-actions {
  text-align: center;
  padding-top: 30px;
}
.trainingsplan .tp-actions .tp-action,
.tagebuch .tp-actions .tp-action {
  display: inline-block;
  font-size: 12px;
  text-align: center;
  width: 70px;
  line-height: 14px;
  position: relative;
}
.trainingsplan .tp-actions .tp-action.done:before,
.tagebuch .tp-actions .tp-action.done:before {
  content: "\edfb";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #76B82A;
  color: #fff;
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: -3px;
  top: -8px;
  line-height: 20px;
  font-size: 12px;
  z-index: 5;
}
.trainingsplan .tp-actions .tp-action a,
.trainingsplan .tp-actions .tp-action button,
.tagebuch .tp-actions .tp-action a,
.tagebuch .tp-actions .tp-action button {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  line-height: 60px;
  color: #fff;
  font-size: 28px;
  text-decoration: none;
  margin-bottom: 6px;
  transition: all 0.1s ease-out;
  border: 0;
  cursor: pointer;
}
.trainingsplan .tp-actions .tp-action a:disabled,
.trainingsplan .tp-actions .tp-action button:disabled,
.tagebuch .tp-actions .tp-action a:disabled,
.tagebuch .tp-actions .tp-action button:disabled {
  background: #F0F2F4 !important;
  color: #777777;
  cursor: default;
}
.trainingsplan .tp-actions .tp-action a:disabled:hover,
.trainingsplan .tp-actions .tp-action button:disabled:hover,
.tagebuch .tp-actions .tp-action a:disabled:hover,
.tagebuch .tp-actions .tp-action button:disabled:hover {
  transform: scale(1);
}
.trainingsplan .tp-actions .tp-action a:hover,
.trainingsplan .tp-actions .tp-action button:hover,
.tagebuch .tp-actions .tp-action a:hover,
.tagebuch .tp-actions .tp-action button:hover {
  transform: scale(1.05);
}
@media only screen and (max-width: 768px) {
  .trainingsplan .trainingsplan-wrapper,
.tagebuch .trainingsplan-wrapper {
    padding: 0 !important;
    margin: 0 !important;
  }
  .trainingsplan .week-nav-wrapper,
.tagebuch .week-nav-wrapper {
    margin-bottom: 0 !important;
  }
  .trainingsplan .tp-wrapper,
.tagebuch .tp-wrapper {
    margin: 0;
    border-radius: 0;
  }
}

.tagebuch {
  background: #263B47;
}
.tagebuch .tagebuch-item {
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin: 0 40px 2px 40px;
  position: relative;
  overflow: hidden;
}
.tagebuch .tagebuch-item .ka-handler {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  padding: 30px 40px 20px 20px;
  position: relative;
  transition: all 0.1s ease-out;
  cursor: pointer;
}
.tagebuch .tagebuch-item .ka-handler .icon-chevron-down {
  color: #29A9FC;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -5px;
  font-size: 16px;
  transition: all 0.1s ease-out;
}
.tagebuch .tagebuch-item .ka-handler:after {
  content: "";
  display: block;
  clear: both;
}
.tagebuch .tagebuch-item .tagebuch-item-date {
  display: inline-block;
  color: #29A9FC;
  padding-right: 5px;
}
.tagebuch .tagebuch-item .tagebuch-item-unit-count {
  display: inline-block;
  border-radius: 20px;
  background: #29A9FC;
  color: #fff;
  padding: 0px 10px;
  height: 18px;
  line-height: 18px;
}
.tagebuch .tagebuch-item .tagebuch-item-unit-count strong {
  font-weight: 700;
}
.tagebuch .tagebuch-item .open .ka-handler {
  background: #F0F2F4;
}
.tagebuch .tagebuch-item .open .ka-handler .icon-chevron-down {
  transform: rotate(180deg);
}
.tagebuch .tagebuch-item .ka-panel .ka-content {
  transition: all 0.5s ease-in-out;
}
.tagebuch .tagebuch-item .ka-panel.close .ka-content {
  height: 0;
  opacity: 0;
}
.tagebuch .tagebuch-item .ka-panel.open .ka-content {
  height: auto;
  opacity: 1;
}
.tagebuch .tagebuch-item .ka-content a {
  font-size: 14px;
  padding: 20px 40px 20px 60px;
  border-top: 1px solid #F0F2F4;
  position: relative;
  cursor: pointer;
  font-weight: 400;
  color: #000;
  display: block;
  text-decoration: none;
  border-top: 1px solid #F0F2F4;
}
.tagebuch .tagebuch-item .ka-content a .tp-day-exercise-info {
  color: #777777;
  font-size: 12px;
  line-height: 14px;
}
.tagebuch .tagebuch-item .ka-content a span {
  float: right;
  transition: all 0.1s ease-out;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
  color: #29A9FC;
}
.tagebuch .tagebuch-item .ka-content a span.icon-flag3, .tagebuch .tagebuch-item .ka-content a span.icon-trophy1 {
  right: auto;
  margin-top: -12px;
  color: #000;
}
.tagebuch .tagebuch-item .ka-content a:before {
  display: block;
  background: #29A9FC;
  width: 24px;
  height: 24px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-weight: 700;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -12px;
  font-size: 16px;
  box-sizing: border-box;
  padding-top: 3px;
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tagebuch .tagebuch-item .ka-content a:hover span {
  right: 15px;
}
.tagebuch .tagebuch-item .ka-content a:hover span.icon-flag3, .tagebuch .tagebuch-item .ka-content a:hover span.icon-trophy1 {
  right: auto;
}
.tagebuch .tagebuch-item .ka-content a.tagebuch-item-unit-done:before {
  content: "\edfb";
}
.tagebuch .tagebuch-item .ka-content a.tagebuch-item-unit-manually:before {
  content: "\e918";
}
.tagebuch .tagebuch-item .ka-content a.tagebuch-item-unit-clock:before {
  content: "\eda1";
}
.tagebuch .tagebuch-item .ka-content a.tagebuch-item-unit-tracking:before {
  content: "\edf1";
}

.choose-week .owl-item,
.choose-month .owl-item,
.choose-year .owl-item {
  opacity: 0.5;
  transition: all 0.1s ease-out;
}
.choose-week .owl-item.active,
.choose-month .owl-item.active,
.choose-year .owl-item.active {
  opacity: 1;
}
.choose-week .owl-item .item a,
.choose-month .owl-item .item a,
.choose-year .owl-item .item a {
  display: block;
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 30px;
  color: #777777;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  padding: 8px 3px 6px 3px;
  transition: all 0.1s ease-out;
  height: 42px;
  box-sizing: border-box;
}
.choose-week .owl-item .item a.active, .choose-week .owl-item .item a:hover,
.choose-month .owl-item .item a.active,
.choose-month .owl-item .item a:hover,
.choose-year .owl-item .item a.active,
.choose-year .owl-item .item a:hover {
  background: #29A9FC;
  color: #fff;
}
.choose-week .owl-item .item a .year,
.choose-month .owl-item .item a .year,
.choose-year .owl-item .item a .year {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 10px;
}

.choose-month .owl-item .item a {
  font-size: 14px;
  line-height: 14px;
}

.choose-year .owl-item .item a {
  line-height: 42px;
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .tagebuch .tagebuch-item {
    margin: 0 0 2px 0;
    border-radius: 0;
  }
}
.training-feeling-bar {
  height: 31px;
  border-radius: 20px;
  background-image: linear-gradient(90deg, #009DF0 0%, #76B82A 30%, #FFA800 64%, #FB2A1A 100%);
  padding: 2px;
  box-sizing: border-box;
  position: relative;
}
.training-feeling-bar > div {
  background: #fff;
  height: 27px;
  border-radius: 20px;
}
.training-feeling-bar > div .training-feeling-bar-count {
  background: red;
  height: 27px;
  border-radius: 20px;
  width: 50%;
  overflow: hidden;
}
.training-feeling-bar > div .training-feeling-bar-count:before {
  content: "";
  display: block;
  height: 27px;
  width: 200%;
  background-image: linear-gradient(90deg, #009DF0 0%, #76B82A 30%, #FFA800 64%, #FB2A1A 100%);
}
.training-feeling-bar > div .training-feeling-bar-count.count-0 {
  width: 0%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-10 {
  width: 10%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-10:before {
  width: 1000%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-20 {
  width: 20%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-20:before {
  width: 500%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-30 {
  width: 30%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-30:before {
  width: 333%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-40 {
  width: 40%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-40:before {
  width: 250%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-30 {
  width: 30%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-30:before {
  width: 333%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-30 {
  width: 30%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-30:before {
  width: 333%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-50 {
  width: 50%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-50:before {
  width: 200%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-60 {
  width: 60%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-60:before {
  width: 166%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-70 {
  width: 70%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-70:before {
  width: 143%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-80 {
  width: 80%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-80:before {
  width: 125%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-90 {
  width: 90%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-90:before {
  width: 111%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-100 {
  width: 100%;
}
.training-feeling-bar > div .training-feeling-bar-count.count-100:before {
  width: 100%;
}

/*Formulare----------------*/
.default-form input[type=text],
.default-form input[type=email],
.default-form input[type=password],
.default-form input[type=date] {
  background: #fff;
  border: 0;
  border-bottom: 2px solid #F0F2F4;
  width: 100%;
  font-size: 18px;
  color: #29A9FC;
  padding: 3px 0 10px 0;
  font-weight: 300;
  transition: all 0.1s ease-out;
}
.default-form input[type=text]:hover, .default-form input[type=text]:active, .default-form input[type=text]:focus,
.default-form input[type=email]:hover,
.default-form input[type=email]:active,
.default-form input[type=email]:focus,
.default-form input[type=password]:hover,
.default-form input[type=password]:active,
.default-form input[type=password]:focus,
.default-form input[type=date]:hover,
.default-form input[type=date]:active,
.default-form input[type=date]:focus {
  color: #000;
}
.default-form select {
  width: 100%;
  font-size: 18px !important;
  padding: 5px;
}
.default-form .calc-element {
  background: #fff;
  border: 0;
  border-bottom: 2px solid transparent;
  width: 100%;
  font-size: 18px;
  color: #000;
  padding: 0px 0 10px 0;
  font-weight: 300;
  text-align: left;
  transition: all 0.1s ease-out;
}
.default-form textarea {
  background: #fff;
  border: 2px solid #F0F2F4;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
  color: #29A9FC;
  padding: 3px 0 10px 0;
  font-weight: 300;
  transition: all 0.1s ease-out;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 15px;
}
.default-form textarea:hover, .default-form textarea:active, .default-form textarea:focus {
  color: #000;
}
.default-form .password-strength {
  position: relative;
}
.default-form .password-strength .bar {
  background-image: linear-gradient(132deg, #00CCF9 0%, #009DF0 100%);
  border-radius: 8px;
  height: 8px;
}
.default-form label {
  font-size: 12px;
  color: #777777;
  text-align: left;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.default-form ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #F0F2F4;
  opacity: 1; /* Firefox */
}
.default-form :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #F0F2F4;
}
.default-form ::-ms-input-placeholder { /* Microsoft Edge */
  color: #F0F2F4;
}
.default-form hr {
  border: 0;
  border-bottom: 1px solid #F0F2F4 !important;
  padding: 0;
  margin: 25px -20px !important;
  width: calc(100% + 60px);
}

.spacer {
  height: 30px;
}

.eigenschaften-form {
  position: relative;
  display: flex !important;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 10px;
}
.eigenschaften-form .custom-checkbox {
  position: relative;
  width: calc(33.3% - 2px);
}
.eigenschaften-form .custom-checkbox label {
  border-radius: 5px;
  text-align: center;
  background: #566B75;
  color: #fff;
  display: block;
  text-align: center;
  text-overflow: inherit !important;
  white-space: normal !important;
  overflow: visible !important;
  hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  padding: 50px 10px 12px 10px !important;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  height: 100%;
  box-sizing: border-box;
  transition: all 0.1s ease-out;
}
.eigenschaften-form .custom-checkbox label:before {
  content: "\edfb";
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: calc(50% - 24px);
  color: #29A9FC;
  font-size: 20px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  left: 50%;
  margin-left: -15px;
  transition: all 0.1s ease-out;
  opacity: 0;
  transform: scale(1.2);
  background: transparent;
}
.eigenschaften-form .custom-checkbox label:after {
  display: none;
}
.eigenschaften-form .custom-checkbox label.checked {
  background: #fff;
  color: #000;
}
.eigenschaften-form .custom-checkbox label.checked:before {
  opacity: 1;
  transform: scale(1);
}
.eigenschaften-form .text-xs-right {
  text-align: right;
  width: 100%;
  padding-top: 10px;
}

@media only screen and (max-width: 500px) {
  .default-form label {
    font-size: 10px;
  }
}
/**
 *
 * Main stylesheet for Powerange.
 * http://abpetkov.github.io/powerange/
 *
 */
/**
 * Horizontal slider style (default).
 */
#js-display-change {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  padding: 0 0 15px 0;
}

.training-feeling-range {
  padding: 2px;
  border-radius: 20px;
  background-image: linear-gradient(90deg, #009DF0 0%, #76B82A 30%, #FFA800 64%, #FB2A1A 100%);
}
.training-feeling-range > div {
  overflow: hidden;
  border-radius: 20px;
}

.range-bar {
  background-color: #fff;
  border-radius: 15px;
  display: block;
  height: 27px;
  position: relative;
  width: calc(100% - 28px);
  margin-left: 6px;
  background-image: linear-gradient(90deg, #009DF0 0%, #76B82A 30%, #FFA800 64%, #FB2A1A 100%);
}

.range-quantity {
  border-radius: 15px;
  display: block;
  height: 100%;
  width: 0;
  position: relative;
}
.range-quantity:before {
  content: "";
  display: block;
  width: 1000px;
  height: 27px;
  background: #fff;
  position: absolute;
  right: -1014px;
  top: 0;
}

.range-handle {
  background-color: #fff;
  border-radius: 100%;
  cursor: move;
  height: 27px;
  left: 0;
  top: 0px;
  position: absolute;
  width: 27px;
  z-index: 20;
  text-align: center;
  line-height: 28px;
  color: #CED1D3;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.range-handle:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ede5";
}

.range-min,
.range-max {
  color: #181819;
  font-size: 12px;
  height: 20px;
  padding-top: 4px;
  position: absolute;
  text-align: center;
  top: -9px;
  width: 24px;
}

.range-min {
  left: -30px;
}

.range-max {
  right: -30px;
}

/**
 * Vertical slider style.
 */
.vertical {
  height: 100%;
  width: 4px;
}

.vertical .range-quantity {
  bottom: 0;
  height: 0;
  position: absolute;
  width: 100%;
}

.vertical .range-handle {
  bottom: 0;
  left: -13px;
  top: auto;
}

.vertical .range-min,
.vertical .range-max {
  left: -10px;
  right: auto;
  top: auto;
}

.vertical .range-min {
  bottom: -30px;
}

.vertical .range-max {
  top: -30px;
}

/**
 * Style for disabling text selection on handle move.
 */
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/**
 * Style for handle cursor on disabled slider.
 */
.range-disabled {
  cursor: default;
}

.lazy-loading-animation {
  text-align: center;
  padding: 20px 0;
}

.popup-training-detail {
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
}

.item-stats-wrapper {
  border-top: 1px solid #F0F2F4;
  border-bottom: 1px solid #F0F2F4;
  margin-left: -10px;
  margin-right: -10px;
  padding: 10px 20px;
}
.item-stats-wrapper:after {
  content: "";
  display: block;
  clear: both;
}

.popup-stats-header {
  padding-left: 30px;
  text-align: left;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
.popup-stats-header span {
  font-size: 24px;
  position: absolute;
  left: 0;
  top: 18px;
}
.popup-stats-header h3 {
  font-family: "Open Sans Condensed";
  font-weight: 700;
  font-size: 21px;
  color: #000000;
  text-transform: uppercase;
}
.popup-stats-header div {
  font-size: 12px;
  color: #777777;
  font-family: "Open Sans";
  font-weight: 400;
  text-transform: none;
}
.popup-stats-header div img {
  height: 12px !important;
  width: auto !important;
  position: relative;
  top: 2px;
}

.item-stat,
.item-stat-text {
  float: left;
  width: 33%;
  line-height: 18px;
  color: #777777;
  text-align: left;
  font-size: 12px;
  padding: 10px 0;
  word-break: break-all;
}
.item-stat .item-stat-count,
.item-stat-text .item-stat-count {
  font-size: 18px;
  color: #000;
}
.item-stat .item-stat-text-wrapper,
.item-stat-text .item-stat-text-wrapper {
  font-size: 14px;
  color: #000;
}

.item-stat-text {
  float: none;
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-right: -30px;
  border-bottom: 1px solid #F0F2F4;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
}

.item-stats-laps-wrapper {
  position: relative;
  height: 120px;
  overflow: hidden;
  width: calc(100% + 60px);
  margin-left: -30px;
  cursor: pointer;
}
.item-stats-laps-wrapper.collapsed {
  height: auto;
  padding-bottom: 52px;
}
.item-stats-laps-wrapper .item-stats-laps-trigger {
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgb(255, 255, 255) 10%, rgba(255, 255, 255, 0) 100%);
  height: 85px;
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
  padding-top: 65px;
  color: #29A9FC;
}
.item-stats-laps-wrapper .item-stats-laps-trigger span {
  display: block;
}
.item-stats-laps-wrapper.active {
  height: auto;
  padding-bottom: 30px;
}
.item-stats-laps-wrapper.active .item-stats-laps-trigger {
  height: 30px;
  padding-top: 10px;
}
.item-stats-laps-wrapper.active .item-stats-laps-trigger span {
  transform: rotate(180deg);
}

table.item-stats-laps {
  font-size: 12px;
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}
table.item-stats-laps tr th {
  color: #29A9FC;
  font-weight: 700;
  padding: 5px 0px;
}
table.item-stats-laps tr th:first-child {
  padding-left: 20px;
}
table.item-stats-laps tr th:last-child {
  padding-right: 20px;
}
table.item-stats-laps tr td {
  padding: 5px 0px;
}
table.item-stats-laps tr td:first-child {
  padding-left: 20px;
}
table.item-stats-laps tr td:last-child {
  padding-right: 20px;
}
table.item-stats-laps tr:nth-child(even) td {
  background: #F0F2F4;
}

.date-range-wrapper {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  background: #F0F2F4;
  margin: 0 40px 20px 40px;
  text-align: center;
  font-size: 12px;
  padding: 10px 0;
}
.date-range-wrapper input {
  font-size: 12px;
  text-align: center;
  border: 0;
  width: 85px;
  height: 22px;
  border-radius: 20px;
  font-weight: 700;
}
.date-range-wrapper .owl-nav .owl-prev,
.date-range-wrapper .owl-nav .owl-next {
  width: 20px;
}
.date-range-wrapper .owl-nav .owl-prev:before, .date-range-wrapper .owl-nav .owl-prev:after,
.date-range-wrapper .owl-nav .owl-next:before,
.date-range-wrapper .owl-nav .owl-next:after {
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
  .date-range-wrapper {
    margin: -22px 0 20px 0;
    border-radius: 0;
  }
}
.item-sats-media-date {
  font-size: 12px;
  color: #777777;
}

.item-sats-media {
  margin-left: -10px;
  margin-right: -10px;
}
.item-sats-media iframe {
  width: 100% !important;
  height: 400px !important;
}
.item-sats-media .item-sats-media-nav {
  text-align: left;
  padding: 15px;
}
.item-sats-media .item-sats-media-nav:after {
  display: block;
  content: "";
  clear: both;
}
.item-sats-media .item-sats-media-nav a {
  display: block;
  width: 50px;
  height: 50px;
  float: left;
  border: 2px solid #F0F2F4;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  text-align: center;
  text-decoration: none;
  color: #F0F2F4;
  margin: 5px;
}
.item-sats-media .item-sats-media-nav a img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.item-sats-media .item-sats-media-nav a span {
  font-size: 36px;
  line-height: 50px;
  text-decoration: none;
}
.item-sats-media .item-sats-media-nav a.active, .item-sats-media .item-sats-media-nav a:hover {
  border: 2px solid #29A9FC;
  color: #29A9FC;
}

.feed-stats {
  margin-bottom: 20px;
}
.feed-stats .item {
  background: rgba(0, 0, 0, 0.1);
  height: 135px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  line-height: 18px;
  position: relative;
}
.feed-stats .item .feed-stats-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 20px);
}
.feed-stats .item span {
  color: #29A9FC;
  font-size: 40px;
  display: block;
  padding-bottom: 35px;
  position: absolute;
  left: 10px;
  top: 10px;
}
.feed-stats .item img {
  position: absolute;
  left: 5px;
  top: 5px;
  max-height: 80px !important;
  width: auto !important;
}
.feed-stats .item .feed-stats-count {
  color: #fff;
  font-weight: 900;
  font-size: 16px;
}
.feed-stats .item .feed-stats-unit {
  color: #29A9FC;
  font-size: 12px;
}
.feed-stats .owl-item {
  opacity: 0.5;
  transition: all 1s ease-out;
}
.feed-stats .active {
  opacity: 1;
}
.feed-stats .owl-nav .owl-prev,
.feed-stats .owl-nav .owl-next {
  height: 135px;
}
.feed-stats .owl-nav .owl-prev:before, .feed-stats .owl-nav .owl-prev:after,
.feed-stats .owl-nav .owl-next:before,
.feed-stats .owl-nav .owl-next:after {
  color: #fff;
}

.trigger-edit-close,
.trigger-zurereit-close {
  color: #29A9FC;
  position: absolute;
  left: 10px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.anmeldung,
.login {
  background: #566B75;
  overflow: hidden;
  /*header {
      height: 50px;
      background: url(images/LT-Signet.svg) 50% 50% no-repeat;
  }*/
}
.anmeldung .error,
.login .error {
  color: #FB2A1A;
  margin-bottom: 15px;
  line-height: 1.2;
}
.anmeldung .form-group .error,
.login .form-group .error {
  color: #FB2A1A;
  font-size: 0.8em;
  margin: 0;
  display: none;
  padding-top: 3px;
}
.anmeldung .form-group--error input, .anmeldung .form-group--error .checkbox-label, .anmeldung .form-group--error .check-label,
.login .form-group--error input,
.login .form-group--error .checkbox-label,
.login .form-group--error .check-label {
  border: #FB2A1A 1px solid !important;
}
.anmeldung .form-group--error .error,
.login .form-group--error .error {
  display: block;
}
.anmeldung .form-group--error .label-animated,
.login .form-group--error .label-animated {
  display: none;
}
.anmeldung .term-condition,
.login .term-condition {
  cursor: pointer;
}
.anmeldung .term-condition:hover,
.login .term-condition:hover {
  text-decoration: underline;
}
.anmeldung .animation-enter-active,
.login .animation-enter-active {
  transition: all 0.2s linear, opacity 0.3s ease;
}
.anmeldung .animation-leave-active,
.login .animation-leave-active {
  transition: all 0.2s linear, opacity 0.3s ease;
}
.anmeldung .animation-leave-to,
.login .animation-leave-to {
  transform: translateX(-400px);
  opacity: 0;
}
.anmeldung .animation-enter,
.login .animation-enter {
  transform: translateX(400px);
  opacity: 0;
}
.anmeldung .form-text,
.login .form-text {
  color: #fff;
  font-weight: 700;
}
.anmeldung .img-choose a,
.anmeldung .img-choose > div,
.login .img-choose a,
.login .img-choose > div {
  display: block;
  position: relative;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  margin: 10px 15px;
  height: 150px;
  overflow: hidden;
}
.anmeldung .img-choose a img,
.anmeldung .img-choose > div img,
.login .img-choose a img,
.login .img-choose > div img {
  width: 100% !important;
  height: auto !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 2s ease-out;
}
.anmeldung .img-choose a span,
.anmeldung .img-choose > div span,
.login .img-choose a span,
.login .img-choose > div span {
  display: block;
  clear: both;
  position: absolute;
  font-weight: 700;
  font-style: italic;
  color: #fff;
  left: 20px;
  bottom: 15px;
  text-transform: uppercase;
  font-size: 24px;
  z-index: 2;
}
.anmeldung .img-choose a:hover img,
.login .img-choose a:hover img {
  transform: scale(1.2);
}
.anmeldung .img-choose > div img,
.login .img-choose > div img {
  opacity: 0.2;
}
@media only screen and (max-width: 400px) {
  .anmeldung .img-choose a,
.anmeldung .img-choose > div,
.login .img-choose a,
.login .img-choose > div {
    margin-right: 30px;
    height: 135px;
  }
  .anmeldung .img-choose a span,
.anmeldung .img-choose > div span,
.login .img-choose a span,
.login .img-choose > div span {
    font-size: 18px;
  }
}
.anmeldung .anmeldung-middle,
.anmeldung .anmeldung-show,
.login .anmeldung-middle,
.login .anmeldung-show {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  transition: all 0.1s ease-out;
}
@media (max-height: 560px) {
  .anmeldung .anmeldung-middle,
.anmeldung .anmeldung-show,
.login .anmeldung-middle,
.login .anmeldung-show {
    height: auto !important;
  }
}
.anmeldung .anmeldung-middle .row,
.anmeldung .anmeldung-show .row,
.login .anmeldung-middle .row,
.login .anmeldung-show .row {
  margin-left: -2px;
  margin-right: -2px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.anmeldung .anmeldung-middle .col-xs-1, .anmeldung .anmeldung-middle .col-sm-1, .anmeldung .anmeldung-middle .col-md-1, .anmeldung .anmeldung-middle .col-lg-1, .anmeldung .anmeldung-middle .col-xs-2, .anmeldung .anmeldung-middle .col-sm-2, .anmeldung .anmeldung-middle .col-md-2, .anmeldung .anmeldung-middle .col-lg-2, .anmeldung .anmeldung-middle .col-xs-3, .anmeldung .anmeldung-middle .col-sm-3, .anmeldung .anmeldung-middle .col-md-3, .anmeldung .anmeldung-middle .col-lg-3, .anmeldung .anmeldung-middle .col-xs-4, .anmeldung .anmeldung-middle .col-sm-4, .anmeldung .anmeldung-middle .col-md-4, .anmeldung .anmeldung-middle .col-lg-4, .anmeldung .anmeldung-middle .col-xs-5, .anmeldung .anmeldung-middle .col-sm-5, .anmeldung .anmeldung-middle .col-md-5, .anmeldung .anmeldung-middle .col-lg-5, .anmeldung .anmeldung-middle .col-xs-6, .anmeldung .anmeldung-middle .col-sm-6, .anmeldung .anmeldung-middle .col-md-6, .anmeldung .anmeldung-middle .col-lg-6, .anmeldung .anmeldung-middle .col-xs-7, .anmeldung .anmeldung-middle .col-sm-7, .anmeldung .anmeldung-middle .col-md-7, .anmeldung .anmeldung-middle .col-lg-7, .anmeldung .anmeldung-middle .col-xs-8, .anmeldung .anmeldung-middle .col-sm-8, .anmeldung .anmeldung-middle .col-md-8, .anmeldung .anmeldung-middle .col-lg-8, .anmeldung .anmeldung-middle .col-xs-9, .anmeldung .anmeldung-middle .col-sm-9, .anmeldung .anmeldung-middle .col-md-9, .anmeldung .anmeldung-middle .col-lg-9, .anmeldung .anmeldung-middle .col-xs-10, .anmeldung .anmeldung-middle .col-sm-10, .anmeldung .anmeldung-middle .col-md-10, .anmeldung .anmeldung-middle .col-lg-10, .anmeldung .anmeldung-middle .col-xs-11, .anmeldung .anmeldung-middle .col-sm-11, .anmeldung .anmeldung-middle .col-md-11, .anmeldung .anmeldung-middle .col-lg-11, .anmeldung .anmeldung-middle .col-xs-12, .anmeldung .anmeldung-middle .col-sm-12, .anmeldung .anmeldung-middle .col-md-12, .anmeldung .anmeldung-middle .col-lg-12,
.anmeldung .anmeldung-show .col-xs-1,
.anmeldung .anmeldung-show .col-sm-1,
.anmeldung .anmeldung-show .col-md-1,
.anmeldung .anmeldung-show .col-lg-1,
.anmeldung .anmeldung-show .col-xs-2,
.anmeldung .anmeldung-show .col-sm-2,
.anmeldung .anmeldung-show .col-md-2,
.anmeldung .anmeldung-show .col-lg-2,
.anmeldung .anmeldung-show .col-xs-3,
.anmeldung .anmeldung-show .col-sm-3,
.anmeldung .anmeldung-show .col-md-3,
.anmeldung .anmeldung-show .col-lg-3,
.anmeldung .anmeldung-show .col-xs-4,
.anmeldung .anmeldung-show .col-sm-4,
.anmeldung .anmeldung-show .col-md-4,
.anmeldung .anmeldung-show .col-lg-4,
.anmeldung .anmeldung-show .col-xs-5,
.anmeldung .anmeldung-show .col-sm-5,
.anmeldung .anmeldung-show .col-md-5,
.anmeldung .anmeldung-show .col-lg-5,
.anmeldung .anmeldung-show .col-xs-6,
.anmeldung .anmeldung-show .col-sm-6,
.anmeldung .anmeldung-show .col-md-6,
.anmeldung .anmeldung-show .col-lg-6,
.anmeldung .anmeldung-show .col-xs-7,
.anmeldung .anmeldung-show .col-sm-7,
.anmeldung .anmeldung-show .col-md-7,
.anmeldung .anmeldung-show .col-lg-7,
.anmeldung .anmeldung-show .col-xs-8,
.anmeldung .anmeldung-show .col-sm-8,
.anmeldung .anmeldung-show .col-md-8,
.anmeldung .anmeldung-show .col-lg-8,
.anmeldung .anmeldung-show .col-xs-9,
.anmeldung .anmeldung-show .col-sm-9,
.anmeldung .anmeldung-show .col-md-9,
.anmeldung .anmeldung-show .col-lg-9,
.anmeldung .anmeldung-show .col-xs-10,
.anmeldung .anmeldung-show .col-sm-10,
.anmeldung .anmeldung-show .col-md-10,
.anmeldung .anmeldung-show .col-lg-10,
.anmeldung .anmeldung-show .col-xs-11,
.anmeldung .anmeldung-show .col-sm-11,
.anmeldung .anmeldung-show .col-md-11,
.anmeldung .anmeldung-show .col-lg-11,
.anmeldung .anmeldung-show .col-xs-12,
.anmeldung .anmeldung-show .col-sm-12,
.anmeldung .anmeldung-show .col-md-12,
.anmeldung .anmeldung-show .col-lg-12,
.login .anmeldung-middle .col-xs-1,
.login .anmeldung-middle .col-sm-1,
.login .anmeldung-middle .col-md-1,
.login .anmeldung-middle .col-lg-1,
.login .anmeldung-middle .col-xs-2,
.login .anmeldung-middle .col-sm-2,
.login .anmeldung-middle .col-md-2,
.login .anmeldung-middle .col-lg-2,
.login .anmeldung-middle .col-xs-3,
.login .anmeldung-middle .col-sm-3,
.login .anmeldung-middle .col-md-3,
.login .anmeldung-middle .col-lg-3,
.login .anmeldung-middle .col-xs-4,
.login .anmeldung-middle .col-sm-4,
.login .anmeldung-middle .col-md-4,
.login .anmeldung-middle .col-lg-4,
.login .anmeldung-middle .col-xs-5,
.login .anmeldung-middle .col-sm-5,
.login .anmeldung-middle .col-md-5,
.login .anmeldung-middle .col-lg-5,
.login .anmeldung-middle .col-xs-6,
.login .anmeldung-middle .col-sm-6,
.login .anmeldung-middle .col-md-6,
.login .anmeldung-middle .col-lg-6,
.login .anmeldung-middle .col-xs-7,
.login .anmeldung-middle .col-sm-7,
.login .anmeldung-middle .col-md-7,
.login .anmeldung-middle .col-lg-7,
.login .anmeldung-middle .col-xs-8,
.login .anmeldung-middle .col-sm-8,
.login .anmeldung-middle .col-md-8,
.login .anmeldung-middle .col-lg-8,
.login .anmeldung-middle .col-xs-9,
.login .anmeldung-middle .col-sm-9,
.login .anmeldung-middle .col-md-9,
.login .anmeldung-middle .col-lg-9,
.login .anmeldung-middle .col-xs-10,
.login .anmeldung-middle .col-sm-10,
.login .anmeldung-middle .col-md-10,
.login .anmeldung-middle .col-lg-10,
.login .anmeldung-middle .col-xs-11,
.login .anmeldung-middle .col-sm-11,
.login .anmeldung-middle .col-md-11,
.login .anmeldung-middle .col-lg-11,
.login .anmeldung-middle .col-xs-12,
.login .anmeldung-middle .col-sm-12,
.login .anmeldung-middle .col-md-12,
.login .anmeldung-middle .col-lg-12,
.login .anmeldung-show .col-xs-1,
.login .anmeldung-show .col-sm-1,
.login .anmeldung-show .col-md-1,
.login .anmeldung-show .col-lg-1,
.login .anmeldung-show .col-xs-2,
.login .anmeldung-show .col-sm-2,
.login .anmeldung-show .col-md-2,
.login .anmeldung-show .col-lg-2,
.login .anmeldung-show .col-xs-3,
.login .anmeldung-show .col-sm-3,
.login .anmeldung-show .col-md-3,
.login .anmeldung-show .col-lg-3,
.login .anmeldung-show .col-xs-4,
.login .anmeldung-show .col-sm-4,
.login .anmeldung-show .col-md-4,
.login .anmeldung-show .col-lg-4,
.login .anmeldung-show .col-xs-5,
.login .anmeldung-show .col-sm-5,
.login .anmeldung-show .col-md-5,
.login .anmeldung-show .col-lg-5,
.login .anmeldung-show .col-xs-6,
.login .anmeldung-show .col-sm-6,
.login .anmeldung-show .col-md-6,
.login .anmeldung-show .col-lg-6,
.login .anmeldung-show .col-xs-7,
.login .anmeldung-show .col-sm-7,
.login .anmeldung-show .col-md-7,
.login .anmeldung-show .col-lg-7,
.login .anmeldung-show .col-xs-8,
.login .anmeldung-show .col-sm-8,
.login .anmeldung-show .col-md-8,
.login .anmeldung-show .col-lg-8,
.login .anmeldung-show .col-xs-9,
.login .anmeldung-show .col-sm-9,
.login .anmeldung-show .col-md-9,
.login .anmeldung-show .col-lg-9,
.login .anmeldung-show .col-xs-10,
.login .anmeldung-show .col-sm-10,
.login .anmeldung-show .col-md-10,
.login .anmeldung-show .col-lg-10,
.login .anmeldung-show .col-xs-11,
.login .anmeldung-show .col-sm-11,
.login .anmeldung-show .col-md-11,
.login .anmeldung-show .col-lg-11,
.login .anmeldung-show .col-xs-12,
.login .anmeldung-show .col-sm-12,
.login .anmeldung-show .col-md-12,
.login .anmeldung-show .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 2px;
  padding-right: 2px;
}
.anmeldung .popup,
.login .popup {
  background: #263B47;
  width: 400px;
  height: 600px;
  box-sizing: border-box;
  font-size: 14px;
  margin: 0;
  position: absolute;
}
.anmeldung .popup.next-step,
.login .popup.next-step {
  position: absolute;
  margin-left: 400px;
  opacity: 0;
}
.anmeldung .popup h4,
.login .popup h4 {
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 22px;
  padding-top: 45px;
  padding-left: 20px;
  padding-right: 20px;
}
.anmeldung .popup h4 .subhead,
.login .popup h4 .subhead {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-top: 15px;
  font-family: "Open Sans";
}
.anmeldung .popup .trigger-back,
.login .popup .trigger-back {
  color: #fff;
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 60;
}
.anmeldung .popup .anmeldung-text,
.login .popup .anmeldung-text {
  position: relative;
  text-align: center;
  color: #fff;
}
.anmeldung .popup .anmeldung-text .img-wrapper,
.login .popup .anmeldung-text .img-wrapper {
  position: absolute;
  top: 155px;
  width: calc(100% - 20px);
}
@media (max-height: 560px) {
  .anmeldung .popup .anmeldung-text .img-wrapper,
.login .popup .anmeldung-text .img-wrapper {
    position: relative;
    width: auto;
    top: 10px;
  }
}
.anmeldung .popup .anmeldung-text a,
.login .popup .anmeldung-text a {
  background: none !important;
  text-decoration: underline !important;
}
.anmeldung .popup .anmeldung-text .preselect,
.login .popup .anmeldung-text .preselect {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}
.anmeldung .popup .anmeldung-text .preselect a,
.login .popup .anmeldung-text .preselect a {
  display: flex;
  background: #29A9FC !important;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  height: 100px;
  text-decoration: none !important;
  width: calc(50% - 3px);
}
.anmeldung .popup .anmeldung-text .preselect a:hover,
.login .popup .anmeldung-text .preselect a:hover {
  background: #fff !important;
  color: #29A9FC;
}
.anmeldung .popup .anmeldung-text .preselect a span,
.login .popup .anmeldung-text .preselect a span {
  display: block;
  margin: auto;
}
.anmeldung .popup .anmeldung-content,
.login .popup .anmeldung-content {
  position: absolute;
  bottom: 0;
  left: 10px;
  width: calc(100% - 40px);
}
@media (max-height: 560px) {
  .anmeldung .popup .anmeldung-content,
.login .popup .anmeldung-content {
    position: relative;
    width: auto;
    padding: 0 20px;
    left: 0;
  }
}
.anmeldung .popup .anmeldung-content a,
.login .popup .anmeldung-content a {
  display: block;
  background: #566B75;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  height: 90px;
  position: relative;
  transition: all 0.1s ease-out;
}
.anmeldung .popup .anmeldung-content a img, .anmeldung .popup .anmeldung-content a svg,
.login .popup .anmeldung-content a img,
.login .popup .anmeldung-content a svg {
  position: absolute;
  right: 10px;
  top: 10px;
}
.anmeldung .popup .anmeldung-content a .text,
.login .popup .anmeldung-content a .text {
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: calc(100% - 20px);
}
.anmeldung .popup .anmeldung-content a .status-bar-wrapper,
.login .popup .anmeldung-content a .status-bar-wrapper {
  position: relative;
  height: 20px;
  margin: 0 10px;
}
.anmeldung .popup .anmeldung-content a .status-bar,
.login .popup .anmeldung-content a .status-bar {
  background-image: linear-gradient(132deg, #00CCF9 0%, #009DF0 100%);
  border-radius: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 10px;
}
.anmeldung .popup .anmeldung-content a .status-bar.status-bar-25,
.login .popup .anmeldung-content a .status-bar.status-bar-25 {
  width: 25%;
}
.anmeldung .popup .anmeldung-content a .status-bar.status-bar-50,
.login .popup .anmeldung-content a .status-bar.status-bar-50 {
  width: 50%;
}
.anmeldung .popup .anmeldung-content a .status-bar.status-bar-75,
.login .popup .anmeldung-content a .status-bar.status-bar-75 {
  width: 75%;
}
.anmeldung .popup .anmeldung-content a .status-bar.status-bar-100,
.login .popup .anmeldung-content a .status-bar.status-bar-100 {
  width: 100%;
}
.anmeldung .popup .anmeldung-content a:hover,
.login .popup .anmeldung-content a:hover {
  background: #fff;
  color: #000;
}
.anmeldung .popup .popup-text span,
.login .popup .popup-text span {
  color: #29A9FC;
  color: rgba(0, 0, 0, 0.3);
  font-size: 40px;
  display: block;
  position: relative;
  padding: 5px 0;
  top: -13px;
}
.anmeldung .popup section,
.anmeldung .popup footer,
.login .popup section,
.login .popup footer {
  background: none;
}
.anmeldung .popup input,
.anmeldung .popup textarea,
.login .popup input,
.login .popup textarea {
  color: #fff;
  background: #263B47;
}
.anmeldung .popup textarea,
.login .popup textarea {
  height: 160px;
  border: 2px solid #566B75;
}
.anmeldung .popup input,
.login .popup input {
  font-size: 14px;
  border-bottom: 1px solid #566B75;
  padding: 0 0 7px 0;
  margin-top: -6px;
  margin-bottom: 4px;
}
.anmeldung .popup input:hover, .anmeldung .popup input:focus, .anmeldung .popup input:active,
.login .popup input:hover,
.login .popup input:focus,
.login .popup input:active {
  color: #fff;
}
.anmeldung .popup label.label-animated,
.login .popup label.label-animated {
  color: #566B75;
  transition: all 0.1s ease-out;
  opacity: 1;
  transform: translateX(0);
  margin-bottom: -8px;
}
.anmeldung .popup .hide-label label,
.login .popup .hide-label label {
  opacity: 0;
  transform: translateX(30px);
}
.anmeldung .popup ::placeholder,
.login .popup ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #566B75;
  opacity: 1; /* Firefox */
  font-size: 12px;
}
.anmeldung .popup :-ms-input-placeholder,
.login .popup :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #566B75;
  font-size: 12px;
}
.anmeldung .popup ::-ms-input-placeholder,
.login .popup ::-ms-input-placeholder { /* Microsoft Edge */
  color: #566B75;
  font-size: 12px;
}
.anmeldung .popup .default-form,
.login .popup .default-form {
  margin-bottom: 15px;
  display: block;
}
@media only screen and (max-width: 400px) {
  .anmeldung .popup,
.login .popup {
    width: 100%;
  }
}
@media only screen and (max-height: 600px) {
  .anmeldung .popup,
.login .popup {
    height: 100%;
  }
}
.anmeldung .popup-edit-progress,
.login .popup-edit-progress {
  background: rgba(0, 0, 0, 0.1);
}
.anmeldung .popup-edit-progress .popup-edit-progress-count,
.login .popup-edit-progress .popup-edit-progress-count {
  color: rgba(0, 0, 0, 0.3);
  display: none;
}
.anmeldung .popup-edit-progress .popup-edit-progress-bar,
.login .popup-edit-progress .popup-edit-progress-bar {
  background: #29A9FC;
}
.anmeldung .fragebogen-end,
.login .fragebogen-end {
  background: url(../images/hg-fragebogen-end.jpg) 50% 50% no-repeat;
  background-size: cover;
}
.anmeldung .fragebogen-end .anmeldung-text,
.login .fragebogen-end .anmeldung-text {
  height: 80%;
  position: relative;
}
.anmeldung .fragebogen-end .anmeldung-text .text,
.login .fragebogen-end .anmeldung-text .text {
  position: absolute;
  bottom: 0;
  width: calc(100% - 20px);
  font-weight: 700;
}
.anmeldung .fragebogen-end .anmeldung-text .text span,
.login .fragebogen-end .anmeldung-text .text span {
  color: #29A9FC;
}
.anmeldung .fragebogen-ziel-art .anmeldung-text .img-wrapper,
.anmeldung .registrierung-form .anmeldung-text .img-wrapper,
.login .fragebogen-ziel-art .anmeldung-text .img-wrapper,
.login .registrierung-form .anmeldung-text .img-wrapper {
  top: 110px;
}
@media (max-height: 560px) {
  .anmeldung .fragebogen-ziel-art .anmeldung-text .img-wrapper,
.anmeldung .registrierung-form .anmeldung-text .img-wrapper,
.login .fragebogen-ziel-art .anmeldung-text .img-wrapper,
.login .registrierung-form .anmeldung-text .img-wrapper {
    top: 10px;
  }
}
.anmeldung .fragebogen-ziel-art label.check-label,
.anmeldung .registrierung-form label.check-label,
.login .fragebogen-ziel-art label.check-label,
.login .registrierung-form label.check-label {
  display: block;
  background: #566B75;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  text-align: center;
  float: left;
  width: calc(50% - 4px);
  margin: 2px 2px 2px 2px;
  height: 45px;
  line-height: 45px;
  padding-left: 45px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  text-align: left;
  transition: all 0.1s ease-out;
}
.anmeldung .fragebogen-ziel-art label.check-label:before,
.anmeldung .registrierung-form label.check-label:before,
.login .fragebogen-ziel-art label.check-label:before,
.login .registrierung-form label.check-label:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\edfb";
  color: #29A9FC;
  opacity: 0;
  transform: scale(1.5);
  position: absolute;
  text-align: center;
  left: 14px;
  top: 10px;
  font-size: 24px;
  transition: all 0.1s ease-out;
}
.anmeldung .fragebogen-ziel-art label.check-label.checked,
.anmeldung .registrierung-form label.check-label.checked,
.login .fragebogen-ziel-art label.check-label.checked,
.login .registrierung-form label.check-label.checked {
  background: #fff;
  color: #000;
}
.anmeldung .fragebogen-ziel-art label.check-label.checked:before,
.anmeldung .registrierung-form label.check-label.checked:before,
.login .fragebogen-ziel-art label.check-label.checked:before,
.login .registrierung-form label.check-label.checked:before {
  opacity: 1;
  transform: scale(1);
}
.anmeldung .fragebogen-ziel-art textarea,
.anmeldung .registrierung-form textarea,
.login .fragebogen-ziel-art textarea,
.login .registrierung-form textarea {
  height: 90px;
  margin-top: 10px;
}
.anmeldung .registrierung-form label.check-label,
.login .registrierung-form label.check-label {
  width: calc(33.3333333333% - 4px);
  padding-left: 37px;
}
.anmeldung .registrierung-form label.check-label:before,
.login .registrierung-form label.check-label:before {
  display: none;
}
.anmeldung .registrierung-form label.check-label:after,
.login .registrierung-form label.check-label:after {
  content: "";
  height: 24px;
  width: 24px;
  display: block;
  position: absolute;
  left: 7px;
  top: 10px;
  background: red;
}
.anmeldung .registrierung-form label.check-label.weiblich:after,
.login .registrierung-form label.check-label.weiblich:after {
  background: url(../images/portrait-female.svg) 50% 50% no-repeat;
}
.anmeldung .registrierung-form label.check-label.maennlich:after,
.login .registrierung-form label.check-label.maennlich:after {
  background: url(../images/portrait-male.svg) 50% 50% no-repeat;
}
.anmeldung .registrierung-form label.check-label.divers:after,
.login .registrierung-form label.check-label.divers:after {
  background: url(../images/portrait-divers.svg) 50% 50% no-repeat;
}
.anmeldung .registrierung-form .picture-upload,
.login .registrierung-form .picture-upload {
  height: 80px;
  width: 80px;
  background: #566B75;
  background-size: cover;
  -webkit-background-size: cover;
  border-radius: 50px;
  font-size: 35px;
  line-height: 80px;
  margin-top: 10px;
}
.anmeldung .registrierung-form label.checkbox-label,
.login .registrierung-form label.checkbox-label {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  width: 24px;
  height: 24px;
  background: #566B75;
  transition: all 0.1s ease-out;
  text-align: center;
  color: #29A9FC;
  font-size: 16px;
  cursor: pointer;
  line-height: 24px;
}
.anmeldung .registrierung-form label.checkbox-label span,
.login .registrierung-form label.checkbox-label span {
  transition: all 0.1s ease-out;
  transform: scale(1.3);
  opacity: 0;
}
.anmeldung .registrierung-form label.checkbox-label.checked,
.login .registrierung-form label.checkbox-label.checked {
  background: #fff;
}
.anmeldung .registrierung-form label.checkbox-label.checked span,
.login .registrierung-form label.checkbox-label.checked span {
  opacity: 1;
  transform: scale(1);
}
.anmeldung .registrierung-form .checkbox-wrapper,
.login .registrierung-form .checkbox-wrapper {
  color: #fff;
  padding: 15px 0 15px 30px;
  position: relative;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  clear: both;
}
.anmeldung .registrierung-form .checkbox-wrapper label.checkbox-label,
.login .registrierung-form .checkbox-wrapper label.checkbox-label {
  position: absolute;
  left: 0;
  top: 10px;
}
.anmeldung .registrierung-form .text-scroll,
.login .registrierung-form .text-scroll {
  min-height: 320px;
  max-height: 450px;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  overflow: scroll;
  padding: 0 10px;
}
.anmeldung .fragebogen-training-day label.check-label,
.login .fragebogen-training-day label.check-label {
  display: block;
  background: #566B75;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  text-align: center;
  float: left;
  width: calc(14.2857142857% - 4px);
  margin: 2px 2px 2px 2px;
  height: 90px;
  padding-top: 52px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  transition: all 0.1s ease-out;
}
.anmeldung .fragebogen-training-day label.check-label:before,
.login .fragebogen-training-day label.check-label:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\edfb";
  color: #29A9FC;
  opacity: 0;
  transform: scale(1.5);
}
.anmeldung .fragebogen-training-day label.check-label:after,
.login .fragebogen-training-day label.check-label:after {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\edfc";
  color: #FB2A1A;
  opacity: 1;
  transform: scale(1);
}
.anmeldung .fragebogen-training-day label.check-label:before, .anmeldung .fragebogen-training-day label.check-label:after,
.login .fragebogen-training-day label.check-label:before,
.login .fragebogen-training-day label.check-label:after {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  top: 12px;
  font-size: 24px;
  transition: all 0.1s ease-out;
}
.anmeldung .fragebogen-training-day label.check-label.checked,
.login .fragebogen-training-day label.check-label.checked {
  background: #fff;
  color: #000;
}
.anmeldung .fragebogen-training-day label.check-label.checked:before,
.login .fragebogen-training-day label.check-label.checked:before {
  opacity: 1;
  transform: scale(1);
}
.anmeldung .fragebogen-training-day label.check-label.checked:after,
.login .fragebogen-training-day label.check-label.checked:after {
  opacity: 0;
  transform: scale(0.5);
}

.just-show {
  overflow: auto;
}
.just-show .popup {
  position: relative;
}

.login {
  background: #F0F2F4;
}
.login .login-form {
  background: #fff;
}
.login .login-form input[type=text],
.login .login-form input[type=email],
.login .login-form input[type=password] {
  background: #fff;
  color: #263B47;
  border-bottom: 1px solid #70c4fa;
}
.login .login-form .login-text {
  color: #263B47 !important;
}
.login .login-form .login-text a {
  color: #263B47 !important;
  display: inline !important;
  background: none !important;
  border-radius: none !important;
  box-shadow: none !important;
}
.login .login-form .page-main {
  flex-direction: column;
}
.login .login-form .page-main .page.messages {
  margin: -15px 20px;
}
.login .anmeldung-text {
  color: #263B47 !important;
}
.login .anmeldung-text img {
  width: 60% !important;
  height: auto !important;
  margin-top: 20px;
}
.login h1 {
  font-size: 40px;
}

/*Dashboard-----------*/
.panel-head {
  color: #CED1D3;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 10px;
}

.dashboard-shortcuts {
  position: relative;
}
.dashboard-shortcuts a.shortcut {
  position: relative;
  background: #29A9FC;
  color: #fff;
  display: table;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  text-align: center;
  height: 170px;
  width: 100%;
  text-decoration: none;
  transition: all 0.1s ease-out;
  box-sizing: border-box;
}
.dashboard-shortcuts a.shortcut > div {
  display: table-row;
}
.dashboard-shortcuts a.shortcut > div > div {
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.dashboard-shortcuts a.shortcut > div > div span {
  position: absolute;
  right: 7px;
  top: 7px;
  font-size: 24px;
}
.dashboard-shortcuts a.shortcut > div > div .dashboard-shortuts-count {
  font-weight: 700;
  font-size: 48px;
  padding-bottom: 12px;
}
.dashboard-shortcuts a.shortcut table.dashboard-marathon {
  width: 100%;
}
.dashboard-shortcuts a.shortcut table.dashboard-marathon td {
  vertical-align: bottom;
  text-align: left;
}
.dashboard-shortcuts a.shortcut table.dashboard-marathon td > span {
  font-size: 20px;
  position: relative;
  right: 0;
  top: 0;
}
.dashboard-shortcuts a.shortcut .competition {
  text-align: left;
  margin-left: -10px;
  margin-right: -10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2px 15px;
  font-size: 14px;
  width: calc(100% + 20px);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.dashboard-shortcuts a.shortcut .competition:last-child {
  border: 0;
}
.dashboard-shortcuts a.shortcut .competition span.icon-trophy1 {
  position: relative;
  top: 0;
  right: 0;
  font-size: 16px;
}
.dashboard-shortcuts a.shortcut .competition span.icon-flag3 {
  position: relative;
  top: 0;
  right: 0;
  font-size: 16px;
}
.dashboard-shortcuts a.shortcut .competition div:first-child {
  flex-basis: 25%;
}
.dashboard-shortcuts a.shortcut .competition div:nth-child(2) {
  text-align: center;
}
.dashboard-shortcuts a.shortcut .competition div:last-child {
  text-align: right;
}
.dashboard-shortcuts a.shortcut .main-competition {
  font-size: 16px;
  padding: 10px 35px 10px 15px;
}
.dashboard-shortcuts a.shortcut .main-competition > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header-info {
  background: #29A9FC;
  color: #fff;
  text-align: center;
  height: auto;
  line-height: 70px;
  vertical-align: middle;
  z-index: 2;
  position: relative;
}
.header-info .header-info_text {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
}

.desktop .dashboard-shortcuts a.shortcut:hover {
  transform: scale(1.05);
}
.desktop .dashboard-shortcuts a.shortcut:active {
  transform: scale(0.95);
}

.dashboard-stats,
.dashboard-shortcuts {
  margin-top: 30px;
  clear: both;
}

.header-info {
  background: #29A9FC;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.content-wrapper {
  position: relative;
}

@media only screen and (max-width: 768px) {
  .cd-main-content .content-wrapper {
    padding-top: 55px;
  }
  .header-info {
    font-size: 14px;
    line-height: 52px;
    height: auto;
  }
  .header-info .header-info_text {
    line-height: 24px;
  }
}
@media only screen and (max-width: 700px) {
  .header-info {
    font-size: 12px;
  }
  .header-info .header-info_text {
    line-height: 16px;
  }
}
@media only screen and (max-width: 550px) {
  .header-info {
    height: auto;
    line-height: 65px;
  }
}
@media only screen and (min-width: 768px) {
  /*.cd-main-content::before {
    content: 'tablet';
  }*/
}
@media only screen and (min-width: 1170px) {
  /*.cd-main-content::before {
    content: 'desktop';
  }*/
}
/* --------------------------------

Header

-------------------------------- */
.cd-main-header {
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  height: 45px;
  width: 100%;
  background: #263B47;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cd-main-header .nav-logo {
  height: 30px;
  width: auto !important;
  margin-left: 20px;
  margin-top: 7px;
}

.cd-main-header::after {
  clear: both;
  content: "";
  display: table;
}

@media only screen and (min-width: 769px) {
  .cd-main-header {
    position: fixed;
    height: 0;
  }
  .cd-main-header .nav-logo {
    display: none;
  }
}
.cd-nav-trigger {
  /* navigation trigger - visible on mobile devices only */
  float: right;
  position: relative;
  display: block;
  width: 34px;
  height: 44px;
  margin-right: 5%;
  /* hide text */
  overflow: hidden;
  white-space: nowrap;
  color: transparent;
}

.cd-nav-trigger span, .cd-nav-trigger span::before, .cd-nav-trigger span::after {
  /* hamburger icon in CSS */
  position: absolute;
  display: inline-block;
  height: 3px;
  width: 24px;
  background: #ffffff;
}

.cd-nav-trigger span {
  /* line in the center */
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -1px;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
}

.cd-nav-trigger span::before, .cd-nav-trigger span::after {
  /* other 2 lines */
  content: "";
  right: 0;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition: -webkit-transform 0.2s;
  -moz-transition: -moz-transform 0.2s;
  transition: transform 0.2s;
}

.cd-nav-trigger span::before {
  /* menu icon top line */
  top: -6px;
}

.cd-nav-trigger span::after {
  /* menu icon bottom line */
  top: 6px;
}

.cd-nav-trigger.nav-is-visible span {
  /* hide line in the center */
  background: rgba(255, 255, 255, 0);
}

.cd-nav-trigger.nav-is-visible span::before, .cd-nav-trigger.nav-is-visible span::after {
  /* keep visible other 2 lines */
  background: white;
}

.cd-nav-trigger.nav-is-visible span::before {
  -webkit-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -moz-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -ms-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -o-transform: translateX(4px) translateY(-3px) rotate(45deg);
  transform: translateX(4px) translateY(-3px) rotate(45deg);
}

.cd-nav-trigger.nav-is-visible span::after {
  -webkit-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -moz-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -ms-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -o-transform: translateX(4px) translateY(2px) rotate(-45deg);
  transform: translateX(4px) translateY(2px) rotate(-45deg);
}

@media only screen and (min-width: 769px) {
  .cd-nav-trigger {
    display: none;
  }
}
/* --------------------------------

Top Navigation

-------------------------------- */
.cd-nav {
  /* top nav - not visible on mobile devices */
  display: none;
}

@media only screen and (min-width: 768px) {
  .cd-nav {
    display: block;
    float: right;
    height: 100%;
  }
}
.cd-top-nav > li > a::before {
  /* reset style */
  display: none;
}

.cd-top-nav > li a {
  padding: 1em 5% !important;
}

.cd-top-nav img {
  /* avatar image */
  position: absolute;
  left: 1.8em;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: none;
}

@media only screen and (min-width: 768px) {
  .cd-top-nav {
    height: 100%;
  }
  .cd-top-nav a {
    display: block;
    font-size: 1.4rem;
    color: #ffffff;
  }
  .cd-top-nav > li {
    display: inline-block;
    margin-right: 1em;
    height: 100%;
  }
  .cd-top-nav > li:last-of-type {
    margin-right: 0;
  }
  .cd-top-nav > li a {
    padding: 1em 0.6em !important;
  }
  .cd-top-nav img {
    display: block;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-top-nav li:not(.has-children) a:hover {
    color: #1784c7;
  }
}
.desktop .cd-side-nav > ul > li a:hover {
  background: #000;
  color: #fff;
  opacity: 1;
}
.desktop .cd-side-nav > ul > li a:hover span {
  opacity: 1;
}

.mt-video-wrapper .mt-video-genre {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
}
.mt-video-wrapper .mt-video-genre:nth-child(odd) {
  background: #fff;
}
.mt-video-wrapper .mt-video-genre:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.mt-video-wrapper .owl-item .item .btn {
  position: absolute;
  top: 50%;
  margin-top: -19px;
  left: 50%;
  margin-left: -62px;
}

.no-display {
  display: none;
}

.video-carousel-nav .item a.add-fav, .video-carousel .item a.add-fav, .fav-video-carousel .item a.add-fav {
  right: 20px;
  top: 20px;
}

.video-carousel-nav,
.video-carousel,
.fav-video-carousel,
.award-carousel,
.award-stats-carousel,
.challenge-carousel {
  overflow: hidden;
  padding-bottom: 10px;
}
.video-carousel-nav .owl-item,
.video-carousel .owl-item,
.fav-video-carousel .owl-item,
.award-carousel .owl-item,
.award-stats-carousel .owl-item,
.challenge-carousel .owl-item {
  opacity: 0.1;
  transition: all 1s ease-out;
}
.video-carousel-nav .owl-item .item,
.video-carousel .owl-item .item,
.fav-video-carousel .owl-item .item,
.award-carousel .owl-item .item,
.award-stats-carousel .owl-item .item,
.challenge-carousel .owl-item .item {
  padding: 10px;
}
.video-carousel-nav .owl-item .item a,
.video-carousel .owl-item .item a,
.fav-video-carousel .owl-item .item a,
.award-carousel .owl-item .item a,
.award-stats-carousel .owl-item .item a,
.challenge-carousel .owl-item .item a {
  text-decoration: none;
  color: #263B47;
}
.video-carousel-nav .owl-item .item a .video-img-wrapper,
.video-carousel .owl-item .item a .video-img-wrapper,
.fav-video-carousel .owl-item .item a .video-img-wrapper,
.award-carousel .owl-item .item a .video-img-wrapper,
.award-stats-carousel .owl-item .item a .video-img-wrapper,
.challenge-carousel .owl-item .item a .video-img-wrapper {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin-bottom: 5px;
}
.video-carousel-nav .owl-item .item.new a .video-img-wrapper,
.video-carousel .owl-item .item.new a .video-img-wrapper,
.fav-video-carousel .owl-item .item.new a .video-img-wrapper,
.award-carousel .owl-item .item.new a .video-img-wrapper,
.award-stats-carousel .owl-item .item.new a .video-img-wrapper,
.challenge-carousel .owl-item .item.new a .video-img-wrapper {
  position: relative;
}
.video-carousel-nav .owl-item .item.new a .video-img-wrapper:before,
.video-carousel .owl-item .item.new a .video-img-wrapper:before,
.fav-video-carousel .owl-item .item.new a .video-img-wrapper:before,
.award-carousel .owl-item .item.new a .video-img-wrapper:before,
.award-stats-carousel .owl-item .item.new a .video-img-wrapper:before,
.challenge-carousel .owl-item .item.new a .video-img-wrapper:before {
  content: "neu";
  background: #FB2A1A;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 5px 0;
  color: #fff;
  text-transform: uppercase;
  padding: 3px 10px;
  font-weight: 700;
}
.video-carousel-nav .owl-item .item .video-dummy .video-img-wrapper,
.video-carousel .owl-item .item .video-dummy .video-img-wrapper,
.fav-video-carousel .owl-item .item .video-dummy .video-img-wrapper,
.award-carousel .owl-item .item .video-dummy .video-img-wrapper,
.award-stats-carousel .owl-item .item .video-dummy .video-img-wrapper,
.challenge-carousel .owl-item .item .video-dummy .video-img-wrapper {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  margin-bottom: 5px;
}
.video-carousel-nav .owl-item.active,
.video-carousel .owl-item.active,
.fav-video-carousel .owl-item.active,
.award-carousel .owl-item.active,
.award-stats-carousel .owl-item.active,
.challenge-carousel .owl-item.active {
  opacity: 1;
}

.video-carousel-nav .owl-nav,
.video-carousel .owl-nav,
.award-carousel .owl-nav,
.fav-video-carousel .owl-nav,
.award-stats-carousel .owl-nav,
.angebote-carousel .owl-nav,
.challenge-carousel .owl-nav {
  margin: 0;
  padding: 0;
}
.video-carousel-nav .owl-nav .owl-prev,
.video-carousel-nav .owl-nav .owl-next,
.video-carousel .owl-nav .owl-prev,
.video-carousel .owl-nav .owl-next,
.award-carousel .owl-nav .owl-prev,
.award-carousel .owl-nav .owl-next,
.fav-video-carousel .owl-nav .owl-prev,
.fav-video-carousel .owl-nav .owl-next,
.award-stats-carousel .owl-nav .owl-prev,
.award-stats-carousel .owl-nav .owl-next,
.angebote-carousel .owl-nav .owl-prev,
.angebote-carousel .owl-nav .owl-next,
.challenge-carousel .owl-nav .owl-prev,
.challenge-carousel .owl-nav .owl-next {
  position: absolute;
  left: 0;
  opacity: 1;
  width: 90px;
  top: 0;
  height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0);
  background: none;
}
.video-carousel-nav .owl-nav .owl-prev:before,
.video-carousel-nav .owl-nav .owl-next:before,
.video-carousel .owl-nav .owl-prev:before,
.video-carousel .owl-nav .owl-next:before,
.award-carousel .owl-nav .owl-prev:before,
.award-carousel .owl-nav .owl-next:before,
.fav-video-carousel .owl-nav .owl-prev:before,
.fav-video-carousel .owl-nav .owl-next:before,
.award-stats-carousel .owl-nav .owl-prev:before,
.award-stats-carousel .owl-nav .owl-next:before,
.angebote-carousel .owl-nav .owl-prev:before,
.angebote-carousel .owl-nav .owl-next:before,
.challenge-carousel .owl-nav .owl-prev:before,
.challenge-carousel .owl-nav .owl-next:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ee05";
  display: block;
  height: 50px;
  line-height: 40px;
  font-size: 40px;
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -20px;
  color: #000;
}
.video-carousel-nav .owl-nav .owl-next,
.video-carousel .owl-nav .owl-next,
.award-carousel .owl-nav .owl-next,
.fav-video-carousel .owl-nav .owl-next,
.award-stats-carousel .owl-nav .owl-next,
.angebote-carousel .owl-nav .owl-next,
.challenge-carousel .owl-nav .owl-next {
  right: 0;
  left: auto;
}
.video-carousel-nav .owl-nav .owl-next:before,
.video-carousel .owl-nav .owl-next:before,
.award-carousel .owl-nav .owl-next:before,
.fav-video-carousel .owl-nav .owl-next:before,
.award-stats-carousel .owl-nav .owl-next:before,
.angebote-carousel .owl-nav .owl-next:before,
.challenge-carousel .owl-nav .owl-next:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ee06";
}
.video-carousel-nav .owl-nav .disabled,
.video-carousel .owl-nav .disabled,
.award-carousel .owl-nav .disabled,
.fav-video-carousel .owl-nav .disabled,
.award-stats-carousel .owl-nav .disabled,
.angebote-carousel .owl-nav .disabled,
.challenge-carousel .owl-nav .disabled {
  display: none;
}

.owl-dots {
  position: relative;
  top: -10px;
}
.owl-dots .owl-dot {
  height: 0;
}
.owl-dots .owl-dot span {
  background: transparent;
  border: 2px solid #CED1D3;
  margin: 4px;
  width: 12px;
  height: 12px;
}
.owl-dots .owl-dot.active span {
  background: #CED1D3;
}

.awards {
  background: #000;
}
.awards h1 {
  color: #263B47;
}
.awards h2 {
  text-align: center;
  color: #263B47;
  padding-bottom: 10px;
}
.awards .award-carousel .owl-item {
  box-sizing: border-box;
  margin: 0 !important;
}
.awards .award-carousel .owl-item .item {
  text-align: center;
  perspective: 400px;
  padding: 0 10px;
  box-sizing: border-box;
  line-height: 16px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #263B47;
}
.awards .award-carousel .owl-item .item img {
  height: 140px !important;
  width: auto !important;
  display: inline-block;
  transform: rotateY(-360deg);
  transition: all 1s ease-out;
}
.awards .award-carousel .owl-item .item.award-next {
  color: #263B47;
}
.awards .award-carousel .owl-item .item.award-next img {
  transform: rotateY(0deg) !important;
  transition: none !important;
  opacity: 0.5;
}
.awards .award-carousel .owl-item.active {
  opacity: 1;
}
.awards .award-carousel .owl-item.active .item img {
  transform: rotateY(0deg);
}
.awards .owl-dots {
  margin-top: -5px !important;
}
.awards .owl-dots .owl-dot span {
  border: 2px solid #263B47;
}
.awards .owl-dots .owl-dot.active span {
  background: #263B47;
}
.awards .video-carousel-nav .owl-nav .owl-prev:before,
.awards .video-carousel-nav .owl-nav .owl-next:before,
.awards .video-carousel .owl-nav .owl-prev:before,
.awards .video-carousel .owl-nav .owl-next:before,
.awards .fav-video-carousel .owl-nav .owl-prev:before,
.awards .fav-video-carousel .owl-nav .owl-next:before,
.awards .award-carousel .owl-nav .owl-prev:before,
.awards .award-carousel .owl-nav .owl-next:before,
.awards .award-stats-carousel .owl-nav .owl-prev:before,
.awards .award-stats-carousel .owl-nav .owl-next:before {
  color: #fff;
}
.awards .award-stats-carousel .owl-item {
  opacity: 0.5;
}
.awards .award-stats-carousel .owl-item .item {
  position: relative;
  background: #263B47;
  color: #000;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin: 0 2px;
  padding-left: 70px;
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.3);
  perspective: 70px;
}
.awards .award-stats-carousel .owl-item .item .fact {
  color: #fff;
  font-size: 24px;
  padding-top: 8px;
  padding-bottom: 5px;
}
.awards .award-stats-carousel .owl-item .item img {
  height: 50px !important;
  width: auto !important;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -25px;
  opacity: 0;
  /*transform: rotateY(-60deg);
            @include animate-all-med;*/
}
.awards .award-stats-carousel .owl-item.active {
  opacity: 1;
}
.awards .award-stats-carousel .owl-item.active .item img {
  opacity: 1;
  transform: rotateY(0deg);
}
.awards .award-stats-carousel .owl-nav .owl-prev,
.awards .award-stats-carousel .owl-nav .owl-next {
  margin-top: 5px !important;
}

.payment-advantages {
  display: inline-block;
  background: #CED1D3;
  border-radius: 20px;
  padding: 7px 15px;
  margin: 10px 10px 25px 10px;
}
.payment-advantages > div {
  display: inline-block;
  padding: 3px 6px;
}
.payment-advantages > div span {
  color: #29A9FC;
}

.payment-routine-header {
  max-width: 880px;
  margin: 0 auto;
  padding: 15px 0 25px 0;
}
.payment-routine-header:after {
  content: "";
  display: block;
  clear: both;
}
.payment-routine-header .payment-routine-step-name {
  font-size: 14px;
  width: 33%;
  float: left;
  color: #CED1D3;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 15px;
}
.payment-routine-header .payment-routine-step-name a {
  color: #CED1D3;
  text-decoration: none;
}
.payment-routine-header .payment-routine-status {
  height: 10px;
  position: relative;
  background: #F0F2F4;
  border-radius: 20px;
}
.payment-routine-header .payment-routine-status:before {
  content: "";
  display: block;
  width: 33%;
  height: 10px;
  background: #29A9FC;
  border-radius: 20px;
  position: absolute;
  left: 0;
  top: 0;
}
.payment-routine-header .payment-routine-status:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top: 7px solid #29A9FC;
  position: absolute;
  top: 10px;
  left: 16.5%;
  margin-left: -7px;
}
.payment-routine-header.step-1 .payment-routine-step-name-step-1 {
  color: #29A9FC;
}
.payment-routine-header.step-1 .payment-routine-status:before {
  left: 0;
}
.payment-routine-header.step-1 .payment-routine-status:after {
  left: 16.5%;
}
.payment-routine-header.step-2 .payment-routine-step-name-step-2 {
  color: #29A9FC;
}
.payment-routine-header.step-2 .payment-routine-status:before {
  left: 33%;
}
.payment-routine-header.step-2 .payment-routine-status:after {
  left: 49.5%;
}
.payment-routine-header.step-3 .payment-routine-step-name-step-3 {
  color: #29A9FC;
}
.payment-routine-header.step-3 .payment-routine-status:before {
  left: 67%;
}
.payment-routine-header.step-3 .payment-routine-status:after {
  left: 82.5%;
}

.desktop .payment-routine-header .payment-routine-step-name a:hover {
  color: #29A9FC;
}

@media only screen and (max-width: 768px) {
  .payment-routine-header .payment-routine-step-name {
    display: none;
  }
  .payment-routine-header .payment-routine-status {
    text-align: center;
    color: #fff;
    height: 32px;
  }
  .payment-routine-header .payment-routine-status:before {
    height: 32px;
    line-height: 32px;
    font-weight: 700;
    font-size: 24px;
  }
  .payment-routine-header .payment-routine-status:after {
    display: none;
  }
  .payment-routine-header.step-1 .payment-routine-status:before {
    content: "1";
  }
  .payment-routine-header.step-2 .payment-routine-status:before {
    content: "2";
  }
  .payment-routine-header.step-3 .payment-routine-status:before {
    content: "3";
  }
  .payment-routine-header.step-4 .payment-routine-status:before {
    content: "4";
  }
}
.angebote-carousel {
  margin-bottom: 20px;
}
.angebote-carousel .owl-item .item {
  opacity: 0.3;
}
.angebote-carousel .owl-item .item a {
  display: block;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
  background: #263B47;
  position: relative;
}
.angebote-carousel .owl-item .item a img {
  opacity: 0.3;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 1s ease-out;
}
.angebote-carousel .owl-item .item a span {
  display: block;
  width: 100%;
  padding: 30px 10px;
  position: relative;
  box-sizing: border-box;
  z-index: 10;
  text-transform: uppercase;
  font-weight: 700;
  text-shadow: 1px 1px 2px #000;
}
.angebote-carousel .owl-item .item.nav-active a {
  box-shadow: 0 0 0 5px #29A9FC;
}
.angebote-carousel .owl-item.active .item {
  opacity: 1;
}
.angebote-carousel .owl-dots {
  margin-top: -5px !important;
}
.angebote-carousel .owl-nav {
  margin: 0;
  padding: 0;
}
.angebote-carousel .owl-nav .owl-prev,
.angebote-carousel .owl-nav .owl-next {
  line-height: 40px !important;
  width: 36px;
  height: 40px;
  top: 50%;
  margin-top: -32px;
  font-size: 22px !important;
}
.angebote-carousel .owl-nav .owl-prev:before,
.angebote-carousel .owl-nav .owl-next:before {
  font-size: 22px !important;
  line-height: 40px !important;
}

@media only screen and (max-width: 768px) {
  .angebote-carousel .item a span {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1200px) {
  .container-angebote {
    width: 100%;
    padding: 0;
  }
  .container-angebote .row {
    padding: 0;
    margin: 0;
  }
  .container-angebote .row > div {
    padding: 0;
    margin: 0;
  }
}
.desktop .angebote-carousel .item a:hover img {
  transform: scale(1.3);
}

.profile-item {
  padding: 3px 15px;
  border-radius: 5px;
  margin-bottom: 2px;
  background: #fff;
  transition: all 0.1s ease-out;
}

.profile-item:after {
  content: "";
  display: block;
  clear: both;
}

.desktop .profile-item:hover {
  background: #F0F2F4;
}

.abo-info, .payment-info {
  background: #F0F2F4;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 5px;
}

.abo-info .payment-img img, .payment-info .payment-img img {
  vertical-align: middle;
  width: auto !important;
}

.abo-info .payment-img img {
  height: 20px !important;
}

.payment-info .payment-img img {
  height: 32px;
  display: inline-block;
  margin-left: 5px;
}

.abo-info .abo-name,
.abo-info .payment-name,
.payment-info .abo-name, .payment-info .payment-name {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  min-height: 25px;
}

.payment-info .payment-name {
  line-height: 32px;
}

.abo-info .abo-type,
.abo-info .abo-time,
.payment-info .abo-type,
.payment-info .abo-time {
  font-size: 14px;
}

.abo-info .abo-duration,
.payment-info .abo-duration {
  font-size: 20px;
  font-weight: 700;
  min-height: 25px;
}

.abo-info .abo-canceled,
.payment-info .abo-canceled {
  background: #263B47;
  color: #fff;
  clear: both;
  position: relative;
  bottom: -12px;
  margin-left: -3px;
  margin-right: -3px;
  text-transform: uppercase;
  border-radius: 0 0 5px 5px;
  padding: 2px;
}

.abo-info .abo-canceled:before,
.payment-info .abo-canceled:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-bottom: 7px solid #263B47;
  position: absolute;
  left: 50%;
  margin-left: -7px;
  top: -14px;
}

.cost-comparison,
.payment-method-wrapper {
  max-width: 880px;
  margin: 0 auto;
}
.cost-comparison > div,
.payment-method-wrapper > div {
  background: #F0F2F4;
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-bottom: 15px;
}
.cost-comparison > div h2,
.payment-method-wrapper > div h2 {
  text-align: center;
}
.cost-comparison > div h2 span,
.payment-method-wrapper > div h2 span {
  display: block;
  font-size: 16px;
  text-transform: none;
}
.cost-comparison > div.cost-comparison-hint,
.payment-method-wrapper > div.cost-comparison-hint {
  background: #29A9FC;
  color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 10;
}
.cost-comparison > div.cost-comparison-hint h2,
.payment-method-wrapper > div.cost-comparison-hint h2 {
  color: #fff;
}
.cost-comparison > div ul,
.payment-method-wrapper > div ul {
  list-style-type: none;
  padding-left: 25px;
  max-width: 300px;
  box-sizing: border-box;
  margin: 0 auto;
}
.cost-comparison > div ul li,
.payment-method-wrapper > div ul li {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4em;
  padding-bottom: 7px;
}
.cost-comparison > div ul li strong,
.payment-method-wrapper > div ul li strong {
  text-transform: uppercase;
}
.cost-comparison > div ul li:before,
.payment-method-wrapper > div ul li:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #29A9FC;
  content: "\edfb";
  position: absolute;
  left: -25px;
  top: 3px;
}
.cost-comparison > div ul.credit-card-types,
.payment-method-wrapper > div ul.credit-card-types {
  padding: 0;
  max-width: none;
}
.cost-comparison > div ul.credit-card-types li,
.payment-method-wrapper > div ul.credit-card-types li {
  list-style-type: none;
  display: inline-block;
  padding-top: 7px;
}
.cost-comparison > div ul.credit-card-types li > img,
.payment-method-wrapper > div ul.credit-card-types li > img {
  max-height: 20px;
  width: auto;
}
.cost-comparison > div ul.credit-card-types li::before,
.payment-method-wrapper > div ul.credit-card-types li::before {
  display: none;
}
.cost-comparison > div ul.credit-card-types.payment-method_right,
.payment-method-wrapper > div ul.credit-card-types.payment-method_right {
  position: absolute;
  right: 10px;
}
.cost-comparison > div ul.credit-card-types.payment-method_right li,
.payment-method-wrapper > div ul.credit-card-types.payment-method_right li {
  padding-top: 0;
}
.cost-comparison > div.cost-comparison-hint,
.payment-method-wrapper > div.cost-comparison-hint {
  background: #29A9FC;
  color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 10;
}
.cost-comparison > div.cost-comparison-hint h2,
.payment-method-wrapper > div.cost-comparison-hint h2 {
  color: #fff;
}
.cost-comparison > div.cost-comparison-hint ul li:before,
.payment-method-wrapper > div.cost-comparison-hint ul li:before {
  color: #fff;
}
.cost-comparison footer,
.payment-method-wrapper footer {
  position: relative;
  bottom: 0;
  left: 0;
  text-align: center;
  background: #263B47;
  color: #fff;
  padding: 15px 10px;
  box-sizing: border-box;
  margin-left: -12px;
  margin-right: -12px;
  font-size: 14px;
  width: calc(100% + 24px);
  margin-top: 20px;
  /*
      .custom-radio {

          label {
              text-align: center;
              padding: 20px 5px 5px 5px;
              background: $color-blue;
              @include border-radius;
              line-height: 18px;
              margin-left: -9px;
              margin-right: -9px;
              height: 100%;

              &:before {
                  top: 5px;
                  left: 50%;
                  margin-left: -10px;
                  margin-top: 0;
              }
              &:after {
                  top: 10px;
                  left: 50%;
                  margin-left: -5px;
                  margin-top: 0;
              }
          }
      }
      */
}
.cost-comparison footer .row,
.payment-method-wrapper footer .row {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
.cost-comparison .input-disabled,
.payment-method-wrapper .input-disabled {
  opacity: 0.15;
}
.cost-comparison .input-disabled label:before,
.payment-method-wrapper .input-disabled label:before {
  display: none;
}
.cost-comparison .active-package .input-disabled,
.payment-method-wrapper .active-package .input-disabled {
  opacity: 1;
}
.cost-comparison .active-package .input-disabled label,
.payment-method-wrapper .active-package .input-disabled label {
  background: #29A9FC;
  border: #29A9FC;
  color: #263B47;
}
.cost-comparison .active-package .input-disabled label:before,
.payment-method-wrapper .active-package .input-disabled label:before {
  display: block;
  border: 1px solid #263B47;
  content: "\edfb";
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #29A9FC;
  top: 50%;
  margin: 0;
  left: 7px;
  margin-top: -11px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.cost-comparison .active-package .input-disabled label .ac-plan-time span,
.payment-method-wrapper .active-package .input-disabled label .ac-plan-time span {
  display: block;
  color: #fff;
}

.payment-finish .check-message-wrapper {
  display: inline-block;
}
.payment-finish .check-message-wrapper .check-message {
  display: table;
  border: 3px solid #29A9FC;
  border-radius: 200px;
  width: 120px;
  height: 120px;
  text-align: center;
  color: #29A9FC;
  font-size: 12px;
  line-height: 14px;
}
.payment-finish .check-message-wrapper .check-message > div {
  display: table-row;
}
.payment-finish .check-message-wrapper .check-message > div > div {
  display: table-cell;
  vertical-align: middle;
  padding: 0 15px 10px 15px;
}
.payment-finish .check-message-wrapper .check-message > div > div span {
  font-size: 45px;
  color: #29A9FC;
  display: block;
  padding-bottom: 10px;
}
.payment-finish a {
  text-decoration: none;
}

.payment {
  padding-bottom: 25px;
}
.payment.eccard {
  padding-bottom: 0;
}

.payment-method-wrapper > div {
  background: none;
}
.payment-method-wrapper .modal-header {
  display: none;
}
.payment-method-wrapper .payment-info-text {
  padding-top: 20px;
  font-size: 12px;
  line-height: 16px;
  color: #CED1D3;
}
.payment-method-wrapper .payment-info-text a {
  color: #CED1D3;
}
.payment-method-wrapper .payment-data {
  background: transparent;
  font-size: 14px;
  line-height: 26px;
}
.payment-method-wrapper .payment-data h3 {
  padding-top: 5px;
}
.payment-method-wrapper .payment-method-row {
  background: none;
}
.payment-method-wrapper .payment-method-row #checkoutSteps li {
  list-style-type: none;
}
.payment-method-wrapper .payment-method-row .payment-method {
  background: #F0F2F4;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  transition: all 0.1s ease-out;
  border: 1px solid #F0F2F4;
  margin-bottom: 5px;
}
.payment-method-wrapper .payment-method-row .payment-method .field-error {
  color: red;
  font-size: 12px;
}
.payment-method-wrapper .payment-method-row .payment-method input[type=email], .payment-method-wrapper .payment-method-row .payment-method input[type=text], .payment-method-wrapper .payment-method-row .payment-method input[type=password], .payment-method-wrapper .payment-method-row .payment-method input[type=number] {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  background: #fff;
  width: 100%;
  border: 0;
  padding: 10px;
  font-size: 16px;
  color: #263B47;
  box-sizing: border-box;
  margin: 3px 0;
  font-family: "Open Sans", sans-serif;
}
.payment-method-wrapper .payment-method-row .payment-method .control select {
  background: #FFFFFF;
  height: 42px;
  font-size: 16px;
  width: 100%;
  border: 0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  color: #6ac2fe;
  padding: 10px;
  margin: 3px 0;
  font-family: "Open Sans", sans-serif;
}
.payment-method-wrapper .payment-method-row .payment-method .payment-form {
  display: none;
  padding-bottom: 10px;
  position: relative;
}
.payment-method-wrapper .payment-method-row .payment-method label.check-label {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border: 0;
  margin: 0;
  color: #000;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.payment-method-wrapper .payment-method-row .payment-method label.check-label img {
  height: 20px !important;
  width: auto !important;
  position: initial;
}
.payment-method-wrapper .payment-method-row .payment-method label.check-label img.payment-icon {
  order: 3;
  margin: 8px 0;
}
.payment-method-wrapper .payment-method-row .payment-method label.check-label .credit-card-types.payment-method_right {
  order: 1;
  position: initial;
  right: 10px;
  width: max-content;
  margin: 8px 0;
}
.payment-method-wrapper .payment-method-row .payment-method label.check-label .credit-card-types.payment-method_right li {
  padding-top: 0;
  padding-bottom: 0;
}
.payment-method-wrapper .payment-method-row .payment-method label.check-label .credit-card-types.payment-method_right li img {
  position: relative;
  right: 0;
  top: 0;
  margin-top: 0;
}
.payment-method-wrapper .payment-method-row .payment-method label.check-label:before {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.payment-method-wrapper .payment-method-row .payment-method.show {
  border: 1px solid #29A9FC;
  background: #c8e9ff;
}
.payment-method-wrapper .payment-method-row .payment-method.show label.check-label:before {
  background: #fff;
  color: #29A9FC;
  border: 1px solid #fff;
}
.payment-method-wrapper .payment-method-row .checkout-billing-address {
  font-size: 0;
}
.payment-method-wrapper .payment-method-row .checkout-billing-address .field {
  width: 50%;
  padding: 0 10px;
  display: inline-block;
  font-size: 16px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.payment-method-wrapper .payment-method-row .checkout-billing-address .field.field-select-billing {
  width: 100%;
}
.payment-method-wrapper .payment-method-row .checkout-billing-address .field .control .field {
  width: 100%;
  padding: 0;
}
.payment-method-wrapper .payment-method-row .billing-address-details, .payment-method-wrapper .payment-method-row .payment-method-note {
  font-size: 16px;
  padding: 0 10px;
}
.payment-method-wrapper .payment-method-row .form {
  padding: 0 10px;
}
.payment-method-wrapper .payment-method-row .actions-toolbar {
  padding: 10px;
}
.payment-method-wrapper .payment-method-row .actions-toolbar.actions-toolbar_ec {
  padding: 0;
}

@media only screen and (min-width: 992px) {
  .cost-comparison > div:nth-child(2) {
    border-right: 5px solid #fff;
  }
  .cost-comparison > div:last-child {
    border-left: 5px solid #fff;
  }
  .cost-comparison > div.cost-comparison-hint {
    top: -10px;
  }
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home2:before {
  content: "\e90f";
}

.icon-office:before {
  content: "\e916";
}

.icon-pencil:before {
  content: "\e918";
}

.icon-fire:before {
  content: "\e95a";
}

.icon-gear3:before {
  content: "\e9c5";
}

.icon-saw:before {
  content: "\e9cd";
}

.icon-heart:before {
  content: "\e9e7";
}

.icon-star:before {
  content: "\e9e9";
}

.icon-flag3:before {
  content: "\e9ee";
}

.icon-paper-plane:before {
  content: "\e9f9";
}

.icon-paperclip:before {
  content: "\e9fa";
}

.icon-eye2:before {
  content: "\ea0f";
}

.icon-printer:before {
  content: "\ea1d";
}

.icon-file-empty:before {
  content: "\ea21";
}

.icon-scissors:before {
  content: "\ea40";
}

.icon-trophy1:before {
  content: "\ea5e";
}

.icon-playlist2:before {
  content: "\ea74";
}

.icon-camera3:before {
  content: "\ea98";
}

.icon-book-bookmarked2:before {
  content: "\eaa8";
}

.icon-user:before {
  content: "\eab4";
}

.icon-user-check:before {
  content: "\eabb";
}

.icon-group:before {
  content: "\eac2";
}

.icon-portrait-male:before {
  content: "\eace";
}

.icon-portrait-female:before {
  content: "\eacf";
}

.icon-running:before {
  content: "\eadf";
}

.icon-bathroom-scale:before {
  content: "\eaea";
}

.icon-height:before {
  content: "\eaeb";
}

.icon-basket:before {
  content: "\eb20";
}

.icon-map:before {
  content: "\eb6f";
}

.icon-calendar1:before {
  content: "\eb73";
}

.icon-lamp-bright:before {
  content: "\ebda";
}

.icon-bubble:before {
  content: "\ec11";
}

.icon-heart2:before {
  content: "\ec2d";
}

.icon-chart:before {
  content: "\ec4e";
}

.icon-pot:before {
  content: "\ec6e";
}

.icon-victory:before {
  content: "\eade";
}

.icon-bootcamp:before {
  content: "\e915";
}

.icon-stretching:before {
  content: "\e917";
}

.icon-yoga:before {
  content: "\e91a";
}

.icon-kraft:before {
  content: "\e919";
}

.icon-info:before {
  content: "\e913";
}

.icon-cardio:before {
  content: "\e911";
}

.icon-apple:before {
  content: "\ec99";
}

.icon-smiley:before {
  content: "\ed1c";
}

.icon-neutral:before {
  content: "\ed20";
}

.icon-grin-evil:before {
  content: "\ed33";
}

.icon-watch-health:before {
  content: "\ed9f";
}

.icon-watch2:before {
  content: "\eda1";
}

.icon-clock:before {
  content: "\eda3";
}

.icon-stopwatch:before {
  content: "\edaa";
}

.icon-stopwatch-running:before {
  content: "\edac";
}

.icon-download:before {
  content: "\edad";
}

.icon-extract-up:before {
  content: "\edb7";
}

.icon-menu:before {
  content: "\ede5";
}

.icon-ul2:before {
  content: "\ede7";
}

.icon-checklist2:before {
  content: "\ede9";
}

.icon-play:before {
  content: "\edf1";
}

.icon-exclamation:before {
  content: "\edf9";
}

.icon-question:before {
  content: "\edfa";
}

.icon-check:before {
  content: "\edfb";
}

.icon-cross:before {
  content: "\edfc";
}

.icon-plus:before {
  content: "\edfe";
}

.icon-percent:before {
  content: "\ee01";
}

.icon-chevron-up:before {
  content: "\ee03";
}

.icon-chevron-down:before {
  content: "\ee04";
}

.icon-chevron-left:before {
  content: "\ee05";
}

.icon-chevron-right:before {
  content: "\ee06";
}

.icon-expand-vertical:before {
  content: "\ee07";
}

.icon-arrow-up:before {
  content: "\ee0b";
}

.icon-arrow-down:before {
  content: "\ee0c";
}

.icon-arrow-left:before {
  content: "\ee0d";
}

.icon-arrow-right:before {
  content: "\ee0e";
}

.icon-info-circle:before {
  content: "\ee28";
}

.icon-ruler:before {
  content: "\ee93";
}

.icon-menu2:before {
  content: "\ef06";
}

.icon-trophy:before {
  content: "\e900";
}

.icon-calendar:before {
  content: "\e901";
}

.icon-map-marker:before {
  content: "\e902";
}

.icon-pin:before {
  content: "\e902";
}

.icon-location:before {
  content: "\e902";
}

.icon-heart-shiny:before {
  content: "\e903";
}

.icon-male:before {
  content: "\e904";
}

.icon-female:before {
  content: "\e905";
}

.icon-pulse:before {
  content: "\e906";
}

.icon-bag1:before {
  content: "\e907";
}

.icon-home-user:before {
  content: "\e908";
}

.icon-bandages:before {
  content: "\e909";
}

.icon-medical:before {
  content: "\e909";
}

.icon-patch:before {
  content: "\e909";
}

.icon-calendar-check:before {
  content: "\e90a";
}

.icon-calendar2:before {
  content: "\e90a";
}

.icon-date:before {
  content: "\e90a";
}

.icon-schedule:before {
  content: "\e90a";
}

.icon-palm:before {
  content: "\e90b";
}

.icon-envelope:before {
  content: "\e90c";
}

.icon-mail2:before {
  content: "\e90c";
}

.icon-letter:before {
  content: "\e90c";
}

.icon-email:before {
  content: "\e90c";
}

.icon-smartphone:before {
  content: "\e90d";
}

.icon-phone2:before {
  content: "\e90d";
}

.icon-mobile-phone:before {
  content: "\e90d";
}

.icon-telephone:before {
  content: "\e90e";
}

.icon-phone22:before {
  content: "\e90e";
}

.icon-call:before {
  content: "\e90e";
}

.icon-contact:before {
  content: "\e90e";
}

.icon-handset:before {
  content: "\e90e";
}

.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  background-color: #000;
  opacity: 0;
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(0, 0, 0, 0.4)), color-stop(100%, rgba(0, 0, 0, 0.9)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  background: radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000',endColorstr='#e6000000',GradientType=1)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.introjs-fixParent {
  z-index: auto !important;
  opacity: 1 !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
}

.introjs-showElement,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
  z-index: 9999999 !important;
}

.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: white;
  opacity: 0;
  filter: alpha(opacity=0);
}

.introjs-relativePosition,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
  position: relative;
}

.introjs-helperLayer {
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  box-shadow: 0 0px 0px 10px rgba(255, 255, 255, 0.3);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.introjs-tooltipReferenceLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.introjs-helperLayer *,
.introjs-helperLayer *:before,
.introjs-helperLayer *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -ms-box-sizing: content-box;
  -o-box-sizing: content-box;
  box-sizing: content-box;
}

.introjs-helperNumberLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  top: 1px;
  left: 1px;
  z-index: 9999999999 !important;
  padding: 2px;
  font-family: Arial, verdana, tahoma;
  font-size: 13px;
  font-weight: 700;
  color: white;
  text-align: center;
  background: #29A9FC;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 4px 0 5px 0;
  display: none;
}

.introjs-arrow {
  border: 5px solid transparent;
  content: "";
  position: absolute;
}

.introjs-arrow.top {
  top: -10px;
  border-bottom-color: white;
}

.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color: white;
}

.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: white;
}

.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color: white;
}

.introjs-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-left-color: white;
}

.introjs-arrow.bottom {
  bottom: -10px;
  border-top-color: white;
}

.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: white;
}

.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: white;
}

.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color: white;
}

.introjs-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-right-color: white;
}

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  padding: 30px 10px 10px 10px;
  background-color: white;
  min-width: 200px;
  max-width: 300px;
  border-radius: 5px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  -webkit-transition: opacity 0.1s ease-out;
  -moz-transition: opacity 0.1s ease-out;
  -ms-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
}

.introjs-tooltipbuttons {
  text-align: right;
  white-space: nowrap;
}

.introjs-tooltiptext {
  padding-bottom: 10px;
}

.introjs-skipbutton {
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 4px;
  top: 4px;
  text-decoration: none;
  text-align: center;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
  color: #263B47;
  padding: 0;
  background: transparent;
}

.introjs-donebutton {
  border-radius: 50px;
  border: 0;
  box-sizing: border-box;
  display: inline-block;
  margin: 2px;
  padding: 0 25px;
  text-decoration: none;
  color: #fff;
  background: #29A9FC;
  cursor: pointer;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  height: 24px;
  line-height: 25px;
  font-size: 14px;
  text-transform: none;
  position: relative;
  width: auto;
  right: 0;
  top: 0;
  float: right;
}

.introjs-disabled, .introjs-disabled:hover, .introjs-disabled:focus {
  display: none;
}

.introjs-hidden {
  display: none;
}

.introjs-bullets {
  text-align: left;
  position: absolute;
  top: 0px;
}

.introjs-bullets ul {
  box-sizing: content-box;
  clear: both;
  margin: 15px auto 0;
  padding: 0;
  display: inline-block;
}

.introjs-bullets ul li {
  box-sizing: content-box;
  list-style: none;
  float: left;
  margin: 0 2px;
}

.introjs-bullets ul li a {
  box-sizing: content-box;
  display: block;
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}

.introjs-bullets ul li a:hover {
  background: #999;
}

.introjs-bullets ul li a.active {
  background: #999;
}

.introjs-progress {
  box-sizing: content-box;
  overflow: hidden;
  height: 10px;
  margin: 10px 0 5px 0;
  border-radius: 4px;
  background-color: #ecf0f1;
}

.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #08c;
}

.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%;
}

.introjs-fixedTooltip {
  position: fixed;
}

.introjs-hint {
  box-sizing: content-box;
  position: absolute;
  background: transparent;
  width: 20px;
  height: 15px;
  cursor: pointer;
}

.introjs-hint:focus {
  border: 0;
  outline: 0;
}

.introjs-hidehint {
  display: none;
}

.introjs-fixedhint {
  position: fixed;
}

.introjs-hint:hover > .introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.57);
}

.introjs-hint-pulse {
  box-sizing: content-box;
  width: 10px;
  height: 10px;
  border: 5px solid rgba(60, 60, 60, 0.27);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: rgba(136, 136, 136, 0.24);
  z-index: 10;
  position: absolute;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.introjs-hint-no-anim .introjs-hint-dot {
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}

.introjs-hint-dot {
  box-sizing: content-box;
  border: 10px solid rgba(146, 146, 146, 0.36);
  background: transparent;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  -webkit-animation: introjspulse 3s ease-out;
  -moz-animation: introjspulse 3s ease-out;
  animation: introjspulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  opacity: 0;
}

.belvg-loader img {
  position: absolute;
  left: 50%;
  margin-left: -32px;
  z-index: 1;
}

.preload-hide > * {
  opacity: 0.2;
  pointer-events: none;
}
.preload-hide > .belvg-loader {
  opacity: 1;
  display: block !important;
}

.gaus-blur {
  -webkit-transition: "1s";
  -moz-transition: "1s";
  -ms-transition: "1s";
  -o-transition: "1s";
  transition: "1s";
  zoom: 1;
  -o-filter: blur(10px);
  -moz-filter: blur(10px);
  -webkit-filter: blur(10px);
  filter: progid:DXImageTransform.Microsoft.Blur(pixelRadius=3);
  -ms-filter: "progid:DXImageTransform.Microsoft.Blur(pixelradius=3)";
  pointer-events: none;
}

.green_checkmark {
  position: relative;
  width: 21px;
  height: 12px;
  display: inline-block;
}

.check-sign {
  position: absolute;
  background: green;
}

#check-part-1 {
  width: 10px;
  height: 2px;
  transform: rotate(45deg);
  top: 7px;
}

#check-part-2 {
  width: 16px;
  height: 2px;
  transform: rotate(-45deg);
  left: 5px;
  top: 5px;
}

@-webkit-keyframes introjspulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@-moz-keyframes introjspulse {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }
  25% {
    -moz-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -moz-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -moz-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}
@keyframes introjspulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.nutrition {
  background: url(../images/hg-ernaehrung.jpg) 50% 50% no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.nutrition a {
  color: #29A9FC;
}

.nutrition .cd-side-nav {
  box-shadow: 0 0 10px 0 black;
}

.nutrition h1 {
  background: #000;
  color: #fff;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .nutrition .content-wrapper {
    padding-top: 45px !important;
  }
}
.nutrition h2 {
  font-family: "Open Sans Condensed";
  font-size: 30px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 32px;
}

.nutrition .header-wrapper {
  padding: 40px 40px 0 40px;
  margin-bottom: 45px;
  clear: both;
}

.nutrition .header-wrapper a {
  text-transform: uppercase;
  float: right;
  margin-top: 4px;
  text-decoration: none;
}

.nutrition .header-wrapper h2, .nutrition .header-wrapper h3 {
  float: left;
  padding: 0;
}

.nutrition .intensity-explanation {
  color: #fff;
}

.nutrition .page-functions a {
  color: rgba(255, 255, 255, 0.5);
}

.nutrition .page-functions a:hover {
  color: #fff;
}

.nutrition .week-nav-wrapper {
  border-radius: 5px;
  background: rgba(150, 150, 150, 0.4);
  margin: 0 40px 40px 40px;
  padding: 0px 0 !important;
}

@media only screen and (max-width: 768px) {
  .nutrition .week-nav-wrapper {
    border-radius: 0;
    margin: 0 0px 40px 0px;
  }
}
.nutrition .week-nav {
  overflow: auto;
}

.nutrition .week-nav .owl-stage-outer,
.nutrition .week-nav .owl-stage {
  padding-bottom: 5px;
  padding-top: 20px;
}

.nutrition .week-nav .item {
  background: #fff;
  border-radius: 100px;
  padding: 2px;
}

.nutrition .week-nav .item:after {
  content: "";
  display: block;
  clear: both;
}

.nutrition .week-nav .item .week-info {
  position: absolute;
  color: #fff;
  font-size: 12px;
  top: -25px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.nutrition .week-nav .item .week-nav-day {
  color: rgba(0, 0, 0, 0.5);
  float: left;
  width: 14.2857142857%;
  text-align: center;
  line-height: 20px;
  padding: 5px 0;
  position: relative;
  border-radius: 100px;
}

.nutrition .week-nav .item .week-nav-day.active {
  background: #F0F2F4;
  cursor: pointer;
}

.nutrition .week-nav .item .week-nav-day.active .number {
  color: #000;
}

/*
.nutrition .week-nav .item .week-nav-day.training-planed .number {
  color: #000;
}

.nutrition .week-nav .item .week-nav-day.training-planed:hover {
  background: $color-gray-light;
}
*/
.nutrition .week-nav .item .week-nav-day .weekday {
  font-weight: 300;
}

.nutrition .week-nav .item .week-nav-day .number {
  font-weight: 700;
}

.nutrition .week-nav .item .week-nav-day.intensity-1:after,
.nutrition .week-nav .item .week-nav-day.intensity-2:after,
.nutrition .week-nav .item .week-nav-day.intensity-3:after,
.nutrition .week-nav .item .week-nav-day.intensity-4:after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  left: 50%;
  margin-left: -5px;
  bottom: -7px;
}

.nutrition .week-nav .item .week-nav-day.intensity-1:after {
  background: #29A9FC;
}

.nutrition .week-nav .item .week-nav-day.intensity-2:after {
  background: #76B82A;
}

.nutrition .week-nav .item .week-nav-day.intensity-3:after {
  background: #ffa800;
}

.nutrition .week-nav .item .week-nav-day.intensity-4:after {
  background: #FB2A1A;
}

.nutrition .week-nav .owl-nav.disabled + .owl-dots {
  margin-top: -5px;
  padding: 0 0 15px 0;
}

.nutrition .week-nav .owl-nav {
  margin: 0;
  padding: 0;
}

.nutrition .week-nav .owl-nav .owl-prev,
.nutrition .week-nav .owl-nav .owl-next {
  position: absolute;
  left: 0px;
  opacity: 1;
  width: 60px;
  top: 0;
  height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 0px;
  margin: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0);
  background: none;
}

.nutrition .week-nav .owl-nav .owl-prev:before,
.nutrition .week-nav .owl-nav .owl-next:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ee05";
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  position: absolute;
  width: 100%;
  top: 0;
  color: #29A9FC;
}

.nutrition .week-nav .owl-nav .owl-next {
  right: 0px;
  left: auto;
}

.nutrition .week-nav .owl-nav .owl-next:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ee06";
  line-height: 40px;
}

.nutrition .week-nav .owl-nav .disabled {
  display: none;
}

@media only screen and (max-width: 768px) {
  .nutrition .week-nav {
    margin-top: -22px;
  }
  .nutrition .week-nav .item .week-nav-day {
    font-size: 12px;
    line-height: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
.nutrition .meal-carousel {
  border-radius: 5px;
  overflow: hidden;
}

.nutrition .meal-carousel .owl-item {
  opacity: 0.3;
}

.nutrition .meal-carousel .owl-item.active {
  opacity: 1;
}

.nutrition .meal-carousel a.open-meal {
  display: block;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  color: #000;
  text-decoration: none;
  font-family: "Open Sans";
  font-size: 18px;
  line-height: 23px;
}

.nutrition .meal-carousel a.open-meal .meal-title {
  padding: 20px;
}

.nutrition .meal-carousel a.open-meal img {
  width: 100% !important;
  height: auto !important;
}

.nutrition .meal-carousel a.open-meal .img-wrapper {
  position: relative;
}

.nutrition .meal-carousel .owl-nav {
  margin: 0;
  padding: 0;
}

.nutrition .meal-carousel .owl-nav .owl-prev,
.nutrition .meal-carousel .owl-nav .owl-next {
  position: absolute;
  left: 0px;
  opacity: 1;
  width: 35px;
  height: 100%;
  top: 0;
  height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 0px;
  margin: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0);
  background: none;
}

.nutrition .meal-carousel .owl-nav .owl-prev:before,
.nutrition .meal-carousel .owl-nav .owl-next:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ee05";
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -20px;
  color: #fff;
}

.nutrition .meal-carousel .owl-nav .owl-next {
  right: 0px;
  left: auto;
}

.nutrition .meal-carousel .owl-nav .owl-next:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ee06";
  line-height: 40px;
}

.nutrition .meal-carousel .owl-nav .disabled {
  display: none;
}

.nutrition .popup-meal {
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  background: url(../images/hg-ernaehrung-blur.jpg) 50% 50% no-repeat;
  background-size: cover;
  color: #fff;
  text-align: left;
}

.nutrition .popup-meal .mfp-close {
  background: none;
  color: #29A9FC;
  opacity: 1;
  border-radius: 50px;
  width: 34px;
  height: 34px;
  line-height: 36px;
  top: 5px;
  right: 5px;
}

.nutrition .popup-meal section {
  padding: 0;
  transition: all 0.3s ease-out;
}

.nutrition .popup-meal section h3 {
  font-family: "Open Sans Condensed";
  font-size: 21px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 28px;
  padding-top: 0;
  font-weight: 700;
}

.nutrition .popup-meal section h3 span {
  font-weight: 400;
  font-size: 12px;
  display: block;
}

.nutrition .popup-meal section .popup-meal-header {
  text-align: left;
  padding: 50px 20px 20px 20px;
}

.nutrition .popup-meal section .popup-meal-text {
  padding: 25px;
  text-align: center;
}

.nutrition .popup-meal section .popup-meal-img-wrapper {
  width: 100% !important;
  min-height: 400px;
  position: relative;
}

.nutrition .popup-meal section img {
  width: 100% !important;
  height: auto !important;
}

.nutrition-challenges-index .ui-datepicker {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  margin-top: -150px;
  margin-left: -139px;
}

@media only screen and (max-width: 768px) {
  .nutrition .nutrition-wrapper {
    padding: 0 !important;
    margin: 0 !important;
  }
  .nutrition .week-nav .owl-nav .owl-prev:before,
.nutrition .week-nav .owl-nav .owl-next:before {
    top: 6px;
  }
}
.nutrition .meal-portions {
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  margin-left: -25px;
  width: calc(100% + 50px);
  padding-bottom: 20px;
  padding-top: 20px;
}

.nutrition .meal-portions .meal-portions-editor {
  position: relative;
  display: inline-block;
  width: 240px;
}

.nutrition .meal-portions .meal-portions-editor .meal-portion-count {
  background: #000000;
  border: 2px solid #009DF0;
  width: 72px;
  height: 72px;
  border-radius: 100px;
  font-size: 36px;
  line-height: 72px;
  display: inline-block;
  font-weight: 700;
  position: relative;
  z-index: 5;
  margin-top: 5px;
}

.nutrition .meal-portions .meal-portions-editor button {
  color: #fff;
  font-size: 36px;
  text-align: center;
  height: 50px;
  border: 0;
  background: #29A9FC;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  width: 95px;
  padding-bottom: 5px;
  font-weight: 300;
  transition: all 0.1s ease-out;
}

.nutrition .meal-portions .meal-portions-editor button:hover {
  transform: scale(1.05);
}

.nutrition .meal-portions .meal-portions-editor button:active {
  transform: scale(0.95);
}

.nutrition .meal-portions .meal-portions-editor .btn-minus {
  left: 0;
  border-radius: 50px 0 0 50px;
}

.nutrition .meal-portions .meal-portions-editor .btn-plus {
  right: 0;
  border-radius: 0 50px 50px 0;
}

.nutrition .meal-checklist .item {
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  padding: 8px 5px;
}

.nutrition .meal-checklist .item .checkbox {
  width: 100px;
  height: 100px;
  display: inline-block;
  margin-bottom: 5px;
  border-radius: 50px;
  background: #000;
  position: relative;
  cursor: pointer;
}

.nutrition .meal-checklist .item .checkbox:after {
  content: "";
  display: block;
  width: 98px;
  height: 40px;
  position: absolute;
  left: 1px;
  top: 30px;
  background: #29A9FC;
  border-radius: 52px;
  z-index: 2;
  transition: all 0.4s ease-out;
}

.nutrition .meal-checklist .item .checkbox:before {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #000;
  border-radius: 52px;
  z-index: 4;
}

.nutrition .meal-checklist .item .checkbox span {
  color: #29A9FC;
  font-size: 32px;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  transition: all 0.2s ease-out;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.nutrition .meal-checklist .item .checkbox img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50px;
  z-index: 5;
  position: relative;
  transition: all 0.3s ease-out;
  margin-bottom: 4px;
}

.nutrition .meal-checklist .item .checkbox:hover img {
  opacity: 0.7;
}

.nutrition .meal-checklist .item .checkbox.active img {
  opacity: 0.3;
}

.nutrition .meal-checklist .item .checkbox.active span {
  opacity: 1;
}

.nutrition .meal-checklist .item .checkbox.active:after {
  width: 104px;
  height: 104px;
  left: -2px;
  top: -2px;
  transform: rotate(180deg);
}

.nutrition .meal-zubreitung ul.meal-ingredients {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nutrition .meal-zubreitung ul.meal-ingredients li {
  text-align: left;
  position: relative;
  box-sizing: border-box;
  padding-left: 85px;
}

.nutrition .meal-zubreitung ul.meal-ingredients li .prop {
  width: 75px;
  position: absolute;
  left: 0;
  top: 3px;
  text-align: center;
  box-sizing: border-box;
}

.nutrition .meal-zubreitung ul.meal-steps {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nutrition .meal-zubreitung ul.meal-steps li .step-number {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff;
  line-height: 50px;
  font-family: "Open Sans Condensed";
  font-weight: 700;
  font-size: 24px;
  border: 2px solid rgba(0, 157, 240, 0.2);
  border-radius: 30px;
  position: relative;
  margin: 10px 0;
}

.nutrition .meal-zubreitung ul.meal-steps li .step-number:before,
.nutrition .meal-zubreitung ul.meal-steps li .step-number:after {
  content: "";
  display: block;
  height: 2px;
  width: 70px;
  background: #29A9FC;
  opacity: 0.2;
  position: absolute;
  top: 50%;
  margin-top: -1px;
}

.nutrition .meal-zubreitung ul.meal-steps li .step-number:before {
  left: -80px;
}

.nutrition .meal-zubreitung ul.meal-steps li .step-number:after {
  right: -80px;
}

.nutrition .trigger-zurereit-close {
  color: #29A9FC;
  position: absolute;
  left: 10px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

a.add-fav {
  position: absolute;
  background: #fff;
  color: #29A9FC !important;
  display: block;
  width: 32px;
  height: 32px;
  line-height: 34px;
  z-index: 10;
  right: 10px;
  top: 10px;
  border-radius: 5px;
  transition: all 0.1s ease-out;
  text-align: center;
  text-decoration: none;
}

a.add-fav:hover {
  transform: scale(1.1);
}

a.add-fav.active {
  background: #29A9FC;
  color: #fff !important;
}

.meal-info {
  text-align: right;
  width: calc(100% - 40px);
  position: absolute;
  right: 20px;
  bottom: 12px;
}

.week-nav-wrapper {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  background: rgba(150, 150, 150, 0.4);
  margin: 0 40px 40px 40px;
  padding: 0px 0 !important;
}

@media only screen and (max-width: 768px) {
  .week-nav-wrapper {
    border-radius: 0;
    margin: 0 0px 40px 0px;
  }
}
.week-nav {
  overflow: hidden;
}
.week-nav .owl-item {
  opacity: 0.2;
  transition: all 0.1s ease-out;
}
.week-nav .owl-item.active {
  opacity: 1;
}
.week-nav .owl-stage {
  padding-bottom: 28px;
  padding-top: 35px;
}
.week-nav .item {
  border-radius: 100px;
  padding: 2px;
}
.week-nav .item:before {
  content: "";
  display: block;
  background: red;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background: #fff;
  z-index: 10;
}
.week-nav .item:after {
  content: "";
  display: block;
  clear: both;
}
.week-nav .item .week-info {
  position: absolute;
  color: #777777;
  font-size: 14px;
  top: -28px;
  text-align: center;
  width: 100%;
  line-height: 20px;
}
.week-nav .item .week-info2 {
  color: #fff;
  border-radius: 10px 10px 0 0;
  background: none;
  position: absolute;
  bottom: -27px;
  font-size: 12px;
  text-align: center;
  border-radius: 20px;
  right: 0;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
}
.week-nav .item .week-info2 strong {
  text-transform: uppercase;
}
.week-nav .item .week-nav-day {
  color: rgba(0, 0, 0, 0.5);
  float: left;
  width: 14.2857142857%;
  text-align: center;
  line-height: 20px;
  padding: 5px 0;
  position: relative;
  border-radius: 100px;
  z-index: 20;
}
.week-nav .item .week-nav-day.active {
  background: #F0F2F4;
}
.week-nav .item .week-nav-day.active .number {
  color: #000;
}
.week-nav .item .week-nav-day.training-planed.intensity-0 .number {
  color: rgba(0, 0, 0, 0.5);
}
.week-nav .item .week-nav-day.training-planed .number {
  color: #000;
  cursor: pointer;
}
.week-nav .item .week-nav-day.training-planed:hover {
  background: #F0F2F4;
}
.week-nav .item .week-nav-day .weekday {
  font-weight: 300;
}
.week-nav .item .week-nav-day .number {
  font-weight: 700;
}
.week-nav .item .week-nav-day.intensity-1:after, .week-nav .item .week-nav-day.intensity-2:after, .week-nav .item .week-nav-day.intensity-3:after, .week-nav .item .week-nav-day.intensity-4:after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  left: 50%;
  margin-left: -5px;
  bottom: -7px;
}
.week-nav .item .week-nav-day.intensity-1:after {
  background: #29A9FC;
}
.week-nav .item .week-nav-day.intensity-2:after {
  background: #76B82A;
}
.week-nav .item .week-nav-day.intensity-3:after {
  background: #ffa800;
}
.week-nav .item .week-nav-day.intensity-4:after {
  background: #FB2A1A;
}
.week-nav .owl-nav .owl-prev,
.week-nav .owl-nav .owl-next {
  width: 15px;
  height: 54px;
  margin-top: 43px;
  top: auto !important;
  bottom: 28px;
}
.week-nav .owl-nav .owl-prev:before, .week-nav .owl-nav .owl-prev:after,
.week-nav .owl-nav .owl-next:before,
.week-nav .owl-nav .owl-next:after {
  color: #fff;
  font-size: 12px;
}
.week-nav .owl-nav.disabled + .owl-dots {
  margin-top: -5px;
  padding: 0 0 15px 0;
}

.nutrition .week-nav .item .week-info {
  color: #fff;
}
.nutrition .week-nav .item .week-info2 {
  color: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 768px) {
  .week-nav {
    margin-top: -22px;
  }
  .week-nav .item .week-nav-day {
    font-size: 12px;
    line-height: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .week-nav .owl-nav .owl-prev,
.week-nav .owl-nav .owl-next {
    width: 20px;
    height: 40px;
  }
}
.subnav {
  position: absolute;
  width: 100%;
  margin-top: -54px;
}
.subnav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.subnav ul:after {
  content: "";
  display: block;
  clear: both;
}
.subnav ul li {
  width: 25%;
  float: left;
  text-align: center;
  padding: 0;
  margin: 0;
}
.subnav ul li a {
  text-decoration: none;
  display: block;
  padding: 4px 10px;
}
.subnav ul li a:hover {
  color: #263B47;
}
.subnav ul li.active a {
  border-bottom: 2px solid #29A9FC;
}

.nutrition .subnav ul li {
  width: calc(33.3333333333% - 20px);
}
.nutrition .subnav ul li a:hover {
  color: #fff;
}
.nutrition .subnav ul li a:hover span {
  color: #fff;
}
.nutrition .subnav ul li:last-child {
  width: 60px;
  font-weight: 700;
}
.nutrition .subnav ul li:last-child a {
  color: #fff;
}
.nutrition .subnav ul li:last-child span {
  color: #29A9FC;
  font-weight: 400;
}
.nutrition .subnav ul li:last-child a:hover {
  color: #fff;
}
.nutrition .subnav ul li:last-child a:hover span {
  color: #fff;
}

.prop {
  background: #fff;
  color: #000;
  font-size: 12px;
  line-height: 12px;
  border-radius: 20px;
  padding: 3px 8px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
}
.prop.intesity-1 {
  background: #29A9FC;
  color: #fff;
}
.prop.intesity-2 {
  background: #76B82A;
  color: #fff;
}
.prop.intesity-3 {
  background: #ffa800;
  color: #fff;
}
.prop.intesity-4 {
  background: #FB2A1A;
  color: #fff;
}

/*Carousel----------------*/
.owl-carousel {
  position: relative;
}

.owl-nav {
  margin: 0;
  padding: 0;
}
.owl-nav .owl-prev,
.owl-nav .owl-next {
  position: absolute;
  left: 0px;
  opacity: 1;
  width: 35px;
  height: 100%;
  top: 0;
  height: 40px;
  box-sizing: border-box;
  padding: 0px;
  margin: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0);
  background: none;
}
.owl-nav .owl-prev:hover,
.owl-nav .owl-next:hover {
  background: none;
  color: rgba(0, 0, 0, 0);
}
.owl-nav .owl-prev:before,
.owl-nav .owl-next:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ee05";
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -20px;
  color: #000;
}
.owl-nav .owl-next {
  right: 0px;
  left: auto;
}
.owl-nav .owl-next:before {
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ee06";
  line-height: 40px;
}
.owl-nav .disabled {
  display: none;
}

.owl-dots .owl-dot span {
  border: 2px solid rgba(255, 255, 255, 0.2);
}
.owl-dots .owl-dot.active span {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0);
}

/*Popups------------------*/
.popup-text {
  padding: 25px 20px;
}
.popup-text-title {
  text-align: center;
}
.popup-text-descr {
  padding-bottom: 15px;
  text-align: initial;
}
.popup-text-descr p {
  padding-bottom: 0;
}
.popup-text-descr blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}
.popup-text-descr ul, .popup-text-descr ol {
  padding-left: 48px;
}
.popup-text .training-feeling-bar-display {
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  padding-bottom: 15px;
}

.popup-meal,
.popup-challenge {
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  background: url(../images/hg-ernaehrung-blur.jpg) 50% 50% no-repeat;
  background-size: cover;
  color: #fff;
  text-align: left;
}
.popup-meal .mfp-close,
.popup-challenge .mfp-close {
  background: none;
  color: #29A9FC;
  opacity: 1;
  border-radius: 50px;
  width: 34px;
  height: 34px;
  line-height: 36px;
  top: 5px;
  right: 5px;
}
.popup-meal section,
.popup-challenge section {
  padding: 0;
  transition: all 0.3s ease-out;
}
.popup-meal section h3,
.popup-challenge section h3 {
  font-family: "Open Sans Condensed";
  font-size: 21px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 28px;
  padding-top: 0;
  font-weight: 700;
}
.popup-meal section h3 span,
.popup-challenge section h3 span {
  font-weight: 400;
  font-size: 12px;
  display: block;
}
.popup-meal section .popup-meal-header,
.popup-challenge section .popup-meal-header {
  text-align: left;
  padding: 50px 20px 20px 20px;
}
.popup-meal section .popup-meal-img-wrapper,
.popup-challenge section .popup-meal-img-wrapper {
  width: 100% !important;
  position: relative;
}
.popup-meal section .popup-meal-img-wrapper img,
.popup-challenge section .popup-meal-img-wrapper img {
  width: 100% !important;
  height: auto !important;
}
.popup-meal section .popup-meal-ingredients,
.popup-challenge section .popup-meal-ingredients {
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 0;
  position: relative;
  top: -5px;
}
.popup-meal section .popup-meal-ingredients:after,
.popup-challenge section .popup-meal-ingredients:after {
  content: "";
  display: block;
  clear: both;
}
.popup-meal section .popup-meal-ingredients .popup-meal-ingredient,
.popup-challenge section .popup-meal-ingredients .popup-meal-ingredient {
  float: left;
  width: 25%;
  box-sizing: border-box;
  padding: 0 5px;
  line-height: 19px;
}
.popup-meal section .popup-meal-ingredients .popup-meal-ingredient .popup-meal-ingredient-count,
.popup-challenge section .popup-meal-ingredients .popup-meal-ingredient .popup-meal-ingredient-count {
  font-weight: 700;
  font-size: 18px;
}
.popup-meal section .popup-meal-ingredients .popup-meal-ingredient .popup-meal-ingredient-unit,
.popup-challenge section .popup-meal-ingredients .popup-meal-ingredient .popup-meal-ingredient-unit {
  font-size: 12px;
}
@media only screen and (max-width: 450px) {
  .popup-meal section .popup-meal-ingredients .popup-meal-ingredient .popup-meal-ingredient-unit,
.popup-challenge section .popup-meal-ingredients .popup-meal-ingredient .popup-meal-ingredient-unit {
    font-size: 12px;
  }
}

.popup-challenge input[type=email],
.popup-challenge input[type=text],
.popup-challenge input[type=date] {
  text-align: center;
  background: transparent !important;
  color: #fff !important;
  border-bottom: 2px solid #566B75 !important;
}
.popup-challenge input[type=email] ::placeholder,
.popup-challenge input[type=text] ::placeholder,
.popup-challenge input[type=date] ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #777777 !important;
  opacity: 1; /* Firefox */
  font-size: 14px !important;
}
.popup-challenge input[type=email] :-ms-input-placeholder,
.popup-challenge input[type=text] :-ms-input-placeholder,
.popup-challenge input[type=date] :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #777777 !important;
  font-size: 14px !important;
}
.popup-challenge input[type=email] ::-ms-input-placeholder,
.popup-challenge input[type=text] ::-ms-input-placeholder,
.popup-challenge input[type=date] ::-ms-input-placeholder { /* Microsoft Edge */
  color: #777777 !important;
  font-size: 14px !important;
}
.popup-challenge .mfp-close {
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
}

.ernaehrung h1,
.trainingsplan h1,
.tagebuch h1,
.meal-intro h1 {
  margin-bottom: 20px;
  background: #fff;
}
@media only screen and (max-width: 768px) {
  .ernaehrung .content-wrapper,
.trainingsplan .content-wrapper,
.tagebuch .content-wrapper,
.meal-intro .content-wrapper {
    padding-top: 45px !important;
  }
}
.ernaehrung h2,
.trainingsplan h2,
.tagebuch h2,
.meal-intro h2 {
  font-family: "Open Sans Condensed";
  font-size: 30px;
  letter-spacing: 0;
  line-height: 32px;
}
.ernaehrung h4,
.trainingsplan h4,
.tagebuch h4,
.meal-intro h4 {
  font-family: "Open Sans Condensed";
  font-weight: 700;
  font-size: 16px;
  padding: 20px 0 10px 0;
}

.nutrition {
  background: url(../images/hg-ernaehrung.jpg) 50% 50% no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.nutrition .popup-slide-content {
  background: url(../images/hg-ernaehrung-blur.jpg) 50% 50% no-repeat;
}
.nutrition .cd-side-nav {
  box-shadow: 0 0 10px 0 rgb(0, 0, 0);
}
.nutrition h1 {
  background: #000;
  color: #fff;
}
.nutrition h2, .nutrition h3, .nutrition h4 {
  color: #fff;
}
.nutrition .header-wrapper {
  padding: 40px 40px 0 40px;
  margin-bottom: 45px;
  clear: both;
}
.nutrition .header-wrapper a {
  text-transform: uppercase;
  float: right;
  margin-top: 4px;
  text-decoration: none;
}
.nutrition .header-wrapper h2, .nutrition .header-wrapper h3 {
  float: left;
  padding: 0;
}
.nutrition .intensity-explanation {
  color: #fff;
}
.nutrition .page-functions a {
  color: rgba(255, 255, 255, 0.5);
}
.nutrition .page-functions a:hover {
  color: #fff;
}
.nutrition .meal-carousel {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
}
.nutrition .meal-carousel .owl-item {
  opacity: 0.3;
}
.nutrition .meal-carousel .owl-item.active {
  opacity: 1;
}
.nutrition .meal-carousel a.open-meal {
  display: block;
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  color: #000;
  text-decoration: none;
  font-family: "Open Sans";
  font-size: 18px;
  line-height: 23px;
}
.nutrition .meal-carousel a.open-meal .meal-title {
  padding: 20px;
}
.nutrition .meal-carousel a.open-meal img {
  width: 100% !important;
  height: auto !important;
}
.nutrition .meal-carousel a.open-meal .img-wrapper {
  position: relative;
}
.nutrition .meal-carousel .owl-nav .owl-prev,
.nutrition .meal-carousel .owl-nav .owl-next {
  height: 100%;
}
.nutrition .meal-carousel .owl-nav .owl-prev:before, .nutrition .meal-carousel .owl-nav .owl-prev:after,
.nutrition .meal-carousel .owl-nav .owl-next:before,
.nutrition .meal-carousel .owl-nav .owl-next:after {
  color: #fff;
}
.nutrition .meal-container-items {
  padding: 0 40px;
}
.nutrition .meal-container .meal-fav a.open-meal {
  text-decoration: none;
  color: #fff;
  display: block;
  position: relative;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  padding-bottom: 20px;
}
.nutrition .meal-container .meal-fav a.open-meal img {
  width: calc(100% + 20px) !important;
  height: auto !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-left: -10px;
  margin-bottom: 5px;
}
@media only screen and (max-width: 768px) {
  .nutrition .meal-container .meal-fav a.open-meal {
    font-size: 12px;
  }
}
@media only screen and (max-width: 768px) {
  .nutrition .meal-container .meal-container-items {
    padding: 0 20px;
  }
  .nutrition .meal-container .header-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.nutrition .meal-container .meal-filter {
  padding: 20px 40px;
  position: relative;
}
.nutrition .meal-container .meal-filter:after {
  content: "";
  display: block;
  clear: both;
}
.nutrition .meal-container .meal-filter label {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  width: calc(25% - 4px);
  float: left;
  padding: 0 4px !important;
  box-sizing: border-box;
  margin: 2px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  height: 40px;
  line-height: 40px;
  position: relative;
  cursor: pointer;
  transition: all 0.1s ease-out;
}
.nutrition .meal-container .meal-filter label:before {
  display: none;
}
.nutrition .meal-container .meal-filter label:after {
  content: "\edfb";
  text-align: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background: none;
  left: 50%;
  width: 30px;
  margin-left: -15px;
  top: 1px;
  margin-top: 0;
  font-size: 18px;
  position: absolute;
  transform: scale(2);
  transition: all 0.1s ease-out;
  opacity: 0;
}
.nutrition .meal-container .meal-filter label.check-label-light {
  color: #29A9FC;
  border: 2px solid rgba(41, 169, 252, 0.2);
}
.nutrition .meal-container .meal-filter label.check-label-light:after {
  color: #29A9FC;
}
.nutrition .meal-container .meal-filter label.check-label-medium {
  color: #76B82A;
  border: 2px solid rgba(118, 184, 42, 0.2);
}
.nutrition .meal-container .meal-filter label.check-label-medium:after {
  color: #76B82A;
}
.nutrition .meal-container .meal-filter label.check-label-power {
  color: #ffa800;
  border: 2px solid rgba(255, 168, 0, 0.2);
}
.nutrition .meal-container .meal-filter label.check-label-power:after {
  color: #ffa800;
}
.nutrition .meal-container .meal-filter label.check-label-booster {
  color: #FB2A1A;
  border: 2px solid rgba(251, 41, 26, 0.2);
}
.nutrition .meal-container .meal-filter label.check-label-booster:after {
  color: #FB2A1A;
}
.nutrition .meal-container .meal-filter label.checked {
  border: 2px solid #fff;
  line-height: 53px;
}
.nutrition .meal-container .meal-filter label.checked:after {
  opacity: 1;
  transform: scale(1);
}
.nutrition .meal-container .meal-filter label.checked.check-label-light {
  border: 2px solid #29A9FC;
}
.nutrition .meal-container .meal-filter label.checked.check-label-medium {
  border: 2px solid #76B82A;
}
.nutrition .meal-container .meal-filter label.checked.check-label-power {
  border: 2px solid #ffa800;
}
.nutrition .meal-container .meal-filter label.checked.check-label-booster {
  border: 2px solid #FB2A1A;
}
@media only screen and (max-width: 768px) {
  .nutrition .meal-container .meal-filter {
    padding: 20px 20px 40px 20px;
  }
}
.nutrition .meal-container .meal-shoppinglist-item {
  background: rgba(0, 0, 0, 0.5);
  min-height: 50px;
  position: relative;
  box-sizing: border-box;
  padding: 8px 10px 5px 60px;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 2px;
}
.nutrition .meal-container .meal-shoppinglist-item .meal-weight {
  font-size: 12px;
  font-weight: 700;
}
.nutrition .meal-container .meal-shoppinglist-item .check-trigger {
  position: absolute;
  left: 10px;
  top: 5px;
  width: 40px !important;
  height: 40px !important;
  border-radius: 20px;
  overflow: hidden;
  background: #29A9FC;
  cursor: pointer;
}
.nutrition .meal-container .meal-shoppinglist-item .check-trigger span {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.2s ease-out;
  transform: scale(1.5);
  opacity: 0;
}
.nutrition .meal-container .meal-shoppinglist-item .check-trigger img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 19px;
  transition: all 0.2s ease-out;
}
.nutrition .meal-container .meal-shoppinglist-item .check-trigger.active img {
  opacity: 0;
}
.nutrition .meal-container .meal-shoppinglist-item .check-trigger.active span {
  opacity: 1;
  transform: scale(1);
}
@media only screen and (max-width: 768px) {
  .nutrition .meal-container .meal-shoppinglist-item {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.nutrition .meal-container .ka-panel .ka-handler {
  cursor: pointer;
}
.nutrition .meal-container .ka-panel .ka-handler span {
  float: right;
  position: relative;
  top: 6px;
  transition: all 0.1s ease-out;
}
.nutrition .meal-container .ka-panel.open .ka-handler span {
  transform: rotate(180deg);
}
@media only screen and (max-width: 768px) {
  .nutrition .meal-container .ka-panel .ka-content {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.challenge-link {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  display: block;
  overflow: hidden;
  position: relative;
  background: #000;
  margin-bottom: 20px;
}
.challenge-link .challenge-photo {
  width: 100% !important;
  height: auto !important;
  opacity: 0.8;
  margin-bottom: 0;
  transition: all 0.5s ease-out;
  object-fit: cover;
  max-height: 195px;
  min-height: 195px;
}
.challenge-link .challenge-description-preview.with-logo, .challenge-link .challenge-description-preview.without-logo {
  overflow: hidden;
  line-height: 19px;
}
.challenge-link .challenge-description-preview.with-logo {
  max-height: 19px;
  bottom: 10%;
  top: auto;
}
.challenge-link .challenge-description-preview.without-logo {
  bottom: auto;
  top: 20px;
  max-height: 156px;
}
.challenge-link .challenge-description-preview p {
  padding: 0;
}
.challenge-link .challenge-logo {
  position: absolute;
  height: auto !important;
  width: 70% !important;
  left: 50%;
  margin-left: -35%;
  z-index: 2;
  top: 50%;
  margin-top: -65px;
  transition: all 0.5s ease-out;
}
.challenge-link .text {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #29A9FC;
  color: #fff;
  box-sizing: border-box;
  opacity: 0;
  transform: scale(1.1);
  transition: all 0.5s ease-out;
  font-size: 14px;
  line-height: 17px;
}
.challenge-link .text > div {
  position: absolute;
  bottom: 9%;
  width: 80%;
  left: 10%;
  text-align: initial;
}
.challenge-link .text .preview-title {
  text-align: center;
}
.challenge-link:hover .challenge-photo {
  transform: scale(1.2);
}
.challenge-link:hover .challenge-logo {
  margin-top: -110px;
}
.challenge-link:hover .text {
  opacity: 1;
  transform: scale(1);
}

@media only screen and (max-width: 1450px) and (min-width: 1200px) {
  .challenge-link .challenge-logo {
    margin-top: -50px;
  }
  .challenge-link .text > div {
    top: 50%;
  }
  .challenge-link:hover .challenge-logo {
    margin-top: -95px;
  }
}
@media only screen and (max-width: 992px) {
  .challenge-link .challenge-logo {
    margin-top: -100px;
  }
  .challenge-link .text > div {
    top: 60%;
  }
  .challenge-link:hover .challenge-logo {
    margin-top: -190px;
  }
}
@media only screen and (max-width: 768px) {
  .challenge-link .challenge-logo {
    width: 300px !important;
    margin-left: -150px;
    margin-top: -75px;
  }
  .challenge-link .text > div {
    top: 60%;
  }
  .challenge-link:hover .challenge-logo {
    margin-top: -140px;
  }
}
@media only screen and (max-width: 450px) {
  .challenge-link .challenge-logo {
    width: 250px !important;
    margin-left: -125px;
    margin-top: -60px;
  }
  .challenge-link .text > div {
    top: 50%;
  }
  .challenge-link:hover .challenge-logo {
    margin-top: -110px;
  }
}
.mini-menu-trigger {
  color: #29A9FC;
  transform: rotate(90deg);
  font-size: 20px;
  cursor: pointer;
}

.popup-menu {
  position: absolute;
  right: 50px;
}

.dropdown-menu {
  position: absolute;
  z-index: 9999999;
  display: none;
  opacity: 0;
  top: 0;
  left: 0;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
  -webkit-transform: translateY(-20px) scale(0.93);
  transform: translateY(-20px) scale(0.93);
  border-radius: 3px;
  z-index: 120;
}

.dropdown-menu.dropdown-opened {
  opacity: 1;
  -webkit-transform: none !important;
  transform: none !important;
}

.dropdown-menu.fixed {
  position: fixed;
}

.dropdown-menu.max-height ul {
  max-height: 184px;
}

.dropdown-menu ul {
  min-width: 120px;
  list-style: none;
  background: #F0F2F4;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  overflow: auto;
  padding: 5px 0;
  margin: 0;
  margin-left: -60px;
}

.dropdown-menu ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 18px;
}

.dropdown-menu ul li > a,
.dropdown-menu ul li label {
  display: block;
  color: #555;
  text-decoration: none;
  line-height: 18px;
  padding: 5px 15px;
  white-space: nowrap;
  font-size: 12px;
  transition: all 0.1s;
  text-align: left;
}
.dropdown-menu ul li > a:hover,
.dropdown-menu ul li label:hover {
  background: #29A9FC;
  color: #fff;
}

@media screen and (max-width: 420px) {
  .dropdown-menu ul li > a {
    line-height: 32px;
    padding-left: 24px;
    padding-right: 24px;
  }
}