@import '../../../../web/css/source/utils/variables';
@import '../../../../web/css/source/utils/mixins';

.ernaehrung-challenges-wrapper,
.meal-intro {
  .challengelist-item {
    background: rgba(0,0,0,0.5);
    min-height: 50px;
    position: relative;
    box-sizing: border-box;
    padding: 8px 10px 5px 60px;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 2px;
    display: block;
    text-align: left;
    text-decoration: none;

    &.challengelist-item-active {
      background: $color-blue;
      text-decoration: none;
      @include border-radius;
    }

    .challengelist-item-title {
      font-weight: 700;
      font-size: 18px;
      padding-bottom: 6px;
    }

    .icon-check,
    .icon-clock {
      position: absolute;
      left: 10px;
      top: 5px;
      width: 40px !important;
      height: 40px !important;
      border-radius: 20px;
      overflow: hidden;
      background: transparent;
      cursor: pointer;
      line-height: 40px;
      text-align: center;
    }

    .icon-clock {
      font-size: 24px;
    }
  }

  .challenge-info {
    display: block;
    border-radius: 0 0 5px 5px;
    position: relative;
    top: -3px;
    font-size: 14px;
    color: #fff;
    padding: 2px 5px;
    width: calc(100% + 6px);
    margin-left: -3px;
    box-sizing: border-box;

    &.challenge-info-active {
      background: $color-blue;
    }
  }

  .challenge-link {
    margin-bottom: 5px;

    &.challenge-active {
      box-shadow: 0 0 0 3px $color-blue;
      border-radius: 5px 5px 0 0;
    }

    &.challenge-done {
      .challenge-photo {
        opacity: 0.2;
      }

      &:before {
        content: "\edfb";
        @include icomoon-settings;
        background: #fff;
        color: $color-blue;
        display: block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 11;
      }
    }

    .challenge-logo-wrapper {
      display: flex;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      transition: all 0.5s ease-out;

      .challenge-logo {
        margin: auto;
        height: auto !important;
        width: 70% !important;
        position: static;
      }
    }

    &:hover {
      .challenge-logo-wrapper {
        top: -20%;
      }
    }
  }

  .owl-dots {
    .owl-dot {
      span {
        border: 2px solid $color-gray-mid;
      }

      &.active {
        span {
          background: $color-gray-mid;
        }
      }
    }
  }

  .ernaehrung-events {
    h4 {
      cursor: pointer;
    }
  }
}

.ernaehrung-challenges-wrapper {
  .challenge-carousel {
    &.challenge-carousel-1 {
      .owl-stage-outer {
        padding-left: 100px;
      }

      .item {
        width: 332px;
      }
    }
  }
}

.meal-intro {
  color: #fff;
  text-align: center;
  margin: 0 40px;
}

.nutrition {
  .nutrition-wrapper {
    .week-nav {
      overflow: hidden;
      
      .owl-nav {
        .owl-prev,
        .owl-next {
          width: 14px;
          height: calc(10%);

          &:before {
            font-size: 12px;
          }
        }

        .owl-prev {
          left: 0;
        }

        .owl-next {
          right: 0;
        }
      }
    }
  }

  .add-fav {
    &.active {
      .meal-plan-loader {
        background: $color-blue;

        img.loader {
          &.loader-white-light {
            display: block;
          }

          &.loader-white {
            display: none;
          }
        }
      }
    }

    .meal-plan-loader {
      position: absolute;
      background: #fff;
      color: $color-blue !important;
      display: block;
      width: 32px;
      height: 32px;
      z-index: 10;
      right: 0;
      top: 0;
      border-radius: 5px;
      transition: all .1s ease-out;
      text-align: center;
      text-decoration: none;
      padding: 0;

      img.loader {
        width: 75% !important;
        margin: 4px auto;

        &.loader-white-light {
          display: none;
        }

        &.loader-white {
          display: block;
        }
      }
    }
  }

  .field {
    .error {
      color: $color-red;
      margin-top: 15px;
      line-height: 1.2;
    }
  }
}

@media only screen and (max-width: 1170px) {
  .ernaehrung-challenges-wrapper {
    .challenge-carousel {
      &.challenge-carousel-1 {
        .owl-stage-outer {
          padding-left: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .meal-intro {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 425px) {
  .ernaehrung-challenges-wrapper {
    .challenge-carousel {
      &.challenge-carousel-1 {
        .owl-stage-outer {
          padding-left: 5px;
        }
      }
    }
  }
}