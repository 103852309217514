.ac-plan {
  table {
    max-width: 100%;

    tr {
      .ac-competition-name-inner {
        word-break: break-all;
      }
    }
  }
}

@media all and (max-width: 1449px) and (min-width: 1200px) {
  .trainingsplan-wrapper.custom-col {
    margin-left: 0;
    width: 100%;
  }
}