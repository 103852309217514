@media screen and (min-width: 992px) {
  .mediathek-videotype {
    padding-bottom: 20px;

    .selectric,
    .selectric-label {
      display: none;
    }
    .selectric-wrapper {
      .selectric-items {
        display: block;
        position: relative;
        width: 100%;
        box-shadow: none;

        ul {
          text-align: center;

          li {
            display: inline-block;
            @include border-radius;
            width: 95px;
            box-sizing: border-box;
            height: 115px;
            border: 1px solid #fff;
            font-size: 12px;
            cursor: pointer;
            vertical-align: top;
            @include animate-all-fast;
            line-height: 16px;

            &:before {
              @include icomoon-settings;
              display: block;
              font-size: 36px;
              padding-bottom: 10px;
              color: $color-blue;
            }

            &:hover {
              &:before {
                text-shadow: 0 0 10px #fff;
                color: #fff;
              }
            }
            &.selected {
              &:before {
                color: #fff;
              }
            }

            &.alle {
              &:before {
                content: "\e912";
              }
            }
            &.kraft {
              &:before {
                content: "\e919";
              }
            }
            &.cardio {
              &:before {
                content: "\e911";
              }
            }
            &.yoga {
              &:before {
                content: "\e91a";
              }
            }
            &.stretching {
              &:before {
                content: "\e917";
              }
            }
            &.bootcamp {
              &:before {
                content: "\e915";
              }
            }
            &.info {
              &:before {
                content: "\e913";
              }
            }
            &.favoriten {
              &:before {
                content: "\ec2d";
              }
            }
          }
        }
      }
    }
  }
}

.live-mediathek {
  clear: both;
  background: #fff;
  padding: 0 30px 50px 30px;

  hr {
    margin: 10px 0;
    border: 0;
    border-top: 1px solid $color-gray-light;
    clear: both;
  }
}

.live-mediathek input[type="date"],
.live-mediathek input#calendar,
.live-mediathek span.fake-calendar {
  background: #fff;
  position: relative;
  @include border-radius;
  margin: 4px 0;
  border: 1px solid $color-blue;
  color: #000;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Open Sans';
  padding: 5px 10px;
  box-sizing: border-box;
  width: 100%;
  outline: none;

  &:focus,
  &:focus-visible,
  &:active,
  &:hover {
    border-color: #c4c4c4;
  }

  &:hover {
    cursor: pointer;
  }
}

.live-mediathek input#calendar {
  opacity: 0;
  visibility: hidden;
}

.live-mediathek span.fake-calendar {
  display: block;
  height: 31px;
  position: absolute;
  width: calc(100% - 24px);
  bottom: 0;
  z-index: 1;
  line-height: 1.4em;
}

.live-mediathek span.fake-calendar::after {
  content: "\e901";
  font-family: icomoon!important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  margin-top: 2px;
}

.live-mediathek input#calendar::placeholder {
  color: #444;
  font-weight: 500;
}

@media screen and (max-width: 550px) {
  .live-mediathek {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.mediathek-wrapper {
  text-align: center;

  .add-fav {
    .lazy-loading-animation {
      background: #fff;
      border-radius: 4px;
      height: 32px;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0;
      width: 32px;

      img {
        position: absolute;
        left: 50%;
        margin-left: -10px;
        margin-top: -10px;
        top: 50%;
        width: 20px;
      }
    }
  }
}

.mediathek-item {
  padding: 5px;
  position: relative;
  display: inline-block;
  width: 200px;
  text-align: left;

  .text-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #fff;
    font-size: 12px;
    line-height: 19px;
    font-weight: 700;
    padding: 40px 12px 18px 12px;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);

    .video-title {
      font-size: 16px;
      font-weight: 300;
      padding-bottom: 2px;
      padding-top: 4px;
    }

    .type-icon {
      font-size: 18px;
      vertical-align: center;
      display: inline-block;
      background: #fff;
      color: #000;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50%;
      position: relative;
      top: 3px;
    }

    .video-status {
      background: $color-blue;
      height: 5px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &.intensity-1 {
    .text-wrapper {
      .type-icon,
      .video-status {
        background: $color-blue;
      }
    }
  }
  &.intensity-2 {
    .text-wrapper {
      .type-icon,
      .video-status {
        background: $color-green;
      }
    }
  }
  &.intensity-3 {
    .text-wrapper {
      .type-icon,
      .video-status {
        background: $color-orange;
      }
    }
  }
  &.intensity-4 {
    .text-wrapper {
      .type-icon,
      .video-status {
        background: $color-gray-mid;
      }
    }
  }

  a.videolink {
    text-decoration: none;
    color: $color-gray-dark;
    position: relative;
    display: block;
    @include border-radius;
    @include box-shadow;
    overflow: hidden;
    @include animate-all-fast;

    .video-img-wrapper {
      overflow: hidden;
      margin-bottom: 5px;

      img {
        width: 100% !important;
        height: auto !important;
        margin-bottom: -8px;
        @include animate-all-fast;
      }
    }

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 10px 10px 0 rgba(0,0,0,0.6);
      z-index: 2;

      .video-img-wrapper {
        img {
          transform: scale(1.1);
        }
      }
    }

  }
  &.new {
    a {
      .video-img-wrapper {
        position: relative;

        &:before {
          content: "neu";
          background: $color-red;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 0 0 5px 0;
          color: #fff;
          text-transform: uppercase;
          padding: 3px 10px;
          font-weight: 700;
        }
      }
    }
  }
  .video-dummy {
    .video-img-wrapper {
      @include border-radius;
      overflow: hidden;
      margin-bottom: 5px;
    }

  }
}

@media screen and (max-width: 550px) {
  .mediathek-item {
    width: calc(50% - 10px);
  }

  .live-mediathek {
    .mediathek-item {
      width: 200px;
    }
  }
}

#popup-tp-live-video {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  .popup-text,
  header,
  h3 {
    text-align: left;
  }

  .header-date {
    font-size: 14px;
  }

  .tp-actions .tp-action {
    vertical-align: top;

    button {
      background: $color-blue;
    }
  }
}