/*======================================
  Selectric v1.11.0
======================================*/

.selectric-wrapper {
  position: relative;
  cursor: pointer;

  &.power {
    .selectric {
      background: $color-power !important;
    }
  }

  &.booster {
    .selectric {
      background: $color-booster !important;
    }
  }

  &.healthy {
    .selectric {
      background: $color-healthy !important;
    }
  }

  &.light {
    .selectric {
      background: $color-light !important;
    }
  }
  &.selectric-enplan-kind-select {
    li[data-index="1"] {
      color: $color-power !important;
    }
  }
  &.selectric-enplan-kind-select {
    li[data-index="2"] {
      color: $color-booster !important;
    }
  }
  &.selectric-enplan-kind-select {
    li[data-index="3"] {
      color: $color-healthy !important;
    }
  }
  &.selectric-enplan-kind-select {
    li[data-index="4"] {
      color: $color-light !important;
    }
  }
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  background: #fff;
  position: relative;
  @include border-radius;
  margin: 4px 0;
  border: 1px solid $color-blue;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 0 10px;
  line-height: 30px;
  color: #444;
  height: 30px;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  color: transprent;
  text-align: center;
  font: 0/0 a;
  *font: 20px/30px Lucida Sans Unicode, Arial Unicode MS, Arial;
}

.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: $color-blue;
  border-bottom: none;
}
.desktop {
  .selectric:hover {
    .button:after {
      border-top-color: $color-green;
    }
  }
}

.selectric-label {
  font-size: 12px;
  color: $color-blue;
  text-transform: uppercase;
  margin-bottom: -6px;
}

.selectric-focus .selectric {
  border-color: #aaaaaa;
}

.selectric-hover .selectric {
  border-color: #c4c4c4;
}

.selectric-hover .selectric .button {
  color: #a2a2a2;
}

.selectric-hover .selectric .button:after {
  border-top-color: #a2a2a2;
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric {
  border-color: #c4c4c4;
}

.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  z-index: 2;
  box-shadow: 0 0 10px -6px;
  @include border-radius;
  @include box-shadow;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items li {
  display: block;
  padding: 10px;
  color: #000;
  cursor: pointer;
  font-size: 14px;
}

.selectric-items li.selected {
  background: $color-blue;
  color: #fff;
}

.selectric-items li.highlighted {
  background: $color-blue;
  color: #fff;
}

.selectric-items li:hover {
  background: $color-blue;
  color: #fff;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}