@import 'components/mediathek';
@import 'components/selectric';

.trainingsplan,
.tagebuch,
.livetraining {
  .header-wrapper {
    padding: 30px 20px;
    h2 {
      font-weight: 700;
      font-size: 21px;
      padding: 0;
      margin-top: -5px;
      margin-bottom: -5px;
    }
    .tp-day-date {
      font-size: 12px;
      color: $color-gray-text;
    }
    .tp-day-intensity {
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .tp-wrapper{
    margin: 0 40px;
    background: #fff;
    @include border-radius;

    .ka-panel {
      &.close {
        .ka-content {
          display: none;
        }
      }

      &.open {
        .ka-content {
          display: block;
        }
      }
    }

    &.intensity-1 {
      .ka-panel .ka-handler span,
      .header-wrapper .tp-day-intensity  {
        color: $color-blue;
      }
      .tp-actions {
        .tp-action {
          a,
          button {
            background: $color-blue;
          }
        }
      }
    }

    &.intensity-2 {
      .ka-panel .ka-handler span,
      .header-wrapper .tp-day-intensity  {
        color: $color-green;
      }
      .tp-actions {
        .tp-action {
          a,
          button {
            background: $color-green;
          }
        }
      }
    }

    &.intensity-3 {
      .ka-panel .ka-handler span,
      .header-wrapper .tp-day-intensity  {
        color: $color-orange;
      }
      .tp-actions {
        .tp-action {
          a,
          button {
            background: $color-orange;
          }
        }
      }
    }

    &.intensity-4 {
      .ka-panel .ka-handler span,
      .header-wrapper .tp-day-intensity  {
        color: $color-red;
      }
      .tp-actions {
        .tp-action {
          a,
          button {
            background: $color-red;
          }
        }
      }
    }
  }
  .tp-content {
    .ka-panel {
      .ka-handler {
        font-size: 14px;
        padding: 20px 40px 20px 60px;
        border-top: 1px solid $color-gray-light;
        position: relative;
        cursor: pointer;
        font-weight: 400;

        &-number {
          display: block;
          background: #000;
          width: 24px;
          height: 24px;
          color: #fff;
          text-align: center;
          @include border-radius;
          line-height: 24px;
          font-weight: 700;
          position: absolute;
          left: 20px;
          top: 50%;
          margin-top: -12px;
        }

        .tp-day-exercise-info {
          color: $color-gray-text;
          font-size: 12px;
          line-height: 14px;
        }

        span {
          float: right;
          @include animate-all-fast;
          position: absolute;
          right: 20px;
          top: 50%;
          margin-top: -8px;
        }

        .tp-live-start-event {
          span {
            float: inherit;
            position: static;
          }
        }
      }
      &.open {
        .ka-handler {
          span {
            transform: rotate(180deg);
          }
        }
      }
      .ka-content {
        padding: 0 20px 20px 20px;
        color: $color-gray-text;
        font-size: 12px;
        line-height: 1.4em;
      }
    }

    &.tp-live-content {
      .video-expand {
        margin-bottom: 25px;
      }

      .tp-live-countdown {
        position: relative;
        background: #000;
        margin-bottom: 25px;
        margin-left: -20px;
        width: calc(100% + 40px);

        .btn-wrapper {
          position: absolute;
          bottom: 10px;
          z-index: 3;
          width: 100%;
          text-align: center;
        }

        img {
          width: 100% !important;
          height: auto !important;
          opacity: 0.4;
          margin-bottom: -5px;
        }

        .countdown {
          color: #fff;
          position: absolute;
          top: 50%;
          margin-top: -18px;
          text-align: center;
          width: 100%;
          z-index: 2;

          > div {
            display: inline-block;
            font-family: 'Open Sans Condensed';
            font-size: 36px;
            font-weight: 700;
            width: 60px;

            span {
              display: block;
              font-family: 'Open Sans';
              font-size: 12px;
              font-weight: 400;
              padding-top: 8px;
            }
          }
        }

        &-video {
          display: none;
          position: relative;
          padding: 56.25% 0 0 0;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

      .ka-panel {
        .ka-handler {
          &:before {
            display: none;
          }
        }
      }

      .tp-live-date {
        border-radius: 0 5px 5px 0;
        color: #fff;
        width: 50px;
        height: 50px;
        //background: $color-blue;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -25px;
        box-sizing: border-box;
        padding: 10px 8px;
        font-size: 12px;

        &-name {
          font-size: 16px;
        }

        .tp-live-date-day {
          font-size: 18px;
          font-weight: 700;
          line-height: 14px;
        }
      }
      .tp-live-date-time {
        display: flex;
        font-size: 12px;
        color: $color-gray-mid;
        margin-bottom: -8px;

        &-start, &-end, &-type {
          margin-right: 5px;
        }
      }
      .tp-live-coach {
        font-weight: 700;
        color: #000;
      }

      .ka-panel {
        &.intensity-1 {
          .ka-handler .icon-chevron-down {
            color: $color-blue;
          }
          .tp-live-date,
          .tp-action button {
            background: $color-blue;
          }
        }
        &.intensity-2 {
          .ka-handler {
            .icon-chevron-down {
              color: $color-green;
            }
          }
          .tp-live-date,
          .tp-action button {
            background: $color-green;
          }
        }
        &.intensity-3 {
          .ka-handler {
            .icon-chevron-down {
              color: $color-orange;
            }
          }
          .tp-live-date,
          .tp-action button {
            background: $color-orange;
          }
        }
        &.intensity-4 {
          .ka-handler {
            .icon-chevron-down {
              color: $color-gray-mid;
            }
          }
          .tp-live-date,
          .tp-action button {
            background: $color-gray-mid;
          }
        }
      }
    }
  }
  .tp-actions {
    text-align: center;
    padding-top: 30px;
    .tp-action {
      display: inline-block;
      font-size: 12px;
      text-align: center;
      width: 70px;
      line-height: 14px;
      position: relative;

      &.done {
        &:before {
          content: "\edfb";
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background: $color-green;
          color: #fff;
          @include icomoon-settings;
          position: absolute;
          right: -3px;
          top: -8px;
          line-height: 20px;
          font-size: 12px;
          z-index: 5;
        }
      }

      a,
      button {
        display: inline-block;
        width: 60px;
        height: 60px;
        @include border-radius;
        line-height: 60px;
        color: #fff;
        font-size: 28px;
        text-decoration: none;
        margin-bottom: 6px;
        @include animate-all-fast;
        border: 0;
        cursor: pointer;

        &:disabled {
          background: $color-gray-light !important;
          color: $color-gray-text;
          cursor: default;


          &:hover {
            transform: scale(1);
          }

        }

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .trainingsplan-wrapper {
      padding: 0 !important;
      margin: 0 !important;
    }
    .week-nav-wrapper {
      margin-bottom: 0 !important;
    }
    .tp-wrapper {
      margin: 0;
      border-radius: 0;
    }
  }
}

.intensity-legend {
  background: transparent !important;
  font-size: 12px;
  line-height: 18px;
  padding-top: 25px;
  padding-bottom: 25px;

  > .intensity {
    padding-left: 16px;
    position: relative;
    display: inline-block;
    margin-left: 5px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      left: 0;
      top: 3px;
    }

    &.intensity-1:before {
      background: $color-blue;
    }
    &.intensity-2:before {
      background: $color-green;
    }
    &.intensity-3:before {
      background: $color-orange;
    }
    &.intensity-4:before {
      background: $color-gray-mid;
    }
  }
}

.tagebuch {
  background: $color-gray-dark;

  .tagebuch-item {
    background: #fff;
    @include border-radius;
    margin: 0 40px 2px 40px;
    position: relative;
    overflow: hidden;

    .ka-handler {
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 12px;
      padding: 30px 40px 20px 20px;
      position: relative;
      @include animate-all-fast;
      cursor: pointer;

      .icon-chevron-down {
        color: $color-blue;
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -5px;
        font-size: 16px;
        @include animate-all-fast;
      }

      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }
    .tagebuch-item-date {
      display: inline-block;
      color: $color-blue;
      padding-right: 5px;
    }
    .tagebuch-item-unit-count {
      display: inline-block;
      border-radius: 20px;
      background: $color-blue;
      color: #fff;
      padding: 0px 10px;
      height: 18px;
      line-height: 18px;

      strong {
        font-weight: 700;
      }
    }

    .open {
      .ka-handler {
        background: $color-gray-light;

        .icon-chevron-down {
          transform: rotate(180deg);
        }
      }
    }
    .ka-panel {
      .ka-content {
        transition: all .5s ease-in-out;
      }
      &.close {
        .ka-content {
          height: 0;
          opacity: 0;
        }
      }

      &.open {
        .ka-content {
          height: auto;
          opacity: 1;
        }
      }
    }
    .ka-content
    a {
      font-size: 14px;
      padding: 20px 40px 20px 60px;
      border-top: 1px solid $color-gray-light;
      position: relative;
      cursor: pointer;
      font-weight: 400;
      color: #000;
      display: block;
      text-decoration: none;
      border-top: 1px solid $color-gray-light;

      .tp-day-exercise-info {
        color: $color-gray-text;
        font-size: 12px;
        line-height: 14px;
      }

      span {
        float: right;
        @include animate-all-fast;
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -8px;
        color: $color-blue;

        &.icon-flag3, &.icon-trophy1 {
          right: auto;
          margin-top: -12px;
          color: #000;
        }
      }
      &:before {
        display: block;
        background: $color-blue;
        width: 24px;
        height: 24px;
        color: #fff;
        text-align: center;
        @include border-radius;
        font-weight: 700;
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -12px;
        font-size: 16px;
        box-sizing: border-box;
        padding-top: 3px;
        @include icomoon-settings;
      }

      &:hover {
        span {
          right: 15px;

          &.icon-flag3, &.icon-trophy1 {
            right: auto;
          }
        }
      }

      &.tagebuch-item-unit-done {
        &:before {
          content: "\edfb";
        }
      }
      &.tagebuch-item-unit-manually {
        &:before {
          content: "\e918";
        }
      }
      &.tagebuch-item-unit-clock {
        &:before {
          content: "\eda1";
        }
      }
      &.tagebuch-item-unit-tracking {
        &:before {
          content: "\edf1";
        }
      }
    }
  }
}

.choose-week,
.choose-month,
.choose-year {
  .owl-item {
    opacity: 0.5;
    @include animate-all-fast;

    &.active {
      opacity: 1;
    }

    .item {
      a {
        display: block;
        background: #fff;
        @include border-radius;
        border-radius: 30px;
        color: $color-gray-text;
        text-decoration: none;
        // font-family: 'Open Sans Condensed';
        font-weight: 700;
        font-size: 16px;
        line-height: 14px;
        padding: 8px 3px 6px 3px;
        @include animate-all-fast;
        height: 42px;
        box-sizing: border-box;

        &.active,
        &:hover {
          background: $color-blue;
          color: #fff;
        }

        .year {

          font-family: 'Open Sans';
          font-weight: 400;
          font-size: 10px;
        }
      }
    }
  }
}
.choose-month {
  .owl-item {
    .item {
      a {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}
.choose-year {
  .owl-item {
    .item {
      a {
        line-height: 42px;
        padding: 0;

      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .tagebuch {
    .tagebuch-item {
      margin: 0 0 2px 0;
      border-radius: 0;
    }
  }
}

.training-feeling-bar {
  height: 31px;
  border-radius: 20px;
  background-image: linear-gradient(90deg, #009DF0 0%, #76B82A 30%, #FFA800 64%, #FB2A1A 100%);
  padding: 2px;
  box-sizing: border-box;
  position: relative;

  > div {
    background: #fff;
    height: 27px;
    border-radius: 20px;

    .training-feeling-bar-count {
      background: red;
      height: 27px;
      border-radius: 20px;
      width: 50%;
      overflow: hidden;

      &:before {
        content: "";
        display: block;
        height: 27px;
        width: 200%;
        background-image: linear-gradient(90deg, #009DF0 0%, #76B82A 30%, #FFA800 64%, #FB2A1A 100%);
      }

      &.count-0 {
        width: 0%;
      }
      &.count-10 {
        width: 10%;
        &:before {
          width: 1000%
        }
      }
      &.count-20 {
        width: 20%;
        &:before {
          width: 500%
        }
      }
      &.count-30 {
        width: 30%;
        &:before {
          width: 333%
        }
      }
      &.count-40 {
        width: 40%;
        &:before {
          width: 250%
        }
      }
      &.count-30 {
        width: 30%;
        &:before {
          width: 333%
        }
      }
      &.count-30 {
        width: 30%;
        &:before {
          width: 333%
        }
      }
      &.count-50 {
        width: 50%;
        &:before {
          width: 200%
        }
      }
      &.count-60 {
        width: 60%;
        &:before {
          width: 166%
        }
      }
      &.count-70 {
        width: 70%;
        &:before {
          width: 143%
        }
      }
      &.count-80 {
        width: 80%;
        &:before {
          width: 125%
        }
      }
      &.count-90 {
        width: 90%;
        &:before {
          width: 111%
        }
      }
      &.count-100 {
        width: 100%;
        &:before {
          width: 100%
        }
      }
    }
  }
}

.ernaehrung,
.trainingsplan,
.tagebuch,
.livetraining {
  h1 {
    margin-bottom: 20px;
    background: #fff;
  }
  @media only screen and (max-width: 768px) {
    .content-wrapper {
      padding-top: 45px !important;
    }
  }

  h2 {
    font-family: 'Open Sans Condensed';
    font-size: 30px;
    letter-spacing: 0;
    line-height: 32px;
  }
  h4 {
    font-family: 'Open Sans Condensed';
    font-weight: 700;
    font-size: 16px;
    padding: 20px 0 10px 0;
  }
}


.diary-wrapper {
  box-shadow: 0 0 0 10px $color-gray-dark;
  background: $color-yellow-light;


  .tp-day {
    font-family: 'Open Sans', sans-serif;
    overflow: hidden;

    strong {
      color: $color-gray-dark !important;
    }
  }

  .week-ribbon {
    overflow: hidden;
    background: $color-red;
    color: $color-red;
    font-weight: 700;
    font-size: 14px;
    position: absolute;
    right: 30px;
    top: -12px;
    padding: 3px 10px 27px 10px;
    text-align: center;
    line-height: 21px;

    > div {
      position: relative;
      z-index: 10;
    }

    &:after {
      content: "";
      display: block;
      width: 100px;
      height: 100px;
      transform: rotate(45deg);
      position: absolute;
      left: 50%;
      margin-left: -50px;
      background: $color-yellow-light;
      box-sizing: border-box;
      bottom: -92px;
      border: 2px solid $color-red;
    }
    &:before {
      content: "";
      display: block;
      width: calc(100% - 4px);
      height: 100px;
      position: absolute;
      left: 2px;
      background: rgba(0,0,0,0.3);
      top: 0;
    }
    .week-number {
      font-size: 30px;
      font-weight: 300;
      color: #fff;
    }
  }
  header {
    border-top: 1px solid $color-gray-dark;
    border-bottom: 1px solid rgba(0,0,0,0.1) !important;

    .tp-day-day {
      width: auto !important;
      text-align: center !important;
      padding-left: 18px !important;

      > div {
        font-weight: 300;
        display: inline-block;
      }
    }
  }
  header:nth-of-type(1) {
    border-top: 0;
  }
  .diary-exercises {
    // color: $color-blue;
    font-size: 14px;

    strong {
      font-family: 'Open sans', sans-serif;
      color: rgba(0,0,0,0.3);
    }


    .diary-exercise-item {
      border-bottom: 1px solid rgba(0,0,0,0.1);
      padding: 4px 15px 4px 20px;
      box-sizing: border-box;
      font-family: 'Coming Soon';

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      .btn-group-row-edit {
        margin-bottom: -10px;
      }

      > div {
        padding-bottom: 5px;
      }

      &.tp-exercise-item-competition {
        position: relative;
        padding-left: 50px;

        .icon-trophy1 {
          position: absolute;
          left: 10px;
          top: 50%;
          margin-top: -18px;
          font-size: 18px;
          display: inline-block;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          border-radius: 30px;
        }
        .text-light {
          opacity: 0.5;
        }
        .tp-exercise-item-competition-detail {
          display: inline-block;
          padding-right: 10px;
        }
      }

      &.tp-exercise-item-maincompetition {
        .icon-trophy1 {
          background: $color-gray-dark;
          color: #fffdde;
        }
      }
      span a.btn {
        display:inline-block;
        margin:0 5px;
        background: rgba(0,0,0,.1);
        color: #263B47;
        font-size: 14px;
        height: 24px;
        line-height: 24px;
        font-family: 'Arial', sans-serif;
        text-transform: none;
      }
    }
  }
  .diary-item {
    font-family: 'Coming Soon';
  }

  .diary-exercise-planed {
    background: #fff;
    box-sizing: border-box;

    .diary-exercise-header {
      padding: 3px 10px;
      text-align: center;
      color: #fff;
      margin-bottom: 3px;
    }

    strong {
      color: #000;
    }

    .diary-exercise-item {
      border-bottom: 1px solid rgba(0,0,0,0.1);
      font-family: 'Open sans', sans-serif;
      color: $color-gray-dark;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 14px;

      &:first-child {
      }
      a.btn {
        display:inline-block;
        background: rgba(0,0,0,.1);
        color: #263B47;
        font-size: 14px;
        height: 24px;
        line-height: 24px;
        font-family: Arial;
        text-transform: none;
      }
      a.open-popup-video {
        display: inline;
      }
    }
  }


  .effort-scale {
    height: 6px;
    border-radius: 10px;
    margin-top: 5px;
    position: relative;
    width: 100px;
    display: inline-block;
    background:-moz-linear-gradient(left,rgba(41,169,252,1) 20%,rgba(90,170,49,1) 30%,rgba(90,170,49,1) 45%,rgba(255,168,0,1) 55%,rgba(255,168,0,1) 70%,rgba(251,42,26,1) 80%);
    background:-webkit-linear-gradient(left,rgba(41,169,252,1) 20%,rgba(90,170,49,1) 30%,rgba(90,170,49,1) 45%,rgba(255,168,0,1) 55%,rgba(255,168,0,1) 70%,rgba(251,42,26,1) 80%);
    background:linear-gradient(to right,rgba(41,169,252,1) 20%,rgba(90,170,49,1) 30%,rgba(90,170,49,1) 45%,rgba(255,168,0,1) 55%,rgba(255,168,0,1) 70%,rgba(251,42,26,1) 80%);

    .effort-scale-marker {
      width: 2px;
      height: 14px;
      position: absolute;
      top: -4px;
      background: $color-gray-dark;

      /*
			width: 30px;
		  	background: rgba(255,255,255,0.33);
		  	height: 10px;
		  	margin-left: -15px;
		  	position: absolute;
		  	top: -3px;
		  	*/
      /*
		  	&:before {
			  	content: "";
			  	clear: both;
			  	width: 16px;
			  	height: 10px;
			  	position: absolute;
			  	top: 0;
			  	left: 7px;
			  	background: rgba(255,255,255,0.6);
		  	}
		  	&:after {
			  	content: "";
			  	clear: both;
			  	width: 2px;
			  	height: 14px;
			  	position: absolute;
			  	top: -5px;
			  	left: 11px;
			  	background: $color-gray-dark;
			  	border: 3px solid #fff;
		  	}
		  	*/
    }
  }
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#29a9fc',endColorstr='#fb2a1a',GradientType=1);
}

.diary-exercise-planed.intensity-1 {
  .diary-exercise-header {
    background: $color-blue;
  }
}
.diary-exercise-planed.intensity-2 {
  .diary-exercise-header {
    background: $color-green;
  }
}
.diary-exercise-planed.intensity-3 {
  .diary-exercise-header {
    background: $color-orange;
  }
}
.diary-exercise-planed.intensity-4 {
  .diary-exercise-header {
    background: $color-red;
  }
}

.diary-carousel {
  position: relative;

  .owl-nav {

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    .disabled {
      display: none;
    }
    .owl-next {
      background: none;
      padding: 0;
      margin: 0;
      float: right;

      .btn {
        background: rgba(0,0,0,0.1);
        color: $color-gray-dark;
        position: relative;
        top: -5px;
        right: -2px;
        border-radius: 30px 0 0 30px;
      }
    }
    .owl-prev {
      background: none;
      padding: 0;
      margin: 0;
      float: left;

      .btn {
        background: rgba(0,0,0,0.1);
        color: $color-gray-dark;
        position: relative;
        top: -5px;
        left: -2px;
        border-radius: 0 30px 30px 0;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .diary-wrapper{
    .diary-exercises {


      > .diary-item {
      }
    }
    .diary-item {
      width: 500px;
    }
    .diary-exercise-planed {
      box-sizing: border-box;
      width: 280px;
      @include box-shadow;
      transform: rotate(3deg);
      margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 20px;
      overflow: hidden;
      @include border-radius;

      .diary-exercise-item {
        font-size: 12px;
        padding: 5px 10px;
        line-height: 16px;


      }
      .diary-exercise-header {
        padding: 3px 10px;
        text-align: center;
        color: #fff;
        margin-bottom: 3px;
        display: block;
      }
    }

  }

  .trainingsplan-wrapper {
    .ac-header {
      .col-md-10 {
        float: inherit;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .diary-wrapper{

    .diary-item {
      width: 600px;
    }
    .diary-exercise-planed {
      width: 280px;
      margin-left: 10px;
    }
  }
}
@media only screen and (min-width: 1450px) {
  .diary-wrapper{

    .diary-item {
      width: 800px;
    }
    .diary-exercise-planed {
      width: 300px;
      margin-left: 20px;
    }
  }
}