#account-nav {
  .customer-account_logo__home {
    padding: 15px 0;
  }

  a, .nav.item.current strong {
    transition: all 0.1s ease-out;
    border-radius: 5px;
  }

  .nav.items {
    .nav-head {
      font-weight: 400;
      font-size: 12px;
      color: $color-blue;
      padding: 8px 0 0 15px;
      border-bottom: 1px solid rgba(255,255,255,0.1);
    }

    .nav {
      &.item, &.item.current {
        position: relative;
        border-bottom: none;
        padding: 2px;

        a, strong {
          padding: 0 10px 0 42px;
          height: 30px;
          line-height: 30px;

          &:hover span {
            color: #fff;
            opacity: 1;
          }

          span {
            font-size: 20px;
            top: 4px;
          }
        }
      }

      &.sub {
        div.item:before {
          top: 12px;
        }
      }

      &.nav-item-logout {
        a {
          height: auto;
          padding: inherit;

          .btn {
            box-shadow: none;
            padding: 0 10px;
            height: auto;
            line-height: inherit;
          }
        }
      }
    }

    .new-message {
      position: relative;
      display: inline-block;
      top: -5px !important;
      right: 0;
      left: 2px;
      background: $color-red;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      opacity: 1 !important;
    }
  }

  .account_nav_icons {
    display: flex;
    padding: 2px;

    a {
      color: #fff;
      display: block;
      box-sizing: border-box;
      text-align: center;
      flex-basis: 25%;
      text-decoration: none;
      padding: 0;
      height: 40px;
      line-height: 40px;
      border: 1px solid $color-gray-dark;

      &:hover {
        background: #000;
      }

      &.active {
        background: #000;
      }

      span {
        font-size: 20px;
      }

      .page-functions {
        position: relative;
        top: 0;
        right: 0;
      }

      .watch-connected {
        position: relative;
        margin-left: 0;

        &:hover {
          color: #fff;
        }

        &.active {
          &:after {
            content: "\edfb";
            display: block;
            position: absolute;
            font-size: 10px;
            background: $color-green;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            line-height: 16px;
            top: 1px;
            left: 50%;
          }
        }

        .open-popup-video {
          margin-left: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1170px) {
  #account-nav {
    .nav.items {
      .nav {
        &.item, &.item.current {
          a, strong {
            padding: 5px;
            line-height: inherit;
            height: auto;
            color: #fff;

            span {
              font-size: 22px;
              top: 0;
            }
          }
        }

        &.item {
          span {
            opacity: 0.2;
          }
        }

        &.item.current {
          span {
            opacity: 1;
          }
        }

        &.sub div.item:before {
          top: 15px;
          left: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #account-nav {
    .nav.items {
      .nav {
        &.item, &.item.current {
          a, strong {
            padding: 10px;
            font-size: 20px;

            span {
              font-size: 22px;
              top: 2px;
              margin-right: 6px;
            }
          }
        }

        &.sub {
          a {
            padding: 10px 10px 10px 21px;
          }

          div.item:before {
            top: 20px;
            left: 7px;
          }
        }
      }
    }
  }
}