@mixin transition($content: all 0.3s ease) {
  -webkit-transition: $content;
  -moz-transition: $content;
  -ms-transition: $content;
  -o-transition: $content;
  transition: $content;
}

@mixin box-shadow {
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
}
@mixin border-radius($content: 5px) {
  border-radius: $content;
  -moz-border-radius: $content;
  -webkit-border-radius: $content;
}
@mixin animate-all-fast {
  transition: all 0.1s ease-out;
}
@mixin animate-all-med {
  transition: all 1s ease-out;
}
@mixin animate-all-slow {
  transition: all 2s ease-out;
}
@mixin boxsize($content: border-box) {
  -webkit-box-sizing: $content;
  -moz-box-sizing: $content;
  -ms-box-sizing: $content;
  -o-box-sizing: $content;
  box-sizing: $content;
}
@mixin icomoon-settings {
  text-align: center;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}